import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button";
import logout from "js_utils/logout";
import CardFooter from "components/Card/CardFooter";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.js";
const {
  getAutenticado,
  postAutenticado,
  mascaraCNPJ,
  formataValorReais,
  formataDataHora,
} = require("../../js_utils/util");
const { API_URL } = require("../../consts");

const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function EstornoParcela() {
  const classes = useStyles();
  let baixas = [];
  const [baixasDado, setBaixasDado] = useState([[]]);
  let lojas = [];
  const [lojaData, setLojaData] = useState([[]]);
  const [etapa, setEtapa] = useState(1);
  const [baixaSelecionada, setBaixaSelecionada] = useState({});
  const [btnEstorno, setBtnEstorno] = useState(true);
  const [confirmacaoBaixa, setConfirmacaoBaixa] = useState(1);

  //Snackbar
  let [mensagemErro, setMensagemErro] = useState("");
  const [alerta, setAlerta] = useState(false);
  const [cor, setCor] = useState("warning");
  const showNotification = () => {
    if (!alerta) {
      setAlerta(true);
      setTimeout(function () {
        setAlerta(false);
      }, 6000);
    }
  };

  //
  const selecionaBaixa = (baixa) => {
    setBaixaSelecionada(baixa);
    setEtapa(3);
  };

  const visualisaBaixasEstab = async (id) => {
    let resultado;
    try {
      resultado = await getAutenticado(API_URL + "baixas/" + id);
      baixas = resultado.data.resultados;
      let items = [];
      baixas.forEach((element) => {
        const baix = {
          ID: element.ID,
          NOME: element.NOME,
          PROPOSTA: element.PROPOSTA,
          NUMERO: element.NUMERO,
          VLR_BAIXA: element.VLR_BAIXA,
          VLR_TOTAL_BAIXA: element.VLR_TOTAL_BAIXA,
          DT_HORA_BAIXA: formataDataHora(element.DT_HORA_BAIXA),
        };
        items.push([
          element.NOME,
          element.PROPOSTA,
          element.NUMERO,
          formataValorReais(element.VLR_BAIXA),
          formataValorReais(element.VLR_TOTAL_BAIXA),
          formataDataHora(element.DT_HORA_BAIXA),
          <Button
            key={element.ID}
            color="danger"
            onClick={() => {
              selecionaBaixa(baix);
            }}
          >
            ESTORNAR
          </Button>,
        ]);
      });
      setBaixasDado(items);
    } catch (err) {
      console.log(err);
      logout();
    }
    setEtapa(2);
  };
  const realizaBaixa = async () => {
    setBtnEstorno(false);
    try {
      await postAutenticado(API_URL + "estornoparcela", {
        baixaID: baixaSelecionada.ID,
      });
      setCor("success");
      setMensagemErro("Baixa Realizada!");
      showNotification();
      setEtapa(1);
    } catch (err) {
      console.log(err);
      setCor("danger");
      setMensagemErro(
        err.response.data.mensagem || "Erro ao realizar o estorno"
      );
      showNotification();
    } finally {
      setBtnEstorno(true);
      setConfirmacaoBaixa(1);
    }
  };
  useEffect(async () => {
    let resultado;
    try {
      resultado = await getAutenticado(API_URL + "estabelecimentos");
      lojas = resultado.data.resultados;
      let items = [];
      lojas.forEach((element) => {
        items.push([
          mascaraCNPJ(element.CNPJ),
          element.RAZAO_SOCIAL,
          element.LOGRADOURO + ", " + element.NUMERO,
          element.CIDADE + "/" + element.SIGLA,
          <Button
            color="info"
            key={element.ID}
            onClick={() => visualisaBaixasEstab(element.ID)}
          >
            Parcelas
          </Button>,
        ]);
      });
      setLojaData(items);
    } catch (err) {
      console.log(err);
      logout();
    }
  }, []);
  const cardEstabs = (
    <CardBody>
      <Table
        tableHeaderColor="primary"
        tableHead={["CNPJ", "Razão Social", "Endereço", "Cidade/UF", "..."]}
        tableData={lojaData}
      ></Table>
    </CardBody>
  );
  const cardBaixas = (
    <CardBody>
      <Table
        tableHeaderColor="primary"
        tableHead={[
          "Cliente",
          "Proposta",
          "Numero",
          "Valor Baixa",
          "Valor Total Recebido",
          "Data Baixa",
        ]}
        tableData={baixasDado}
      ></Table>
    </CardBody>
  );

  const selecParcela = (
    <CardBody>
      <table>
        <tbody>
          <tr>
            <td>Cliente:</td>
            <td>{baixaSelecionada.NOME}</td>
          </tr>
          <tr>
            <td>Proposta:</td>
            <td>{"#" + baixaSelecionada.PROPOSTA}</td>
          </tr>
          <tr>
            <td>Parcela número:</td>
            <td>{baixaSelecionada.NUMERO}</td>
          </tr>
          <tr>
            <td>Valor Baixado:</td>
            <td>{formataValorReais(baixaSelecionada.VLR_BAIXA)}</td>
          </tr>
          <tr>
            <td>Valor Total Recebido:</td>
            <td>{formataValorReais(baixaSelecionada.VLR_TOTAL_BAIXA)}</td>
          </tr>
          <tr>
            <td>Data Baixa: </td>
            <td>{baixaSelecionada.DT_HORA_BAIXA}</td>
          </tr>
        </tbody>
      </table>

      <br></br>
      {confirmacaoBaixa === 2 ? (
        <div>
          Confirma o estorno da baixa?<br></br>
          <Button color="danger" disabled={!btnEstorno} onClick={realizaBaixa}>
            Confirmar
          </Button>
        </div>
      ) : (
        <Button color="danger" onClick={() => setConfirmacaoBaixa(2)}>
          Estornar Baixa
        </Button>
      )}
    </CardBody>
  );
  const botaoVoltar = (
    <Button
      color="info"
      onClick={() => {
        setEtapa(1);
        setConfirmacaoBaixa(1);
      }}
    >
      Voltar
    </Button>
  );

  return (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>
            Estorno de Parcelas Recebidas
          </h4>
          <p className={classes.cardCategoryWhite}></p>
        </CardHeader>
        {etapa == 1 ? cardEstabs : etapa == 2 ? cardBaixas : selecParcela}
        <CardFooter>{etapa == 2 || etapa == 3 ? botaoVoltar : null}</CardFooter>
      </Card>
      <Snackbar
        place="bc"
        color={cor || "warning"}
        icon={AddAlert}
        message={mensagemErro}
        open={alerta}
        closeNotification={() => setAlerta(false)}
        close
      />
    </div>
  );
}
