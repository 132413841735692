import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Logout from "components/Logout";
//import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import Table from "components/Table/Table.js";
import SimplesFiltro from "components/Filter/SimplesFiltro";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardBody from "components/Card/CardBody";
import logout from "js_utils/logout";
import { TextField } from "@material-ui/core";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";

const {
    getAutenticado,
    formataValorReais,
    formataData,
    postAutenticado,
} = require("../../js_utils/util");

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0",
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
    },
};

const useStyles = makeStyles(styles);

export default function Renegociacao() {
    const { API_URL } = require("../../consts");
    let [logado, setLogado] = useState(true);
    const [nomePesquisa, setNomePesquisa] = useState('');
    const [estabelecimentos, setEstabelecimentos] = useState([]);
    const [selecionado, setSelecionado] = useState(null);
    const [parcelasDado, setParcelasDado] = useState([])
    const [parcelasSelecionadas, setParcelasSelecionadas] = useState([]);
    const [nomeCliente, setNomeCliente] = useState(null);
    const [numParcelas, setNumeroParcelas] = useState(1)

    const [juros, setJuros] = useState(0)
    const [desconto, setDesconto] = useState(0)

    const mudaValor = (e) => {
        let valor = e.target.value;
        valor = valor.replace(/\D/g, "");
        valor = parseFloat(valor) || 0.0;
        valor = valor / 100.0;
        return valor;
    };

    //Snackbar
    let [mensagemErro, setMensagemErro] = useState("");
    const [alerta, setAlerta] = useState(false);
    const [cor, setCor] = useState("warning");
    const showNotification = () => {
        if (!alerta) {
            setAlerta(true);
            setTimeout(function () {
                setAlerta(false);
            }, 6000);
        }
    };
    const mostraMensagem = (msg, cor) => {
        setCor(cor);
        setMensagemErro(msg);
        showNotification();
    };

    const confirmaRenegociacao = async () => {
        try {
            const resultado = await postAutenticado(API_URL + "renegociaparcelasfixas", {
                juros,
                desconto,
                parcelas: parcelasSelecionadas.map(p => p.ID),
                numParcelas
            })
            if (resultado && resultado.data.sucesso) {
                mostraMensagem(resultado.data.mensagem, "success")
            } else if (resultado.data.mensagem) {
                mostraMensagem(resultado.data.mensagem, "danger")
            } else {
                mostraMensagem("Erro interno", "danger")
            }

        } catch (err) {
            mostraMensagem(err, "danger")
        }
    }

    const visualisaParcelasEstab = async () => {
        if (!selecionado) return;
        let resultado;
        try {
            resultado = await getAutenticado(API_URL + "parcelasaberto/" + selecionado);
            const parcelas = resultado.data.resultados;
            let items = [];
            parcelas.forEach((element) => {
                const parc = {
                    ID: element.ID,
                    NOME: element.NOME,
                    PROPOSTA: element.PROPOSTA,
                    NUMERO: element.NUMERO,
                    VLR_PARCELA: parseFloat(element.VLR_PARCELA),
                    VLR_SALDO: parseFloat(element.VLR_SALDO),
                    VENCIMENTO: formataData(element.VENCIMENTO),
                };
                if (element.STATUS_PARCELA === 'N') {
                    items.push(parc);
                }
            });
            setParcelasDado(items);
        } catch (err) {
            console.log(err);
            setLogado(false);
            logout();
        }
    };


    const buscaEstabs = async () => {
        let resultado
        try {
            resultado = await getAutenticado(API_URL + "estabelecimentos");
            setEstabelecimentos(resultado.data.resultados)
        } catch (err) {
            mostraMensagem(err.message, "danger")
            // logout();
        }
    }
    useEffect(buscaEstabs, []);
    useEffect(visualisaParcelasEstab, [selecionado]);
    const classes = useStyles();
    return logado ? (
        <div>
            <GridContainer>
                <GridItem sm={6}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className={classes.cardTitleWhite}>Selecionar Parcelas</h4>
                        </CardHeader>
                        <CardBody>
                            <SimplesFiltro itens={estabelecimentos} setIds={setSelecionado} chaveLabel="RAZAO_SOCIAL" chaveID="ID" />
                            <TextField
                                label="Pesquisar Nome"
                                onChange={(e) => {
                                    const { value } = e.target;
                                    setNomePesquisa(value);
                                }}
                                fullWidth={true}
                                value={nomePesquisa}
                            />
                            <Table
                                tableHeaderColor="primary"
                                tableHead={[
                                    "Cliente",
                                    "Proposta",
                                    "Numero",
                                    "Valor",
                                    "Saldo a Receber",
                                    "Vencimento",
                                    "..."
                                ]}
                                tableData={
                                    (nomePesquisa && nomePesquisa.length > 0
                                        ? parcelasDado.filter((dado) =>
                                            dado.NOME.match(new RegExp(nomePesquisa, "i"))
                                        )
                                        : parcelasDado).map(a => [a.NOME, a.PROPOSTA, a.NUMERO, formataValorReais(a.VLR_PARCELA), formataValorReais(a.VLR_SALDO), a.VENCIMENTO,
                                        <Button color="success" key={a.ID}
                                            onClick={() => {
                                                setParcelasSelecionadas(prevState => {
                                                    if (!nomeCliente) {
                                                        setNomeCliente(a.NOME)
                                                    } else if (nomeCliente !== a.NOME) {
                                                        mostraMensagem("Não é possível selecionar parcelas de vários clientes", "danger")
                                                        return prevState;
                                                    }
                                                    if (prevState.find(el => el.ID === a.ID))
                                                        return prevState;
                                                    return [...prevState, a]
                                                })
                                            }}
                                        >
                                            Selec.
                                        </Button>
                                        ])
                                }
                            />
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem sm={6}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className={classes.cardTitleWhite}>Parcelas Selecionadas</h4>
                            <span className={classes.cardTitleWhite}>{nomeCliente}</span>
                        </CardHeader>
                        <CardBody>
                            <Table
                                tableHeaderColor="primary"
                                tableHead={[
                                    "Cliente",
                                    "Proposta",
                                    "Numero",
                                    "Valor",
                                    "Saldo a Receber",
                                    "Vencimento",
                                    "..."
                                ]}
                                tableData={parcelasSelecionadas.map(a => [a.NOME, a.PROPOSTA, a.NUMERO, formataValorReais(a.VLR_PARCELA), formataValorReais(a.VLR_SALDO), a.VENCIMENTO,
                                <Button key={a.ID}
                                    color="warning"
                                    onClick={() => {
                                        setParcelasSelecionadas(prevState => {
                                            if (prevState.length <= 1)
                                                setNomeCliente(null)
                                            return prevState.filter(el => el.ID !== a.ID)
                                        })
                                    }}
                                >
                                    Remove
                                </Button>
                                ])}
                            />
                            <GridContainer >
                                <div>

                                    <TextField
                                        label="Juros"
                                        onChange={(e) => setJuros(mudaValor(e))}
                                        value={formataValorReais(juros)}

                                    />
                                    <TextField
                                        label="Desconto"
                                        onChange={(e) => setDesconto(mudaValor(e))}
                                        value={formataValorReais(desconto)}
                                    />
                                    <TextField
                                        label="Parcelas"
                                        value={numParcelas}
                                        onChange={(e) => setNumeroParcelas(parseInt(e.target.value.replace(/\D/g, "")))}
                                    />
                                    <TextField
                                        label="Total"
                                        value={formataValorReais(juros - desconto + parcelasSelecionadas.reduce((prev, curr) => prev + curr.VLR_SALDO, 0.0))}

                                    />
                                </div>
                                <div>
                                    <Button color="primary" onClick={confirmaRenegociacao} >Renegociar</Button>
                                </div>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
            <Snackbar
                place="bc"
                color={cor || "warning"}
                icon={AddAlert}
                message={mensagemErro}
                open={alerta}
                closeNotification={() => setAlerta(false)}
                close
            />
        </div>
    ) : (
        <Logout></Logout>
    );
}
