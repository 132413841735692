import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Logout from "components/Logout";
import Button from "components/CustomButtons/Button.js";
import { TextField } from "@material-ui/core";
import AddAlert from "@material-ui/icons/AddAlert";
import Table from "components/Table/Table.js";
import Snackbar from "components/Snackbar/Snackbar.js";
const { API_URL } = require("../../consts");
const {
  getAutenticado,
  postAutenticado,
  validaUsuario,
} = require("../../js_utils/util");

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  inputMargin: {
    marginRight: "5px",
  },
};

const useStyles = makeStyles(styles);

const Usuarios = (props) => {
  let [logado, setLogado] = useState(true);
  let [mensagemErro, setMensagemErro] = useState("");
  const [usuarios, setUsuarios] = useState([]);
  const [usuarioAlteracao, setUsuarioAlteracao] = useState(null);
  const [novaSenha, setNovaSenha] = useState("");
  const [novaSenhaConf, setNovaSenhaConf] = useState("");
  const [novoUsuario, setNovoUsuario] = useState(null);
  const [alerta, setAlerta] = useState(false);
  const classes = useStyles();

  const mostraErro = (mensagem) => {
    setCor("danger");
    setMensagemErro(mensagem);
    showNotification();
  };

  const mostraAcerto = (mensagem) => {
    setCor("success");
    setMensagemErro(mensagem);
    showNotification();
  };

  const [cor, setCor] = useState("warning");
  const showNotification = () => {
    if (!alerta) {
      setAlerta(true);
      setTimeout(function () {
        setAlerta(false);
      }, 6000);
    }
  };

  const alteracaoDados = (e) => {
    const { name, value } = e.target;
    if (name === "nova-senha") {
      setNovaSenha(value);
    } else if (name === "senha-conf") {
      setNovaSenhaConf(value);
    }
  };

  const carregaDados = async () => {
    let resultado;
    try {
      resultado = await getAutenticado(
        API_URL + `usuarios/${props.estabelecimento.ID}`
      );
      resultado = resultado.data;
      setUsuarios(resultado.resultados);
    } catch (error) {
      if (error.response && error.response.status == 401) setLogado(false);
    }
  };
  useEffect(() => {
    carregaDados();
  }, []);

  const tentaAlterarSenha = async () => {
    if (novaSenha.length < 6) {
      mostraErro("Senha muito curta. Pelo menos 6 caracteres! ");
    } else if (novaSenha != novaSenhaConf) {
      mostraErro("As senhas não conferem!");
    } else {
      try {
        let resultado = await postAutenticado(API_URL + "senhausuario", {
          userID: usuarioAlteracao.ID,
          password: novaSenha,
          passwordConfirm: novaSenhaConf,
        });
        resultado = resultado.data;
        if (resultado.sucesso) {
          mostraAcerto("Senha alterada com sucesso!");
        } else {
          mostraErro(resultado.smensagem);
        }
      } catch (error) {
        if (error.message) {
          mostraErro(error.message);
        }
      } finally {
        setNovaSenha("");
        setNovaSenhaConf("");
        setUsuarioAlteracao(null);
      }
    }

    return null;
  };

  const onChangeNovoUsuario = (e) => {
    let { name, value } = e.target;

    if (name == "LOGIN") {
      value = value.toLowerCase();
    }
    setNovoUsuario((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const tentaCriarUsuario = async () => {
    setNovoUsuario((prevState) => ({
      ...prevState,
      ESTABELECIMENTO: props.estabelecimento.ID,
    }));
    const errors = validaUsuario(novoUsuario);
    if (errors.length > 0) {
      let msg = errors.join("\n");
      mostraErro(msg);
    } else {
      let resultado = null;
      try {
        resultado = await postAutenticado(API_URL + "addusuario", {
          usuario: novoUsuario,
        });
        if (
          resultado.data &&
          resultado.data.sucesso &&
          resultado.data.mensagem
        ) {
          mostraAcerto(resultado.data.mensagem);
        } else if (resultado.data && resultado.data.mensagem) {
          mostraErro(resultado.data.mensagem);
        } else {
          mostraErro("Erro interno. Tente mais tarde");
        }
      } catch (e) {
        mostraErro(e.message);
      }
      carregaDados();
    }
  };

  const alteracaosenha = (usuario) => {
    return usuarioAlteracao && usuarioAlteracao.ID === usuario.ID ? (
      <span>
        <TextField
          label="Nova senha"
          autoComplete="new-password"
          type="password"
          name="nova-senha"
          onChange={alteracaoDados}
        ></TextField>
        <TextField
          label="Confirme a senha"
          type="password"
          autoComplete="new-password"
          name="senha-conf"
          onChange={alteracaoDados}
        ></TextField>
        <Button color="success" onClick={tentaAlterarSenha}>
          Confirma
        </Button>
        <Button color="warning" onClick={() => setUsuarioAlteracao(null)}>
          Cancelar
        </Button>
      </span>
    ) : (
      <Button color="info" onClick={() => setUsuarioAlteracao(usuario)}>
        Alterar Senha
      </Button>
    );
  };

  const tabelaUsuarios = () => {
    return (
      <Table
        tableHeaderColor="primary"
        tableHead={["Nome", "Login", "..."]}
        tableData={usuarios.map((u) => [u.NOME, u.LOGIN, alteracaosenha(u)])}
      ></Table>
    );
  };

  const viewNovoUsuario = () => {
    return novoUsuario ? (
      <GridContainer>
        <GridItem>
          <span>
            <TextField
              name="NOME"
              label="Nome Usuário"
              autoComplete="new-password"
              value={novoUsuario.NOME}
              onChange={onChangeNovoUsuario}
              style={styles.inputMargin}
            ></TextField>
            <TextField
              name="LOGIN"
              label="Novo Login"
              autoComplete="new-password"
              value={novoUsuario.LOGIN}
              onChange={onChangeNovoUsuario}
            ></TextField>
          </span>
        </GridItem>
        <GridItem>
          <span>
            <TextField
              name="SENHA"
              type="password"
              label="Senha"
              autoComplete="new-password"
              value={novoUsuario.SENHA}
              onChange={onChangeNovoUsuario}
              style={styles.inputMargin}
            ></TextField>
            <TextField
              name="SENHA_CONFIRMA"
              type="password"
              label="Confirma Senha"
              autoComplete="new-password"
              value={novoUsuario.SENHA_CONFIRMA}
              onChange={onChangeNovoUsuario}
            ></TextField>
          </span>
        </GridItem>
        <GridItem style={{ float: "right" }}>
          <span>
            <Button color="success" onClick={tentaCriarUsuario}>
              Criar Usuário
            </Button>
            <Button color="warning" onClick={() => setNovoUsuario(null)}>
              Cancelar
            </Button>
          </span>
        </GridItem>
      </GridContainer>
    ) : (
      <Button
        color="success"
        onClick={() =>
          setNovoUsuario({ ESTABELECIMENTO: props.estabelecimento.ID })
        }
      >
        {" "}
        Novo Usuário
      </Button>
    );
  };

  const viewUsuarios = (
    <GridContainer>
      <Card>
        <CardHeader color="primary">
          <h3 className={classes.cardTitleWhite}>
            {props.estabelecimento.RAZAO_SOCIAL}
          </h3>
          <h5 className={classes.cardTitleWhite}>
            {props.estabelecimento.NOME_FANTASIA}
          </h5>
        </CardHeader>
        <CardBody>
          {viewNovoUsuario()}
          {tabelaUsuarios()}
        </CardBody>
      </Card>
      <Button onClick={props.volta} color="info">
        Volta
      </Button>
    </GridContainer>
  );

  return logado ? (
    <div>
      {viewUsuarios}
      <Snackbar
        place="bc"
        color={cor || "warning"}
        icon={AddAlert}
        message={mensagemErro}
        open={alerta}
        closeNotification={() => setAlerta(false)}
        close
      />
    </div>
  ) : (
    <Logout></Logout>
  );
};

Usuarios.propTypes = {
  estabelecimento: PropTypes.shape({
    ID: PropTypes.number,
    RAZAO_SOCIAL: PropTypes.string,
    NOME_FANTASIA: PropTypes.string,
  }),
  volta: PropTypes.func,
};

export default Usuarios;
