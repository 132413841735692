import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button";
import logout from "js_utils/logout";
import MenuItem from "@material-ui/core/MenuItem";
import CardFooter from "components/Card/CardFooter";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.js";
import { Select, TextField } from "@material-ui/core";
const {
  getAutenticado,
  postAutenticado,
  mascaraCNPJ,
  formatSQLDate,
  formataValorReais,
} = require("../../js_utils/util");
const { API_URL } = require("../../consts");

const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function BaixaParcela() {
  const classes = useStyles();
  let parcelas = [];
  const [parcelasDado, setParcelasDado] = useState([[]]);
  let lojas = [];
  const [lojaData, setLojaData] = useState([[]]);
  const [etapa, setEtapa] = useState(1);
  const [parcelaSelecionada, setParcelaSelecionada] = useState({});
  const [vlrBaixa, setVlrBaixa] = useState([]);
  const [contas, setContas] = useState({});
  const [contaSelecionada, setContaSelecionada] = useState({});
  const [btnBaixa, setBtnBaixa] = useState(true);

  //Snackbar
  let [mensagemErro, setMensagemErro] = useState("");
  const [alerta, setAlerta] = useState(false);
  const [cor, setCor] = useState("warning");
  const showNotification = () => {
    if (!alerta) {
      setAlerta(true);
      setTimeout(function () {
        setAlerta(false);
      }, 6000);
    }
  };

  //
  const buscaContas = async () => {
    try {
      const resultados = await getAutenticado(API_URL + "contas");
      setContas(resultados.data.resultados);
      //PERIGOSO SE NÃO HOUVER PELO MENOS UMA CONTA
      setContaSelecionada(resultados.data.resultados[0]);
    } catch (err) {
      console.log(err);
      logout();
    }
  };
  const selecionaBaixa = (parc) => {
    setParcelaSelecionada(parc);
    setVlrBaixa({
      vlrBaixa: parseFloat(parc.VLR_SALDO),
      vlrJuros: 0.0,
      vlrDesconto: 0.0,
    });
    setEtapa(3);
  };
  const mudaValor = (e) => {
    let valor = e.target.value;
    valor = valor.replace(/\D/g, "");
    valor = parseFloat(valor) || 0.0;
    valor = valor / 100.0;
    let nome = e.target.name;
    setVlrBaixa((prevState) => ({
      ...prevState,
      [nome]: valor,
    }));
    console.log(nome + " " + valor);
  };
  const visualisaParcelasEstab = async (id) => {
    await buscaContas();
    let resultado;
    try {
      resultado = await getAutenticado(API_URL + "parcelasaberto/" + id);
      parcelas = resultado.data.resultados;
      let items = [];
      parcelas.forEach((element) => {
        const parc = {
          ID: element.ID,
          NOME: element.NOME,
          PROPOSTA: element.PROPOSTA,
          NUMERO: element.NUMERO,
          VLR_PARCELA: element.VLR_PARCELA,
          VLR_SALDO: element.VLR_SALDO,
          VENCIMENTO: formatSQLDate(element.VENCIMENTO),
        };
        if (element.STATUS_PARCELA === 'N')
          items.push([
            element.NOME,
            element.PROPOSTA,
            element.NUMERO,
            formataValorReais(element.VLR_PARCELA),
            formataValorReais(element.VLR_SALDO),
            formatSQLDate(element.VENCIMENTO),
            <Button
              key={element.ID}
              color="success"
              onClick={() => {
                selecionaBaixa(parc);
              }}
            >
              BAIXA
            </Button>,
          ]);
      });
      setParcelasDado(items);
    } catch (err) {
      console.log(err);
      logout();
    }
    setEtapa(2);
  };
  const realizaBaixa = async () => {
    setBtnBaixa(false);
    console.log(contaSelecionada);
    console.log(parcelaSelecionada);
    try {
      await postAutenticado(API_URL + "baixaparcela", {
        parcelaID: parcelaSelecionada.ID,
        contaID: contaSelecionada.ID,
        ...vlrBaixa,
      });
      setCor("success");
      setMensagemErro("Baixa Realizada!");
      showNotification();
      setEtapa(1);
    } catch (err) {
      console.log(err);
      setCor("danger");
      setMensagemErro(err.response.data.mensagem || "Erro ao realizar a baixa");
      showNotification();
    } finally {
      setBtnBaixa(true);
    }
  };
  useEffect(async () => {
    let resultado;
    try {
      resultado = await getAutenticado(API_URL + "estabelecimentos");
      lojas = resultado.data.resultados;
      let items = [];
      lojas.forEach((element) => {
        items.push([
          mascaraCNPJ(element.CNPJ),
          element.RAZAO_SOCIAL,
          element.LOGRADOURO + ", " + element.NUMERO,
          element.CIDADE + "/" + element.SIGLA,
          <Button
            color="info"
            key={element.ID}
            onClick={() => visualisaParcelasEstab(element.ID)}
          >
            Parcelas
          </Button>,
        ]);
      });
      setLojaData(items);
    } catch (err) {
      console.log(err);
      logout();
    }
  }, []);
  const cardEstabs = (
    <CardBody>
      <Table
        tableHeaderColor="primary"
        tableHead={["CNPJ", "Razão Social", "Endereço", "Cidade/UF", "..."]}
        tableData={lojaData}
      ></Table>
    </CardBody>
  );

  const [nomePesquisa, setNomePesquisa] = useState("");
  const cardParcelas = (
    <CardBody>
      <TextField
        label="Pesquisar Nome"
        onChange={(e) => {
          const { value } = e.target;
          setNomePesquisa(value);
        }}
        fullWidth={true}
        value={nomePesquisa}
      ></TextField>
      <Table
        tableHeaderColor="primary"
        tableHead={[
          "Cliente",
          "Proposta",
          "Numero",
          "Valor",
          "Saldo a Receber",
          "Vencimento",
        ]}
        tableData={
          nomePesquisa && nomePesquisa.length > 0
            ? parcelasDado.filter((dado) =>
              dado[0].match(new RegExp(nomePesquisa, "i")),
            )
            : parcelasDado
        }
      ></Table>
    </CardBody>
  );
  const listaContas = () => {
    let items = [];
    for (let i = 0; i < contas.length; i++)
      items.push(
        <MenuItem key={i} value={contas[i]}>
          {contas[i].NUM_CONTA + "-" + contas[i].DV_CONTA}
        </MenuItem>,
      );
    return items;
  };
  const selecParcela = (
    <CardBody>
      <table>
        <tbody>
          <tr>
            <td>Cliente:</td>
            <td>{parcelaSelecionada.NOME}</td>
          </tr>
          <tr>
            <td>Proposta:</td>
            <td>{"#" + parcelaSelecionada.PROPOSTA}</td>
          </tr>
          <tr>
            <td>Parcela número:</td>
            <td>{parcelaSelecionada.NUMERO}</td>
          </tr>
          <tr>
            <td>Saldo a Pagar:</td>
            <td>{formataValorReais(parcelaSelecionada.VLR_SALDO)}</td>
          </tr>
          <tr>
            <td>Vencimento: </td>
            <td>{parcelaSelecionada.VENCIMENTO}</td>
          </tr>
        </tbody>
      </table>
      <TextField
        label="Vlr. Baixa"
        name="vlrBaixa"
        value={formataValorReais(vlrBaixa.vlrBaixa)}
        onChange={(e) => mudaValor(e)}
      />
      <TextField
        label="Vlr. Juros"
        name="vlrJuros"
        value={formataValorReais(vlrBaixa.vlrJuros)}
        onChange={(e) => mudaValor(e)}
      />
      <TextField
        label="Vlr. Desconto"
        name="vlrDesconto"
        value={formataValorReais(vlrBaixa.vlrDesconto)}
        onChange={(e) => mudaValor(e)}
      />

      <h4>
        Valor Total:{" "}
        {formataValorReais(
          vlrBaixa.vlrBaixa + vlrBaixa.vlrJuros - vlrBaixa.vlrDesconto,
        )}
      </h4>
      <h4>
        Conta:
        <Select
          style={{ marginLeft: 5 }}
          value={contaSelecionada}
          onChange={(e) => setContaSelecionada(e.target.value)}
        >
          {listaContas()}
        </Select>
      </h4>
      <br></br>
      <Button color="success" disabled={!btnBaixa} onClick={realizaBaixa}>
        Baixar Parcela
      </Button>
    </CardBody>
  );
  const botaoVoltar = (
    <Button color="info" onClick={() => setEtapa(1)}>
      Voltar
    </Button>
  );

  return (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>
            Baixa de Parcelas Recebidas
          </h4>
          <p className={classes.cardCategoryWhite}></p>
        </CardHeader>
        {etapa == 1 ? cardEstabs : etapa == 2 ? cardParcelas : selecParcela}
        <CardFooter>{etapa == 2 || etapa == 3 ? botaoVoltar : null}</CardFooter>
      </Card>
      <Snackbar
        place="bc"
        color={cor || "warning"}
        icon={AddAlert}
        message={mensagemErro}
        open={alerta}
        closeNotification={() => setAlerta(false)}
        close
      />
    </div>
  );
}
