import { React, useState } from "react";
import PropTypes from "prop-types";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button";
import DatePicker from "react-datepicker";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import { TextField } from "@material-ui/core";

const {
  formataData,
  mascaraPhone,
  cpfMask,
  formataDataHora,
  formataValorReais,
  atualizaValor,
} = require("../../js_utils/util");

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  modalBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    overflowX: "auto",
    overflowY: "auto",
    backgroundColor: "white",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
};

const styleQuitado = {
  color: "green",
};
const styleVencido = {
  color: "red",
};
const styleProtestado = {
  color: "purple",
  fontWeight: "bold",
};
const styleRenegociado = {
  color: "blue",
  fontWeight: "bold",
};
const styleCobrancaExterna = {
  color: "yellow",
  backgroundColor: "gray",
  fontWeight: "bold",
};
const msgImportantAlert = {
  color: "red",
  fontWeight: "bold",
};

const msgWarning = {
  color: "#aa0000",
  fontWeight: "bold",
  fontSize: "1.2rem",
};

const useStyles = makeStyles(styles);
const EmprestimoCard = (props) => {
  const classes = useStyles();
  const dataAtual = new Date();
  const {
    emprestimo,
    tentaAlterarVencimento,
    setDados,
    setObsAdmin,
    setModalObsVisible,
    setModalProtVisible,
    setPropostaelecionada,
    setPropObsId,
    toggleCliSelecionado,
    idCliSelecionado,
    mostraErro,
    verificaPossibilidadeExclusao,
    excluiParcela,
    desfazRenegociacao,
    setModalCobrancaVisible,
    enviaBoleto,
    apagaBoleto,
  } = props;
  const [excluindo, setExcluindo] = useState(false);
  const [enviandoBoleto, setEnviandoBoleto] = useState(false);
  const [observacaoBoleto, setObservacaoBoleto] = useState();
  const [removendoBoleto, setRemovendoBoleto] = useState(false);
  const [mostraAtual, setMostraAtual] = useState(false);
  return (
    <Card key={emprestimo.ID}>
      <CardHeader
        color={emprestimo.TIPO_NEGOCIACAO === "E" ? "primary" : "info"}
      >
        <p className={classes.cardTitleWhite}>Proposta: #{emprestimo.ID}</p>
        <p>Cliente: {emprestimo.NOME_CLIENTE}</p>
        <p>Telefone: {mascaraPhone(emprestimo.TELEFONE)}</p>
        <p>CPF: {cpfMask(emprestimo.CPF)}</p>
        <p>Valor Venda: {emprestimo.VALOR_VENDA}</p>
        <p>Valor Total Parcelas: {emprestimo.VALOR_PARCELAS}</p>
        <p>Obs.: {emprestimo.OBSERVACAO}</p>
        <p>Loja.: {emprestimo.NOME_ESTAB}</p>
        <p>Fantasia: {emprestimo.NOME_FANTASIA}</p>
      </CardHeader>
      <CardBody>
        <div
          onClick={() => setMostraAtual(!mostraAtual)}
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            fontWeight: "bold",
          }}
        >
          Valores: {mostraAtual ? "atualizados" : "nominais"}
        </div>
        <table>
          <thead>
            <tr>
              <td>Parcela</td>
              <td>Vencimento</td>
              <td>Valor</td>
              {mostraAtual ? <td>Vlr. Atual</td> : <td>Em aberto</td>}
              <td>Prorrogar</td>
            </tr>
          </thead>
          <tbody>
            {emprestimo.rows.map((parc) => {
              let date = new Date(parc.VENCIMENTO);
              return (
                <tr
                  style={
                    parc.VLR_SALDO == 0.0
                      ? styleQuitado
                      : parc.DATA_COBRANCA_EXTERNA
                      ? styleCobrancaExterna
                      : parc.DATA_PROTESTO
                      ? styleProtestado
                      : dataAtual.toISOString().substring(0, 10) >
                          parc.VENCIMENTO && parc.VLR_SALDO > 0.0
                      ? styleVencido
                      : parc.STATUS_PARCELA === "R"
                      ? styleRenegociado
                      : null
                  }
                  key={parc.ID_PARCELA}
                >
                  <td>
                    {parc.NUMERO + (parc.STATUS_PARCELA === "R" ? "R" : "")}
                  </td>
                  <td>{formataData(parc.VENCIMENTO)}</td>
                  <td>{parc.VLR_PARCELA} </td>
                  {!mostraAtual && (
                    <td style={{ textAlign: "right" }}>{parc.VLR_SALDO}</td>
                  )}
                  {mostraAtual && (
                    <td>
                      {" "}
                      {!parc.DATA_COBRANCA_EXTERNA &&
                        !parc.DATA_PROTESTO &&
                        parc.STATUS_PARCELA !== "R" && (
                          <span style={{ fontWeight: "bold" }}>
                            {formataValorReais(
                              atualizaValor(parc.VLR_SALDO, parc.VENCIMENTO)
                            )}
                          </span>
                        )}
                    </td>
                  )}
                  <td>
                    {parc.VLR_PARCELA === parc.VLR_SALDO &&
                    parc.STATUS_PARCELA !== "R" ? (
                      <DatePicker
                        style={{ backgroundColor: "#010101" }}
                        selected={date}
                        onChange={async (value) => {
                          const res = await tentaAlterarVencimento(
                            parc.ID_PARCELA,
                            value
                          );
                          if (res) {
                            setDados((prevDados) => {
                              const dadosA = prevDados.data.filter(
                                (d) => d.ID < emprestimo.ID
                              );

                              const dadosB = prevDados.data.filter(
                                (d) => d.ID > emprestimo.ID
                              );

                              const em = emprestimo;
                              emprestimo.rows = emprestimo.rows.map((pa) => {
                                if (pa.ID_PARCELA !== parc.ID_PARCELA)
                                  return pa;
                                else {
                                  const pa2 = pa;
                                  pa2.VENCIMENTO = value.toISOString();
                                  return pa2;
                                }
                              });
                              return {
                                data: [...dadosA, em, ...dadosB],
                              };
                            });
                          }
                        }}
                        dateFormat="dd/MM/yyyy'"
                      />
                    ) : null}
                  </td>
                </tr>
              );
            })}
          </tbody>
          {emprestimo.VLR_SALDO_A_REPASSAR &&
            Number(emprestimo.VLR_SALDO_A_REPASSAR) > 0 && (
              <p style={msgImportantAlert}>REPASSE PENDENTE - NÃO PROTESTAR</p>
            )}
        </table>
        <div>
          {emprestimo.DATA_ENVIO_BOLETO ? (
            <>
              <div>
                Boleto enviado{" "}
                {formataDataHora(new Date(emprestimo.DATA_ENVIO_BOLETO))}
                {emprestimo.OBSERVACAO_BOLETO && (
                  <div>Obs: {emprestimo.OBSERVACAO_BOLETO}</div>
                )}
              </div>
              {!removendoBoleto ? (
                <Button
                  size="sm"
                  color="danger"
                  onClick={() => setRemovendoBoleto(true)}
                >
                  Remover Boleto
                </Button>
              ) : (
                <>
                  <span>Remover registro de envio dos boletos?</span>
                  <div>
                    <Button
                      size="sm"
                      color="info"
                      onClick={() => setRemovendoBoleto(false)}
                    >
                      cancelar
                    </Button>
                    <Button
                      size="sm"
                      color="danger"
                      onClick={() => apagaBoleto(emprestimo.ID_ENVIO_BOLETO)}
                    >
                      confirmar
                    </Button>
                  </div>
                </>
              )}
            </>
          ) : (
            <div style={msgWarning}>
              Boleto não enviado
              {enviandoBoleto && (
                <>
                  <TextField
                    value={observacaoBoleto}
                    onChange={({ target: { value } }) => {
                      setObservacaoBoleto(value);
                    }}
                    placeholder="Observação boleto"
                    fullWidth
                  />
                  <Button
                    color="success"
                    onClick={() => {
                      enviaBoleto(emprestimo.ID, observacaoBoleto);
                    }}
                    size="sm"
                  >
                    Enviar
                  </Button>
                  <Button
                    color="danger"
                    onClick={() => {
                      setEnviandoBoleto(false);
                    }}
                    size="sm"
                  >
                    Cancelar
                  </Button>
                </>
              )}
              {!enviandoBoleto && (
                <Button onClick={() => setEnviandoBoleto(true)} size="sm">
                  Enviar...
                </Button>
              )}
            </div>
          )}
        </div>

        {excluindo ? (
          <GridContainer>
            <GridItem md={12}>
              <h4>
                Confirma{" "}
                {emprestimo.TIPO_NEGOCIACAO === "E" ? "Exclusão" : "desfazer"}?
              </h4>
            </GridItem>
            <GridItem
              xs={12}
              sm={6}
              onClick={() => {
                if (emprestimo.TIPO_NEGOCIACAO === "E")
                  excluiParcela(emprestimo.ID);
                else {
                  desfazRenegociacao(emprestimo.ID);
                }
              }}
            >
              <Button color="danger">Confirmar</Button>
            </GridItem>
            <GridItem xs={12} sm={6} onClick={() => setExcluindo(false)}>
              <Button color="info">Voltar</Button>
            </GridItem>
          </GridContainer>
        ) : (
          <GridContainer>
            <GridItem xs={12} sm={6} lg={4}>
              <Button
                color="info"
                onClick={() => {
                  setObsAdmin(emprestimo.observacoes);
                  setModalObsVisible(true);
                  setPropObsId(emprestimo.ID);
                }}
              >
                {emprestimo.observacoes.length == 0
                  ? "+ observação"
                  : emprestimo.observacoes.length == 1
                  ? "1 observação"
                  : `${emprestimo.observacoes.length} observações`}
              </Button>
            </GridItem>
            <GridItem xs={12} sm={6} lg={4}>
              <Button
                color="primary"
                onClick={() => toggleCliSelecionado(emprestimo.ID_CLIENTE)}
              >
                {idCliSelecionado === 0 ? "Mostra Cliente" : "Mostra Todos"}
              </Button>
            </GridItem>
            <GridItem xs={12} sm={6} lg={2}>
              {verificaPossibilidadeExclusao ? (
                emprestimo.TIPO_NEGOCIACAO === "E" ? (
                  <Button
                    color="danger"
                    onClick={async () => {
                      const res = await verificaPossibilidadeExclusao(
                        emprestimo.ID
                      );
                      if (res && res.sucesso && res.podeExcluir) {
                        setExcluindo(true);
                      } else {
                        mostraErro(
                          `Proposta ${emprestimo.ID} não pode ser excluida: ${res.mensagem}`
                        );
                      }
                    }}
                  >
                    Excluir
                  </Button>
                ) : (
                  <Button
                    color="danger"
                    onClick={() => {
                      setExcluindo(true);
                    }}
                  >
                    Desfazer
                  </Button>
                )
              ) : null}
            </GridItem>
            <GridItem xs={12} sm={6} lg={4}>
              <Button
                color="warning"
                onClick={() => {
                  setModalProtVisible(true);
                  setPropostaelecionada(emprestimo.rows);
                }}
              >
                Protestos
              </Button>
            </GridItem>
            <GridItem xs={12} sm={6} lg={4}>
              <Button
                color="rose"
                onClick={() => {
                  setModalCobrancaVisible(true);
                  setPropostaelecionada(emprestimo.rows);
                }}
              >
                Cobranças
              </Button>
            </GridItem>
          </GridContainer>
        )}
      </CardBody>
    </Card>
  );
};

EmprestimoCard.propTypes = {
  emprestimo: PropTypes.shape({
    ID: PropTypes.number.isRequired,
    NOME_CLIENTE: PropTypes.string,
    TELEFONE: PropTypes.string,
    CPF: PropTypes.string,
    OBSERVACAO: PropTypes.string,
    NOME_ESTAB: PropTypes.string,
    NOME_FANTASIA: PropTypes.string,
    VALOR_VENDA: PropTypes.string.isRequired,
    VALOR_PARCELAS: PropTypes.string.isRequired,
    VLR_SALDO_A_REPASSAR: PropTypes.string.isRequired,
    observacoes: PropTypes.isRequired,
    rows: PropTypes.array.isRequired,
    ID_CLIENTE: PropTypes.number.isRequired,
    TIPO_NEGOCIACAO: PropTypes.string.isRequired,
    DATA_ENVIO_BOLETO: PropTypes.string,
    OBSERVACAO_BOLETO: PropTypes.string,
    ID_ENVIO_BOLETO: PropTypes.string,
  }),
  tentaAlterarVencimento: PropTypes.func,
  setDados: PropTypes.func,
  setObsAdmin: PropTypes.func,
  setModalObsVisible: PropTypes.func,
  setModalProtVisible: PropTypes.func,
  setModalCobrancaVisible: PropTypes.func,
  setPropostaelecionada: PropTypes.func,
  setPropObsId: PropTypes.func,
  idCliSelecionado: PropTypes.number,
  toggleCliSelecionado: PropTypes.func,
  verificaPossibilidadeExclusao: PropTypes.func,
  mostraErro: PropTypes.func,
  excluiParcela: PropTypes.func,
  desfazRenegociacao: PropTypes.func,
  enviaBoleto: PropTypes.func,
  apagaBoleto: PropTypes.func,
};

export default EmprestimoCard;
