const axios = require("axios");
export const cpfMask = (value) => {
  return value
    .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1"); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
};

export const validaCpf = (strCPF) => {
  var Soma;
  var Resto;
  Soma = 0;
  strCPF = strCPF.replace(/[^\d]/g, "");
  if (strCPF == "00000000000") return false;

  if (
    !strCPF ||
    strCPF.length != 11 ||
    strCPF == "00000000000" ||
    strCPF == "11111111111" ||
    strCPF == "22222222222" ||
    strCPF == "33333333333" ||
    strCPF == "44444444444" ||
    strCPF == "55555555555" ||
    strCPF == "66666666666" ||
    strCPF == "77777777777" ||
    strCPF == "88888888888" ||
    strCPF == "99999999999"
  )
    return false;

  for (var i = 1; i <= 9; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;
  if (Resto == 10 || Resto == 11) Resto = 0;
  if (Resto != parseInt(strCPF.substring(9, 10))) return false;
  Soma = 0;
  for (var j = 1; j <= 10; j++)
    Soma = Soma + parseInt(strCPF.substring(j - 1, j)) * (12 - j);
  Resto = (Soma * 10) % 11;
  if (Resto == 10 || Resto == 11) Resto = 0;
  if (Resto != parseInt(strCPF.substring(10, 11))) return false;
  return true;
};

const validaCNPJ = (value) => {
  if (!value) return false;

  // Aceita receber o valor como string, número ou array com todos os dígitos
  const isString = typeof value === "string";
  const validTypes =
    isString || Number.isInteger(value) || Array.isArray(value);

  // Elimina valor em formato inválido
  if (!validTypes) return false;

  // Filtro inicial para entradas do tipo string
  if (isString) {
    // Limita ao máximo de 18 caracteres, para CNPJ formatado
    if (value.length > 18) return false;

    // Teste Regex para veificar se é uma string apenas dígitos válida
    const digitsOnly = /^\d{14}$/.test(value);
    // Teste Regex para verificar se é uma string formatada válida
    const validFormat = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/.test(value);

    // Se o formato é válido, usa um truque para seguir o fluxo da validação
    if (digitsOnly || validFormat) true;
    // Se não, retorna inválido
    else return false;
  }

  // Guarda um array com todos os dígitos do valor
  const match = value.toString().match(/\d/g);
  const numbers = Array.isArray(match) ? match.map(Number) : [];

  // Valida a quantidade de dígitos
  if (numbers.length !== 14) return false;

  // Elimina inválidos com todos os dígitos iguais
  const items = [...new Set(numbers)];
  if (items.length === 1) return false;

  // Cálculo validador
  const calc = (x) => {
    const slice = numbers.slice(0, x);
    let factor = x - 7;
    let sum = 0;

    for (let i = x; i >= 1; i--) {
      const n = slice[x - i];
      sum += n * factor--;
      if (factor < 2) factor = 9;
    }

    const result = 11 - (sum % 11);

    return result > 9 ? 0 : result;
  };

  // Separa os 2 últimos dígitos de verificadores
  const digits = numbers.slice(12);

  // Valida 1o. dígito verificador
  const digit0 = calc(12);
  if (digit0 !== digits[0]) return false;

  // Valida 2o. dígito verificador
  const digit1 = calc(13);
  return digit1 === digits[1];
};

export const mascaraCEP = (cep) => {
  cep = cep.replace(/\D/g, "");
  return cep.substring(0, 5) + "-" + cep.substring(5, 8);
};

export const mascaraCNPJ = (cnpj) => {
  return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
};

export const mascaraPhone = (v) => {
  var r = v.replace(/\D/g, "");
  r = r.replace(/^0/, "");
  if (r.length > 10) {
    r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
  } else if (r.length > 5) {
    r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
  } else if (r.length > 2) {
    r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
  } else {
    r = r.replace(/^(\d*)/, "($1");
  }
  return r;
};

export const getAutenticado = async (apiUrl) => {
  const token = localStorage.getItem("vsTokenAdmin");
  return axios.get(apiUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const postAutenticado = async (apiUrl, dados) => {
  const token = localStorage.getItem("vsTokenAdmin");
  return axios.post(apiUrl, dados, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteAutenticado = async (apiUrl) => {
  const token = localStorage.getItem("vsTokenAdmin");
  return axios.delete(apiUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const formataValorReais = (valor) => {
  let formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
  return formatter.format(valor);
};

const arred = (val) => {
  return Math.round(val * 100) / 100;
};
const calcParcelaAux = (capital, taxa, parcs) => {
  return (capital * taxa) / (1 - Math.pow(1 + taxa, -1 * parcs));
};

const maxParcelasValor = (
  valor,
  taxa,
  parcela_minima,
  acrescimo,
  num_parcelas_maximo
) => {
  let num_parcelas = num_parcelas_maximo;
  while (num_parcelas > 0) {
    let parcela = calcParcelaAux(valor, taxa, num_parcelas);
    parcela += acrescimo;
    if (arred(parcela) >= arred(parcela_minima)) return num_parcelas;
    else num_parcelas--;
  }
  return 0;
};

export const parcelasMaximas_2 = (valor) => {
  let {
    MIN_PRESTACAO,
    TAXA,
    NUM_MAX_PARCELAS,
    ACRESCIMO,
  } = require("../consts");
  TAXA = TAXA / 100.0;
  return maxParcelasValor(
    valor,
    TAXA,
    MIN_PRESTACAO,
    ACRESCIMO,
    NUM_MAX_PARCELAS
  );
};

export const parcelasMaximas = (
  valor,
  parcelaMinima,
  taxa,
  numeroMaximoParcelas,
  acrescimo
) => {
  taxa = taxa / 100;
  return maxParcelasValor(
    valor,
    taxa,
    parcelaMinima,
    acrescimo,
    numeroMaximoParcelas
  );
};

export const estimaParcela = (capital, numParcelas, taxa, acrescimo) => {
  taxa = taxa / 100.0;
  const valor =
    acrescimo + (capital * taxa) / (1 - Math.pow(1 + taxa, -1 * numParcelas));
  return valor;
};

export const formatSQLDate = (date) => {
  return (
    date.substring(8, 10) +
    "/" +
    date.substring(5, 7) +
    "/" +
    date.substring(0, 4)
  );
};

export const calculaParcelas = function (capital, taxa_porcent, num_parcelas) {
  const taxa = taxa_porcent / 100.0;
  capital = arred(capital);
  const rateio = arred(capital / num_parcelas);
  const rat_ultimo = arred(rateio + (capital - num_parcelas * rateio));
  const hoje = new Date();
  if (num_parcelas < 1)
    return {
      erro: true,
      mensagem: "Numero de parcelas é menor que 1",
    };
  const CF = taxa / (1 - Math.pow(1 + taxa, -1 * num_parcelas));
  const parcela = arred(CF * capital);
  const montante = arred(parcela * num_parcelas);
  let parcelas = [];
  let data = hoje;
  for (let i = 1; i <= num_parcelas - 1; i++) {
    data.setDate(data.getDate() + 30);
    parcelas.push({
      parcela: i,
      valor: parcela,
      rateio: rateio,
      vencimento: new Date(data),
    });
  }
  data.setDate(data.getDate() + 30);
  parcelas.push({
    parcela: num_parcelas,
    valor: parcela,
    rateio: rat_ultimo,
    vencimento: data,
  });
  return {
    parcelas: parcelas,
    taxa: taxa_porcent,
    capital: capital,
    montante: montante,
    num_parcelas: num_parcelas,
  };
};

export const calculaParcelasPersonalizado = (
  capital,
  taxa_porcent,
  num_parcelas,
  parc_minima,
  acrescimo
) => {
  const taxa = taxa_porcent / 100.0;
  capital = arred(capital);
  //calcula o rateio levando em conta as prestações acima
  if (num_parcelas < 1)
    return {
      erro: true,
    };
  const num_parcelas_efetivo = maxParcelasValor(
    capital,
    taxa,
    parc_minima,
    acrescimo,
    num_parcelas
  );
  let resultado = calculaParcelas(capital, taxa_porcent, num_parcelas_efetivo);
  resultado.parcelas = resultado.parcelas.map((item) => {
    item.valor = arred(item.valor + acrescimo);
    return item;
  });
  resultado.montante = arred(
    resultado.montante + num_parcelas_efetivo * acrescimo
  );
  return resultado;
};

export const formataData = (data) => {
  data = new Date(data);
  let dia = data.getDate().toString();
  let diaF = dia.length == 1 ? "0" + dia : dia;
  let mes = (data.getMonth() + 1).toString(); //+1 pois no getMonth Janeiro começa com zero.
  let mesF = mes.length == 1 ? "0" + mes : mes;
  let anoF = data.getFullYear();
  return diaF + "/" + mesF + "/" + anoF;
};

export const formataDataHora = (dataHora) => {
  dataHora = new Date(dataHora);
  let dia = dataHora.getDate().toString();
  let diaF = dia.length == 1 ? "0" + dia : dia;
  let mes = (dataHora.getMonth() + 1).toString(); //+1 pois no getMonth Janeiro começa com zero.
  let mesF = mes.length == 1 ? "0" + mes : mes;
  let anoF = dataHora.getFullYear();
  let hora = dataHora.getHours().toString();
  let horaF = hora.length == 1 ? "0" + hora : hora;
  let minuto = dataHora.getMinutes().toString();
  let minutoF = minuto.length == 1 ? "0" + minuto : minuto;
  let segundo = dataHora.getSeconds().toString();
  let segundoF = segundo.length == 1 ? "0" + segundo : segundo;
  return (
    diaF +
    "/" +
    mesF +
    "/" +
    anoF +
    " " +
    horaF +
    ":" +
    minutoF +
    ":" +
    segundoF
  );
};

export const formataDataHoraSQL = (dataHora, endOfDay) => {
  dataHora = new Date(dataHora);
  if (endOfDay) {
    dataHora.setHours(23, 59, 59, 999);
  }
  let dia = dataHora.getDate().toString();
  let diaF = dia.length == 1 ? "0" + dia : dia;
  let mes = (dataHora.getMonth() + 1).toString(); //+1 pois no getMonth Janeiro começa com zero.
  let mesF = mes.length == 1 ? "0" + mes : mes;
  let anoF = dataHora.getFullYear();
  let hora = dataHora.getHours().toString();
  let horaF = hora.length == 1 ? "0" + hora : hora;
  let minuto = dataHora.getMinutes().toString();
  let minutoF = minuto.length == 1 ? "0" + minuto : minuto;
  let segundo = dataHora.getSeconds().toString();
  let segundoF = segundo.length == 1 ? "0" + segundo : segundo;
  return (
    anoF +
    "-" +
    mesF +
    "-" +
    diaF +
    "T" +
    horaF +
    ":" +
    minutoF +
    ":" +
    segundoF
  );
};

export const formataDataSQL = (data) => {
  data = new Date(data);

  let dia = data.getDate().toString();
  let diaF = dia.length == 1 ? "0" + dia : dia;
  let mes = (data.getMonth() + 1).toString(); //+1 pois no getMonth Janeiro começa com zero.
  let mesF = mes.length == 1 ? "0" + mes : mes;
  let anoF = data.getFullYear();

  return anoF + "-" + mesF + "-" + diaF;
};

export const validaUsuario = (usuario) => {
  const erros = [];
  const { NOME, LOGIN, SENHA, SENHA_CONFIRMA } = usuario;
  if (!NOME || NOME.length < 5)
    erros.push("Nome muito curto: pelo menos 5 dígitos!");
  if (!LOGIN || LOGIN.length < 5)
    erros.push("Login muito curto: pelo menos 5 dígitos!");
  if (SENHA != SENHA_CONFIRMA) erros.push("As senhas não conferem!");
  else if (!SENHA || SENHA.length < 6)
    erros.push("Senha muito curta: pelo menos 6 dígitos!");
  return erros;
};

export const validaEstabelecimento = (estabelecimento) => {
  const erros = [];
  const { RAZAO_SOCIAL, CNPJ } = estabelecimento;
  if (!RAZAO_SOCIAL || !RAZAO_SOCIAL.length || RAZAO_SOCIAL.length < 5)
    erros.push("Razão social muito curta: pelo menos 5 dígitos!");
  const cnpj = CNPJ ? CNPJ.replace(/\D/g, "") : "";
  if (!validaCNPJ(cnpj)) erros.push("CNPJ inválido");

  return erros;
};

export const validaEndereco = (entidade) => {
  const erros = [];
  const { LOGRADOURO, BAIRRO, NUMERO, CIDADE, CEP } = entidade;
  if (!LOGRADOURO || !LOGRADOURO.length || LOGRADOURO.length < 3)
    erros.push("Logradouro: nome muito curto; pelo menos 3 dígitos!");
  if (!BAIRRO) erros.push("Informar o bairro");
  if (!NUMERO) erros.push("Informar o número");
  if (!CIDADE) erros.push("Informar a cidade");
  const cep = CEP ? CEP.replace(/\D/g, "") : "";
  if (!cep || cep.length !== 8) erros.push("CEP inválido: informar 8 dígitos");
  return erros;
};

function daysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}

function breakDate(date) {
  if (typeof date === "string") date = new Date(date);
  const y = date.getUTCFullYear();
  const m = date.getUTCMonth() + 1;
  const d = date.getUTCDate();
  return [y, m, d];
}

export function getMonthDayDiff(dtVenc, dtBase) {
  const [yBase, mBase, dBase] = breakDate(dtBase);
  const [yVenc, mVenc, dVenc] = breakDate(dtVenc);
  let ymd = (yBase - yVenc) * 12;
  let md = mBase - mVenc + ymd;
  let ddif = 0;
  if (dBase > dVenc) {
    ddif = dBase - dVenc;
  } else {
    md -= 1;
    ddif = dBase - dVenc + daysInMonth(mVenc, yVenc);
  }
  return [md > 0 ? md : 0, ddif > 0 && md >= 0 ? ddif : 0];
}

export function atualizaValor(parcela, vencimento) {
  parcela = typeof parcela === "number" ? parcela : Number(parcela);
  const [m, d] = getMonthDayDiff(vencimento, new Date());
  const perc = m + d / 30;
  const atual = perc > 0 ? parcela * (1.01 + 0.01 * perc) : parcela;
  return atual;
}
