import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Logout from "components/Logout";
//import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import Table from "components/Table/Table.js";

const {
  getAutenticado,
  formataValorReais,
  formataDataHora,
} = require("../../js_utils/util");

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function Contas() {
  const { API_URL } = require("../../consts");
  let [logado, setLogado] = useState(true);
  const [contasTable, setContasTable] = useState([[]]);
  const [movimentacaoTable, setMovimentacaoTable] = useState([[]]);
  const [contaSelecionada, setContaSelecionada] = useState(null);

  const visualizaConta = async (contaSelec, all) => {
    const url = all ? API_URL + "contas/" + contaSelec.ID : API_URL + "contas/" + contaSelec.ID + "/10"
    try {
      const resultados = await getAutenticado(url);
      let saldo_inicial = contaSelec.SALDO_INICIAL;
      let items = [];

      resultados.data.resultados.forEach((element) => {
        let tipoMov = element.CD;
        let vlr = parseFloat(element.VLR_LANCAMENTO) || 0.0;
        vlr = tipoMov === "D" ? -vlr : vlr;
        const saldo = element.SALDO;
        items.push([
          formataDataHora(new Date(element.DT_HORA_LANCAMENTO)),
          tipoMov,
          element.DESCRICAO,
          formataValorReais(vlr),
          formataValorReais(saldo),
          element.OBSERVACOES
            ? element.OBSERVACOES
            : element.OBS_REPASSE
              ? element.OBS_REPASSE
              : element.OBS_PARCELA,
        ]);
      });
      items.push(["", "", "SALDO INICIAL", "", formataValorReais(saldo_inicial), ""]);
      setContaSelecionada(contaSelec);
      setMovimentacaoTable(items);
    } catch (err) {
      console.log(err.message);
      setLogado(false);
    }
  };
  const buscaContas = async () => {
    try {
      const resultados = await getAutenticado(API_URL + "contas");
      //PERIGOSO SE NÃO HOUVER PELO MENOS UMA CONTA
      let items = [];
      resultados.data.resultados.forEach((element) => {
        let contaObj = {
          ID: element.ID,
          NUM_CONTA: element.NUM_CONTA + "-" + element.DV_CONTA,
          NUM_AGENCIA: element.NUM_AGENCIA + "-" + element.DV_AGENCIA,
          NUM_BANCO: element.NUM_BANCO + "",
          SALDO_INICIAL: parseFloat(element.SALDO_INICIAL) || 0.0,
        };
        items.push([
          element.NUM_CONTA + "-" + element.DV_CONTA,
          element.NUM_AGENCIA + "-" + element.DV_AGENCIA,
          element.NUM_BANCO + "",
          <Button
            key={contaObj.ID}
            color="info"
            onClick={() => visualizaConta(contaObj)}
          >
            Detalhes
          </Button>,
        ]);
      });
      setContasTable(items);
      setContaSelecionada(resultados.data.resultados[0]);
    } catch (err) {
      console.log(err);
      setLogado(false);
    }
  };

  useEffect(buscaContas, []);
  const classes = useStyles();
  return logado ? (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>Contas</h4>
        </CardHeader>
        <Table
          tableHeaderColor="primary"
          tableHead={["Conta", "Agência", "Banco", "..."]}
          tableData={contasTable}
        ></Table>
      </Card>
      {contaSelecionada ? (
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>
              Conta: {contaSelecionada.NUM_CONTA}
            </h4>
          </CardHeader>
          <Button color="info" onClick={() => visualizaConta(contaSelecionada, true)}>Carregar Tudo</Button>
          <Table
            tableHeaderColor="primary"
            tableHead={[
              "Data",
              "D/C",
              "Tipo",
              "Movimentação",
              "Saldo",
              "Observação",
            ]}
            tableData={movimentacaoTable}
          ></Table>
        </Card>
      ) : null}
    </div>
  ) : (
    <Logout></Logout>
  );
}
