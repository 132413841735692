import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Table from "components/Table/Table.js";
import { formataData } from "js_utils/util";
import { formataValorReais } from "js_utils/util";
// import cliente from "./clienteMock";

const styleQuitado = {
  color: "green",
};
const styleVencido = {
  color: "red",
};
const styleRenegociado = {
  color: "blue",
  fontWeight: "bold",
};
const styleProtestado = {
  color: "purple",
  fontWeight: "bold",
};
const styleCobrancaExterna = {
  color: "yellow",
  backgroundColor: "gray",
  fontWeight: "bold",
};

const FichaFinanceiraCliente = (props) => {
  const [info, setInfo] = useState(undefined);

  useEffect(async () => {
    const result = await props.fetchInfo();
    setInfo(result);
  }, []);

  if (!info) return "Carregando...";
  const { NOME, PAGAMENTOS, PARCELAS } = info;
  const pagamentosRows = PAGAMENTOS.map((p) => {
    const {
      DATA,
      VENCIMENTO,
      PROPOSTA,
      PARCELA,
      VLR_ABATIDO,
      VLR_JUROS,
      VLR_DESCONTO,
      VLR_PAGO,
    } = p;
    return [
      formataData(DATA),
      formataData(VENCIMENTO),
      PROPOSTA,
      PARCELA,
      formataValorReais(VLR_ABATIDO),
      formataValorReais(VLR_JUROS),
      formataValorReais(VLR_DESCONTO),
      <span style={{ fontWeight: "bold" }} key={PROPOSTA + "-" + PARCELA}>
        {formataValorReais(VLR_PAGO)}
      </span>,
    ];
  });
  //TODO: estilos protesto e cobranca
  const parcelasRows = PARCELAS.filter((p) => Number(p.SALDO) > 0).map((p) => {
    const dataAtual = new Date();
    const {
      VENCIMENTO,
      VALOR,
      SALDO,
      PROPOSTA,
      PARCELA,
      LOJA,
      DATA_COBRANCA_EXTERNA,
      DATA_PROTESTO,
      STATUS_PARCELA,
    } = p;

    const style = DATA_COBRANCA_EXTERNA
      ? styleCobrancaExterna
      : DATA_PROTESTO
      ? styleProtestado
      : STATUS_PARCELA === "R"
      ? styleRenegociado
      : SALDO == 0.0
      ? styleQuitado
      : dataAtual.toISOString().substring(0, 10) > VENCIMENTO && SALDO > 0.0
      ? styleVencido
      : null;

    return [
      <span key={`parc ${PARCELA}`} style={style}>
        {formataData(VENCIMENTO)}
      </span>,
      <span key={`parc ${PARCELA}`} style={style}>
        {PROPOSTA}
      </span>,
      <span key={`parc ${PARCELA}`} style={style}>
        {PARCELA}
      </span>,
      <span key={`parc ${PARCELA}`} style={style}>
        {formataValorReais(VALOR)}
      </span>,
      <span key={`parc ${PARCELA}`} style={style}>
        {formataValorReais(SALDO)}
      </span>,
      <span key={`parc ${PARCELA}`} style={style}>
        {LOJA}
      </span>,
    ];
  });
  return (
    <>
      <Card>
        <CardHeader color="primary">
          Pagamentos Recebidos Cliente {NOME}
        </CardHeader>
        <CardBody>
          <Table
            tableHeaderColor="primary"
            tableHead={[
              "Dt. Pagamento",
              "Vencimento",
              "Proposta",
              "Parcela",
              "Vlr. Abatido",
              "Vlr. Juros",
              "Vlr. Desconto",
              "Vlr. Pago",
            ]}
            tableData={pagamentosRows}
          />
        </CardBody>
      </Card>
      <Card>
        <CardHeader color="primary">
          Parcelas em Aberto Cliente {NOME}
        </CardHeader>
        <CardBody>
          <Table
            tableHeaderColor="primary"
            tableHead={[
              "Vencimento",
              "Proposta",
              "Parcela",
              "Valor",
              "Saldo",
              "Loja",
            ]}
            tableData={parcelasRows}
          />
        </CardBody>
      </Card>
    </>
  );
};

FichaFinanceiraCliente.propTypes = {
  fetchInfo: PropTypes.func,
};

export default FichaFinanceiraCliente;
