import React, { useEffect, useState } from "react";
// @material-ui/core components
// core components
import { makeStyles } from "@material-ui/core/styles";
import Logout from "components/Logout";
//import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody";
import logout from "js_utils/logout";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button";
import { TextField } from "@material-ui/core";
import MultiFiltro from "components/Filter/MultiFiltro"

const { getAutenticado, postAutenticado, formataValorReais } = require("../../js_utils/util");

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0",
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
    },
};

export default function Vencimentos() {
    const useStyles = makeStyles(styles);
    const classes = useStyles();
    const [estabelecimentos, setEstabelecimentos] = useState([]);
    const [idSelecionados, setIdSelecionados] = useState([]);
    const [habilitaFiltro, setHabilitaFiltro] = useState(false);

    const { API_URL } = require("../../consts");
    const logado = true;

    //Snackbar
    let [mensagemErro, setMensagemErro] = useState("");
    const [alerta, setAlerta] = useState(false);
    const [cor, setCor] = useState("warning");
    const showNotification = () => {
        if (!alerta) {
            setAlerta(true);
            setTimeout(function () {
                setAlerta(false);
            }, 6000);
        }
    };
    const mostraMensagem = (msg, cor) => {
        setCor(cor);
        setMensagemErro(msg);
        showNotification();
    };
    const buscaDados = async () => {
        const dados = dadosTabela.slice()
        for (let i = 0; i < dados.length; i++) {
            const de = dados[i][0];
            const ate = dados[i][1];
            try {
                let resultados = await postAutenticado(
                    API_URL + "totalvencido", {
                    diasde: de,
                    diasate: ate,
                    empresas: habilitaFiltro ? idSelecionados : []
                }
                );
                if (resultados.data.sucesso) {
                    const { VALOR_FINANC_RECEBIDO, VALOR_FINANC_VENCIDO } = resultados.data.dados;
                    let valor_vencido = resultados.data.dados.VALOR_VENCIDO;
                    let valor_recebido = resultados.data.dados.VALOR_RECEBIDO;
                    let valor_liquido = (parseFloat(valor_recebido) || 0)
                        - (parseFloat(VALOR_FINANC_RECEBIDO) || 0) -
                        (parseFloat(VALOR_FINANC_VENCIDO) || 0)
                    dados[i][2] = formataValorReais(valor_vencido);
                    dados[i][3] = formataValorReais(valor_recebido);
                    dados[i][4] = formataValorReais(valor_liquido || 0.0);
                } else {
                    mostraMensagem("Erro interno.", "danger");
                }
            } catch (error) {
                mostraMensagem(JSON.stringify(error), "danger");
                logout();
            }
        }
        setDadosTabela(dados);

    };
    const buscaEstabs = async () => {
        let resultado
        try {
            resultado = await getAutenticado(API_URL + "estabelecimentos");
            setEstabelecimentos(resultado.data.resultados)
        } catch (err) {
            mostraMensagem(err.message, "danger")
            // logout();
        }
    }
    const buscaDadosCustom = async () => {
        try {
            let resultados = await postAutenticado(
                API_URL + "totalvencido", {
                diasde: deCustom,
                diasate: ateCustom,
                empresas: habilitaFiltro ? idSelecionados : []

            }
            );
            if (resultados.data.sucesso) {
                let valor_vencido = resultados.data.dados.VALOR_VENCIDO;
                setVencidoCustom(formataValorReais(valor_vencido))
            } else {
                mostraMensagem("Erro interno.", "danger");
            }
        } catch (error) {
            mostraMensagem(JSON.stringify(error), "danger");
            logout();
        }
    }
    useEffect(async () => {
        buscaEstabs();
    }, []);
    useEffect(async () => {
        buscaDados();
    }, [idSelecionados, habilitaFiltro]);
    const [dadosTabela, setDadosTabela] = useState([
        [1, 30, "...", "...", "..."],
        [31, 60, "...", "...", "..."],
        [61, 90, "...", "...", "..."],
        [91, 120, "...", "...", "..."],
        [121, 180, "...", "...", "..."],
        [181, 99999, "...", "...", "..."]
    ])
    const [vencidoCustom, setVencidoCustom] = useState("...")
    const [deCustom, setDeCustom] = useState(1);
    const [ateCustom, setAteCustom] = useState(8);

    return logado ? (
        <div>
            <Card>
                <CardHeader color="primary">
                    <h5 className={classes.cardTitleWhite}>Valor vencido por prazo</h5>
                </CardHeader>
                <CardBody>
                    <Button
                        color="primary"
                        onClick={() => setHabilitaFiltro(prevState => !prevState)}
                    >
                        Selecionar Lojas
                    </Button>
                    {habilitaFiltro ?
                        <>
                            <MultiFiltro itens={estabelecimentos} setIds={setIdSelecionados} chaveLabel="RAZAO_SOCIAL" chaveID="ID" />
                            {idSelecionados.length === 0 ? <span style={{ color: "red" }}>Nenhum estabelecimento selecionado <br /> Mostrando dados totais </span> : ""}
                        </> : null}
                    <Table
                        tableHeaderColor="primary"
                        tableHead={["De (dias)", "Até (dias)", "Valor Vencido", "Valor Recebido", "Liquido"]}
                        tableData={dadosTabela}
                    />
                </CardBody>
            </Card>
            <Card>
                <CardHeader color="primary">
                    <h5 className={classes.cardTitleWhite}>Personalizado</h5>
                </CardHeader>
                <CardBody>
                    <table>
                        <thead style={{ textAlign: "left" }}>
                            <tr>
                                <th>De (dias) </th><th>Até (dias)</th><th>Valor Vencido</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr >
                                <td>
                                    <TextField
                                        value={deCustom}
                                        onChange={(e) => setDeCustom(e.target.value.replace(/\D/g, ''))}
                                    />
                                </td>
                                <td>
                                    <TextField
                                        value={ateCustom}
                                        onChange={(e) => setAteCustom(e.target.value.replace(/\D/g, ''))}
                                    />
                                </td>
                                <td><TextField value={vencidoCustom} /></td>
                            </tr>
                            <tr><td><Button color="primary" onClick={buscaDadosCustom}>Buscar</Button></td></tr>
                        </tbody>
                    </table>
                </CardBody>
            </Card>
            <Snackbar
                place="bc"
                color={cor || "warning"}
                icon={AddAlert}
                message={mensagemErro}
                open={alerta}
                closeNotification={() => setAlerta(false)}
                close
            />
        </div>
    ) : (
        <Logout></Logout>
    );
}
