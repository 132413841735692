/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import People from "@material-ui/icons/People";
import Money from "@material-ui/icons/AttachMoney";
import Nota from "@material-ui/icons/Money";
import Info from "@material-ui/icons/Info";
import MoneyOff from "@material-ui/icons/MoneyOff";
import Coin from "@material-ui/icons/AccountBalanceWallet";
import Signout from "@material-ui/icons/ExitToApp";
import Assessment from "@material-ui/icons/Assessment";
import Shuffle from "@material-ui/icons/Shuffle";
import Store from "@material-ui/icons/Store";
// core components/views for Admin layout
import MeusDados from "views/MeusDados/MeusDados.js";
import Contas from "views/Contas/Contas";
import Clientes from "views/Clientes/Clientes";
import BaixaParcela from "views/BaixaParcela/BaixaParcela";
import BaixaRepasse from "views/BaixaRepasse/BaixaRepasse";
import EstornoParcela from "views/EstornoParcela/EstornoParcela";
import Logout from "components/Logout";
import Entradas from "views/Entradas/Entradas";
import Saidas from "views/Saidas/Saidas";
import Emprestimo from "views/Emprestimo/Emprestimo";
import EmprestimoNovo from "views/EmprestimoNovo/Emprestimo";
import Relatorios from "views/Relatorios/Relatorios";
import Estabelecimentos from "views/Estabelecimentos/Estabelecimentos";
import Renegociacao from "views/BaixaParcela/Renegociacao";

const dashboardRoutes = [
  // {
  //   path: "/emprestimo",
  //   name: "Empréstimo",
  //   rtlName: "قائمة الجدول",
  //   icon: Money,
  //   component: Emprestimo,
  //   layout: "/admin",
  // },
  {
    path: "/contas",
    name: "Contas",
    rtlName: "قائمة الجدول",
    icon: Coin,
    component: Contas,
    layout: "/admin",
  },
  {
    path: "/clientes",
    name: "Clientes",
    rtlName: "قائمة الجدول",
    icon: People,
    component: Clientes,
    layout: "/admin",
  },
  {
    path: "/estabelecimentos",
    name: "Estabelecimentos",
    rtlName: "قائمة الجدول",
    icon: Store,
    component: Estabelecimentos,
    layout: "/admin",
  },
  {
    path: "/emprestimos",
    name: "Empréstimos",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Info,
    component: Emprestimo,
    layout: "/admin",
  },
  {
    path: "/emprestimos-novo",
    name: "+ Recentes",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Info,
    component: EmprestimoNovo,
    layout: "/admin",
  },
  {
    path: "/relatorios",
    name: "Relatorios",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Assessment,
    component: Relatorios,
    layout: "/admin",
  },
  {
    path: "/baixa-parcela",
    name: "Baixa Parcela",
    rtlName: "قائمة الجدول",
    icon: Money,
    component: BaixaParcela,
    layout: "/admin",
  },
  {
    path: "/renegociacao",
    name: "Renegociação",
    rtlName: "قائمة الجدول",
    icon: Shuffle,
    component: Renegociacao,
    layout: "/admin",
  },
  {
    path: "/baixa-repasse",
    name: "Baixa Repasse",
    rtlName: "قائمة الجدول",
    icon: Money,
    component: BaixaRepasse,
    layout: "/admin",
  },
  {
    path: "/estorno-parcela",
    name: "Estorno de Parcela",
    rtlName: "قائمة الجدول",
    icon: MoneyOff,
    component: EstornoParcela,
    layout: "/admin",
  },
  {
    path: "/entradas",
    name: "Entrada de Valor",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Nota,
    component: Entradas,
    layout: "/admin",
  },
  {
    path: "/saidas",
    name: "Saída de Valor",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Nota,
    component: Saidas,
    layout: "/admin",
  },
  {
    path: "/user",
    name: "Meus Dados",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: MeusDados,
    layout: "/admin",
  },
  {
    path: "/logout",
    name: "Sair",
    rtlName: "إخطارات",
    icon: Signout,
    component: Logout,
    layout: "/admin",
  },
];

export default dashboardRoutes;
