import React, { useState, useEffect } from "react"
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";

const MultiFiltro = (props) => {
    const { itens, setIds, chaveLabel, chaveID } = props
    const [itensSelecao, setItensSelecao] = useState([])
    useEffect(() => {
        setItensSelecao(itens.map(it => ({
            selecionado: false,
            dado: it
        })))
        setIds([])

    }, [itens])

    return (<>
        <GridContainer>
            {itensSelecao.map((it, thisIndex) => <GridItem xs={12} sm={12} md={12} key={it.dado.ID}>
                <div style={{ border: "2px solid black" }}
                    onClick={
                        () => {
                            setItensSelecao(prevState => {
                                return prevState.map((item, index) => thisIndex !== index ? item : { ...item, selecionado: !item.selecionado })
                            });
                            setIds(prevState => {
                                const arr = prevState.slice();
                                const idx = prevState.indexOf(it.dado[chaveID]);
                                console.log(idx)
                                if (idx === -1) arr.push(it.dado[chaveID])
                                else arr.splice(idx, 1)
                                return arr;
                            })
                        }
                    }>
                    {it.selecionado ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />} <span>{it.dado[chaveLabel]}</span>
                </div>
            </GridItem>)}
        </GridContainer>
    </>)
}
MultiFiltro.propTypes = {
    itens: PropTypes.array,
    setIds: PropTypes.func,
    chaveLabel: PropTypes.string,
    chaveID: PropTypes.string
}

export default MultiFiltro