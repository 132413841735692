import React, { useEffect, useState } from "react";
// @material-ui/core components
// core components
import { makeStyles } from "@material-ui/core/styles";
import Logout from "components/Logout";
//import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody";
import logout from "js_utils/logout";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.js";
import DatePicker from "react-datepicker";
import Table from "components/Table/Table.js";

const { getAutenticado, formataValorReais } = require("../../js_utils/util");

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

export default function Movimentacoes() {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const { API_URL } = require("../../consts");
  //let [logado, setLogado] = useState(true);
  const logado = true;
  const [resumoMov, setResumoMov] = useState([]);
  const [dataInicial, setDataInicial] = useState(null);
  const [dataFinal, setDataFinal] = useState(null);
  /*const [dataFinal, setDataFinal] = useState(null);*/

  //Snackbar
  let [mensagemErro, setMensagemErro] = useState("");
  const [alerta, setAlerta] = useState(false);
  const [cor, setCor] = useState("warning");
  const showNotification = () => {
    if (!alerta) {
      setAlerta(true);
      setTimeout(function () {
        setAlerta(false);
      }, 6000);
    }
  };
  const mostraMensagem = (msg, cor) => {
    setCor(cor);
    setMensagemErro(msg);
    showNotification();
  };
  const buscaDados = async () => {
    if (dataFinal && dataInicial && dataInicial > dataFinal) {
      mostraMensagem("Erro: data inicial posterior à data final", "danger");
      setDataFinal(null);
      setDataInicial(null);
    }
    const pesquisa = {};
    if (dataInicial) {
      pesquisa.dataInicial = dataInicial;
    }
    if (dataFinal) {
      pesquisa.dataFinal = dataFinal;
    }
    let query = "";
    if (dataInicial) {
      query = "dataInicial=" + dataInicial.toISOString();
    }
    if (dataFinal) {
      query ? (query += "&") : "";
      query += "dataFinal=" + dataFinal.toISOString();
    }
    try {
      let resultados = await getAutenticado(
        API_URL + "resumomovimentacoes?" + query,
      );
      if (resultados.data.sucesso) {
        let resumos = resultados.data.resultado;
        setResumoMov(resumos);
        mostraMensagem("Dados carregados!", "success");
      } else {
        mostraMensagem("Erro interno.", "danger");
      }
    } catch (error) {
      mostraMensagem(JSON.stringify(error), "danger");
      logout();
    }
  };
  useEffect(async () => {
    buscaDados();
  }, [dataInicial, dataFinal]);
  return logado ? (
    <div>
      <Card>
        <CardHeader color="primary">
          <h5 className={classes.cardTitleWhite}>Movimentações</h5>
        </CardHeader>
        <CardBody>
          <div style={{ width: "50%" }}>
            <h4>De</h4>
            <DatePicker
              style={{ backgroundColor: "#010101", width: "50%" }}
              selected={dataInicial}
              onChange={(value) => {
                console.log(value);
                setDataInicial(value);
              }}
              dateFormat="dd/MM/yyyy'"
            />
            <h4>Até</h4>
            <DatePicker
              style={{ backgroundColor: "#010101" }}
              selected={dataFinal}
              onChange={(value) => {
                console.log(value);
                setDataFinal(value);
              }}
              dateFormat="dd/MM/yyyy'"
            />
          </div>
          <Table
            tableHeaderColor="primary"
            tableHead={["C/D", "Descrição", "Valor"]}
            tableData={resumoMov.map((linha) => [
              linha.CD,
              linha.DESCRICAO,
              formataValorReais(linha.VALOR),
            ])}
          />
        </CardBody>
      </Card>
      <Snackbar
        place="bc"
        color={cor || "warning"}
        icon={AddAlert}
        message={mensagemErro}
        open={alerta}
        closeNotification={() => setAlerta(false)}
        close
      />
    </div>
  ) : (
    <Logout></Logout>
  );
}
