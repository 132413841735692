import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Logout from "components/Logout";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.js";

import DatePicker from "react-datepicker";

import { triggerBase64Download } from "common-base64-downloader-react";

const {
  //   getAutenticado,
  postAutenticado,
  formataDataHoraSQL,
  //   formataValorReais,
  //   formataDataHoraSQL,
} = require("../../js_utils/util");

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

const { API_URL } = require("../../consts");
export default function ExportacaoParcelasVirtuoso() {
  //let [logado, setLogado] = useState(true);
  const logado = true;
  //Snackbar
  let [mensagemErro, setMensagemErro] = useState("");
  const [dataInicial, setDataInicial] = useState(null);
  const [dataFinal, setDataFinal] = useState(null);
  const [alerta, setAlerta] = useState(false);
  const [cor, setCor] = useState("warning");

  const showNotification = () => {
    if (!alerta) {
      setAlerta(true);
      setTimeout(function () {
        setAlerta(false);
      }, 6000);
    }
  };

  const mostraMensagem = (msg, cor) => {
    setCor(cor);
    setMensagemErro(msg);
    showNotification();
  };

  const iniciaDownloadPlanilha = async () => {
    try {
      const resultados = await postAutenticado(
        API_URL + "exportacaovirtuoso/",
        {
          dataInicial: formataDataHoraSQL(dataInicial || "0001-01-01"),
          dataFinal: formataDataHoraSQL(dataFinal || "9999-12-31", true),
        }
      );
      if (resultados.data.sucesso)
        triggerBase64Download(resultados.data.data, "planilha_virtuoso");
      else {
        mostraMensagem("Problema ao carregar a planilha", "danger");
      }
    } catch (error) {
      mostraMensagem(error.message, "danger");
    }
  };

  const classes = useStyles();
  return logado ? (
    <div>
      <Card>
        <CardHeader color="primary">
          <h5 className={classes.cardTitleWhite}>
            Exportação Parcelas Virtuoso
          </h5>
        </CardHeader>
        <CardBody>
          <div style={{ width: "50%" }}>
            <h3>Vencimento</h3>
            De:
            <DatePicker
              style={{ backgroundColor: "#010101", width: "50%" }}
              selected={dataInicial}
              onChange={setDataInicial}
              dateFormat="dd/MM/yyyy'"
            />
            Até:
            <DatePicker
              style={{ backgroundColor: "#010101", width: "50%" }}
              selected={dataFinal}
              onChange={setDataFinal}
              dateFormat="dd/MM/yyyy'"
            />
          </div>
          <Button onClick={iniciaDownloadPlanilha} color="success">
            Baixar
          </Button>
        </CardBody>
      </Card>
      <Snackbar
        place="bc"
        color={cor || "warning"}
        icon={AddAlert}
        message={mensagemErro}
        open={alerta}
        closeNotification={() => setAlerta(false)}
        close
      />
    </div>
  ) : (
    <Logout></Logout>
  );
}
