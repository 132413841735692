const estados = {
  data: [
    {
      SIGLA: "RO",
      NOME: "Rondônia",
      CODIGO_IBGE: 11,
    },
    {
      SIGLA: "AC",
      NOME: "Acre",
      CODIGO_IBGE: 12,
    },
    {
      SIGLA: "AM",
      NOME: "Amazonas",
      CODIGO_IBGE: 13,
    },
    {
      SIGLA: "RR",
      NOME: "Roraima",
      CODIGO_IBGE: 14,
    },
    {
      SIGLA: "PA",
      NOME: "Pará",
      CODIGO_IBGE: 15,
    },
    {
      SIGLA: "AP",
      NOME: "Amapá",
      CODIGO_IBGE: 16,
    },
    {
      SIGLA: "TO",
      NOME: "Tocantins",
      CODIGO_IBGE: 17,
    },
    {
      SIGLA: "MA",
      NOME: "Maranhão",
      CODIGO_IBGE: 21,
    },
    {
      SIGLA: "PI",
      NOME: "Piauí",
      CODIGO_IBGE: 22,
    },
    {
      SIGLA: "CE",
      NOME: "Ceará",
      CODIGO_IBGE: 23,
    },
    {
      SIGLA: "RN",
      NOME: "Rio Grande do Norte",
      CODIGO_IBGE: 24,
    },
    {
      SIGLA: "PB",
      NOME: "Paraíba",
      CODIGO_IBGE: 25,
    },
    {
      SIGLA: "PE",
      NOME: "Pernambuco",
      CODIGO_IBGE: 26,
    },
    {
      SIGLA: "AL",
      NOME: "Alagoas",
      CODIGO_IBGE: 27,
    },
    {
      SIGLA: "SE",
      NOME: "Sergipe",
      CODIGO_IBGE: 28,
    },
    {
      SIGLA: "BA",
      NOME: "Bahia",
      CODIGO_IBGE: 29,
    },
    {
      SIGLA: "MG",
      NOME: "Minas Gerais",
      CODIGO_IBGE: 31,
    },
    {
      SIGLA: "ES",
      NOME: "Espírito Santo",
      CODIGO_IBGE: 32,
    },
    {
      SIGLA: "RJ",
      NOME: "Rio de Janeiro",
      CODIGO_IBGE: 33,
    },
    {
      SIGLA: "SP",
      NOME: "São Paulo",
      CODIGO_IBGE: 35,
    },
    {
      SIGLA: "PR",
      NOME: "Paraná",
      CODIGO_IBGE: 41,
    },
    {
      SIGLA: "SC",
      NOME: "Santa Catarina",
      CODIGO_IBGE: 42,
    },
    {
      SIGLA: "RS",
      NOME: "Rio Grande do Sul",
      CODIGO_IBGE: 43,
    },
    {
      SIGLA: "MS",
      NOME: "Mato Grosso do Sul",
      CODIGO_IBGE: 50,
    },
    {
      SIGLA: "MT",
      NOME: "Mato Grosso",
      CODIGO_IBGE: 51,
    },
    {
      SIGLA: "GO",
      NOME: "Goiás",
      CODIGO_IBGE: 52,
    },
    {
      SIGLA: "DF",
      NOME: "Distrito Federal",
      CODIGO_IBGE: 53,
    },
  ],
};

const cidades = {
  data: {
    11: [
      {
        NOME: "Alta Floresta D'Oeste",
        CODIGO_IBGE: 1100015,
      },
      {
        NOME: "Ariquemes",
        CODIGO_IBGE: 1100023,
      },
      {
        NOME: "Cabixi",
        CODIGO_IBGE: 1100031,
      },
      {
        NOME: "Cacoal",
        CODIGO_IBGE: 1100049,
      },
      {
        NOME: "Cerejeiras",
        CODIGO_IBGE: 1100056,
      },
      {
        NOME: "Colorado do Oeste",
        CODIGO_IBGE: 1100064,
      },
      {
        NOME: "Corumbiara",
        CODIGO_IBGE: 1100072,
      },
      {
        NOME: "Costa Marques",
        CODIGO_IBGE: 1100080,
      },
      {
        NOME: "Espigão D'Oeste",
        CODIGO_IBGE: 1100098,
      },
      {
        NOME: "Guajará-Mirim",
        CODIGO_IBGE: 1100106,
      },
      {
        NOME: "Jaru",
        CODIGO_IBGE: 1100114,
      },
      {
        NOME: "Ji-Paraná",
        CODIGO_IBGE: 1100122,
      },
      {
        NOME: "Machadinho D'Oeste",
        CODIGO_IBGE: 1100130,
      },
      {
        NOME: "Nova Brasilândia D'Oeste",
        CODIGO_IBGE: 1100148,
      },
      {
        NOME: "Ouro Preto do Oeste",
        CODIGO_IBGE: 1100155,
      },
      {
        NOME: "Pimenta Bueno",
        CODIGO_IBGE: 1100189,
      },
      {
        NOME: "Porto Velho",
        CODIGO_IBGE: 1100205,
      },
      {
        NOME: "Presidente Médici",
        CODIGO_IBGE: 1100254,
      },
      {
        NOME: "Rio Crespo",
        CODIGO_IBGE: 1100262,
      },
      {
        NOME: "Rolim de Moura",
        CODIGO_IBGE: 1100288,
      },
      {
        NOME: "Santa Luzia D'Oeste",
        CODIGO_IBGE: 1100296,
      },
      {
        NOME: "Vilhena",
        CODIGO_IBGE: 1100304,
      },
      {
        NOME: "São Miguel do Guaporé",
        CODIGO_IBGE: 1100320,
      },
      {
        NOME: "Nova Mamoré",
        CODIGO_IBGE: 1100338,
      },
      {
        NOME: "Alvorada D'Oeste",
        CODIGO_IBGE: 1100346,
      },
      {
        NOME: "Alto Alegre dos Parecis",
        CODIGO_IBGE: 1100379,
      },
      {
        NOME: "Alto Paraíso",
        CODIGO_IBGE: 1100403,
      },
      {
        NOME: "Buritis",
        CODIGO_IBGE: 1100452,
      },
      {
        NOME: "Novo Horizonte do Oeste",
        CODIGO_IBGE: 1100502,
      },
      {
        NOME: "Cacaulândia",
        CODIGO_IBGE: 1100601,
      },
      {
        NOME: "Campo Novo de Rondônia",
        CODIGO_IBGE: 1100700,
      },
      {
        NOME: "Candeias do Jamari",
        CODIGO_IBGE: 1100809,
      },
      {
        NOME: "Castanheiras",
        CODIGO_IBGE: 1100908,
      },
      {
        NOME: "Chupinguaia",
        CODIGO_IBGE: 1100924,
      },
      {
        NOME: "Cujubim",
        CODIGO_IBGE: 1100940,
      },
      {
        NOME: "Governador Jorge Teixeira",
        CODIGO_IBGE: 1101005,
      },
      {
        NOME: "Itapuã do Oeste",
        CODIGO_IBGE: 1101104,
      },
      {
        NOME: "Ministro Andreazza",
        CODIGO_IBGE: 1101203,
      },
      {
        NOME: "Mirante da Serra",
        CODIGO_IBGE: 1101302,
      },
      {
        NOME: "Monte Negro",
        CODIGO_IBGE: 1101401,
      },
      {
        NOME: "Nova União",
        CODIGO_IBGE: 1101435,
      },
      {
        NOME: "Parecis",
        CODIGO_IBGE: 1101450,
      },
      {
        NOME: "Pimenteiras do Oeste",
        CODIGO_IBGE: 1101468,
      },
      {
        NOME: "Primavera de Rondônia",
        CODIGO_IBGE: 1101476,
      },
      {
        NOME: "São Felipe D'Oeste",
        CODIGO_IBGE: 1101484,
      },
      {
        NOME: "São Francisco do Guaporé",
        CODIGO_IBGE: 1101492,
      },
      {
        NOME: "Seringueiras",
        CODIGO_IBGE: 1101500,
      },
      {
        NOME: "Teixeirópolis",
        CODIGO_IBGE: 1101559,
      },
      {
        NOME: "Theobroma",
        CODIGO_IBGE: 1101609,
      },
      {
        NOME: "Urupá",
        CODIGO_IBGE: 1101708,
      },
      {
        NOME: "Vale do Anari",
        CODIGO_IBGE: 1101757,
      },
      {
        NOME: "Vale do Paraíso",
        CODIGO_IBGE: 1101807,
      },
    ],
    12: [
      {
        NOME: "Acrelândia",
        CODIGO_IBGE: 1200013,
      },
      {
        NOME: "Assis Brasil",
        CODIGO_IBGE: 1200054,
      },
      {
        NOME: "Brasiléia",
        CODIGO_IBGE: 1200104,
      },
      {
        NOME: "Bujari",
        CODIGO_IBGE: 1200138,
      },
      {
        NOME: "Capixaba",
        CODIGO_IBGE: 1200179,
      },
      {
        NOME: "Cruzeiro do Sul",
        CODIGO_IBGE: 1200203,
      },
      {
        NOME: "Epitaciolândia",
        CODIGO_IBGE: 1200252,
      },
      {
        NOME: "Feijó",
        CODIGO_IBGE: 1200302,
      },
      {
        NOME: "Jordão",
        CODIGO_IBGE: 1200328,
      },
      {
        NOME: "Mâncio Lima",
        CODIGO_IBGE: 1200336,
      },
      {
        NOME: "Manoel Urbano",
        CODIGO_IBGE: 1200344,
      },
      {
        NOME: "Marechal Thaumaturgo",
        CODIGO_IBGE: 1200351,
      },
      {
        NOME: "Plácido de Castro",
        CODIGO_IBGE: 1200385,
      },
      {
        NOME: "Porto Walter",
        CODIGO_IBGE: 1200393,
      },
      {
        NOME: "Rio Branco",
        CODIGO_IBGE: 1200401,
      },
      {
        NOME: "Rodrigues Alves",
        CODIGO_IBGE: 1200427,
      },
      {
        NOME: "Santa Rosa do Purus",
        CODIGO_IBGE: 1200435,
      },
      {
        NOME: "Senador Guiomard",
        CODIGO_IBGE: 1200450,
      },
      {
        NOME: "Sena Madureira",
        CODIGO_IBGE: 1200500,
      },
      {
        NOME: "Tarauacá",
        CODIGO_IBGE: 1200609,
      },
      {
        NOME: "Xapuri",
        CODIGO_IBGE: 1200708,
      },
      {
        NOME: "Porto Acre",
        CODIGO_IBGE: 1200807,
      },
    ],
    13: [
      {
        NOME: "Alvarães",
        CODIGO_IBGE: 1300029,
      },
      {
        NOME: "Amaturá",
        CODIGO_IBGE: 1300060,
      },
      {
        NOME: "Anamã",
        CODIGO_IBGE: 1300086,
      },
      {
        NOME: "Anori",
        CODIGO_IBGE: 1300102,
      },
      {
        NOME: "Apuí",
        CODIGO_IBGE: 1300144,
      },
      {
        NOME: "Atalaia do Norte",
        CODIGO_IBGE: 1300201,
      },
      {
        NOME: "Autazes",
        CODIGO_IBGE: 1300300,
      },
      {
        NOME: "Barcelos",
        CODIGO_IBGE: 1300409,
      },
      {
        NOME: "Barreirinha",
        CODIGO_IBGE: 1300508,
      },
      {
        NOME: "Benjamin Constant",
        CODIGO_IBGE: 1300607,
      },
      {
        NOME: "Beruri",
        CODIGO_IBGE: 1300631,
      },
      {
        NOME: "Boa Vista do Ramos",
        CODIGO_IBGE: 1300680,
      },
      {
        NOME: "Boca do Acre",
        CODIGO_IBGE: 1300706,
      },
      {
        NOME: "Borba",
        CODIGO_IBGE: 1300805,
      },
      {
        NOME: "Caapiranga",
        CODIGO_IBGE: 1300839,
      },
      {
        NOME: "Canutama",
        CODIGO_IBGE: 1300904,
      },
      {
        NOME: "Carauari",
        CODIGO_IBGE: 1301001,
      },
      {
        NOME: "Careiro",
        CODIGO_IBGE: 1301100,
      },
      {
        NOME: "Careiro da Várzea",
        CODIGO_IBGE: 1301159,
      },
      {
        NOME: "Coari",
        CODIGO_IBGE: 1301209,
      },
      {
        NOME: "Codajás",
        CODIGO_IBGE: 1301308,
      },
      {
        NOME: "Eirunepé",
        CODIGO_IBGE: 1301407,
      },
      {
        NOME: "Envira",
        CODIGO_IBGE: 1301506,
      },
      {
        NOME: "Fonte Boa",
        CODIGO_IBGE: 1301605,
      },
      {
        NOME: "Guajará",
        CODIGO_IBGE: 1301654,
      },
      {
        NOME: "Humaitá",
        CODIGO_IBGE: 1301704,
      },
      {
        NOME: "Ipixuna",
        CODIGO_IBGE: 1301803,
      },
      {
        NOME: "Iranduba",
        CODIGO_IBGE: 1301852,
      },
      {
        NOME: "Itacoatiara",
        CODIGO_IBGE: 1301902,
      },
      {
        NOME: "Itamarati",
        CODIGO_IBGE: 1301951,
      },
      {
        NOME: "Itapiranga",
        CODIGO_IBGE: 1302009,
      },
      {
        NOME: "Japurá",
        CODIGO_IBGE: 1302108,
      },
      {
        NOME: "Juruá",
        CODIGO_IBGE: 1302207,
      },
      {
        NOME: "Jutaí",
        CODIGO_IBGE: 1302306,
      },
      {
        NOME: "Lábrea",
        CODIGO_IBGE: 1302405,
      },
      {
        NOME: "Manacapuru",
        CODIGO_IBGE: 1302504,
      },
      {
        NOME: "Manaquiri",
        CODIGO_IBGE: 1302553,
      },
      {
        NOME: "Manaus",
        CODIGO_IBGE: 1302603,
      },
      {
        NOME: "Manicoré",
        CODIGO_IBGE: 1302702,
      },
      {
        NOME: "Maraã",
        CODIGO_IBGE: 1302801,
      },
      {
        NOME: "Maués",
        CODIGO_IBGE: 1302900,
      },
      {
        NOME: "Nhamundá",
        CODIGO_IBGE: 1303007,
      },
      {
        NOME: "Nova Olinda do Norte",
        CODIGO_IBGE: 1303106,
      },
      {
        NOME: "Novo Airão",
        CODIGO_IBGE: 1303205,
      },
      {
        NOME: "Novo Aripuanã",
        CODIGO_IBGE: 1303304,
      },
      {
        NOME: "Parintins",
        CODIGO_IBGE: 1303403,
      },
      {
        NOME: "Pauini",
        CODIGO_IBGE: 1303502,
      },
      {
        NOME: "Presidente Figueiredo",
        CODIGO_IBGE: 1303536,
      },
      {
        NOME: "Rio Preto da Eva",
        CODIGO_IBGE: 1303569,
      },
      {
        NOME: "Santa Isabel do Rio Negro",
        CODIGO_IBGE: 1303601,
      },
      {
        NOME: "Santo Antônio do Içá",
        CODIGO_IBGE: 1303700,
      },
      {
        NOME: "São Gabriel da Cachoeira",
        CODIGO_IBGE: 1303809,
      },
      {
        NOME: "São Paulo de Olivença",
        CODIGO_IBGE: 1303908,
      },
      {
        NOME: "São Sebastião do Uatumã",
        CODIGO_IBGE: 1303957,
      },
      {
        NOME: "Silves",
        CODIGO_IBGE: 1304005,
      },
      {
        NOME: "Tabatinga",
        CODIGO_IBGE: 1304062,
      },
      {
        NOME: "Tapauá",
        CODIGO_IBGE: 1304104,
      },
      {
        NOME: "Tefé",
        CODIGO_IBGE: 1304203,
      },
      {
        NOME: "Tonantins",
        CODIGO_IBGE: 1304237,
      },
      {
        NOME: "Uarini",
        CODIGO_IBGE: 1304260,
      },
      {
        NOME: "Urucará",
        CODIGO_IBGE: 1304302,
      },
      {
        NOME: "Urucurituba",
        CODIGO_IBGE: 1304401,
      },
    ],
    14: [
      {
        NOME: "Amajari",
        CODIGO_IBGE: 1400027,
      },
      {
        NOME: "Alto Alegre",
        CODIGO_IBGE: 1400050,
      },
      {
        NOME: "Boa Vista",
        CODIGO_IBGE: 1400100,
      },
      {
        NOME: "Bonfim",
        CODIGO_IBGE: 1400159,
      },
      {
        NOME: "Cantá",
        CODIGO_IBGE: 1400175,
      },
      {
        NOME: "Caracaraí",
        CODIGO_IBGE: 1400209,
      },
      {
        NOME: "Caroebe",
        CODIGO_IBGE: 1400233,
      },
      {
        NOME: "Iracema",
        CODIGO_IBGE: 1400282,
      },
      {
        NOME: "Mucajaí",
        CODIGO_IBGE: 1400308,
      },
      {
        NOME: "Normandia",
        CODIGO_IBGE: 1400407,
      },
      {
        NOME: "Pacaraima",
        CODIGO_IBGE: 1400456,
      },
      {
        NOME: "Rorainópolis",
        CODIGO_IBGE: 1400472,
      },
      {
        NOME: "São João da Baliza",
        CODIGO_IBGE: 1400506,
      },
      {
        NOME: "São Luiz",
        CODIGO_IBGE: 1400605,
      },
      {
        NOME: "Uiramutã",
        CODIGO_IBGE: 1400704,
      },
    ],
    15: [
      {
        NOME: "Abaetetuba",
        CODIGO_IBGE: 1500107,
      },
      {
        NOME: "Abel Figueiredo",
        CODIGO_IBGE: 1500131,
      },
      {
        NOME: "Acará",
        CODIGO_IBGE: 1500206,
      },
      {
        NOME: "Afuá",
        CODIGO_IBGE: 1500305,
      },
      {
        NOME: "Água Azul do Norte",
        CODIGO_IBGE: 1500347,
      },
      {
        NOME: "Alenquer",
        CODIGO_IBGE: 1500404,
      },
      {
        NOME: "Almeirim",
        CODIGO_IBGE: 1500503,
      },
      {
        NOME: "Altamira",
        CODIGO_IBGE: 1500602,
      },
      {
        NOME: "Anajás",
        CODIGO_IBGE: 1500701,
      },
      {
        NOME: "Ananindeua",
        CODIGO_IBGE: 1500800,
      },
      {
        NOME: "Anapu",
        CODIGO_IBGE: 1500859,
      },
      {
        NOME: "Augusto Corrêa",
        CODIGO_IBGE: 1500909,
      },
      {
        NOME: "Aurora do Pará",
        CODIGO_IBGE: 1500958,
      },
      {
        NOME: "Aveiro",
        CODIGO_IBGE: 1501006,
      },
      {
        NOME: "Bagre",
        CODIGO_IBGE: 1501105,
      },
      {
        NOME: "Baião",
        CODIGO_IBGE: 1501204,
      },
      {
        NOME: "Bannach",
        CODIGO_IBGE: 1501253,
      },
      {
        NOME: "Barcarena",
        CODIGO_IBGE: 1501303,
      },
      {
        NOME: "Belém",
        CODIGO_IBGE: 1501402,
      },
      {
        NOME: "Belterra",
        CODIGO_IBGE: 1501451,
      },
      {
        NOME: "Benevides",
        CODIGO_IBGE: 1501501,
      },
      {
        NOME: "Bom Jesus do Tocantins",
        CODIGO_IBGE: 1501576,
      },
      {
        NOME: "Bonito",
        CODIGO_IBGE: 1501600,
      },
      {
        NOME: "Bragança",
        CODIGO_IBGE: 1501709,
      },
      {
        NOME: "Brasil Novo",
        CODIGO_IBGE: 1501725,
      },
      {
        NOME: "Brejo Grande do Araguaia",
        CODIGO_IBGE: 1501758,
      },
      {
        NOME: "Breu Branco",
        CODIGO_IBGE: 1501782,
      },
      {
        NOME: "Breves",
        CODIGO_IBGE: 1501808,
      },
      {
        NOME: "Bujaru",
        CODIGO_IBGE: 1501907,
      },
      {
        NOME: "Cachoeira do Piriá",
        CODIGO_IBGE: 1501956,
      },
      {
        NOME: "Cachoeira do Arari",
        CODIGO_IBGE: 1502004,
      },
      {
        NOME: "Cametá",
        CODIGO_IBGE: 1502103,
      },
      {
        NOME: "Canaã dos Carajás",
        CODIGO_IBGE: 1502152,
      },
      {
        NOME: "Capanema",
        CODIGO_IBGE: 1502202,
      },
      {
        NOME: "Capitão Poço",
        CODIGO_IBGE: 1502301,
      },
      {
        NOME: "Castanhal",
        CODIGO_IBGE: 1502400,
      },
      {
        NOME: "Chaves",
        CODIGO_IBGE: 1502509,
      },
      {
        NOME: "Colares",
        CODIGO_IBGE: 1502608,
      },
      {
        NOME: "Conceição do Araguaia",
        CODIGO_IBGE: 1502707,
      },
      {
        NOME: "Concórdia do Pará",
        CODIGO_IBGE: 1502756,
      },
      {
        NOME: "Cumaru do Norte",
        CODIGO_IBGE: 1502764,
      },
      {
        NOME: "Curionópolis",
        CODIGO_IBGE: 1502772,
      },
      {
        NOME: "Curralinho",
        CODIGO_IBGE: 1502806,
      },
      {
        NOME: "Curuá",
        CODIGO_IBGE: 1502855,
      },
      {
        NOME: "Curuçá",
        CODIGO_IBGE: 1502905,
      },
      {
        NOME: "Dom Eliseu",
        CODIGO_IBGE: 1502939,
      },
      {
        NOME: "Eldorado do Carajás",
        CODIGO_IBGE: 1502954,
      },
      {
        NOME: "Faro",
        CODIGO_IBGE: 1503002,
      },
      {
        NOME: "Floresta do Araguaia",
        CODIGO_IBGE: 1503044,
      },
      {
        NOME: "Garrafão do Norte",
        CODIGO_IBGE: 1503077,
      },
      {
        NOME: "Goianésia do Pará",
        CODIGO_IBGE: 1503093,
      },
      {
        NOME: "Gurupá",
        CODIGO_IBGE: 1503101,
      },
      {
        NOME: "Igarapé-Açu",
        CODIGO_IBGE: 1503200,
      },
      {
        NOME: "Igarapé-Miri",
        CODIGO_IBGE: 1503309,
      },
      {
        NOME: "Inhangapi",
        CODIGO_IBGE: 1503408,
      },
      {
        NOME: "Ipixuna do Pará",
        CODIGO_IBGE: 1503457,
      },
      {
        NOME: "Irituia",
        CODIGO_IBGE: 1503507,
      },
      {
        NOME: "Itaituba",
        CODIGO_IBGE: 1503606,
      },
      {
        NOME: "Itupiranga",
        CODIGO_IBGE: 1503705,
      },
      {
        NOME: "Jacareacanga",
        CODIGO_IBGE: 1503754,
      },
      {
        NOME: "Jacundá",
        CODIGO_IBGE: 1503804,
      },
      {
        NOME: "Juruti",
        CODIGO_IBGE: 1503903,
      },
      {
        NOME: "Limoeiro do Ajuru",
        CODIGO_IBGE: 1504000,
      },
      {
        NOME: "Mãe do Rio",
        CODIGO_IBGE: 1504059,
      },
      {
        NOME: "Magalhães Barata",
        CODIGO_IBGE: 1504109,
      },
      {
        NOME: "Marabá",
        CODIGO_IBGE: 1504208,
      },
      {
        NOME: "Maracanã",
        CODIGO_IBGE: 1504307,
      },
      {
        NOME: "Marapanim",
        CODIGO_IBGE: 1504406,
      },
      {
        NOME: "Marituba",
        CODIGO_IBGE: 1504422,
      },
      {
        NOME: "Medicilândia",
        CODIGO_IBGE: 1504455,
      },
      {
        NOME: "Melgaço",
        CODIGO_IBGE: 1504505,
      },
      {
        NOME: "Mocajuba",
        CODIGO_IBGE: 1504604,
      },
      {
        NOME: "Moju",
        CODIGO_IBGE: 1504703,
      },
      {
        NOME: "Mojuí dos Campos",
        CODIGO_IBGE: 1504752,
      },
      {
        NOME: "Monte Alegre",
        CODIGO_IBGE: 1504802,
      },
      {
        NOME: "Muaná",
        CODIGO_IBGE: 1504901,
      },
      {
        NOME: "Nova Esperança do Piriá",
        CODIGO_IBGE: 1504950,
      },
      {
        NOME: "Nova Ipixuna",
        CODIGO_IBGE: 1504976,
      },
      {
        NOME: "Nova Timboteua",
        CODIGO_IBGE: 1505007,
      },
      {
        NOME: "Novo Progresso",
        CODIGO_IBGE: 1505031,
      },
      {
        NOME: "Novo Repartimento",
        CODIGO_IBGE: 1505064,
      },
      {
        NOME: "Óbidos",
        CODIGO_IBGE: 1505106,
      },
      {
        NOME: "Oeiras do Pará",
        CODIGO_IBGE: 1505205,
      },
      {
        NOME: "Oriximiná",
        CODIGO_IBGE: 1505304,
      },
      {
        NOME: "Ourém",
        CODIGO_IBGE: 1505403,
      },
      {
        NOME: "Ourilândia do Norte",
        CODIGO_IBGE: 1505437,
      },
      {
        NOME: "Pacajá",
        CODIGO_IBGE: 1505486,
      },
      {
        NOME: "Palestina do Pará",
        CODIGO_IBGE: 1505494,
      },
      {
        NOME: "Paragominas",
        CODIGO_IBGE: 1505502,
      },
      {
        NOME: "Parauapebas",
        CODIGO_IBGE: 1505536,
      },
      {
        NOME: "Pau D'Arco",
        CODIGO_IBGE: 1505551,
      },
      {
        NOME: "Peixe-Boi",
        CODIGO_IBGE: 1505601,
      },
      {
        NOME: "Piçarra",
        CODIGO_IBGE: 1505635,
      },
      {
        NOME: "Placas",
        CODIGO_IBGE: 1505650,
      },
      {
        NOME: "Ponta de Pedras",
        CODIGO_IBGE: 1505700,
      },
      {
        NOME: "Portel",
        CODIGO_IBGE: 1505809,
      },
      {
        NOME: "Porto de Moz",
        CODIGO_IBGE: 1505908,
      },
      {
        NOME: "Prainha",
        CODIGO_IBGE: 1506005,
      },
      {
        NOME: "Primavera",
        CODIGO_IBGE: 1506104,
      },
      {
        NOME: "Quatipuru",
        CODIGO_IBGE: 1506112,
      },
      {
        NOME: "Redenção",
        CODIGO_IBGE: 1506138,
      },
      {
        NOME: "Rio Maria",
        CODIGO_IBGE: 1506161,
      },
      {
        NOME: "Rondon do Pará",
        CODIGO_IBGE: 1506187,
      },
      {
        NOME: "Rurópolis",
        CODIGO_IBGE: 1506195,
      },
      {
        NOME: "Salinópolis",
        CODIGO_IBGE: 1506203,
      },
      {
        NOME: "Salvaterra",
        CODIGO_IBGE: 1506302,
      },
      {
        NOME: "Santa Bárbara do Pará",
        CODIGO_IBGE: 1506351,
      },
      {
        NOME: "Santa Cruz do Arari",
        CODIGO_IBGE: 1506401,
      },
      {
        NOME: "Santa Izabel do Pará",
        CODIGO_IBGE: 1506500,
      },
      {
        NOME: "Santa Luzia do Pará",
        CODIGO_IBGE: 1506559,
      },
      {
        NOME: "Santa Maria das Barreiras",
        CODIGO_IBGE: 1506583,
      },
      {
        NOME: "Santa Maria do Pará",
        CODIGO_IBGE: 1506609,
      },
      {
        NOME: "Santana do Araguaia",
        CODIGO_IBGE: 1506708,
      },
      {
        NOME: "Santarém",
        CODIGO_IBGE: 1506807,
      },
      {
        NOME: "Santarém Novo",
        CODIGO_IBGE: 1506906,
      },
      {
        NOME: "Santo Antônio do Tauá",
        CODIGO_IBGE: 1507003,
      },
      {
        NOME: "São Caetano de Odivelas",
        CODIGO_IBGE: 1507102,
      },
      {
        NOME: "São Domingos do Araguaia",
        CODIGO_IBGE: 1507151,
      },
      {
        NOME: "São Domingos do Capim",
        CODIGO_IBGE: 1507201,
      },
      {
        NOME: "São Félix do Xingu",
        CODIGO_IBGE: 1507300,
      },
      {
        NOME: "São Francisco do Pará",
        CODIGO_IBGE: 1507409,
      },
      {
        NOME: "São Geraldo do Araguaia",
        CODIGO_IBGE: 1507458,
      },
      {
        NOME: "São João da Ponta",
        CODIGO_IBGE: 1507466,
      },
      {
        NOME: "São João de Pirabas",
        CODIGO_IBGE: 1507474,
      },
      {
        NOME: "São João do Araguaia",
        CODIGO_IBGE: 1507508,
      },
      {
        NOME: "São Miguel do Guamá",
        CODIGO_IBGE: 1507607,
      },
      {
        NOME: "São Sebastião da Boa Vista",
        CODIGO_IBGE: 1507706,
      },
      {
        NOME: "Sapucaia",
        CODIGO_IBGE: 1507755,
      },
      {
        NOME: "Senador José Porfírio",
        CODIGO_IBGE: 1507805,
      },
      {
        NOME: "Soure",
        CODIGO_IBGE: 1507904,
      },
      {
        NOME: "Tailândia",
        CODIGO_IBGE: 1507953,
      },
      {
        NOME: "Terra Alta",
        CODIGO_IBGE: 1507961,
      },
      {
        NOME: "Terra Santa",
        CODIGO_IBGE: 1507979,
      },
      {
        NOME: "Tomé-Açu",
        CODIGO_IBGE: 1508001,
      },
      {
        NOME: "Tracuateua",
        CODIGO_IBGE: 1508035,
      },
      {
        NOME: "Trairão",
        CODIGO_IBGE: 1508050,
      },
      {
        NOME: "Tucumã",
        CODIGO_IBGE: 1508084,
      },
      {
        NOME: "Tucuruí",
        CODIGO_IBGE: 1508100,
      },
      {
        NOME: "Ulianópolis",
        CODIGO_IBGE: 1508126,
      },
      {
        NOME: "Uruará",
        CODIGO_IBGE: 1508159,
      },
      {
        NOME: "Vigia",
        CODIGO_IBGE: 1508209,
      },
      {
        NOME: "Viseu",
        CODIGO_IBGE: 1508308,
      },
      {
        NOME: "Vitória do Xingu",
        CODIGO_IBGE: 1508357,
      },
      {
        NOME: "Xinguara",
        CODIGO_IBGE: 1508407,
      },
    ],
    16: [
      {
        NOME: "Serra do Navio",
        CODIGO_IBGE: 1600055,
      },
      {
        NOME: "Amapá",
        CODIGO_IBGE: 1600105,
      },
      {
        NOME: "Pedra Branca do Amapari",
        CODIGO_IBGE: 1600154,
      },
      {
        NOME: "Calçoene",
        CODIGO_IBGE: 1600204,
      },
      {
        NOME: "Cutias",
        CODIGO_IBGE: 1600212,
      },
      {
        NOME: "Ferreira Gomes",
        CODIGO_IBGE: 1600238,
      },
      {
        NOME: "Itaubal",
        CODIGO_IBGE: 1600253,
      },
      {
        NOME: "Laranjal do Jari",
        CODIGO_IBGE: 1600279,
      },
      {
        NOME: "Macapá",
        CODIGO_IBGE: 1600303,
      },
      {
        NOME: "Mazagão",
        CODIGO_IBGE: 1600402,
      },
      {
        NOME: "Oiapoque",
        CODIGO_IBGE: 1600501,
      },
      {
        NOME: "Porto Grande",
        CODIGO_IBGE: 1600535,
      },
      {
        NOME: "Pracuúba",
        CODIGO_IBGE: 1600550,
      },
      {
        NOME: "Santana",
        CODIGO_IBGE: 1600600,
      },
      {
        NOME: "Tartarugalzinho",
        CODIGO_IBGE: 1600709,
      },
      {
        NOME: "Vitória do Jari",
        CODIGO_IBGE: 1600808,
      },
    ],
    17: [
      {
        NOME: "Abreulândia",
        CODIGO_IBGE: 1700251,
      },
      {
        NOME: "Aguiarnópolis",
        CODIGO_IBGE: 1700301,
      },
      {
        NOME: "Aliança do Tocantins",
        CODIGO_IBGE: 1700350,
      },
      {
        NOME: "Almas",
        CODIGO_IBGE: 1700400,
      },
      {
        NOME: "Alvorada",
        CODIGO_IBGE: 1700707,
      },
      {
        NOME: "Ananás",
        CODIGO_IBGE: 1701002,
      },
      {
        NOME: "Angico",
        CODIGO_IBGE: 1701051,
      },
      {
        NOME: "Aparecida do Rio Negro",
        CODIGO_IBGE: 1701101,
      },
      {
        NOME: "Aragominas",
        CODIGO_IBGE: 1701309,
      },
      {
        NOME: "Araguacema",
        CODIGO_IBGE: 1701903,
      },
      {
        NOME: "Araguaçu",
        CODIGO_IBGE: 1702000,
      },
      {
        NOME: "Araguaína",
        CODIGO_IBGE: 1702109,
      },
      {
        NOME: "Araguanã",
        CODIGO_IBGE: 1702158,
      },
      {
        NOME: "Araguatins",
        CODIGO_IBGE: 1702208,
      },
      {
        NOME: "Arapoema",
        CODIGO_IBGE: 1702307,
      },
      {
        NOME: "Arraias",
        CODIGO_IBGE: 1702406,
      },
      {
        NOME: "Augustinópolis",
        CODIGO_IBGE: 1702554,
      },
      {
        NOME: "Aurora do Tocantins",
        CODIGO_IBGE: 1702703,
      },
      {
        NOME: "Axixá do Tocantins",
        CODIGO_IBGE: 1702901,
      },
      {
        NOME: "Babaçulândia",
        CODIGO_IBGE: 1703008,
      },
      {
        NOME: "Bandeirantes do Tocantins",
        CODIGO_IBGE: 1703057,
      },
      {
        NOME: "Barra do Ouro",
        CODIGO_IBGE: 1703073,
      },
      {
        NOME: "Barrolândia",
        CODIGO_IBGE: 1703107,
      },
      {
        NOME: "Bernardo Sayão",
        CODIGO_IBGE: 1703206,
      },
      {
        NOME: "Bom Jesus do Tocantins",
        CODIGO_IBGE: 1703305,
      },
      {
        NOME: "Brasilândia do Tocantins",
        CODIGO_IBGE: 1703602,
      },
      {
        NOME: "Brejinho de Nazaré",
        CODIGO_IBGE: 1703701,
      },
      {
        NOME: "Buriti do Tocantins",
        CODIGO_IBGE: 1703800,
      },
      {
        NOME: "Cachoeirinha",
        CODIGO_IBGE: 1703826,
      },
      {
        NOME: "Campos Lindos",
        CODIGO_IBGE: 1703842,
      },
      {
        NOME: "Cariri do Tocantins",
        CODIGO_IBGE: 1703867,
      },
      {
        NOME: "Carmolândia",
        CODIGO_IBGE: 1703883,
      },
      {
        NOME: "Carrasco Bonito",
        CODIGO_IBGE: 1703891,
      },
      {
        NOME: "Caseara",
        CODIGO_IBGE: 1703909,
      },
      {
        NOME: "Centenário",
        CODIGO_IBGE: 1704105,
      },
      {
        NOME: "Chapada de Areia",
        CODIGO_IBGE: 1704600,
      },
      {
        NOME: "Chapada da Natividade",
        CODIGO_IBGE: 1705102,
      },
      {
        NOME: "Colinas do Tocantins",
        CODIGO_IBGE: 1705508,
      },
      {
        NOME: "Combinado",
        CODIGO_IBGE: 1705557,
      },
      {
        NOME: "Conceição do Tocantins",
        CODIGO_IBGE: 1705607,
      },
      {
        NOME: "Couto Magalhães",
        CODIGO_IBGE: 1706001,
      },
      {
        NOME: "Cristalândia",
        CODIGO_IBGE: 1706100,
      },
      {
        NOME: "Crixás do Tocantins",
        CODIGO_IBGE: 1706258,
      },
      {
        NOME: "Darcinópolis",
        CODIGO_IBGE: 1706506,
      },
      {
        NOME: "Dianópolis",
        CODIGO_IBGE: 1707009,
      },
      {
        NOME: "Divinópolis do Tocantins",
        CODIGO_IBGE: 1707108,
      },
      {
        NOME: "Dois Irmãos do Tocantins",
        CODIGO_IBGE: 1707207,
      },
      {
        NOME: "Dueré",
        CODIGO_IBGE: 1707306,
      },
      {
        NOME: "Esperantina",
        CODIGO_IBGE: 1707405,
      },
      {
        NOME: "Fátima",
        CODIGO_IBGE: 1707553,
      },
      {
        NOME: "Figueirópolis",
        CODIGO_IBGE: 1707652,
      },
      {
        NOME: "Filadélfia",
        CODIGO_IBGE: 1707702,
      },
      {
        NOME: "Formoso do Araguaia",
        CODIGO_IBGE: 1708205,
      },
      {
        NOME: "Fortaleza do Tabocão",
        CODIGO_IBGE: 1708254,
      },
      {
        NOME: "Goianorte",
        CODIGO_IBGE: 1708304,
      },
      {
        NOME: "Goiatins",
        CODIGO_IBGE: 1709005,
      },
      {
        NOME: "Guaraí",
        CODIGO_IBGE: 1709302,
      },
      {
        NOME: "Gurupi",
        CODIGO_IBGE: 1709500,
      },
      {
        NOME: "Ipueiras",
        CODIGO_IBGE: 1709807,
      },
      {
        NOME: "Itacajá",
        CODIGO_IBGE: 1710508,
      },
      {
        NOME: "Itaguatins",
        CODIGO_IBGE: 1710706,
      },
      {
        NOME: "Itapiratins",
        CODIGO_IBGE: 1710904,
      },
      {
        NOME: "Itaporã do Tocantins",
        CODIGO_IBGE: 1711100,
      },
      {
        NOME: "Jaú do Tocantins",
        CODIGO_IBGE: 1711506,
      },
      {
        NOME: "Juarina",
        CODIGO_IBGE: 1711803,
      },
      {
        NOME: "Lagoa da Confusão",
        CODIGO_IBGE: 1711902,
      },
      {
        NOME: "Lagoa do Tocantins",
        CODIGO_IBGE: 1711951,
      },
      {
        NOME: "Lajeado",
        CODIGO_IBGE: 1712009,
      },
      {
        NOME: "Lavandeira",
        CODIGO_IBGE: 1712157,
      },
      {
        NOME: "Lizarda",
        CODIGO_IBGE: 1712405,
      },
      {
        NOME: "Luzinópolis",
        CODIGO_IBGE: 1712454,
      },
      {
        NOME: "Marianópolis do Tocantins",
        CODIGO_IBGE: 1712504,
      },
      {
        NOME: "Mateiros",
        CODIGO_IBGE: 1712702,
      },
      {
        NOME: "Maurilândia do Tocantins",
        CODIGO_IBGE: 1712801,
      },
      {
        NOME: "Miracema do Tocantins",
        CODIGO_IBGE: 1713205,
      },
      {
        NOME: "Miranorte",
        CODIGO_IBGE: 1713304,
      },
      {
        NOME: "Monte do Carmo",
        CODIGO_IBGE: 1713601,
      },
      {
        NOME: "Monte Santo do Tocantins",
        CODIGO_IBGE: 1713700,
      },
      {
        NOME: "Palmeiras do Tocantins",
        CODIGO_IBGE: 1713809,
      },
      {
        NOME: "Muricilândia",
        CODIGO_IBGE: 1713957,
      },
      {
        NOME: "Natividade",
        CODIGO_IBGE: 1714203,
      },
      {
        NOME: "Nazaré",
        CODIGO_IBGE: 1714302,
      },
      {
        NOME: "Nova Olinda",
        CODIGO_IBGE: 1714880,
      },
      {
        NOME: "Nova Rosalândia",
        CODIGO_IBGE: 1715002,
      },
      {
        NOME: "Novo Acordo",
        CODIGO_IBGE: 1715101,
      },
      {
        NOME: "Novo Alegre",
        CODIGO_IBGE: 1715150,
      },
      {
        NOME: "Novo Jardim",
        CODIGO_IBGE: 1715259,
      },
      {
        NOME: "Oliveira de Fátima",
        CODIGO_IBGE: 1715507,
      },
      {
        NOME: "Palmeirante",
        CODIGO_IBGE: 1715705,
      },
      {
        NOME: "Palmeirópolis",
        CODIGO_IBGE: 1715754,
      },
      {
        NOME: "Paraíso do Tocantins",
        CODIGO_IBGE: 1716109,
      },
      {
        NOME: "Paranã",
        CODIGO_IBGE: 1716208,
      },
      {
        NOME: "Pau D'Arco",
        CODIGO_IBGE: 1716307,
      },
      {
        NOME: "Pedro Afonso",
        CODIGO_IBGE: 1716505,
      },
      {
        NOME: "Peixe",
        CODIGO_IBGE: 1716604,
      },
      {
        NOME: "Pequizeiro",
        CODIGO_IBGE: 1716653,
      },
      {
        NOME: "Colméia",
        CODIGO_IBGE: 1716703,
      },
      {
        NOME: "Pindorama do Tocantins",
        CODIGO_IBGE: 1717008,
      },
      {
        NOME: "Piraquê",
        CODIGO_IBGE: 1717206,
      },
      {
        NOME: "Pium",
        CODIGO_IBGE: 1717503,
      },
      {
        NOME: "Ponte Alta do Bom Jesus",
        CODIGO_IBGE: 1717800,
      },
      {
        NOME: "Ponte Alta do Tocantins",
        CODIGO_IBGE: 1717909,
      },
      {
        NOME: "Porto Alegre do Tocantins",
        CODIGO_IBGE: 1718006,
      },
      {
        NOME: "Porto Nacional",
        CODIGO_IBGE: 1718204,
      },
      {
        NOME: "Praia Norte",
        CODIGO_IBGE: 1718303,
      },
      {
        NOME: "Presidente Kennedy",
        CODIGO_IBGE: 1718402,
      },
      {
        NOME: "Pugmil",
        CODIGO_IBGE: 1718451,
      },
      {
        NOME: "Recursolândia",
        CODIGO_IBGE: 1718501,
      },
      {
        NOME: "Riachinho",
        CODIGO_IBGE: 1718550,
      },
      {
        NOME: "Rio da Conceição",
        CODIGO_IBGE: 1718659,
      },
      {
        NOME: "Rio dos Bois",
        CODIGO_IBGE: 1718709,
      },
      {
        NOME: "Rio Sono",
        CODIGO_IBGE: 1718758,
      },
      {
        NOME: "Sampaio",
        CODIGO_IBGE: 1718808,
      },
      {
        NOME: "Sandolândia",
        CODIGO_IBGE: 1718840,
      },
      {
        NOME: "Santa Fé do Araguaia",
        CODIGO_IBGE: 1718865,
      },
      {
        NOME: "Santa Maria do Tocantins",
        CODIGO_IBGE: 1718881,
      },
      {
        NOME: "Santa Rita do Tocantins",
        CODIGO_IBGE: 1718899,
      },
      {
        NOME: "Santa Rosa do Tocantins",
        CODIGO_IBGE: 1718907,
      },
      {
        NOME: "Santa Tereza do Tocantins",
        CODIGO_IBGE: 1719004,
      },
      {
        NOME: "Santa Terezinha do Tocantins",
        CODIGO_IBGE: 1720002,
      },
      {
        NOME: "São Bento do Tocantins",
        CODIGO_IBGE: 1720101,
      },
      {
        NOME: "São Félix do Tocantins",
        CODIGO_IBGE: 1720150,
      },
      {
        NOME: "São Miguel do Tocantins",
        CODIGO_IBGE: 1720200,
      },
      {
        NOME: "São Salvador do Tocantins",
        CODIGO_IBGE: 1720259,
      },
      {
        NOME: "São Sebastião do Tocantins",
        CODIGO_IBGE: 1720309,
      },
      {
        NOME: "São Valério",
        CODIGO_IBGE: 1720499,
      },
      {
        NOME: "Silvanópolis",
        CODIGO_IBGE: 1720655,
      },
      {
        NOME: "Sítio Novo do Tocantins",
        CODIGO_IBGE: 1720804,
      },
      {
        NOME: "Sucupira",
        CODIGO_IBGE: 1720853,
      },
      {
        NOME: "Taguatinga",
        CODIGO_IBGE: 1720903,
      },
      {
        NOME: "Taipas do Tocantins",
        CODIGO_IBGE: 1720937,
      },
      {
        NOME: "Talismã",
        CODIGO_IBGE: 1720978,
      },
      {
        NOME: "Palmas",
        CODIGO_IBGE: 1721000,
      },
      {
        NOME: "Tocantínia",
        CODIGO_IBGE: 1721109,
      },
      {
        NOME: "Tocantinópolis",
        CODIGO_IBGE: 1721208,
      },
      {
        NOME: "Tupirama",
        CODIGO_IBGE: 1721257,
      },
      {
        NOME: "Tupiratins",
        CODIGO_IBGE: 1721307,
      },
      {
        NOME: "Wanderlândia",
        CODIGO_IBGE: 1722081,
      },
      {
        NOME: "Xambioá",
        CODIGO_IBGE: 1722107,
      },
    ],
    21: [
      {
        NOME: "Açailândia",
        CODIGO_IBGE: 2100055,
      },
      {
        NOME: "Afonso Cunha",
        CODIGO_IBGE: 2100105,
      },
      {
        NOME: "Água Doce do Maranhão",
        CODIGO_IBGE: 2100154,
      },
      {
        NOME: "Alcântara",
        CODIGO_IBGE: 2100204,
      },
      {
        NOME: "Aldeias Altas",
        CODIGO_IBGE: 2100303,
      },
      {
        NOME: "Altamira do Maranhão",
        CODIGO_IBGE: 2100402,
      },
      {
        NOME: "Alto Alegre do Maranhão",
        CODIGO_IBGE: 2100436,
      },
      {
        NOME: "Alto Alegre do Pindaré",
        CODIGO_IBGE: 2100477,
      },
      {
        NOME: "Alto Parnaíba",
        CODIGO_IBGE: 2100501,
      },
      {
        NOME: "Amapá do Maranhão",
        CODIGO_IBGE: 2100550,
      },
      {
        NOME: "Amarante do Maranhão",
        CODIGO_IBGE: 2100600,
      },
      {
        NOME: "Anajatuba",
        CODIGO_IBGE: 2100709,
      },
      {
        NOME: "Anapurus",
        CODIGO_IBGE: 2100808,
      },
      {
        NOME: "Apicum-Açu",
        CODIGO_IBGE: 2100832,
      },
      {
        NOME: "Araguanã",
        CODIGO_IBGE: 2100873,
      },
      {
        NOME: "Araioses",
        CODIGO_IBGE: 2100907,
      },
      {
        NOME: "Arame",
        CODIGO_IBGE: 2100956,
      },
      {
        NOME: "Arari",
        CODIGO_IBGE: 2101004,
      },
      {
        NOME: "Axixá",
        CODIGO_IBGE: 2101103,
      },
      {
        NOME: "Bacabal",
        CODIGO_IBGE: 2101202,
      },
      {
        NOME: "Bacabeira",
        CODIGO_IBGE: 2101251,
      },
      {
        NOME: "Bacuri",
        CODIGO_IBGE: 2101301,
      },
      {
        NOME: "Bacurituba",
        CODIGO_IBGE: 2101350,
      },
      {
        NOME: "Balsas",
        CODIGO_IBGE: 2101400,
      },
      {
        NOME: "Barão de Grajaú",
        CODIGO_IBGE: 2101509,
      },
      {
        NOME: "Barra do Corda",
        CODIGO_IBGE: 2101608,
      },
      {
        NOME: "Barreirinhas",
        CODIGO_IBGE: 2101707,
      },
      {
        NOME: "Belágua",
        CODIGO_IBGE: 2101731,
      },
      {
        NOME: "Bela Vista do Maranhão",
        CODIGO_IBGE: 2101772,
      },
      {
        NOME: "Benedito Leite",
        CODIGO_IBGE: 2101806,
      },
      {
        NOME: "Bequimão",
        CODIGO_IBGE: 2101905,
      },
      {
        NOME: "Bernardo do Mearim",
        CODIGO_IBGE: 2101939,
      },
      {
        NOME: "Boa Vista do Gurupi",
        CODIGO_IBGE: 2101970,
      },
      {
        NOME: "Bom Jardim",
        CODIGO_IBGE: 2102002,
      },
      {
        NOME: "Bom Jesus das Selvas",
        CODIGO_IBGE: 2102036,
      },
      {
        NOME: "Bom Lugar",
        CODIGO_IBGE: 2102077,
      },
      {
        NOME: "Brejo",
        CODIGO_IBGE: 2102101,
      },
      {
        NOME: "Brejo de Areia",
        CODIGO_IBGE: 2102150,
      },
      {
        NOME: "Buriti",
        CODIGO_IBGE: 2102200,
      },
      {
        NOME: "Buriti Bravo",
        CODIGO_IBGE: 2102309,
      },
      {
        NOME: "Buriticupu",
        CODIGO_IBGE: 2102325,
      },
      {
        NOME: "Buritirana",
        CODIGO_IBGE: 2102358,
      },
      {
        NOME: "Cachoeira Grande",
        CODIGO_IBGE: 2102374,
      },
      {
        NOME: "Cajapió",
        CODIGO_IBGE: 2102408,
      },
      {
        NOME: "Cajari",
        CODIGO_IBGE: 2102507,
      },
      {
        NOME: "Campestre do Maranhão",
        CODIGO_IBGE: 2102556,
      },
      {
        NOME: "Cândido Mendes",
        CODIGO_IBGE: 2102606,
      },
      {
        NOME: "Cantanhede",
        CODIGO_IBGE: 2102705,
      },
      {
        NOME: "Capinzal do Norte",
        CODIGO_IBGE: 2102754,
      },
      {
        NOME: "Carolina",
        CODIGO_IBGE: 2102804,
      },
      {
        NOME: "Carutapera",
        CODIGO_IBGE: 2102903,
      },
      {
        NOME: "Caxias",
        CODIGO_IBGE: 2103000,
      },
      {
        NOME: "Cedral",
        CODIGO_IBGE: 2103109,
      },
      {
        NOME: "Central do Maranhão",
        CODIGO_IBGE: 2103125,
      },
      {
        NOME: "Centro do Guilherme",
        CODIGO_IBGE: 2103158,
      },
      {
        NOME: "Centro Novo do Maranhão",
        CODIGO_IBGE: 2103174,
      },
      {
        NOME: "Chapadinha",
        CODIGO_IBGE: 2103208,
      },
      {
        NOME: "Cidelândia",
        CODIGO_IBGE: 2103257,
      },
      {
        NOME: "Codó",
        CODIGO_IBGE: 2103307,
      },
      {
        NOME: "Coelho Neto",
        CODIGO_IBGE: 2103406,
      },
      {
        NOME: "Colinas",
        CODIGO_IBGE: 2103505,
      },
      {
        NOME: "Conceição do Lago-Açu",
        CODIGO_IBGE: 2103554,
      },
      {
        NOME: "Coroatá",
        CODIGO_IBGE: 2103604,
      },
      {
        NOME: "Cururupu",
        CODIGO_IBGE: 2103703,
      },
      {
        NOME: "Davinópolis",
        CODIGO_IBGE: 2103752,
      },
      {
        NOME: "Dom Pedro",
        CODIGO_IBGE: 2103802,
      },
      {
        NOME: "Duque Bacelar",
        CODIGO_IBGE: 2103901,
      },
      {
        NOME: "Esperantinópolis",
        CODIGO_IBGE: 2104008,
      },
      {
        NOME: "Estreito",
        CODIGO_IBGE: 2104057,
      },
      {
        NOME: "Feira Nova do Maranhão",
        CODIGO_IBGE: 2104073,
      },
      {
        NOME: "Fernando Falcão",
        CODIGO_IBGE: 2104081,
      },
      {
        NOME: "Formosa da Serra Negra",
        CODIGO_IBGE: 2104099,
      },
      {
        NOME: "Fortaleza dos Nogueiras",
        CODIGO_IBGE: 2104107,
      },
      {
        NOME: "Fortuna",
        CODIGO_IBGE: 2104206,
      },
      {
        NOME: "Godofredo Viana",
        CODIGO_IBGE: 2104305,
      },
      {
        NOME: "Gonçalves Dias",
        CODIGO_IBGE: 2104404,
      },
      {
        NOME: "Governador Archer",
        CODIGO_IBGE: 2104503,
      },
      {
        NOME: "Governador Edison Lobão",
        CODIGO_IBGE: 2104552,
      },
      {
        NOME: "Governador Eugênio Barros",
        CODIGO_IBGE: 2104602,
      },
      {
        NOME: "Governador Luiz Rocha",
        CODIGO_IBGE: 2104628,
      },
      {
        NOME: "Governador Newton Bello",
        CODIGO_IBGE: 2104651,
      },
      {
        NOME: "Governador Nunes Freire",
        CODIGO_IBGE: 2104677,
      },
      {
        NOME: "Graça Aranha",
        CODIGO_IBGE: 2104701,
      },
      {
        NOME: "Grajaú",
        CODIGO_IBGE: 2104800,
      },
      {
        NOME: "Guimarães",
        CODIGO_IBGE: 2104909,
      },
      {
        NOME: "Humberto de Campos",
        CODIGO_IBGE: 2105005,
      },
      {
        NOME: "Icatu",
        CODIGO_IBGE: 2105104,
      },
      {
        NOME: "Igarapé do Meio",
        CODIGO_IBGE: 2105153,
      },
      {
        NOME: "Igarapé Grande",
        CODIGO_IBGE: 2105203,
      },
      {
        NOME: "Imperatriz",
        CODIGO_IBGE: 2105302,
      },
      {
        NOME: "Itaipava do Grajaú",
        CODIGO_IBGE: 2105351,
      },
      {
        NOME: "Itapecuru Mirim",
        CODIGO_IBGE: 2105401,
      },
      {
        NOME: "Itinga do Maranhão",
        CODIGO_IBGE: 2105427,
      },
      {
        NOME: "Jatobá",
        CODIGO_IBGE: 2105450,
      },
      {
        NOME: "Jenipapo dos Vieiras",
        CODIGO_IBGE: 2105476,
      },
      {
        NOME: "João Lisboa",
        CODIGO_IBGE: 2105500,
      },
      {
        NOME: "Joselândia",
        CODIGO_IBGE: 2105609,
      },
      {
        NOME: "Junco do Maranhão",
        CODIGO_IBGE: 2105658,
      },
      {
        NOME: "Lago da Pedra",
        CODIGO_IBGE: 2105708,
      },
      {
        NOME: "Lago do Junco",
        CODIGO_IBGE: 2105807,
      },
      {
        NOME: "Lago Verde",
        CODIGO_IBGE: 2105906,
      },
      {
        NOME: "Lagoa do Mato",
        CODIGO_IBGE: 2105922,
      },
      {
        NOME: "Lago dos Rodrigues",
        CODIGO_IBGE: 2105948,
      },
      {
        NOME: "Lagoa Grande do Maranhão",
        CODIGO_IBGE: 2105963,
      },
      {
        NOME: "Lajeado Novo",
        CODIGO_IBGE: 2105989,
      },
      {
        NOME: "Lima Campos",
        CODIGO_IBGE: 2106003,
      },
      {
        NOME: "Loreto",
        CODIGO_IBGE: 2106102,
      },
      {
        NOME: "Luís Domingues",
        CODIGO_IBGE: 2106201,
      },
      {
        NOME: "Magalhães de Almeida",
        CODIGO_IBGE: 2106300,
      },
      {
        NOME: "Maracaçumé",
        CODIGO_IBGE: 2106326,
      },
      {
        NOME: "Marajá do Sena",
        CODIGO_IBGE: 2106359,
      },
      {
        NOME: "Maranhãozinho",
        CODIGO_IBGE: 2106375,
      },
      {
        NOME: "Mata Roma",
        CODIGO_IBGE: 2106409,
      },
      {
        NOME: "Matinha",
        CODIGO_IBGE: 2106508,
      },
      {
        NOME: "Matões",
        CODIGO_IBGE: 2106607,
      },
      {
        NOME: "Matões do Norte",
        CODIGO_IBGE: 2106631,
      },
      {
        NOME: "Milagres do Maranhão",
        CODIGO_IBGE: 2106672,
      },
      {
        NOME: "Mirador",
        CODIGO_IBGE: 2106706,
      },
      {
        NOME: "Miranda do Norte",
        CODIGO_IBGE: 2106755,
      },
      {
        NOME: "Mirinzal",
        CODIGO_IBGE: 2106805,
      },
      {
        NOME: "Monção",
        CODIGO_IBGE: 2106904,
      },
      {
        NOME: "Montes Altos",
        CODIGO_IBGE: 2107001,
      },
      {
        NOME: "Morros",
        CODIGO_IBGE: 2107100,
      },
      {
        NOME: "Nina Rodrigues",
        CODIGO_IBGE: 2107209,
      },
      {
        NOME: "Nova Colinas",
        CODIGO_IBGE: 2107258,
      },
      {
        NOME: "Nova Iorque",
        CODIGO_IBGE: 2107308,
      },
      {
        NOME: "Nova Olinda do Maranhão",
        CODIGO_IBGE: 2107357,
      },
      {
        NOME: "Olho d'Água das Cunhãs",
        CODIGO_IBGE: 2107407,
      },
      {
        NOME: "Olinda Nova do Maranhão",
        CODIGO_IBGE: 2107456,
      },
      {
        NOME: "Paço do Lumiar",
        CODIGO_IBGE: 2107506,
      },
      {
        NOME: "Palmeirândia",
        CODIGO_IBGE: 2107605,
      },
      {
        NOME: "Paraibano",
        CODIGO_IBGE: 2107704,
      },
      {
        NOME: "Parnarama",
        CODIGO_IBGE: 2107803,
      },
      {
        NOME: "Passagem Franca",
        CODIGO_IBGE: 2107902,
      },
      {
        NOME: "Pastos Bons",
        CODIGO_IBGE: 2108009,
      },
      {
        NOME: "Paulino Neves",
        CODIGO_IBGE: 2108058,
      },
      {
        NOME: "Paulo Ramos",
        CODIGO_IBGE: 2108108,
      },
      {
        NOME: "Pedreiras",
        CODIGO_IBGE: 2108207,
      },
      {
        NOME: "Pedro do Rosário",
        CODIGO_IBGE: 2108256,
      },
      {
        NOME: "Penalva",
        CODIGO_IBGE: 2108306,
      },
      {
        NOME: "Peri Mirim",
        CODIGO_IBGE: 2108405,
      },
      {
        NOME: "Peritoró",
        CODIGO_IBGE: 2108454,
      },
      {
        NOME: "Pindaré-Mirim",
        CODIGO_IBGE: 2108504,
      },
      {
        NOME: "Pinheiro",
        CODIGO_IBGE: 2108603,
      },
      {
        NOME: "Pio XII",
        CODIGO_IBGE: 2108702,
      },
      {
        NOME: "Pirapemas",
        CODIGO_IBGE: 2108801,
      },
      {
        NOME: "Poção de Pedras",
        CODIGO_IBGE: 2108900,
      },
      {
        NOME: "Porto Franco",
        CODIGO_IBGE: 2109007,
      },
      {
        NOME: "Porto Rico do Maranhão",
        CODIGO_IBGE: 2109056,
      },
      {
        NOME: "Presidente Dutra",
        CODIGO_IBGE: 2109106,
      },
      {
        NOME: "Presidente Juscelino",
        CODIGO_IBGE: 2109205,
      },
      {
        NOME: "Presidente Médici",
        CODIGO_IBGE: 2109239,
      },
      {
        NOME: "Presidente Sarney",
        CODIGO_IBGE: 2109270,
      },
      {
        NOME: "Presidente Vargas",
        CODIGO_IBGE: 2109304,
      },
      {
        NOME: "Primeira Cruz",
        CODIGO_IBGE: 2109403,
      },
      {
        NOME: "Raposa",
        CODIGO_IBGE: 2109452,
      },
      {
        NOME: "Riachão",
        CODIGO_IBGE: 2109502,
      },
      {
        NOME: "Ribamar Fiquene",
        CODIGO_IBGE: 2109551,
      },
      {
        NOME: "Rosário",
        CODIGO_IBGE: 2109601,
      },
      {
        NOME: "Sambaíba",
        CODIGO_IBGE: 2109700,
      },
      {
        NOME: "Santa Filomena do Maranhão",
        CODIGO_IBGE: 2109759,
      },
      {
        NOME: "Santa Helena",
        CODIGO_IBGE: 2109809,
      },
      {
        NOME: "Santa Inês",
        CODIGO_IBGE: 2109908,
      },
      {
        NOME: "Santa Luzia",
        CODIGO_IBGE: 2110005,
      },
      {
        NOME: "Santa Luzia do Paruá",
        CODIGO_IBGE: 2110039,
      },
      {
        NOME: "Santa Quitéria do Maranhão",
        CODIGO_IBGE: 2110104,
      },
      {
        NOME: "Santa Rita",
        CODIGO_IBGE: 2110203,
      },
      {
        NOME: "Santana do Maranhão",
        CODIGO_IBGE: 2110237,
      },
      {
        NOME: "Santo Amaro do Maranhão",
        CODIGO_IBGE: 2110278,
      },
      {
        NOME: "Santo Antônio dos Lopes",
        CODIGO_IBGE: 2110302,
      },
      {
        NOME: "São Benedito do Rio Preto",
        CODIGO_IBGE: 2110401,
      },
      {
        NOME: "São Bento",
        CODIGO_IBGE: 2110500,
      },
      {
        NOME: "São Bernardo",
        CODIGO_IBGE: 2110609,
      },
      {
        NOME: "São Domingos do Azeitão",
        CODIGO_IBGE: 2110658,
      },
      {
        NOME: "São Domingos do Maranhão",
        CODIGO_IBGE: 2110708,
      },
      {
        NOME: "São Félix de Balsas",
        CODIGO_IBGE: 2110807,
      },
      {
        NOME: "São Francisco do Brejão",
        CODIGO_IBGE: 2110856,
      },
      {
        NOME: "São Francisco do Maranhão",
        CODIGO_IBGE: 2110906,
      },
      {
        NOME: "São João Batista",
        CODIGO_IBGE: 2111003,
      },
      {
        NOME: "São João do Carú",
        CODIGO_IBGE: 2111029,
      },
      {
        NOME: "São João do Paraíso",
        CODIGO_IBGE: 2111052,
      },
      {
        NOME: "São João do Soter",
        CODIGO_IBGE: 2111078,
      },
      {
        NOME: "São João dos Patos",
        CODIGO_IBGE: 2111102,
      },
      {
        NOME: "São José de Ribamar",
        CODIGO_IBGE: 2111201,
      },
      {
        NOME: "São José dos Basílios",
        CODIGO_IBGE: 2111250,
      },
      {
        NOME: "São Luís",
        CODIGO_IBGE: 2111300,
      },
      {
        NOME: "São Luís Gonzaga do Maranhão",
        CODIGO_IBGE: 2111409,
      },
      {
        NOME: "São Mateus do Maranhão",
        CODIGO_IBGE: 2111508,
      },
      {
        NOME: "São Pedro da Água Branca",
        CODIGO_IBGE: 2111532,
      },
      {
        NOME: "São Pedro dos Crentes",
        CODIGO_IBGE: 2111573,
      },
      {
        NOME: "São Raimundo das Mangabeiras",
        CODIGO_IBGE: 2111607,
      },
      {
        NOME: "São Raimundo do Doca Bezerra",
        CODIGO_IBGE: 2111631,
      },
      {
        NOME: "São Roberto",
        CODIGO_IBGE: 2111672,
      },
      {
        NOME: "São Vicente Ferrer",
        CODIGO_IBGE: 2111706,
      },
      {
        NOME: "Satubinha",
        CODIGO_IBGE: 2111722,
      },
      {
        NOME: "Senador Alexandre Costa",
        CODIGO_IBGE: 2111748,
      },
      {
        NOME: "Senador La Rocque",
        CODIGO_IBGE: 2111763,
      },
      {
        NOME: "Serrano do Maranhão",
        CODIGO_IBGE: 2111789,
      },
      {
        NOME: "Sítio Novo",
        CODIGO_IBGE: 2111805,
      },
      {
        NOME: "Sucupira do Norte",
        CODIGO_IBGE: 2111904,
      },
      {
        NOME: "Sucupira do Riachão",
        CODIGO_IBGE: 2111953,
      },
      {
        NOME: "Tasso Fragoso",
        CODIGO_IBGE: 2112001,
      },
      {
        NOME: "Timbiras",
        CODIGO_IBGE: 2112100,
      },
      {
        NOME: "Timon",
        CODIGO_IBGE: 2112209,
      },
      {
        NOME: "Trizidela do Vale",
        CODIGO_IBGE: 2112233,
      },
      {
        NOME: "Tufilândia",
        CODIGO_IBGE: 2112274,
      },
      {
        NOME: "Tuntum",
        CODIGO_IBGE: 2112308,
      },
      {
        NOME: "Turiaçu",
        CODIGO_IBGE: 2112407,
      },
      {
        NOME: "Turilândia",
        CODIGO_IBGE: 2112456,
      },
      {
        NOME: "Tutóia",
        CODIGO_IBGE: 2112506,
      },
      {
        NOME: "Urbano Santos",
        CODIGO_IBGE: 2112605,
      },
      {
        NOME: "Vargem Grande",
        CODIGO_IBGE: 2112704,
      },
      {
        NOME: "Viana",
        CODIGO_IBGE: 2112803,
      },
      {
        NOME: "Vila Nova dos Martírios",
        CODIGO_IBGE: 2112852,
      },
      {
        NOME: "Vitória do Mearim",
        CODIGO_IBGE: 2112902,
      },
      {
        NOME: "Vitorino Freire",
        CODIGO_IBGE: 2113009,
      },
      {
        NOME: "Zé Doca",
        CODIGO_IBGE: 2114007,
      },
    ],
    22: [
      {
        NOME: "Acauã",
        CODIGO_IBGE: 2200053,
      },
      {
        NOME: "Agricolândia",
        CODIGO_IBGE: 2200103,
      },
      {
        NOME: "Água Branca",
        CODIGO_IBGE: 2200202,
      },
      {
        NOME: "Alagoinha do Piauí",
        CODIGO_IBGE: 2200251,
      },
      {
        NOME: "Alegrete do Piauí",
        CODIGO_IBGE: 2200277,
      },
      {
        NOME: "Alto Longá",
        CODIGO_IBGE: 2200301,
      },
      {
        NOME: "Altos",
        CODIGO_IBGE: 2200400,
      },
      {
        NOME: "Alvorada do Gurguéia",
        CODIGO_IBGE: 2200459,
      },
      {
        NOME: "Amarante",
        CODIGO_IBGE: 2200509,
      },
      {
        NOME: "Angical do Piauí",
        CODIGO_IBGE: 2200608,
      },
      {
        NOME: "Anísio de Abreu",
        CODIGO_IBGE: 2200707,
      },
      {
        NOME: "Antônio Almeida",
        CODIGO_IBGE: 2200806,
      },
      {
        NOME: "Aroazes",
        CODIGO_IBGE: 2200905,
      },
      {
        NOME: "Aroeiras do Itaim",
        CODIGO_IBGE: 2200954,
      },
      {
        NOME: "Arraial",
        CODIGO_IBGE: 2201002,
      },
      {
        NOME: "Assunção do Piauí",
        CODIGO_IBGE: 2201051,
      },
      {
        NOME: "Avelino Lopes",
        CODIGO_IBGE: 2201101,
      },
      {
        NOME: "Baixa Grande do Ribeiro",
        CODIGO_IBGE: 2201150,
      },
      {
        NOME: "Barra D'Alcântara",
        CODIGO_IBGE: 2201176,
      },
      {
        NOME: "Barras",
        CODIGO_IBGE: 2201200,
      },
      {
        NOME: "Barreiras do Piauí",
        CODIGO_IBGE: 2201309,
      },
      {
        NOME: "Barro Duro",
        CODIGO_IBGE: 2201408,
      },
      {
        NOME: "Batalha",
        CODIGO_IBGE: 2201507,
      },
      {
        NOME: "Bela Vista do Piauí",
        CODIGO_IBGE: 2201556,
      },
      {
        NOME: "Belém do Piauí",
        CODIGO_IBGE: 2201572,
      },
      {
        NOME: "Beneditinos",
        CODIGO_IBGE: 2201606,
      },
      {
        NOME: "Bertolínia",
        CODIGO_IBGE: 2201705,
      },
      {
        NOME: "Betânia do Piauí",
        CODIGO_IBGE: 2201739,
      },
      {
        NOME: "Boa Hora",
        CODIGO_IBGE: 2201770,
      },
      {
        NOME: "Bocaina",
        CODIGO_IBGE: 2201804,
      },
      {
        NOME: "Bom Jesus",
        CODIGO_IBGE: 2201903,
      },
      {
        NOME: "Bom Princípio do Piauí",
        CODIGO_IBGE: 2201919,
      },
      {
        NOME: "Bonfim do Piauí",
        CODIGO_IBGE: 2201929,
      },
      {
        NOME: "Boqueirão do Piauí",
        CODIGO_IBGE: 2201945,
      },
      {
        NOME: "Brasileira",
        CODIGO_IBGE: 2201960,
      },
      {
        NOME: "Brejo do Piauí",
        CODIGO_IBGE: 2201988,
      },
      {
        NOME: "Buriti dos Lopes",
        CODIGO_IBGE: 2202000,
      },
      {
        NOME: "Buriti dos Montes",
        CODIGO_IBGE: 2202026,
      },
      {
        NOME: "Cabeceiras do Piauí",
        CODIGO_IBGE: 2202059,
      },
      {
        NOME: "Cajazeiras do Piauí",
        CODIGO_IBGE: 2202075,
      },
      {
        NOME: "Cajueiro da Praia",
        CODIGO_IBGE: 2202083,
      },
      {
        NOME: "Caldeirão Grande do Piauí",
        CODIGO_IBGE: 2202091,
      },
      {
        NOME: "Campinas do Piauí",
        CODIGO_IBGE: 2202109,
      },
      {
        NOME: "Campo Alegre do Fidalgo",
        CODIGO_IBGE: 2202117,
      },
      {
        NOME: "Campo Grande do Piauí",
        CODIGO_IBGE: 2202133,
      },
      {
        NOME: "Campo Largo do Piauí",
        CODIGO_IBGE: 2202174,
      },
      {
        NOME: "Campo Maior",
        CODIGO_IBGE: 2202208,
      },
      {
        NOME: "Canavieira",
        CODIGO_IBGE: 2202251,
      },
      {
        NOME: "Canto do Buriti",
        CODIGO_IBGE: 2202307,
      },
      {
        NOME: "Capitão de Campos",
        CODIGO_IBGE: 2202406,
      },
      {
        NOME: "Capitão Gervásio Oliveira",
        CODIGO_IBGE: 2202455,
      },
      {
        NOME: "Caracol",
        CODIGO_IBGE: 2202505,
      },
      {
        NOME: "Caraúbas do Piauí",
        CODIGO_IBGE: 2202539,
      },
      {
        NOME: "Caridade do Piauí",
        CODIGO_IBGE: 2202554,
      },
      {
        NOME: "Castelo do Piauí",
        CODIGO_IBGE: 2202604,
      },
      {
        NOME: "Caxingó",
        CODIGO_IBGE: 2202653,
      },
      {
        NOME: "Cocal",
        CODIGO_IBGE: 2202703,
      },
      {
        NOME: "Cocal de Telha",
        CODIGO_IBGE: 2202711,
      },
      {
        NOME: "Cocal dos Alves",
        CODIGO_IBGE: 2202729,
      },
      {
        NOME: "Coivaras",
        CODIGO_IBGE: 2202737,
      },
      {
        NOME: "Colônia do Gurguéia",
        CODIGO_IBGE: 2202752,
      },
      {
        NOME: "Colônia do Piauí",
        CODIGO_IBGE: 2202778,
      },
      {
        NOME: "Conceição do Canindé",
        CODIGO_IBGE: 2202802,
      },
      {
        NOME: "Coronel José Dias",
        CODIGO_IBGE: 2202851,
      },
      {
        NOME: "Corrente",
        CODIGO_IBGE: 2202901,
      },
      {
        NOME: "Cristalândia do Piauí",
        CODIGO_IBGE: 2203008,
      },
      {
        NOME: "Cristino Castro",
        CODIGO_IBGE: 2203107,
      },
      {
        NOME: "Curimatá",
        CODIGO_IBGE: 2203206,
      },
      {
        NOME: "Currais",
        CODIGO_IBGE: 2203230,
      },
      {
        NOME: "Curralinhos",
        CODIGO_IBGE: 2203255,
      },
      {
        NOME: "Curral Novo do Piauí",
        CODIGO_IBGE: 2203271,
      },
      {
        NOME: "Demerval Lobão",
        CODIGO_IBGE: 2203305,
      },
      {
        NOME: "Dirceu Arcoverde",
        CODIGO_IBGE: 2203354,
      },
      {
        NOME: "Dom Expedito Lopes",
        CODIGO_IBGE: 2203404,
      },
      {
        NOME: "Domingos Mourão",
        CODIGO_IBGE: 2203420,
      },
      {
        NOME: "Dom Inocêncio",
        CODIGO_IBGE: 2203453,
      },
      {
        NOME: "Elesbão Veloso",
        CODIGO_IBGE: 2203503,
      },
      {
        NOME: "Eliseu Martins",
        CODIGO_IBGE: 2203602,
      },
      {
        NOME: "Esperantina",
        CODIGO_IBGE: 2203701,
      },
      {
        NOME: "Fartura do Piauí",
        CODIGO_IBGE: 2203750,
      },
      {
        NOME: "Flores do Piauí",
        CODIGO_IBGE: 2203800,
      },
      {
        NOME: "Floresta do Piauí",
        CODIGO_IBGE: 2203859,
      },
      {
        NOME: "Floriano",
        CODIGO_IBGE: 2203909,
      },
      {
        NOME: "Francinópolis",
        CODIGO_IBGE: 2204006,
      },
      {
        NOME: "Francisco Ayres",
        CODIGO_IBGE: 2204105,
      },
      {
        NOME: "Francisco Macedo",
        CODIGO_IBGE: 2204154,
      },
      {
        NOME: "Francisco Santos",
        CODIGO_IBGE: 2204204,
      },
      {
        NOME: "Fronteiras",
        CODIGO_IBGE: 2204303,
      },
      {
        NOME: "Geminiano",
        CODIGO_IBGE: 2204352,
      },
      {
        NOME: "Gilbués",
        CODIGO_IBGE: 2204402,
      },
      {
        NOME: "Guadalupe",
        CODIGO_IBGE: 2204501,
      },
      {
        NOME: "Guaribas",
        CODIGO_IBGE: 2204550,
      },
      {
        NOME: "Hugo Napoleão",
        CODIGO_IBGE: 2204600,
      },
      {
        NOME: "Ilha Grande",
        CODIGO_IBGE: 2204659,
      },
      {
        NOME: "Inhuma",
        CODIGO_IBGE: 2204709,
      },
      {
        NOME: "Ipiranga do Piauí",
        CODIGO_IBGE: 2204808,
      },
      {
        NOME: "Isaías Coelho",
        CODIGO_IBGE: 2204907,
      },
      {
        NOME: "Itainópolis",
        CODIGO_IBGE: 2205003,
      },
      {
        NOME: "Itaueira",
        CODIGO_IBGE: 2205102,
      },
      {
        NOME: "Jacobina do Piauí",
        CODIGO_IBGE: 2205151,
      },
      {
        NOME: "Jaicós",
        CODIGO_IBGE: 2205201,
      },
      {
        NOME: "Jardim do Mulato",
        CODIGO_IBGE: 2205250,
      },
      {
        NOME: "Jatobá do Piauí",
        CODIGO_IBGE: 2205276,
      },
      {
        NOME: "Jerumenha",
        CODIGO_IBGE: 2205300,
      },
      {
        NOME: "João Costa",
        CODIGO_IBGE: 2205359,
      },
      {
        NOME: "Joaquim Pires",
        CODIGO_IBGE: 2205409,
      },
      {
        NOME: "Joca Marques",
        CODIGO_IBGE: 2205458,
      },
      {
        NOME: "José de Freitas",
        CODIGO_IBGE: 2205508,
      },
      {
        NOME: "Juazeiro do Piauí",
        CODIGO_IBGE: 2205516,
      },
      {
        NOME: "Júlio Borges",
        CODIGO_IBGE: 2205524,
      },
      {
        NOME: "Jurema",
        CODIGO_IBGE: 2205532,
      },
      {
        NOME: "Lagoinha do Piauí",
        CODIGO_IBGE: 2205540,
      },
      {
        NOME: "Lagoa Alegre",
        CODIGO_IBGE: 2205557,
      },
      {
        NOME: "Lagoa do Barro do Piauí",
        CODIGO_IBGE: 2205565,
      },
      {
        NOME: "Lagoa de São Francisco",
        CODIGO_IBGE: 2205573,
      },
      {
        NOME: "Lagoa do Piauí",
        CODIGO_IBGE: 2205581,
      },
      {
        NOME: "Lagoa do Sítio",
        CODIGO_IBGE: 2205599,
      },
      {
        NOME: "Landri Sales",
        CODIGO_IBGE: 2205607,
      },
      {
        NOME: "Luís Correia",
        CODIGO_IBGE: 2205706,
      },
      {
        NOME: "Luzilândia",
        CODIGO_IBGE: 2205805,
      },
      {
        NOME: "Madeiro",
        CODIGO_IBGE: 2205854,
      },
      {
        NOME: "Manoel Emídio",
        CODIGO_IBGE: 2205904,
      },
      {
        NOME: "Marcolândia",
        CODIGO_IBGE: 2205953,
      },
      {
        NOME: "Marcos Parente",
        CODIGO_IBGE: 2206001,
      },
      {
        NOME: "Massapê do Piauí",
        CODIGO_IBGE: 2206050,
      },
      {
        NOME: "Matias Olímpio",
        CODIGO_IBGE: 2206100,
      },
      {
        NOME: "Miguel Alves",
        CODIGO_IBGE: 2206209,
      },
      {
        NOME: "Miguel Leão",
        CODIGO_IBGE: 2206308,
      },
      {
        NOME: "Milton Brandão",
        CODIGO_IBGE: 2206357,
      },
      {
        NOME: "Monsenhor Gil",
        CODIGO_IBGE: 2206407,
      },
      {
        NOME: "Monsenhor Hipólito",
        CODIGO_IBGE: 2206506,
      },
      {
        NOME: "Monte Alegre do Piauí",
        CODIGO_IBGE: 2206605,
      },
      {
        NOME: "Morro Cabeça no Tempo",
        CODIGO_IBGE: 2206654,
      },
      {
        NOME: "Morro do Chapéu do Piauí",
        CODIGO_IBGE: 2206670,
      },
      {
        NOME: "Murici dos Portelas",
        CODIGO_IBGE: 2206696,
      },
      {
        NOME: "Nazaré do Piauí",
        CODIGO_IBGE: 2206704,
      },
      {
        NOME: "Nazária",
        CODIGO_IBGE: 2206720,
      },
      {
        NOME: "Nossa Senhora de Nazaré",
        CODIGO_IBGE: 2206753,
      },
      {
        NOME: "Nossa Senhora dos Remédios",
        CODIGO_IBGE: 2206803,
      },
      {
        NOME: "Novo Oriente do Piauí",
        CODIGO_IBGE: 2206902,
      },
      {
        NOME: "Novo Santo Antônio",
        CODIGO_IBGE: 2206951,
      },
      {
        NOME: "Oeiras",
        CODIGO_IBGE: 2207009,
      },
      {
        NOME: "Olho D'Água do Piauí",
        CODIGO_IBGE: 2207108,
      },
      {
        NOME: "Padre Marcos",
        CODIGO_IBGE: 2207207,
      },
      {
        NOME: "Paes Landim",
        CODIGO_IBGE: 2207306,
      },
      {
        NOME: "Pajeú do Piauí",
        CODIGO_IBGE: 2207355,
      },
      {
        NOME: "Palmeira do Piauí",
        CODIGO_IBGE: 2207405,
      },
      {
        NOME: "Palmeirais",
        CODIGO_IBGE: 2207504,
      },
      {
        NOME: "Paquetá",
        CODIGO_IBGE: 2207553,
      },
      {
        NOME: "Parnaguá",
        CODIGO_IBGE: 2207603,
      },
      {
        NOME: "Parnaíba",
        CODIGO_IBGE: 2207702,
      },
      {
        NOME: "Passagem Franca do Piauí",
        CODIGO_IBGE: 2207751,
      },
      {
        NOME: "Patos do Piauí",
        CODIGO_IBGE: 2207777,
      },
      {
        NOME: "Pau D'Arco do Piauí",
        CODIGO_IBGE: 2207793,
      },
      {
        NOME: "Paulistana",
        CODIGO_IBGE: 2207801,
      },
      {
        NOME: "Pavussu",
        CODIGO_IBGE: 2207850,
      },
      {
        NOME: "Pedro II",
        CODIGO_IBGE: 2207900,
      },
      {
        NOME: "Pedro Laurentino",
        CODIGO_IBGE: 2207934,
      },
      {
        NOME: "Nova Santa Rita",
        CODIGO_IBGE: 2207959,
      },
      {
        NOME: "Picos",
        CODIGO_IBGE: 2208007,
      },
      {
        NOME: "Pimenteiras",
        CODIGO_IBGE: 2208106,
      },
      {
        NOME: "Pio IX",
        CODIGO_IBGE: 2208205,
      },
      {
        NOME: "Piracuruca",
        CODIGO_IBGE: 2208304,
      },
      {
        NOME: "Piripiri",
        CODIGO_IBGE: 2208403,
      },
      {
        NOME: "Porto",
        CODIGO_IBGE: 2208502,
      },
      {
        NOME: "Porto Alegre do Piauí",
        CODIGO_IBGE: 2208551,
      },
      {
        NOME: "Prata do Piauí",
        CODIGO_IBGE: 2208601,
      },
      {
        NOME: "Queimada Nova",
        CODIGO_IBGE: 2208650,
      },
      {
        NOME: "Redenção do Gurguéia",
        CODIGO_IBGE: 2208700,
      },
      {
        NOME: "Regeneração",
        CODIGO_IBGE: 2208809,
      },
      {
        NOME: "Riacho Frio",
        CODIGO_IBGE: 2208858,
      },
      {
        NOME: "Ribeira do Piauí",
        CODIGO_IBGE: 2208874,
      },
      {
        NOME: "Ribeiro Gonçalves",
        CODIGO_IBGE: 2208908,
      },
      {
        NOME: "Rio Grande do Piauí",
        CODIGO_IBGE: 2209005,
      },
      {
        NOME: "Santa Cruz do Piauí",
        CODIGO_IBGE: 2209104,
      },
      {
        NOME: "Santa Cruz dos Milagres",
        CODIGO_IBGE: 2209153,
      },
      {
        NOME: "Santa Filomena",
        CODIGO_IBGE: 2209203,
      },
      {
        NOME: "Santa Luz",
        CODIGO_IBGE: 2209302,
      },
      {
        NOME: "Santana do Piauí",
        CODIGO_IBGE: 2209351,
      },
      {
        NOME: "Santa Rosa do Piauí",
        CODIGO_IBGE: 2209377,
      },
      {
        NOME: "Santo Antônio de Lisboa",
        CODIGO_IBGE: 2209401,
      },
      {
        NOME: "Santo Antônio dos Milagres",
        CODIGO_IBGE: 2209450,
      },
      {
        NOME: "Santo Inácio do Piauí",
        CODIGO_IBGE: 2209500,
      },
      {
        NOME: "São Braz do Piauí",
        CODIGO_IBGE: 2209559,
      },
      {
        NOME: "São Félix do Piauí",
        CODIGO_IBGE: 2209609,
      },
      {
        NOME: "São Francisco de Assis do Piauí",
        CODIGO_IBGE: 2209658,
      },
      {
        NOME: "São Francisco do Piauí",
        CODIGO_IBGE: 2209708,
      },
      {
        NOME: "São Gonçalo do Gurguéia",
        CODIGO_IBGE: 2209757,
      },
      {
        NOME: "São Gonçalo do Piauí",
        CODIGO_IBGE: 2209807,
      },
      {
        NOME: "São João da Canabrava",
        CODIGO_IBGE: 2209856,
      },
      {
        NOME: "São João da Fronteira",
        CODIGO_IBGE: 2209872,
      },
      {
        NOME: "São João da Serra",
        CODIGO_IBGE: 2209906,
      },
      {
        NOME: "São João da Varjota",
        CODIGO_IBGE: 2209955,
      },
      {
        NOME: "São João do Arraial",
        CODIGO_IBGE: 2209971,
      },
      {
        NOME: "São João do Piauí",
        CODIGO_IBGE: 2210003,
      },
      {
        NOME: "São José do Divino",
        CODIGO_IBGE: 2210052,
      },
      {
        NOME: "São José do Peixe",
        CODIGO_IBGE: 2210102,
      },
      {
        NOME: "São José do Piauí",
        CODIGO_IBGE: 2210201,
      },
      {
        NOME: "São Julião",
        CODIGO_IBGE: 2210300,
      },
      {
        NOME: "São Lourenço do Piauí",
        CODIGO_IBGE: 2210359,
      },
      {
        NOME: "São Luis do Piauí",
        CODIGO_IBGE: 2210375,
      },
      {
        NOME: "São Miguel da Baixa Grande",
        CODIGO_IBGE: 2210383,
      },
      {
        NOME: "São Miguel do Fidalgo",
        CODIGO_IBGE: 2210391,
      },
      {
        NOME: "São Miguel do Tapuio",
        CODIGO_IBGE: 2210409,
      },
      {
        NOME: "São Pedro do Piauí",
        CODIGO_IBGE: 2210508,
      },
      {
        NOME: "São Raimundo Nonato",
        CODIGO_IBGE: 2210607,
      },
      {
        NOME: "Sebastião Barros",
        CODIGO_IBGE: 2210623,
      },
      {
        NOME: "Sebastião Leal",
        CODIGO_IBGE: 2210631,
      },
      {
        NOME: "Sigefredo Pacheco",
        CODIGO_IBGE: 2210656,
      },
      {
        NOME: "Simões",
        CODIGO_IBGE: 2210706,
      },
      {
        NOME: "Simplício Mendes",
        CODIGO_IBGE: 2210805,
      },
      {
        NOME: "Socorro do Piauí",
        CODIGO_IBGE: 2210904,
      },
      {
        NOME: "Sussuapara",
        CODIGO_IBGE: 2210938,
      },
      {
        NOME: "Tamboril do Piauí",
        CODIGO_IBGE: 2210953,
      },
      {
        NOME: "Tanque do Piauí",
        CODIGO_IBGE: 2210979,
      },
      {
        NOME: "Teresina",
        CODIGO_IBGE: 2211001,
      },
      {
        NOME: "União",
        CODIGO_IBGE: 2211100,
      },
      {
        NOME: "Uruçuí",
        CODIGO_IBGE: 2211209,
      },
      {
        NOME: "Valença do Piauí",
        CODIGO_IBGE: 2211308,
      },
      {
        NOME: "Várzea Branca",
        CODIGO_IBGE: 2211357,
      },
      {
        NOME: "Várzea Grande",
        CODIGO_IBGE: 2211407,
      },
      {
        NOME: "Vera Mendes",
        CODIGO_IBGE: 2211506,
      },
      {
        NOME: "Vila Nova do Piauí",
        CODIGO_IBGE: 2211605,
      },
      {
        NOME: "Wall Ferraz",
        CODIGO_IBGE: 2211704,
      },
    ],
    23: [
      {
        NOME: "Abaiara",
        CODIGO_IBGE: 2300101,
      },
      {
        NOME: "Acarape",
        CODIGO_IBGE: 2300150,
      },
      {
        NOME: "Acaraú",
        CODIGO_IBGE: 2300200,
      },
      {
        NOME: "Acopiara",
        CODIGO_IBGE: 2300309,
      },
      {
        NOME: "Aiuaba",
        CODIGO_IBGE: 2300408,
      },
      {
        NOME: "Alcântaras",
        CODIGO_IBGE: 2300507,
      },
      {
        NOME: "Altaneira",
        CODIGO_IBGE: 2300606,
      },
      {
        NOME: "Alto Santo",
        CODIGO_IBGE: 2300705,
      },
      {
        NOME: "Amontada",
        CODIGO_IBGE: 2300754,
      },
      {
        NOME: "Antonina do Norte",
        CODIGO_IBGE: 2300804,
      },
      {
        NOME: "Apuiarés",
        CODIGO_IBGE: 2300903,
      },
      {
        NOME: "Aquiraz",
        CODIGO_IBGE: 2301000,
      },
      {
        NOME: "Aracati",
        CODIGO_IBGE: 2301109,
      },
      {
        NOME: "Aracoiaba",
        CODIGO_IBGE: 2301208,
      },
      {
        NOME: "Ararendá",
        CODIGO_IBGE: 2301257,
      },
      {
        NOME: "Araripe",
        CODIGO_IBGE: 2301307,
      },
      {
        NOME: "Aratuba",
        CODIGO_IBGE: 2301406,
      },
      {
        NOME: "Arneiroz",
        CODIGO_IBGE: 2301505,
      },
      {
        NOME: "Assaré",
        CODIGO_IBGE: 2301604,
      },
      {
        NOME: "Aurora",
        CODIGO_IBGE: 2301703,
      },
      {
        NOME: "Baixio",
        CODIGO_IBGE: 2301802,
      },
      {
        NOME: "Banabuiú",
        CODIGO_IBGE: 2301851,
      },
      {
        NOME: "Barbalha",
        CODIGO_IBGE: 2301901,
      },
      {
        NOME: "Barreira",
        CODIGO_IBGE: 2301950,
      },
      {
        NOME: "Barro",
        CODIGO_IBGE: 2302008,
      },
      {
        NOME: "Barroquinha",
        CODIGO_IBGE: 2302057,
      },
      {
        NOME: "Baturité",
        CODIGO_IBGE: 2302107,
      },
      {
        NOME: "Beberibe",
        CODIGO_IBGE: 2302206,
      },
      {
        NOME: "Bela Cruz",
        CODIGO_IBGE: 2302305,
      },
      {
        NOME: "Boa Viagem",
        CODIGO_IBGE: 2302404,
      },
      {
        NOME: "Brejo Santo",
        CODIGO_IBGE: 2302503,
      },
      {
        NOME: "Camocim",
        CODIGO_IBGE: 2302602,
      },
      {
        NOME: "Campos Sales",
        CODIGO_IBGE: 2302701,
      },
      {
        NOME: "Canindé",
        CODIGO_IBGE: 2302800,
      },
      {
        NOME: "Capistrano",
        CODIGO_IBGE: 2302909,
      },
      {
        NOME: "Caridade",
        CODIGO_IBGE: 2303006,
      },
      {
        NOME: "Cariré",
        CODIGO_IBGE: 2303105,
      },
      {
        NOME: "Caririaçu",
        CODIGO_IBGE: 2303204,
      },
      {
        NOME: "Cariús",
        CODIGO_IBGE: 2303303,
      },
      {
        NOME: "Carnaubal",
        CODIGO_IBGE: 2303402,
      },
      {
        NOME: "Cascavel",
        CODIGO_IBGE: 2303501,
      },
      {
        NOME: "Catarina",
        CODIGO_IBGE: 2303600,
      },
      {
        NOME: "Catunda",
        CODIGO_IBGE: 2303659,
      },
      {
        NOME: "Caucaia",
        CODIGO_IBGE: 2303709,
      },
      {
        NOME: "Cedro",
        CODIGO_IBGE: 2303808,
      },
      {
        NOME: "Chaval",
        CODIGO_IBGE: 2303907,
      },
      {
        NOME: "Choró",
        CODIGO_IBGE: 2303931,
      },
      {
        NOME: "Chorozinho",
        CODIGO_IBGE: 2303956,
      },
      {
        NOME: "Coreaú",
        CODIGO_IBGE: 2304004,
      },
      {
        NOME: "Crateús",
        CODIGO_IBGE: 2304103,
      },
      {
        NOME: "Crato",
        CODIGO_IBGE: 2304202,
      },
      {
        NOME: "Croatá",
        CODIGO_IBGE: 2304236,
      },
      {
        NOME: "Cruz",
        CODIGO_IBGE: 2304251,
      },
      {
        NOME: "Deputado Irapuan Pinheiro",
        CODIGO_IBGE: 2304269,
      },
      {
        NOME: "Ererê",
        CODIGO_IBGE: 2304277,
      },
      {
        NOME: "Eusébio",
        CODIGO_IBGE: 2304285,
      },
      {
        NOME: "Farias Brito",
        CODIGO_IBGE: 2304301,
      },
      {
        NOME: "Forquilha",
        CODIGO_IBGE: 2304350,
      },
      {
        NOME: "Fortaleza",
        CODIGO_IBGE: 2304400,
      },
      {
        NOME: "Fortim",
        CODIGO_IBGE: 2304459,
      },
      {
        NOME: "Frecheirinha",
        CODIGO_IBGE: 2304509,
      },
      {
        NOME: "General Sampaio",
        CODIGO_IBGE: 2304608,
      },
      {
        NOME: "Graça",
        CODIGO_IBGE: 2304657,
      },
      {
        NOME: "Granja",
        CODIGO_IBGE: 2304707,
      },
      {
        NOME: "Granjeiro",
        CODIGO_IBGE: 2304806,
      },
      {
        NOME: "Groaíras",
        CODIGO_IBGE: 2304905,
      },
      {
        NOME: "Guaiúba",
        CODIGO_IBGE: 2304954,
      },
      {
        NOME: "Guaraciaba do Norte",
        CODIGO_IBGE: 2305001,
      },
      {
        NOME: "Guaramiranga",
        CODIGO_IBGE: 2305100,
      },
      {
        NOME: "Hidrolândia",
        CODIGO_IBGE: 2305209,
      },
      {
        NOME: "Horizonte",
        CODIGO_IBGE: 2305233,
      },
      {
        NOME: "Ibaretama",
        CODIGO_IBGE: 2305266,
      },
      {
        NOME: "Ibiapina",
        CODIGO_IBGE: 2305308,
      },
      {
        NOME: "Ibicuitinga",
        CODIGO_IBGE: 2305332,
      },
      {
        NOME: "Icapuí",
        CODIGO_IBGE: 2305357,
      },
      {
        NOME: "Icó",
        CODIGO_IBGE: 2305407,
      },
      {
        NOME: "Iguatu",
        CODIGO_IBGE: 2305506,
      },
      {
        NOME: "Independência",
        CODIGO_IBGE: 2305605,
      },
      {
        NOME: "Ipaporanga",
        CODIGO_IBGE: 2305654,
      },
      {
        NOME: "Ipaumirim",
        CODIGO_IBGE: 2305704,
      },
      {
        NOME: "Ipu",
        CODIGO_IBGE: 2305803,
      },
      {
        NOME: "Ipueiras",
        CODIGO_IBGE: 2305902,
      },
      {
        NOME: "Iracema",
        CODIGO_IBGE: 2306009,
      },
      {
        NOME: "Irauçuba",
        CODIGO_IBGE: 2306108,
      },
      {
        NOME: "Itaiçaba",
        CODIGO_IBGE: 2306207,
      },
      {
        NOME: "Itaitinga",
        CODIGO_IBGE: 2306256,
      },
      {
        NOME: "Itapajé",
        CODIGO_IBGE: 2306306,
      },
      {
        NOME: "Itapipoca",
        CODIGO_IBGE: 2306405,
      },
      {
        NOME: "Itapiúna",
        CODIGO_IBGE: 2306504,
      },
      {
        NOME: "Itarema",
        CODIGO_IBGE: 2306553,
      },
      {
        NOME: "Itatira",
        CODIGO_IBGE: 2306603,
      },
      {
        NOME: "Jaguaretama",
        CODIGO_IBGE: 2306702,
      },
      {
        NOME: "Jaguaribara",
        CODIGO_IBGE: 2306801,
      },
      {
        NOME: "Jaguaribe",
        CODIGO_IBGE: 2306900,
      },
      {
        NOME: "Jaguaruana",
        CODIGO_IBGE: 2307007,
      },
      {
        NOME: "Jardim",
        CODIGO_IBGE: 2307106,
      },
      {
        NOME: "Jati",
        CODIGO_IBGE: 2307205,
      },
      {
        NOME: "Jijoca de Jericoacoara",
        CODIGO_IBGE: 2307254,
      },
      {
        NOME: "Juazeiro do Norte",
        CODIGO_IBGE: 2307304,
      },
      {
        NOME: "Jucás",
        CODIGO_IBGE: 2307403,
      },
      {
        NOME: "Lavras da Mangabeira",
        CODIGO_IBGE: 2307502,
      },
      {
        NOME: "Limoeiro do Norte",
        CODIGO_IBGE: 2307601,
      },
      {
        NOME: "Madalena",
        CODIGO_IBGE: 2307635,
      },
      {
        NOME: "Maracanaú",
        CODIGO_IBGE: 2307650,
      },
      {
        NOME: "Maranguape",
        CODIGO_IBGE: 2307700,
      },
      {
        NOME: "Marco",
        CODIGO_IBGE: 2307809,
      },
      {
        NOME: "Martinópole",
        CODIGO_IBGE: 2307908,
      },
      {
        NOME: "Massapê",
        CODIGO_IBGE: 2308005,
      },
      {
        NOME: "Mauriti",
        CODIGO_IBGE: 2308104,
      },
      {
        NOME: "Meruoca",
        CODIGO_IBGE: 2308203,
      },
      {
        NOME: "Milagres",
        CODIGO_IBGE: 2308302,
      },
      {
        NOME: "Milhã",
        CODIGO_IBGE: 2308351,
      },
      {
        NOME: "Miraíma",
        CODIGO_IBGE: 2308377,
      },
      {
        NOME: "Missão Velha",
        CODIGO_IBGE: 2308401,
      },
      {
        NOME: "Mombaça",
        CODIGO_IBGE: 2308500,
      },
      {
        NOME: "Monsenhor Tabosa",
        CODIGO_IBGE: 2308609,
      },
      {
        NOME: "Morada Nova",
        CODIGO_IBGE: 2308708,
      },
      {
        NOME: "Moraújo",
        CODIGO_IBGE: 2308807,
      },
      {
        NOME: "Morrinhos",
        CODIGO_IBGE: 2308906,
      },
      {
        NOME: "Mucambo",
        CODIGO_IBGE: 2309003,
      },
      {
        NOME: "Mulungu",
        CODIGO_IBGE: 2309102,
      },
      {
        NOME: "Nova Olinda",
        CODIGO_IBGE: 2309201,
      },
      {
        NOME: "Nova Russas",
        CODIGO_IBGE: 2309300,
      },
      {
        NOME: "Novo Oriente",
        CODIGO_IBGE: 2309409,
      },
      {
        NOME: "Ocara",
        CODIGO_IBGE: 2309458,
      },
      {
        NOME: "Orós",
        CODIGO_IBGE: 2309508,
      },
      {
        NOME: "Pacajus",
        CODIGO_IBGE: 2309607,
      },
      {
        NOME: "Pacatuba",
        CODIGO_IBGE: 2309706,
      },
      {
        NOME: "Pacoti",
        CODIGO_IBGE: 2309805,
      },
      {
        NOME: "Pacujá",
        CODIGO_IBGE: 2309904,
      },
      {
        NOME: "Palhano",
        CODIGO_IBGE: 2310001,
      },
      {
        NOME: "Palmácia",
        CODIGO_IBGE: 2310100,
      },
      {
        NOME: "Paracuru",
        CODIGO_IBGE: 2310209,
      },
      {
        NOME: "Paraipaba",
        CODIGO_IBGE: 2310258,
      },
      {
        NOME: "Parambu",
        CODIGO_IBGE: 2310308,
      },
      {
        NOME: "Paramoti",
        CODIGO_IBGE: 2310407,
      },
      {
        NOME: "Pedra Branca",
        CODIGO_IBGE: 2310506,
      },
      {
        NOME: "Penaforte",
        CODIGO_IBGE: 2310605,
      },
      {
        NOME: "Pentecoste",
        CODIGO_IBGE: 2310704,
      },
      {
        NOME: "Pereiro",
        CODIGO_IBGE: 2310803,
      },
      {
        NOME: "Pindoretama",
        CODIGO_IBGE: 2310852,
      },
      {
        NOME: "Piquet Carneiro",
        CODIGO_IBGE: 2310902,
      },
      {
        NOME: "Pires Ferreira",
        CODIGO_IBGE: 2310951,
      },
      {
        NOME: "Poranga",
        CODIGO_IBGE: 2311009,
      },
      {
        NOME: "Porteiras",
        CODIGO_IBGE: 2311108,
      },
      {
        NOME: "Potengi",
        CODIGO_IBGE: 2311207,
      },
      {
        NOME: "Potiretama",
        CODIGO_IBGE: 2311231,
      },
      {
        NOME: "Quiterianópolis",
        CODIGO_IBGE: 2311264,
      },
      {
        NOME: "Quixadá",
        CODIGO_IBGE: 2311306,
      },
      {
        NOME: "Quixelô",
        CODIGO_IBGE: 2311355,
      },
      {
        NOME: "Quixeramobim",
        CODIGO_IBGE: 2311405,
      },
      {
        NOME: "Quixeré",
        CODIGO_IBGE: 2311504,
      },
      {
        NOME: "Redenção",
        CODIGO_IBGE: 2311603,
      },
      {
        NOME: "Reriutaba",
        CODIGO_IBGE: 2311702,
      },
      {
        NOME: "Russas",
        CODIGO_IBGE: 2311801,
      },
      {
        NOME: "Saboeiro",
        CODIGO_IBGE: 2311900,
      },
      {
        NOME: "Salitre",
        CODIGO_IBGE: 2311959,
      },
      {
        NOME: "Santana do Acaraú",
        CODIGO_IBGE: 2312007,
      },
      {
        NOME: "Santana do Cariri",
        CODIGO_IBGE: 2312106,
      },
      {
        NOME: "Santa Quitéria",
        CODIGO_IBGE: 2312205,
      },
      {
        NOME: "São Benedito",
        CODIGO_IBGE: 2312304,
      },
      {
        NOME: "São Gonçalo do Amarante",
        CODIGO_IBGE: 2312403,
      },
      {
        NOME: "São João do Jaguaribe",
        CODIGO_IBGE: 2312502,
      },
      {
        NOME: "São Luís do Curu",
        CODIGO_IBGE: 2312601,
      },
      {
        NOME: "Senador Pompeu",
        CODIGO_IBGE: 2312700,
      },
      {
        NOME: "Senador Sá",
        CODIGO_IBGE: 2312809,
      },
      {
        NOME: "Sobral",
        CODIGO_IBGE: 2312908,
      },
      {
        NOME: "Solonópole",
        CODIGO_IBGE: 2313005,
      },
      {
        NOME: "Tabuleiro do Norte",
        CODIGO_IBGE: 2313104,
      },
      {
        NOME: "Tamboril",
        CODIGO_IBGE: 2313203,
      },
      {
        NOME: "Tarrafas",
        CODIGO_IBGE: 2313252,
      },
      {
        NOME: "Tauá",
        CODIGO_IBGE: 2313302,
      },
      {
        NOME: "Tejuçuoca",
        CODIGO_IBGE: 2313351,
      },
      {
        NOME: "Tianguá",
        CODIGO_IBGE: 2313401,
      },
      {
        NOME: "Trairi",
        CODIGO_IBGE: 2313500,
      },
      {
        NOME: "Tururu",
        CODIGO_IBGE: 2313559,
      },
      {
        NOME: "Ubajara",
        CODIGO_IBGE: 2313609,
      },
      {
        NOME: "Umari",
        CODIGO_IBGE: 2313708,
      },
      {
        NOME: "Umirim",
        CODIGO_IBGE: 2313757,
      },
      {
        NOME: "Uruburetama",
        CODIGO_IBGE: 2313807,
      },
      {
        NOME: "Uruoca",
        CODIGO_IBGE: 2313906,
      },
      {
        NOME: "Varjota",
        CODIGO_IBGE: 2313955,
      },
      {
        NOME: "Várzea Alegre",
        CODIGO_IBGE: 2314003,
      },
      {
        NOME: "Viçosa do Ceará",
        CODIGO_IBGE: 2314102,
      },
    ],
    24: [
      {
        NOME: "Acari",
        CODIGO_IBGE: 2400109,
      },
      {
        NOME: "Açu",
        CODIGO_IBGE: 2400208,
      },
      {
        NOME: "Afonso Bezerra",
        CODIGO_IBGE: 2400307,
      },
      {
        NOME: "Água Nova",
        CODIGO_IBGE: 2400406,
      },
      {
        NOME: "Alexandria",
        CODIGO_IBGE: 2400505,
      },
      {
        NOME: "Almino Afonso",
        CODIGO_IBGE: 2400604,
      },
      {
        NOME: "Alto do Rodrigues",
        CODIGO_IBGE: 2400703,
      },
      {
        NOME: "Angicos",
        CODIGO_IBGE: 2400802,
      },
      {
        NOME: "Antônio Martins",
        CODIGO_IBGE: 2400901,
      },
      {
        NOME: "Apodi",
        CODIGO_IBGE: 2401008,
      },
      {
        NOME: "Areia Branca",
        CODIGO_IBGE: 2401107,
      },
      {
        NOME: "Arês",
        CODIGO_IBGE: 2401206,
      },
      {
        NOME: "Augusto Severo",
        CODIGO_IBGE: 2401305,
      },
      {
        NOME: "Baía Formosa",
        CODIGO_IBGE: 2401404,
      },
      {
        NOME: "Baraúna",
        CODIGO_IBGE: 2401453,
      },
      {
        NOME: "Barcelona",
        CODIGO_IBGE: 2401503,
      },
      {
        NOME: "Bento Fernandes",
        CODIGO_IBGE: 2401602,
      },
      {
        NOME: "Bodó",
        CODIGO_IBGE: 2401651,
      },
      {
        NOME: "Bom Jesus",
        CODIGO_IBGE: 2401701,
      },
      {
        NOME: "Brejinho",
        CODIGO_IBGE: 2401800,
      },
      {
        NOME: "Caiçara do Norte",
        CODIGO_IBGE: 2401859,
      },
      {
        NOME: "Caiçara do Rio do Vento",
        CODIGO_IBGE: 2401909,
      },
      {
        NOME: "Caicó",
        CODIGO_IBGE: 2402006,
      },
      {
        NOME: "Campo Redondo",
        CODIGO_IBGE: 2402105,
      },
      {
        NOME: "Canguaretama",
        CODIGO_IBGE: 2402204,
      },
      {
        NOME: "Caraúbas",
        CODIGO_IBGE: 2402303,
      },
      {
        NOME: "Carnaúba dos Dantas",
        CODIGO_IBGE: 2402402,
      },
      {
        NOME: "Carnaubais",
        CODIGO_IBGE: 2402501,
      },
      {
        NOME: "Ceará-Mirim",
        CODIGO_IBGE: 2402600,
      },
      {
        NOME: "Cerro Corá",
        CODIGO_IBGE: 2402709,
      },
      {
        NOME: "Coronel Ezequiel",
        CODIGO_IBGE: 2402808,
      },
      {
        NOME: "Coronel João Pessoa",
        CODIGO_IBGE: 2402907,
      },
      {
        NOME: "Cruzeta",
        CODIGO_IBGE: 2403004,
      },
      {
        NOME: "Currais Novos",
        CODIGO_IBGE: 2403103,
      },
      {
        NOME: "Doutor Severiano",
        CODIGO_IBGE: 2403202,
      },
      {
        NOME: "Parnamirim",
        CODIGO_IBGE: 2403251,
      },
      {
        NOME: "Encanto",
        CODIGO_IBGE: 2403301,
      },
      {
        NOME: "Equador",
        CODIGO_IBGE: 2403400,
      },
      {
        NOME: "Espírito Santo",
        CODIGO_IBGE: 2403509,
      },
      {
        NOME: "Extremoz",
        CODIGO_IBGE: 2403608,
      },
      {
        NOME: "Felipe Guerra",
        CODIGO_IBGE: 2403707,
      },
      {
        NOME: "Fernando Pedroza",
        CODIGO_IBGE: 2403756,
      },
      {
        NOME: "Florânia",
        CODIGO_IBGE: 2403806,
      },
      {
        NOME: "Francisco Dantas",
        CODIGO_IBGE: 2403905,
      },
      {
        NOME: "Frutuoso Gomes",
        CODIGO_IBGE: 2404002,
      },
      {
        NOME: "Galinhos",
        CODIGO_IBGE: 2404101,
      },
      {
        NOME: "Goianinha",
        CODIGO_IBGE: 2404200,
      },
      {
        NOME: "Governador Dix-Sept Rosado",
        CODIGO_IBGE: 2404309,
      },
      {
        NOME: "Grossos",
        CODIGO_IBGE: 2404408,
      },
      {
        NOME: "Guamaré",
        CODIGO_IBGE: 2404507,
      },
      {
        NOME: "Ielmo Marinho",
        CODIGO_IBGE: 2404606,
      },
      {
        NOME: "Ipanguaçu",
        CODIGO_IBGE: 2404705,
      },
      {
        NOME: "Ipueira",
        CODIGO_IBGE: 2404804,
      },
      {
        NOME: "Itajá",
        CODIGO_IBGE: 2404853,
      },
      {
        NOME: "Itaú",
        CODIGO_IBGE: 2404903,
      },
      {
        NOME: "Jaçanã",
        CODIGO_IBGE: 2405009,
      },
      {
        NOME: "Jandaíra",
        CODIGO_IBGE: 2405108,
      },
      {
        NOME: "Janduís",
        CODIGO_IBGE: 2405207,
      },
      {
        NOME: "Januário Cicco",
        CODIGO_IBGE: 2405306,
      },
      {
        NOME: "Japi",
        CODIGO_IBGE: 2405405,
      },
      {
        NOME: "Jardim de Angicos",
        CODIGO_IBGE: 2405504,
      },
      {
        NOME: "Jardim de Piranhas",
        CODIGO_IBGE: 2405603,
      },
      {
        NOME: "Jardim do Seridó",
        CODIGO_IBGE: 2405702,
      },
      {
        NOME: "João Câmara",
        CODIGO_IBGE: 2405801,
      },
      {
        NOME: "João Dias",
        CODIGO_IBGE: 2405900,
      },
      {
        NOME: "José da Penha",
        CODIGO_IBGE: 2406007,
      },
      {
        NOME: "Jucurutu",
        CODIGO_IBGE: 2406106,
      },
      {
        NOME: "Jundiá",
        CODIGO_IBGE: 2406155,
      },
      {
        NOME: "Lagoa d'Anta",
        CODIGO_IBGE: 2406205,
      },
      {
        NOME: "Lagoa de Pedras",
        CODIGO_IBGE: 2406304,
      },
      {
        NOME: "Lagoa de Velhos",
        CODIGO_IBGE: 2406403,
      },
      {
        NOME: "Lagoa Nova",
        CODIGO_IBGE: 2406502,
      },
      {
        NOME: "Lagoa Salgada",
        CODIGO_IBGE: 2406601,
      },
      {
        NOME: "Lajes",
        CODIGO_IBGE: 2406700,
      },
      {
        NOME: "Lajes Pintadas",
        CODIGO_IBGE: 2406809,
      },
      {
        NOME: "Lucrécia",
        CODIGO_IBGE: 2406908,
      },
      {
        NOME: "Luís Gomes",
        CODIGO_IBGE: 2407005,
      },
      {
        NOME: "Macaíba",
        CODIGO_IBGE: 2407104,
      },
      {
        NOME: "Macau",
        CODIGO_IBGE: 2407203,
      },
      {
        NOME: "Major Sales",
        CODIGO_IBGE: 2407252,
      },
      {
        NOME: "Marcelino Vieira",
        CODIGO_IBGE: 2407302,
      },
      {
        NOME: "Martins",
        CODIGO_IBGE: 2407401,
      },
      {
        NOME: "Maxaranguape",
        CODIGO_IBGE: 2407500,
      },
      {
        NOME: "Messias Targino",
        CODIGO_IBGE: 2407609,
      },
      {
        NOME: "Montanhas",
        CODIGO_IBGE: 2407708,
      },
      {
        NOME: "Monte Alegre",
        CODIGO_IBGE: 2407807,
      },
      {
        NOME: "Monte das Gameleiras",
        CODIGO_IBGE: 2407906,
      },
      {
        NOME: "Mossoró",
        CODIGO_IBGE: 2408003,
      },
      {
        NOME: "Natal",
        CODIGO_IBGE: 2408102,
      },
      {
        NOME: "Nísia Floresta",
        CODIGO_IBGE: 2408201,
      },
      {
        NOME: "Nova Cruz",
        CODIGO_IBGE: 2408300,
      },
      {
        NOME: "Olho d'Água do Borges",
        CODIGO_IBGE: 2408409,
      },
      {
        NOME: "Ouro Branco",
        CODIGO_IBGE: 2408508,
      },
      {
        NOME: "Paraná",
        CODIGO_IBGE: 2408607,
      },
      {
        NOME: "Paraú",
        CODIGO_IBGE: 2408706,
      },
      {
        NOME: "Parazinho",
        CODIGO_IBGE: 2408805,
      },
      {
        NOME: "Parelhas",
        CODIGO_IBGE: 2408904,
      },
      {
        NOME: "Rio do Fogo",
        CODIGO_IBGE: 2408953,
      },
      {
        NOME: "Passa e Fica",
        CODIGO_IBGE: 2409100,
      },
      {
        NOME: "Passagem",
        CODIGO_IBGE: 2409209,
      },
      {
        NOME: "Patu",
        CODIGO_IBGE: 2409308,
      },
      {
        NOME: "Santa Maria",
        CODIGO_IBGE: 2409332,
      },
      {
        NOME: "Pau dos Ferros",
        CODIGO_IBGE: 2409407,
      },
      {
        NOME: "Pedra Grande",
        CODIGO_IBGE: 2409506,
      },
      {
        NOME: "Pedra Preta",
        CODIGO_IBGE: 2409605,
      },
      {
        NOME: "Pedro Avelino",
        CODIGO_IBGE: 2409704,
      },
      {
        NOME: "Pedro Velho",
        CODIGO_IBGE: 2409803,
      },
      {
        NOME: "Pendências",
        CODIGO_IBGE: 2409902,
      },
      {
        NOME: "Pilões",
        CODIGO_IBGE: 2410009,
      },
      {
        NOME: "Poço Branco",
        CODIGO_IBGE: 2410108,
      },
      {
        NOME: "Portalegre",
        CODIGO_IBGE: 2410207,
      },
      {
        NOME: "Porto do Mangue",
        CODIGO_IBGE: 2410256,
      },
      {
        NOME: "Serra Caiada",
        CODIGO_IBGE: 2410306,
      },
      {
        NOME: "Pureza",
        CODIGO_IBGE: 2410405,
      },
      {
        NOME: "Rafael Fernandes",
        CODIGO_IBGE: 2410504,
      },
      {
        NOME: "Rafael Godeiro",
        CODIGO_IBGE: 2410603,
      },
      {
        NOME: "Riacho da Cruz",
        CODIGO_IBGE: 2410702,
      },
      {
        NOME: "Riacho de Santana",
        CODIGO_IBGE: 2410801,
      },
      {
        NOME: "Riachuelo",
        CODIGO_IBGE: 2410900,
      },
      {
        NOME: "Rodolfo Fernandes",
        CODIGO_IBGE: 2411007,
      },
      {
        NOME: "Tibau",
        CODIGO_IBGE: 2411056,
      },
      {
        NOME: "Ruy Barbosa",
        CODIGO_IBGE: 2411106,
      },
      {
        NOME: "Santa Cruz",
        CODIGO_IBGE: 2411205,
      },
      {
        NOME: "Santana do Matos",
        CODIGO_IBGE: 2411403,
      },
      {
        NOME: "Santana do Seridó",
        CODIGO_IBGE: 2411429,
      },
      {
        NOME: "Santo Antônio",
        CODIGO_IBGE: 2411502,
      },
      {
        NOME: "São Bento do Norte",
        CODIGO_IBGE: 2411601,
      },
      {
        NOME: "São Bento do Trairí",
        CODIGO_IBGE: 2411700,
      },
      {
        NOME: "São Fernando",
        CODIGO_IBGE: 2411809,
      },
      {
        NOME: "São Francisco do Oeste",
        CODIGO_IBGE: 2411908,
      },
      {
        NOME: "São Gonçalo do Amarante",
        CODIGO_IBGE: 2412005,
      },
      {
        NOME: "São João do Sabugi",
        CODIGO_IBGE: 2412104,
      },
      {
        NOME: "São José de Mipibu",
        CODIGO_IBGE: 2412203,
      },
      {
        NOME: "São José do Campestre",
        CODIGO_IBGE: 2412302,
      },
      {
        NOME: "São José do Seridó",
        CODIGO_IBGE: 2412401,
      },
      {
        NOME: "São Miguel",
        CODIGO_IBGE: 2412500,
      },
      {
        NOME: "São Miguel do Gostoso",
        CODIGO_IBGE: 2412559,
      },
      {
        NOME: "São Paulo do Potengi",
        CODIGO_IBGE: 2412609,
      },
      {
        NOME: "São Pedro",
        CODIGO_IBGE: 2412708,
      },
      {
        NOME: "São Rafael",
        CODIGO_IBGE: 2412807,
      },
      {
        NOME: "São Tomé",
        CODIGO_IBGE: 2412906,
      },
      {
        NOME: "São Vicente",
        CODIGO_IBGE: 2413003,
      },
      {
        NOME: "Senador Elói de Souza",
        CODIGO_IBGE: 2413102,
      },
      {
        NOME: "Senador Georgino Avelino",
        CODIGO_IBGE: 2413201,
      },
      {
        NOME: "Serra de São Bento",
        CODIGO_IBGE: 2413300,
      },
      {
        NOME: "Serra do Mel",
        CODIGO_IBGE: 2413359,
      },
      {
        NOME: "Serra Negra do Norte",
        CODIGO_IBGE: 2413409,
      },
      {
        NOME: "Serrinha",
        CODIGO_IBGE: 2413508,
      },
      {
        NOME: "Serrinha dos Pintos",
        CODIGO_IBGE: 2413557,
      },
      {
        NOME: "Severiano Melo",
        CODIGO_IBGE: 2413607,
      },
      {
        NOME: "Sítio Novo",
        CODIGO_IBGE: 2413706,
      },
      {
        NOME: "Taboleiro Grande",
        CODIGO_IBGE: 2413805,
      },
      {
        NOME: "Taipu",
        CODIGO_IBGE: 2413904,
      },
      {
        NOME: "Tangará",
        CODIGO_IBGE: 2414001,
      },
      {
        NOME: "Tenente Ananias",
        CODIGO_IBGE: 2414100,
      },
      {
        NOME: "Tenente Laurentino Cruz",
        CODIGO_IBGE: 2414159,
      },
      {
        NOME: "Tibau do Sul",
        CODIGO_IBGE: 2414209,
      },
      {
        NOME: "Timbaúba dos Batistas",
        CODIGO_IBGE: 2414308,
      },
      {
        NOME: "Touros",
        CODIGO_IBGE: 2414407,
      },
      {
        NOME: "Triunfo Potiguar",
        CODIGO_IBGE: 2414456,
      },
      {
        NOME: "Umarizal",
        CODIGO_IBGE: 2414506,
      },
      {
        NOME: "Upanema",
        CODIGO_IBGE: 2414605,
      },
      {
        NOME: "Várzea",
        CODIGO_IBGE: 2414704,
      },
      {
        NOME: "Venha-Ver",
        CODIGO_IBGE: 2414753,
      },
      {
        NOME: "Vera Cruz",
        CODIGO_IBGE: 2414803,
      },
      {
        NOME: "Viçosa",
        CODIGO_IBGE: 2414902,
      },
      {
        NOME: "Vila Flor",
        CODIGO_IBGE: 2415008,
      },
    ],
    25: [
      {
        NOME: "Água Branca",
        CODIGO_IBGE: 2500106,
      },
      {
        NOME: "Aguiar",
        CODIGO_IBGE: 2500205,
      },
      {
        NOME: "Alagoa Grande",
        CODIGO_IBGE: 2500304,
      },
      {
        NOME: "Alagoa Nova",
        CODIGO_IBGE: 2500403,
      },
      {
        NOME: "Alagoinha",
        CODIGO_IBGE: 2500502,
      },
      {
        NOME: "Alcantil",
        CODIGO_IBGE: 2500536,
      },
      {
        NOME: "Algodão de Jandaíra",
        CODIGO_IBGE: 2500577,
      },
      {
        NOME: "Alhandra",
        CODIGO_IBGE: 2500601,
      },
      {
        NOME: "São João do Rio do Peixe",
        CODIGO_IBGE: 2500700,
      },
      {
        NOME: "Amparo",
        CODIGO_IBGE: 2500734,
      },
      {
        NOME: "Aparecida",
        CODIGO_IBGE: 2500775,
      },
      {
        NOME: "Araçagi",
        CODIGO_IBGE: 2500809,
      },
      {
        NOME: "Arara",
        CODIGO_IBGE: 2500908,
      },
      {
        NOME: "Araruna",
        CODIGO_IBGE: 2501005,
      },
      {
        NOME: "Areia",
        CODIGO_IBGE: 2501104,
      },
      {
        NOME: "Areia de Baraúnas",
        CODIGO_IBGE: 2501153,
      },
      {
        NOME: "Areial",
        CODIGO_IBGE: 2501203,
      },
      {
        NOME: "Aroeiras",
        CODIGO_IBGE: 2501302,
      },
      {
        NOME: "Assunção",
        CODIGO_IBGE: 2501351,
      },
      {
        NOME: "Baía da Traição",
        CODIGO_IBGE: 2501401,
      },
      {
        NOME: "Bananeiras",
        CODIGO_IBGE: 2501500,
      },
      {
        NOME: "Baraúna",
        CODIGO_IBGE: 2501534,
      },
      {
        NOME: "Barra de Santana",
        CODIGO_IBGE: 2501575,
      },
      {
        NOME: "Barra de Santa Rosa",
        CODIGO_IBGE: 2501609,
      },
      {
        NOME: "Barra de São Miguel",
        CODIGO_IBGE: 2501708,
      },
      {
        NOME: "Bayeux",
        CODIGO_IBGE: 2501807,
      },
      {
        NOME: "Belém",
        CODIGO_IBGE: 2501906,
      },
      {
        NOME: "Belém do Brejo do Cruz",
        CODIGO_IBGE: 2502003,
      },
      {
        NOME: "Bernardino Batista",
        CODIGO_IBGE: 2502052,
      },
      {
        NOME: "Boa Ventura",
        CODIGO_IBGE: 2502102,
      },
      {
        NOME: "Boa Vista",
        CODIGO_IBGE: 2502151,
      },
      {
        NOME: "Bom Jesus",
        CODIGO_IBGE: 2502201,
      },
      {
        NOME: "Bom Sucesso",
        CODIGO_IBGE: 2502300,
      },
      {
        NOME: "Bonito de Santa Fé",
        CODIGO_IBGE: 2502409,
      },
      {
        NOME: "Boqueirão",
        CODIGO_IBGE: 2502508,
      },
      {
        NOME: "Igaracy",
        CODIGO_IBGE: 2502607,
      },
      {
        NOME: "Borborema",
        CODIGO_IBGE: 2502706,
      },
      {
        NOME: "Brejo do Cruz",
        CODIGO_IBGE: 2502805,
      },
      {
        NOME: "Brejo dos Santos",
        CODIGO_IBGE: 2502904,
      },
      {
        NOME: "Caaporã",
        CODIGO_IBGE: 2503001,
      },
      {
        NOME: "Cabaceiras",
        CODIGO_IBGE: 2503100,
      },
      {
        NOME: "Cabedelo",
        CODIGO_IBGE: 2503209,
      },
      {
        NOME: "Cachoeira dos Índios",
        CODIGO_IBGE: 2503308,
      },
      {
        NOME: "Cacimba de Areia",
        CODIGO_IBGE: 2503407,
      },
      {
        NOME: "Cacimba de Dentro",
        CODIGO_IBGE: 2503506,
      },
      {
        NOME: "Cacimbas",
        CODIGO_IBGE: 2503555,
      },
      {
        NOME: "Caiçara",
        CODIGO_IBGE: 2503605,
      },
      {
        NOME: "Cajazeiras",
        CODIGO_IBGE: 2503704,
      },
      {
        NOME: "Cajazeirinhas",
        CODIGO_IBGE: 2503753,
      },
      {
        NOME: "Caldas Brandão",
        CODIGO_IBGE: 2503803,
      },
      {
        NOME: "Camalaú",
        CODIGO_IBGE: 2503902,
      },
      {
        NOME: "Campina Grande",
        CODIGO_IBGE: 2504009,
      },
      {
        NOME: "Capim",
        CODIGO_IBGE: 2504033,
      },
      {
        NOME: "Caraúbas",
        CODIGO_IBGE: 2504074,
      },
      {
        NOME: "Carrapateira",
        CODIGO_IBGE: 2504108,
      },
      {
        NOME: "Casserengue",
        CODIGO_IBGE: 2504157,
      },
      {
        NOME: "Catingueira",
        CODIGO_IBGE: 2504207,
      },
      {
        NOME: "Catolé do Rocha",
        CODIGO_IBGE: 2504306,
      },
      {
        NOME: "Caturité",
        CODIGO_IBGE: 2504355,
      },
      {
        NOME: "Conceição",
        CODIGO_IBGE: 2504405,
      },
      {
        NOME: "Condado",
        CODIGO_IBGE: 2504504,
      },
      {
        NOME: "Conde",
        CODIGO_IBGE: 2504603,
      },
      {
        NOME: "Congo",
        CODIGO_IBGE: 2504702,
      },
      {
        NOME: "Coremas",
        CODIGO_IBGE: 2504801,
      },
      {
        NOME: "Coxixola",
        CODIGO_IBGE: 2504850,
      },
      {
        NOME: "Cruz do Espírito Santo",
        CODIGO_IBGE: 2504900,
      },
      {
        NOME: "Cubati",
        CODIGO_IBGE: 2505006,
      },
      {
        NOME: "Cuité",
        CODIGO_IBGE: 2505105,
      },
      {
        NOME: "Cuitegi",
        CODIGO_IBGE: 2505204,
      },
      {
        NOME: "Cuité de Mamanguape",
        CODIGO_IBGE: 2505238,
      },
      {
        NOME: "Curral de Cima",
        CODIGO_IBGE: 2505279,
      },
      {
        NOME: "Curral Velho",
        CODIGO_IBGE: 2505303,
      },
      {
        NOME: "Damião",
        CODIGO_IBGE: 2505352,
      },
      {
        NOME: "Desterro",
        CODIGO_IBGE: 2505402,
      },
      {
        NOME: "Vista Serrana",
        CODIGO_IBGE: 2505501,
      },
      {
        NOME: "Diamante",
        CODIGO_IBGE: 2505600,
      },
      {
        NOME: "Dona Inês",
        CODIGO_IBGE: 2505709,
      },
      {
        NOME: "Duas Estradas",
        CODIGO_IBGE: 2505808,
      },
      {
        NOME: "Emas",
        CODIGO_IBGE: 2505907,
      },
      {
        NOME: "Esperança",
        CODIGO_IBGE: 2506004,
      },
      {
        NOME: "Fagundes",
        CODIGO_IBGE: 2506103,
      },
      {
        NOME: "Frei Martinho",
        CODIGO_IBGE: 2506202,
      },
      {
        NOME: "Gado Bravo",
        CODIGO_IBGE: 2506251,
      },
      {
        NOME: "Guarabira",
        CODIGO_IBGE: 2506301,
      },
      {
        NOME: "Gurinhém",
        CODIGO_IBGE: 2506400,
      },
      {
        NOME: "Gurjão",
        CODIGO_IBGE: 2506509,
      },
      {
        NOME: "Ibiara",
        CODIGO_IBGE: 2506608,
      },
      {
        NOME: "Imaculada",
        CODIGO_IBGE: 2506707,
      },
      {
        NOME: "Ingá",
        CODIGO_IBGE: 2506806,
      },
      {
        NOME: "Itabaiana",
        CODIGO_IBGE: 2506905,
      },
      {
        NOME: "Itaporanga",
        CODIGO_IBGE: 2507002,
      },
      {
        NOME: "Itapororoca",
        CODIGO_IBGE: 2507101,
      },
      {
        NOME: "Itatuba",
        CODIGO_IBGE: 2507200,
      },
      {
        NOME: "Jacaraú",
        CODIGO_IBGE: 2507309,
      },
      {
        NOME: "Jericó",
        CODIGO_IBGE: 2507408,
      },
      {
        NOME: "João Pessoa",
        CODIGO_IBGE: 2507507,
      },
      {
        NOME: "Juarez Távora",
        CODIGO_IBGE: 2507606,
      },
      {
        NOME: "Juazeirinho",
        CODIGO_IBGE: 2507705,
      },
      {
        NOME: "Junco do Seridó",
        CODIGO_IBGE: 2507804,
      },
      {
        NOME: "Juripiranga",
        CODIGO_IBGE: 2507903,
      },
      {
        NOME: "Juru",
        CODIGO_IBGE: 2508000,
      },
      {
        NOME: "Lagoa",
        CODIGO_IBGE: 2508109,
      },
      {
        NOME: "Lagoa de Dentro",
        CODIGO_IBGE: 2508208,
      },
      {
        NOME: "Lagoa Seca",
        CODIGO_IBGE: 2508307,
      },
      {
        NOME: "Lastro",
        CODIGO_IBGE: 2508406,
      },
      {
        NOME: "Livramento",
        CODIGO_IBGE: 2508505,
      },
      {
        NOME: "Logradouro",
        CODIGO_IBGE: 2508554,
      },
      {
        NOME: "Lucena",
        CODIGO_IBGE: 2508604,
      },
      {
        NOME: "Mãe d'Água",
        CODIGO_IBGE: 2508703,
      },
      {
        NOME: "Malta",
        CODIGO_IBGE: 2508802,
      },
      {
        NOME: "Mamanguape",
        CODIGO_IBGE: 2508901,
      },
      {
        NOME: "Manaíra",
        CODIGO_IBGE: 2509008,
      },
      {
        NOME: "Marcação",
        CODIGO_IBGE: 2509057,
      },
      {
        NOME: "Mari",
        CODIGO_IBGE: 2509107,
      },
      {
        NOME: "Marizópolis",
        CODIGO_IBGE: 2509156,
      },
      {
        NOME: "Massaranduba",
        CODIGO_IBGE: 2509206,
      },
      {
        NOME: "Mataraca",
        CODIGO_IBGE: 2509305,
      },
      {
        NOME: "Matinhas",
        CODIGO_IBGE: 2509339,
      },
      {
        NOME: "Mato Grosso",
        CODIGO_IBGE: 2509370,
      },
      {
        NOME: "Maturéia",
        CODIGO_IBGE: 2509396,
      },
      {
        NOME: "Mogeiro",
        CODIGO_IBGE: 2509404,
      },
      {
        NOME: "Montadas",
        CODIGO_IBGE: 2509503,
      },
      {
        NOME: "Monte Horebe",
        CODIGO_IBGE: 2509602,
      },
      {
        NOME: "Monteiro",
        CODIGO_IBGE: 2509701,
      },
      {
        NOME: "Mulungu",
        CODIGO_IBGE: 2509800,
      },
      {
        NOME: "Natuba",
        CODIGO_IBGE: 2509909,
      },
      {
        NOME: "Nazarezinho",
        CODIGO_IBGE: 2510006,
      },
      {
        NOME: "Nova Floresta",
        CODIGO_IBGE: 2510105,
      },
      {
        NOME: "Nova Olinda",
        CODIGO_IBGE: 2510204,
      },
      {
        NOME: "Nova Palmeira",
        CODIGO_IBGE: 2510303,
      },
      {
        NOME: "Olho d'Água",
        CODIGO_IBGE: 2510402,
      },
      {
        NOME: "Olivedos",
        CODIGO_IBGE: 2510501,
      },
      {
        NOME: "Ouro Velho",
        CODIGO_IBGE: 2510600,
      },
      {
        NOME: "Parari",
        CODIGO_IBGE: 2510659,
      },
      {
        NOME: "Passagem",
        CODIGO_IBGE: 2510709,
      },
      {
        NOME: "Patos",
        CODIGO_IBGE: 2510808,
      },
      {
        NOME: "Paulista",
        CODIGO_IBGE: 2510907,
      },
      {
        NOME: "Pedra Branca",
        CODIGO_IBGE: 2511004,
      },
      {
        NOME: "Pedra Lavrada",
        CODIGO_IBGE: 2511103,
      },
      {
        NOME: "Pedras de Fogo",
        CODIGO_IBGE: 2511202,
      },
      {
        NOME: "Piancó",
        CODIGO_IBGE: 2511301,
      },
      {
        NOME: "Picuí",
        CODIGO_IBGE: 2511400,
      },
      {
        NOME: "Pilar",
        CODIGO_IBGE: 2511509,
      },
      {
        NOME: "Pilões",
        CODIGO_IBGE: 2511608,
      },
      {
        NOME: "Pilõezinhos",
        CODIGO_IBGE: 2511707,
      },
      {
        NOME: "Pirpirituba",
        CODIGO_IBGE: 2511806,
      },
      {
        NOME: "Pitimbu",
        CODIGO_IBGE: 2511905,
      },
      {
        NOME: "Pocinhos",
        CODIGO_IBGE: 2512002,
      },
      {
        NOME: "Poço Dantas",
        CODIGO_IBGE: 2512036,
      },
      {
        NOME: "Poço de José de Moura",
        CODIGO_IBGE: 2512077,
      },
      {
        NOME: "Pombal",
        CODIGO_IBGE: 2512101,
      },
      {
        NOME: "Prata",
        CODIGO_IBGE: 2512200,
      },
      {
        NOME: "Princesa Isabel",
        CODIGO_IBGE: 2512309,
      },
      {
        NOME: "Puxinanã",
        CODIGO_IBGE: 2512408,
      },
      {
        NOME: "Queimadas",
        CODIGO_IBGE: 2512507,
      },
      {
        NOME: "Quixaba",
        CODIGO_IBGE: 2512606,
      },
      {
        NOME: "Remígio",
        CODIGO_IBGE: 2512705,
      },
      {
        NOME: "Pedro Régis",
        CODIGO_IBGE: 2512721,
      },
      {
        NOME: "Riachão",
        CODIGO_IBGE: 2512747,
      },
      {
        NOME: "Riachão do Bacamarte",
        CODIGO_IBGE: 2512754,
      },
      {
        NOME: "Riachão do Poço",
        CODIGO_IBGE: 2512762,
      },
      {
        NOME: "Riacho de Santo Antônio",
        CODIGO_IBGE: 2512788,
      },
      {
        NOME: "Riacho dos Cavalos",
        CODIGO_IBGE: 2512804,
      },
      {
        NOME: "Rio Tinto",
        CODIGO_IBGE: 2512903,
      },
      {
        NOME: "Salgadinho",
        CODIGO_IBGE: 2513000,
      },
      {
        NOME: "Salgado de São Félix",
        CODIGO_IBGE: 2513109,
      },
      {
        NOME: "Santa Cecília",
        CODIGO_IBGE: 2513158,
      },
      {
        NOME: "Santa Cruz",
        CODIGO_IBGE: 2513208,
      },
      {
        NOME: "Santa Helena",
        CODIGO_IBGE: 2513307,
      },
      {
        NOME: "Santa Inês",
        CODIGO_IBGE: 2513356,
      },
      {
        NOME: "Santa Luzia",
        CODIGO_IBGE: 2513406,
      },
      {
        NOME: "Santana de Mangueira",
        CODIGO_IBGE: 2513505,
      },
      {
        NOME: "Santana dos Garrotes",
        CODIGO_IBGE: 2513604,
      },
      {
        NOME: "Joca Claudino",
        CODIGO_IBGE: 2513653,
      },
      {
        NOME: "Santa Rita",
        CODIGO_IBGE: 2513703,
      },
      {
        NOME: "Santa Teresinha",
        CODIGO_IBGE: 2513802,
      },
      {
        NOME: "Santo André",
        CODIGO_IBGE: 2513851,
      },
      {
        NOME: "São Bento",
        CODIGO_IBGE: 2513901,
      },
      {
        NOME: "São Bentinho",
        CODIGO_IBGE: 2513927,
      },
      {
        NOME: "São Domingos do Cariri",
        CODIGO_IBGE: 2513943,
      },
      {
        NOME: "São Domingos",
        CODIGO_IBGE: 2513968,
      },
      {
        NOME: "São Francisco",
        CODIGO_IBGE: 2513984,
      },
      {
        NOME: "São João do Cariri",
        CODIGO_IBGE: 2514008,
      },
      {
        NOME: "São João do Tigre",
        CODIGO_IBGE: 2514107,
      },
      {
        NOME: "São José da Lagoa Tapada",
        CODIGO_IBGE: 2514206,
      },
      {
        NOME: "São José de Caiana",
        CODIGO_IBGE: 2514305,
      },
      {
        NOME: "São José de Espinharas",
        CODIGO_IBGE: 2514404,
      },
      {
        NOME: "São José dos Ramos",
        CODIGO_IBGE: 2514453,
      },
      {
        NOME: "São José de Piranhas",
        CODIGO_IBGE: 2514503,
      },
      {
        NOME: "São José de Princesa",
        CODIGO_IBGE: 2514552,
      },
      {
        NOME: "São José do Bonfim",
        CODIGO_IBGE: 2514602,
      },
      {
        NOME: "São José do Brejo do Cruz",
        CODIGO_IBGE: 2514651,
      },
      {
        NOME: "São José do Sabugi",
        CODIGO_IBGE: 2514701,
      },
      {
        NOME: "São José dos Cordeiros",
        CODIGO_IBGE: 2514800,
      },
      {
        NOME: "São Mamede",
        CODIGO_IBGE: 2514909,
      },
      {
        NOME: "São Miguel de Taipu",
        CODIGO_IBGE: 2515005,
      },
      {
        NOME: "São Sebastião de Lagoa de Roça",
        CODIGO_IBGE: 2515104,
      },
      {
        NOME: "São Sebastião do Umbuzeiro",
        CODIGO_IBGE: 2515203,
      },
      {
        NOME: "Sapé",
        CODIGO_IBGE: 2515302,
      },
      {
        NOME: "São Vicente do Seridó",
        CODIGO_IBGE: 2515401,
      },
      {
        NOME: "Serra Branca",
        CODIGO_IBGE: 2515500,
      },
      {
        NOME: "Serra da Raiz",
        CODIGO_IBGE: 2515609,
      },
      {
        NOME: "Serra Grande",
        CODIGO_IBGE: 2515708,
      },
      {
        NOME: "Serra Redonda",
        CODIGO_IBGE: 2515807,
      },
      {
        NOME: "Serraria",
        CODIGO_IBGE: 2515906,
      },
      {
        NOME: "Sertãozinho",
        CODIGO_IBGE: 2515930,
      },
      {
        NOME: "Sobrado",
        CODIGO_IBGE: 2515971,
      },
      {
        NOME: "Solânea",
        CODIGO_IBGE: 2516003,
      },
      {
        NOME: "Soledade",
        CODIGO_IBGE: 2516102,
      },
      {
        NOME: "Sossêgo",
        CODIGO_IBGE: 2516151,
      },
      {
        NOME: "Sousa",
        CODIGO_IBGE: 2516201,
      },
      {
        NOME: "Sumé",
        CODIGO_IBGE: 2516300,
      },
      {
        NOME: "Tacima",
        CODIGO_IBGE: 2516409,
      },
      {
        NOME: "Taperoá",
        CODIGO_IBGE: 2516508,
      },
      {
        NOME: "Tavares",
        CODIGO_IBGE: 2516607,
      },
      {
        NOME: "Teixeira",
        CODIGO_IBGE: 2516706,
      },
      {
        NOME: "Tenório",
        CODIGO_IBGE: 2516755,
      },
      {
        NOME: "Triunfo",
        CODIGO_IBGE: 2516805,
      },
      {
        NOME: "Uiraúna",
        CODIGO_IBGE: 2516904,
      },
      {
        NOME: "Umbuzeiro",
        CODIGO_IBGE: 2517001,
      },
      {
        NOME: "Várzea",
        CODIGO_IBGE: 2517100,
      },
      {
        NOME: "Vieirópolis",
        CODIGO_IBGE: 2517209,
      },
      {
        NOME: "Zabelê",
        CODIGO_IBGE: 2517407,
      },
    ],
    26: [
      {
        NOME: "Abreu e Lima",
        CODIGO_IBGE: 2600054,
      },
      {
        NOME: "Afogados da Ingazeira",
        CODIGO_IBGE: 2600104,
      },
      {
        NOME: "Afrânio",
        CODIGO_IBGE: 2600203,
      },
      {
        NOME: "Agrestina",
        CODIGO_IBGE: 2600302,
      },
      {
        NOME: "Água Preta",
        CODIGO_IBGE: 2600401,
      },
      {
        NOME: "Águas Belas",
        CODIGO_IBGE: 2600500,
      },
      {
        NOME: "Alagoinha",
        CODIGO_IBGE: 2600609,
      },
      {
        NOME: "Aliança",
        CODIGO_IBGE: 2600708,
      },
      {
        NOME: "Altinho",
        CODIGO_IBGE: 2600807,
      },
      {
        NOME: "Amaraji",
        CODIGO_IBGE: 2600906,
      },
      {
        NOME: "Angelim",
        CODIGO_IBGE: 2601003,
      },
      {
        NOME: "Araçoiaba",
        CODIGO_IBGE: 2601052,
      },
      {
        NOME: "Araripina",
        CODIGO_IBGE: 2601102,
      },
      {
        NOME: "Arcoverde",
        CODIGO_IBGE: 2601201,
      },
      {
        NOME: "Barra de Guabiraba",
        CODIGO_IBGE: 2601300,
      },
      {
        NOME: "Barreiros",
        CODIGO_IBGE: 2601409,
      },
      {
        NOME: "Belém de Maria",
        CODIGO_IBGE: 2601508,
      },
      {
        NOME: "Belém do São Francisco",
        CODIGO_IBGE: 2601607,
      },
      {
        NOME: "Belo Jardim",
        CODIGO_IBGE: 2601706,
      },
      {
        NOME: "Betânia",
        CODIGO_IBGE: 2601805,
      },
      {
        NOME: "Bezerros",
        CODIGO_IBGE: 2601904,
      },
      {
        NOME: "Bodocó",
        CODIGO_IBGE: 2602001,
      },
      {
        NOME: "Bom Conselho",
        CODIGO_IBGE: 2602100,
      },
      {
        NOME: "Bom Jardim",
        CODIGO_IBGE: 2602209,
      },
      {
        NOME: "Bonito",
        CODIGO_IBGE: 2602308,
      },
      {
        NOME: "Brejão",
        CODIGO_IBGE: 2602407,
      },
      {
        NOME: "Brejinho",
        CODIGO_IBGE: 2602506,
      },
      {
        NOME: "Brejo da Madre de Deus",
        CODIGO_IBGE: 2602605,
      },
      {
        NOME: "Buenos Aires",
        CODIGO_IBGE: 2602704,
      },
      {
        NOME: "Buíque",
        CODIGO_IBGE: 2602803,
      },
      {
        NOME: "Cabo de Santo Agostinho",
        CODIGO_IBGE: 2602902,
      },
      {
        NOME: "Cabrobó",
        CODIGO_IBGE: 2603009,
      },
      {
        NOME: "Cachoeirinha",
        CODIGO_IBGE: 2603108,
      },
      {
        NOME: "Caetés",
        CODIGO_IBGE: 2603207,
      },
      {
        NOME: "Calçado",
        CODIGO_IBGE: 2603306,
      },
      {
        NOME: "Calumbi",
        CODIGO_IBGE: 2603405,
      },
      {
        NOME: "Camaragibe",
        CODIGO_IBGE: 2603454,
      },
      {
        NOME: "Camocim de São Félix",
        CODIGO_IBGE: 2603504,
      },
      {
        NOME: "Camutanga",
        CODIGO_IBGE: 2603603,
      },
      {
        NOME: "Canhotinho",
        CODIGO_IBGE: 2603702,
      },
      {
        NOME: "Capoeiras",
        CODIGO_IBGE: 2603801,
      },
      {
        NOME: "Carnaíba",
        CODIGO_IBGE: 2603900,
      },
      {
        NOME: "Carnaubeira da Penha",
        CODIGO_IBGE: 2603926,
      },
      {
        NOME: "Carpina",
        CODIGO_IBGE: 2604007,
      },
      {
        NOME: "Caruaru",
        CODIGO_IBGE: 2604106,
      },
      {
        NOME: "Casinhas",
        CODIGO_IBGE: 2604155,
      },
      {
        NOME: "Catende",
        CODIGO_IBGE: 2604205,
      },
      {
        NOME: "Cedro",
        CODIGO_IBGE: 2604304,
      },
      {
        NOME: "Chã de Alegria",
        CODIGO_IBGE: 2604403,
      },
      {
        NOME: "Chã Grande",
        CODIGO_IBGE: 2604502,
      },
      {
        NOME: "Condado",
        CODIGO_IBGE: 2604601,
      },
      {
        NOME: "Correntes",
        CODIGO_IBGE: 2604700,
      },
      {
        NOME: "Cortês",
        CODIGO_IBGE: 2604809,
      },
      {
        NOME: "Cumaru",
        CODIGO_IBGE: 2604908,
      },
      {
        NOME: "Cupira",
        CODIGO_IBGE: 2605004,
      },
      {
        NOME: "Custódia",
        CODIGO_IBGE: 2605103,
      },
      {
        NOME: "Dormentes",
        CODIGO_IBGE: 2605152,
      },
      {
        NOME: "Escada",
        CODIGO_IBGE: 2605202,
      },
      {
        NOME: "Exu",
        CODIGO_IBGE: 2605301,
      },
      {
        NOME: "Feira Nova",
        CODIGO_IBGE: 2605400,
      },
      {
        NOME: "Fernando de Noronha",
        CODIGO_IBGE: 2605459,
      },
      {
        NOME: "Ferreiros",
        CODIGO_IBGE: 2605509,
      },
      {
        NOME: "Flores",
        CODIGO_IBGE: 2605608,
      },
      {
        NOME: "Floresta",
        CODIGO_IBGE: 2605707,
      },
      {
        NOME: "Frei Miguelinho",
        CODIGO_IBGE: 2605806,
      },
      {
        NOME: "Gameleira",
        CODIGO_IBGE: 2605905,
      },
      {
        NOME: "Garanhuns",
        CODIGO_IBGE: 2606002,
      },
      {
        NOME: "Glória do Goitá",
        CODIGO_IBGE: 2606101,
      },
      {
        NOME: "Goiana",
        CODIGO_IBGE: 2606200,
      },
      {
        NOME: "Granito",
        CODIGO_IBGE: 2606309,
      },
      {
        NOME: "Gravatá",
        CODIGO_IBGE: 2606408,
      },
      {
        NOME: "Iati",
        CODIGO_IBGE: 2606507,
      },
      {
        NOME: "Ibimirim",
        CODIGO_IBGE: 2606606,
      },
      {
        NOME: "Ibirajuba",
        CODIGO_IBGE: 2606705,
      },
      {
        NOME: "Igarassu",
        CODIGO_IBGE: 2606804,
      },
      {
        NOME: "Iguaracy",
        CODIGO_IBGE: 2606903,
      },
      {
        NOME: "Inajá",
        CODIGO_IBGE: 2607000,
      },
      {
        NOME: "Ingazeira",
        CODIGO_IBGE: 2607109,
      },
      {
        NOME: "Ipojuca",
        CODIGO_IBGE: 2607208,
      },
      {
        NOME: "Ipubi",
        CODIGO_IBGE: 2607307,
      },
      {
        NOME: "Itacuruba",
        CODIGO_IBGE: 2607406,
      },
      {
        NOME: "Itaíba",
        CODIGO_IBGE: 2607505,
      },
      {
        NOME: "Ilha de Itamaracá",
        CODIGO_IBGE: 2607604,
      },
      {
        NOME: "Itambé",
        CODIGO_IBGE: 2607653,
      },
      {
        NOME: "Itapetim",
        CODIGO_IBGE: 2607703,
      },
      {
        NOME: "Itapissuma",
        CODIGO_IBGE: 2607752,
      },
      {
        NOME: "Itaquitinga",
        CODIGO_IBGE: 2607802,
      },
      {
        NOME: "Jaboatão dos Guararapes",
        CODIGO_IBGE: 2607901,
      },
      {
        NOME: "Jaqueira",
        CODIGO_IBGE: 2607950,
      },
      {
        NOME: "Jataúba",
        CODIGO_IBGE: 2608008,
      },
      {
        NOME: "Jatobá",
        CODIGO_IBGE: 2608057,
      },
      {
        NOME: "João Alfredo",
        CODIGO_IBGE: 2608107,
      },
      {
        NOME: "Joaquim Nabuco",
        CODIGO_IBGE: 2608206,
      },
      {
        NOME: "Jucati",
        CODIGO_IBGE: 2608255,
      },
      {
        NOME: "Jupi",
        CODIGO_IBGE: 2608305,
      },
      {
        NOME: "Jurema",
        CODIGO_IBGE: 2608404,
      },
      {
        NOME: "Lagoa do Carro",
        CODIGO_IBGE: 2608453,
      },
      {
        NOME: "Lagoa de Itaenga",
        CODIGO_IBGE: 2608503,
      },
      {
        NOME: "Lagoa do Ouro",
        CODIGO_IBGE: 2608602,
      },
      {
        NOME: "Lagoa dos Gatos",
        CODIGO_IBGE: 2608701,
      },
      {
        NOME: "Lagoa Grande",
        CODIGO_IBGE: 2608750,
      },
      {
        NOME: "Lajedo",
        CODIGO_IBGE: 2608800,
      },
      {
        NOME: "Limoeiro",
        CODIGO_IBGE: 2608909,
      },
      {
        NOME: "Macaparana",
        CODIGO_IBGE: 2609006,
      },
      {
        NOME: "Machados",
        CODIGO_IBGE: 2609105,
      },
      {
        NOME: "Manari",
        CODIGO_IBGE: 2609154,
      },
      {
        NOME: "Maraial",
        CODIGO_IBGE: 2609204,
      },
      {
        NOME: "Mirandiba",
        CODIGO_IBGE: 2609303,
      },
      {
        NOME: "Moreno",
        CODIGO_IBGE: 2609402,
      },
      {
        NOME: "Nazaré da Mata",
        CODIGO_IBGE: 2609501,
      },
      {
        NOME: "Olinda",
        CODIGO_IBGE: 2609600,
      },
      {
        NOME: "Orobó",
        CODIGO_IBGE: 2609709,
      },
      {
        NOME: "Orocó",
        CODIGO_IBGE: 2609808,
      },
      {
        NOME: "Ouricuri",
        CODIGO_IBGE: 2609907,
      },
      {
        NOME: "Palmares",
        CODIGO_IBGE: 2610004,
      },
      {
        NOME: "Palmeirina",
        CODIGO_IBGE: 2610103,
      },
      {
        NOME: "Panelas",
        CODIGO_IBGE: 2610202,
      },
      {
        NOME: "Paranatama",
        CODIGO_IBGE: 2610301,
      },
      {
        NOME: "Parnamirim",
        CODIGO_IBGE: 2610400,
      },
      {
        NOME: "Passira",
        CODIGO_IBGE: 2610509,
      },
      {
        NOME: "Paudalho",
        CODIGO_IBGE: 2610608,
      },
      {
        NOME: "Paulista",
        CODIGO_IBGE: 2610707,
      },
      {
        NOME: "Pedra",
        CODIGO_IBGE: 2610806,
      },
      {
        NOME: "Pesqueira",
        CODIGO_IBGE: 2610905,
      },
      {
        NOME: "Petrolândia",
        CODIGO_IBGE: 2611002,
      },
      {
        NOME: "Petrolina",
        CODIGO_IBGE: 2611101,
      },
      {
        NOME: "Poção",
        CODIGO_IBGE: 2611200,
      },
      {
        NOME: "Pombos",
        CODIGO_IBGE: 2611309,
      },
      {
        NOME: "Primavera",
        CODIGO_IBGE: 2611408,
      },
      {
        NOME: "Quipapá",
        CODIGO_IBGE: 2611507,
      },
      {
        NOME: "Quixaba",
        CODIGO_IBGE: 2611533,
      },
      {
        NOME: "Recife",
        CODIGO_IBGE: 2611606,
      },
      {
        NOME: "Riacho das Almas",
        CODIGO_IBGE: 2611705,
      },
      {
        NOME: "Ribeirão",
        CODIGO_IBGE: 2611804,
      },
      {
        NOME: "Rio Formoso",
        CODIGO_IBGE: 2611903,
      },
      {
        NOME: "Sairé",
        CODIGO_IBGE: 2612000,
      },
      {
        NOME: "Salgadinho",
        CODIGO_IBGE: 2612109,
      },
      {
        NOME: "Salgueiro",
        CODIGO_IBGE: 2612208,
      },
      {
        NOME: "Saloá",
        CODIGO_IBGE: 2612307,
      },
      {
        NOME: "Sanharó",
        CODIGO_IBGE: 2612406,
      },
      {
        NOME: "Santa Cruz",
        CODIGO_IBGE: 2612455,
      },
      {
        NOME: "Santa Cruz da Baixa Verde",
        CODIGO_IBGE: 2612471,
      },
      {
        NOME: "Santa Cruz do Capibaribe",
        CODIGO_IBGE: 2612505,
      },
      {
        NOME: "Santa Filomena",
        CODIGO_IBGE: 2612554,
      },
      {
        NOME: "Santa Maria da Boa Vista",
        CODIGO_IBGE: 2612604,
      },
      {
        NOME: "Santa Maria do Cambucá",
        CODIGO_IBGE: 2612703,
      },
      {
        NOME: "Santa Terezinha",
        CODIGO_IBGE: 2612802,
      },
      {
        NOME: "São Benedito do Sul",
        CODIGO_IBGE: 2612901,
      },
      {
        NOME: "São Bento do Una",
        CODIGO_IBGE: 2613008,
      },
      {
        NOME: "São Caitano",
        CODIGO_IBGE: 2613107,
      },
      {
        NOME: "São João",
        CODIGO_IBGE: 2613206,
      },
      {
        NOME: "São Joaquim do Monte",
        CODIGO_IBGE: 2613305,
      },
      {
        NOME: "São José da Coroa Grande",
        CODIGO_IBGE: 2613404,
      },
      {
        NOME: "São José do Belmonte",
        CODIGO_IBGE: 2613503,
      },
      {
        NOME: "São José do Egito",
        CODIGO_IBGE: 2613602,
      },
      {
        NOME: "São Lourenço da Mata",
        CODIGO_IBGE: 2613701,
      },
      {
        NOME: "São Vicente Férrer",
        CODIGO_IBGE: 2613800,
      },
      {
        NOME: "Serra Talhada",
        CODIGO_IBGE: 2613909,
      },
      {
        NOME: "Serrita",
        CODIGO_IBGE: 2614006,
      },
      {
        NOME: "Sertânia",
        CODIGO_IBGE: 2614105,
      },
      {
        NOME: "Sirinhaém",
        CODIGO_IBGE: 2614204,
      },
      {
        NOME: "Moreilândia",
        CODIGO_IBGE: 2614303,
      },
      {
        NOME: "Solidão",
        CODIGO_IBGE: 2614402,
      },
      {
        NOME: "Surubim",
        CODIGO_IBGE: 2614501,
      },
      {
        NOME: "Tabira",
        CODIGO_IBGE: 2614600,
      },
      {
        NOME: "Tacaimbó",
        CODIGO_IBGE: 2614709,
      },
      {
        NOME: "Tacaratu",
        CODIGO_IBGE: 2614808,
      },
      {
        NOME: "Tamandaré",
        CODIGO_IBGE: 2614857,
      },
      {
        NOME: "Taquaritinga do Norte",
        CODIGO_IBGE: 2615003,
      },
      {
        NOME: "Terezinha",
        CODIGO_IBGE: 2615102,
      },
      {
        NOME: "Terra Nova",
        CODIGO_IBGE: 2615201,
      },
      {
        NOME: "Timbaúba",
        CODIGO_IBGE: 2615300,
      },
      {
        NOME: "Toritama",
        CODIGO_IBGE: 2615409,
      },
      {
        NOME: "Tracunhaém",
        CODIGO_IBGE: 2615508,
      },
      {
        NOME: "Trindade",
        CODIGO_IBGE: 2615607,
      },
      {
        NOME: "Triunfo",
        CODIGO_IBGE: 2615706,
      },
      {
        NOME: "Tupanatinga",
        CODIGO_IBGE: 2615805,
      },
      {
        NOME: "Tuparetama",
        CODIGO_IBGE: 2615904,
      },
      {
        NOME: "Venturosa",
        CODIGO_IBGE: 2616001,
      },
      {
        NOME: "Verdejante",
        CODIGO_IBGE: 2616100,
      },
      {
        NOME: "Vertente do Lério",
        CODIGO_IBGE: 2616183,
      },
      {
        NOME: "Vertentes",
        CODIGO_IBGE: 2616209,
      },
      {
        NOME: "Vicência",
        CODIGO_IBGE: 2616308,
      },
      {
        NOME: "Vitória de Santo Antão",
        CODIGO_IBGE: 2616407,
      },
      {
        NOME: "Xexéu",
        CODIGO_IBGE: 2616506,
      },
    ],
    27: [
      {
        NOME: "Água Branca",
        CODIGO_IBGE: 2700102,
      },
      {
        NOME: "Anadia",
        CODIGO_IBGE: 2700201,
      },
      {
        NOME: "Arapiraca",
        CODIGO_IBGE: 2700300,
      },
      {
        NOME: "Atalaia",
        CODIGO_IBGE: 2700409,
      },
      {
        NOME: "Barra de Santo Antônio",
        CODIGO_IBGE: 2700508,
      },
      {
        NOME: "Barra de São Miguel",
        CODIGO_IBGE: 2700607,
      },
      {
        NOME: "Batalha",
        CODIGO_IBGE: 2700706,
      },
      {
        NOME: "Belém",
        CODIGO_IBGE: 2700805,
      },
      {
        NOME: "Belo Monte",
        CODIGO_IBGE: 2700904,
      },
      {
        NOME: "Boca da Mata",
        CODIGO_IBGE: 2701001,
      },
      {
        NOME: "Branquinha",
        CODIGO_IBGE: 2701100,
      },
      {
        NOME: "Cacimbinhas",
        CODIGO_IBGE: 2701209,
      },
      {
        NOME: "Cajueiro",
        CODIGO_IBGE: 2701308,
      },
      {
        NOME: "Campestre",
        CODIGO_IBGE: 2701357,
      },
      {
        NOME: "Campo Alegre",
        CODIGO_IBGE: 2701407,
      },
      {
        NOME: "Campo Grande",
        CODIGO_IBGE: 2701506,
      },
      {
        NOME: "Canapi",
        CODIGO_IBGE: 2701605,
      },
      {
        NOME: "Capela",
        CODIGO_IBGE: 2701704,
      },
      {
        NOME: "Carneiros",
        CODIGO_IBGE: 2701803,
      },
      {
        NOME: "Chã Preta",
        CODIGO_IBGE: 2701902,
      },
      {
        NOME: "Coité do Nóia",
        CODIGO_IBGE: 2702009,
      },
      {
        NOME: "Colônia Leopoldina",
        CODIGO_IBGE: 2702108,
      },
      {
        NOME: "Coqueiro Seco",
        CODIGO_IBGE: 2702207,
      },
      {
        NOME: "Coruripe",
        CODIGO_IBGE: 2702306,
      },
      {
        NOME: "Craíbas",
        CODIGO_IBGE: 2702355,
      },
      {
        NOME: "Delmiro Gouveia",
        CODIGO_IBGE: 2702405,
      },
      {
        NOME: "Dois Riachos",
        CODIGO_IBGE: 2702504,
      },
      {
        NOME: "Estrela de Alagoas",
        CODIGO_IBGE: 2702553,
      },
      {
        NOME: "Feira Grande",
        CODIGO_IBGE: 2702603,
      },
      {
        NOME: "Feliz Deserto",
        CODIGO_IBGE: 2702702,
      },
      {
        NOME: "Flexeiras",
        CODIGO_IBGE: 2702801,
      },
      {
        NOME: "Girau do Ponciano",
        CODIGO_IBGE: 2702900,
      },
      {
        NOME: "Ibateguara",
        CODIGO_IBGE: 2703007,
      },
      {
        NOME: "Igaci",
        CODIGO_IBGE: 2703106,
      },
      {
        NOME: "Igreja Nova",
        CODIGO_IBGE: 2703205,
      },
      {
        NOME: "Inhapi",
        CODIGO_IBGE: 2703304,
      },
      {
        NOME: "Jacaré dos Homens",
        CODIGO_IBGE: 2703403,
      },
      {
        NOME: "Jacuípe",
        CODIGO_IBGE: 2703502,
      },
      {
        NOME: "Japaratinga",
        CODIGO_IBGE: 2703601,
      },
      {
        NOME: "Jaramataia",
        CODIGO_IBGE: 2703700,
      },
      {
        NOME: "Jequiá da Praia",
        CODIGO_IBGE: 2703759,
      },
      {
        NOME: "Joaquim Gomes",
        CODIGO_IBGE: 2703809,
      },
      {
        NOME: "Jundiá",
        CODIGO_IBGE: 2703908,
      },
      {
        NOME: "Junqueiro",
        CODIGO_IBGE: 2704005,
      },
      {
        NOME: "Lagoa da Canoa",
        CODIGO_IBGE: 2704104,
      },
      {
        NOME: "Limoeiro de Anadia",
        CODIGO_IBGE: 2704203,
      },
      {
        NOME: "Maceió",
        CODIGO_IBGE: 2704302,
      },
      {
        NOME: "Major Isidoro",
        CODIGO_IBGE: 2704401,
      },
      {
        NOME: "Maragogi",
        CODIGO_IBGE: 2704500,
      },
      {
        NOME: "Maravilha",
        CODIGO_IBGE: 2704609,
      },
      {
        NOME: "Marechal Deodoro",
        CODIGO_IBGE: 2704708,
      },
      {
        NOME: "Maribondo",
        CODIGO_IBGE: 2704807,
      },
      {
        NOME: "Mar Vermelho",
        CODIGO_IBGE: 2704906,
      },
      {
        NOME: "Mata Grande",
        CODIGO_IBGE: 2705002,
      },
      {
        NOME: "Matriz de Camaragibe",
        CODIGO_IBGE: 2705101,
      },
      {
        NOME: "Messias",
        CODIGO_IBGE: 2705200,
      },
      {
        NOME: "Minador do Negrão",
        CODIGO_IBGE: 2705309,
      },
      {
        NOME: "Monteirópolis",
        CODIGO_IBGE: 2705408,
      },
      {
        NOME: "Murici",
        CODIGO_IBGE: 2705507,
      },
      {
        NOME: "Novo Lino",
        CODIGO_IBGE: 2705606,
      },
      {
        NOME: "Olho d'Água das Flores",
        CODIGO_IBGE: 2705705,
      },
      {
        NOME: "Olho d'Água do Casado",
        CODIGO_IBGE: 2705804,
      },
      {
        NOME: "Olho d'Água Grande",
        CODIGO_IBGE: 2705903,
      },
      {
        NOME: "Olivença",
        CODIGO_IBGE: 2706000,
      },
      {
        NOME: "Ouro Branco",
        CODIGO_IBGE: 2706109,
      },
      {
        NOME: "Palestina",
        CODIGO_IBGE: 2706208,
      },
      {
        NOME: "Palmeira dos Índios",
        CODIGO_IBGE: 2706307,
      },
      {
        NOME: "Pão de Açúcar",
        CODIGO_IBGE: 2706406,
      },
      {
        NOME: "Pariconha",
        CODIGO_IBGE: 2706422,
      },
      {
        NOME: "Paripueira",
        CODIGO_IBGE: 2706448,
      },
      {
        NOME: "Passo de Camaragibe",
        CODIGO_IBGE: 2706505,
      },
      {
        NOME: "Paulo Jacinto",
        CODIGO_IBGE: 2706604,
      },
      {
        NOME: "Penedo",
        CODIGO_IBGE: 2706703,
      },
      {
        NOME: "Piaçabuçu",
        CODIGO_IBGE: 2706802,
      },
      {
        NOME: "Pilar",
        CODIGO_IBGE: 2706901,
      },
      {
        NOME: "Pindoba",
        CODIGO_IBGE: 2707008,
      },
      {
        NOME: "Piranhas",
        CODIGO_IBGE: 2707107,
      },
      {
        NOME: "Poço das Trincheiras",
        CODIGO_IBGE: 2707206,
      },
      {
        NOME: "Porto Calvo",
        CODIGO_IBGE: 2707305,
      },
      {
        NOME: "Porto de Pedras",
        CODIGO_IBGE: 2707404,
      },
      {
        NOME: "Porto Real do Colégio",
        CODIGO_IBGE: 2707503,
      },
      {
        NOME: "Quebrangulo",
        CODIGO_IBGE: 2707602,
      },
      {
        NOME: "Rio Largo",
        CODIGO_IBGE: 2707701,
      },
      {
        NOME: "Roteiro",
        CODIGO_IBGE: 2707800,
      },
      {
        NOME: "Santa Luzia do Norte",
        CODIGO_IBGE: 2707909,
      },
      {
        NOME: "Santana do Ipanema",
        CODIGO_IBGE: 2708006,
      },
      {
        NOME: "Santana do Mundaú",
        CODIGO_IBGE: 2708105,
      },
      {
        NOME: "São Brás",
        CODIGO_IBGE: 2708204,
      },
      {
        NOME: "São José da Laje",
        CODIGO_IBGE: 2708303,
      },
      {
        NOME: "São José da Tapera",
        CODIGO_IBGE: 2708402,
      },
      {
        NOME: "São Luís do Quitunde",
        CODIGO_IBGE: 2708501,
      },
      {
        NOME: "São Miguel dos Campos",
        CODIGO_IBGE: 2708600,
      },
      {
        NOME: "São Miguel dos Milagres",
        CODIGO_IBGE: 2708709,
      },
      {
        NOME: "São Sebastião",
        CODIGO_IBGE: 2708808,
      },
      {
        NOME: "Satuba",
        CODIGO_IBGE: 2708907,
      },
      {
        NOME: "Senador Rui Palmeira",
        CODIGO_IBGE: 2708956,
      },
      {
        NOME: "Tanque d'Arca",
        CODIGO_IBGE: 2709004,
      },
      {
        NOME: "Taquarana",
        CODIGO_IBGE: 2709103,
      },
      {
        NOME: "Teotônio Vilela",
        CODIGO_IBGE: 2709152,
      },
      {
        NOME: "Traipu",
        CODIGO_IBGE: 2709202,
      },
      {
        NOME: "União dos Palmares",
        CODIGO_IBGE: 2709301,
      },
      {
        NOME: "Viçosa",
        CODIGO_IBGE: 2709400,
      },
    ],
    28: [
      {
        NOME: "Amparo de São Francisco",
        CODIGO_IBGE: 2800100,
      },
      {
        NOME: "Aquidabã",
        CODIGO_IBGE: 2800209,
      },
      {
        NOME: "Aracaju",
        CODIGO_IBGE: 2800308,
      },
      {
        NOME: "Arauá",
        CODIGO_IBGE: 2800407,
      },
      {
        NOME: "Areia Branca",
        CODIGO_IBGE: 2800506,
      },
      {
        NOME: "Barra dos Coqueiros",
        CODIGO_IBGE: 2800605,
      },
      {
        NOME: "Boquim",
        CODIGO_IBGE: 2800670,
      },
      {
        NOME: "Brejo Grande",
        CODIGO_IBGE: 2800704,
      },
      {
        NOME: "Campo do Brito",
        CODIGO_IBGE: 2801009,
      },
      {
        NOME: "Canhoba",
        CODIGO_IBGE: 2801108,
      },
      {
        NOME: "Canindé de São Francisco",
        CODIGO_IBGE: 2801207,
      },
      {
        NOME: "Capela",
        CODIGO_IBGE: 2801306,
      },
      {
        NOME: "Carira",
        CODIGO_IBGE: 2801405,
      },
      {
        NOME: "Carmópolis",
        CODIGO_IBGE: 2801504,
      },
      {
        NOME: "Cedro de São João",
        CODIGO_IBGE: 2801603,
      },
      {
        NOME: "Cristinápolis",
        CODIGO_IBGE: 2801702,
      },
      {
        NOME: "Cumbe",
        CODIGO_IBGE: 2801900,
      },
      {
        NOME: "Divina Pastora",
        CODIGO_IBGE: 2802007,
      },
      {
        NOME: "Estância",
        CODIGO_IBGE: 2802106,
      },
      {
        NOME: "Feira Nova",
        CODIGO_IBGE: 2802205,
      },
      {
        NOME: "Frei Paulo",
        CODIGO_IBGE: 2802304,
      },
      {
        NOME: "Gararu",
        CODIGO_IBGE: 2802403,
      },
      {
        NOME: "General Maynard",
        CODIGO_IBGE: 2802502,
      },
      {
        NOME: "Gracho Cardoso",
        CODIGO_IBGE: 2802601,
      },
      {
        NOME: "Ilha das Flores",
        CODIGO_IBGE: 2802700,
      },
      {
        NOME: "Indiaroba",
        CODIGO_IBGE: 2802809,
      },
      {
        NOME: "Itabaiana",
        CODIGO_IBGE: 2802908,
      },
      {
        NOME: "Itabaianinha",
        CODIGO_IBGE: 2803005,
      },
      {
        NOME: "Itabi",
        CODIGO_IBGE: 2803104,
      },
      {
        NOME: "Itaporanga d'Ajuda",
        CODIGO_IBGE: 2803203,
      },
      {
        NOME: "Japaratuba",
        CODIGO_IBGE: 2803302,
      },
      {
        NOME: "Japoatã",
        CODIGO_IBGE: 2803401,
      },
      {
        NOME: "Lagarto",
        CODIGO_IBGE: 2803500,
      },
      {
        NOME: "Laranjeiras",
        CODIGO_IBGE: 2803609,
      },
      {
        NOME: "Macambira",
        CODIGO_IBGE: 2803708,
      },
      {
        NOME: "Malhada dos Bois",
        CODIGO_IBGE: 2803807,
      },
      {
        NOME: "Malhador",
        CODIGO_IBGE: 2803906,
      },
      {
        NOME: "Maruim",
        CODIGO_IBGE: 2804003,
      },
      {
        NOME: "Moita Bonita",
        CODIGO_IBGE: 2804102,
      },
      {
        NOME: "Monte Alegre de Sergipe",
        CODIGO_IBGE: 2804201,
      },
      {
        NOME: "Muribeca",
        CODIGO_IBGE: 2804300,
      },
      {
        NOME: "Neópolis",
        CODIGO_IBGE: 2804409,
      },
      {
        NOME: "Nossa Senhora Aparecida",
        CODIGO_IBGE: 2804458,
      },
      {
        NOME: "Nossa Senhora da Glória",
        CODIGO_IBGE: 2804508,
      },
      {
        NOME: "Nossa Senhora das Dores",
        CODIGO_IBGE: 2804607,
      },
      {
        NOME: "Nossa Senhora de Lourdes",
        CODIGO_IBGE: 2804706,
      },
      {
        NOME: "Nossa Senhora do Socorro",
        CODIGO_IBGE: 2804805,
      },
      {
        NOME: "Pacatuba",
        CODIGO_IBGE: 2804904,
      },
      {
        NOME: "Pedra Mole",
        CODIGO_IBGE: 2805000,
      },
      {
        NOME: "Pedrinhas",
        CODIGO_IBGE: 2805109,
      },
      {
        NOME: "Pinhão",
        CODIGO_IBGE: 2805208,
      },
      {
        NOME: "Pirambu",
        CODIGO_IBGE: 2805307,
      },
      {
        NOME: "Poço Redondo",
        CODIGO_IBGE: 2805406,
      },
      {
        NOME: "Poço Verde",
        CODIGO_IBGE: 2805505,
      },
      {
        NOME: "Porto da Folha",
        CODIGO_IBGE: 2805604,
      },
      {
        NOME: "Propriá",
        CODIGO_IBGE: 2805703,
      },
      {
        NOME: "Riachão do Dantas",
        CODIGO_IBGE: 2805802,
      },
      {
        NOME: "Riachuelo",
        CODIGO_IBGE: 2805901,
      },
      {
        NOME: "Ribeirópolis",
        CODIGO_IBGE: 2806008,
      },
      {
        NOME: "Rosário do Catete",
        CODIGO_IBGE: 2806107,
      },
      {
        NOME: "Salgado",
        CODIGO_IBGE: 2806206,
      },
      {
        NOME: "Santa Luzia do Itanhy",
        CODIGO_IBGE: 2806305,
      },
      {
        NOME: "Santana do São Francisco",
        CODIGO_IBGE: 2806404,
      },
      {
        NOME: "Santa Rosa de Lima",
        CODIGO_IBGE: 2806503,
      },
      {
        NOME: "Santo Amaro das Brotas",
        CODIGO_IBGE: 2806602,
      },
      {
        NOME: "São Cristóvão",
        CODIGO_IBGE: 2806701,
      },
      {
        NOME: "São Domingos",
        CODIGO_IBGE: 2806800,
      },
      {
        NOME: "São Francisco",
        CODIGO_IBGE: 2806909,
      },
      {
        NOME: "São Miguel do Aleixo",
        CODIGO_IBGE: 2807006,
      },
      {
        NOME: "Simão Dias",
        CODIGO_IBGE: 2807105,
      },
      {
        NOME: "Siriri",
        CODIGO_IBGE: 2807204,
      },
      {
        NOME: "Telha",
        CODIGO_IBGE: 2807303,
      },
      {
        NOME: "Tobias Barreto",
        CODIGO_IBGE: 2807402,
      },
      {
        NOME: "Tomar do Geru",
        CODIGO_IBGE: 2807501,
      },
      {
        NOME: "Umbaúba",
        CODIGO_IBGE: 2807600,
      },
    ],
    29: [
      {
        NOME: "Abaíra",
        CODIGO_IBGE: 2900108,
      },
      {
        NOME: "Abaré",
        CODIGO_IBGE: 2900207,
      },
      {
        NOME: "Acajutiba",
        CODIGO_IBGE: 2900306,
      },
      {
        NOME: "Adustina",
        CODIGO_IBGE: 2900355,
      },
      {
        NOME: "Água Fria",
        CODIGO_IBGE: 2900405,
      },
      {
        NOME: "Érico Cardoso",
        CODIGO_IBGE: 2900504,
      },
      {
        NOME: "Aiquara",
        CODIGO_IBGE: 2900603,
      },
      {
        NOME: "Alagoinhas",
        CODIGO_IBGE: 2900702,
      },
      {
        NOME: "Alcobaça",
        CODIGO_IBGE: 2900801,
      },
      {
        NOME: "Almadina",
        CODIGO_IBGE: 2900900,
      },
      {
        NOME: "Amargosa",
        CODIGO_IBGE: 2901007,
      },
      {
        NOME: "Amélia Rodrigues",
        CODIGO_IBGE: 2901106,
      },
      {
        NOME: "América Dourada",
        CODIGO_IBGE: 2901155,
      },
      {
        NOME: "Anagé",
        CODIGO_IBGE: 2901205,
      },
      {
        NOME: "Andaraí",
        CODIGO_IBGE: 2901304,
      },
      {
        NOME: "Andorinha",
        CODIGO_IBGE: 2901353,
      },
      {
        NOME: "Angical",
        CODIGO_IBGE: 2901403,
      },
      {
        NOME: "Anguera",
        CODIGO_IBGE: 2901502,
      },
      {
        NOME: "Antas",
        CODIGO_IBGE: 2901601,
      },
      {
        NOME: "Antônio Cardoso",
        CODIGO_IBGE: 2901700,
      },
      {
        NOME: "Antônio Gonçalves",
        CODIGO_IBGE: 2901809,
      },
      {
        NOME: "Aporá",
        CODIGO_IBGE: 2901908,
      },
      {
        NOME: "Apuarema",
        CODIGO_IBGE: 2901957,
      },
      {
        NOME: "Aracatu",
        CODIGO_IBGE: 2902005,
      },
      {
        NOME: "Araçás",
        CODIGO_IBGE: 2902054,
      },
      {
        NOME: "Araci",
        CODIGO_IBGE: 2902104,
      },
      {
        NOME: "Aramari",
        CODIGO_IBGE: 2902203,
      },
      {
        NOME: "Arataca",
        CODIGO_IBGE: 2902252,
      },
      {
        NOME: "Aratuípe",
        CODIGO_IBGE: 2902302,
      },
      {
        NOME: "Aurelino Leal",
        CODIGO_IBGE: 2902401,
      },
      {
        NOME: "Baianópolis",
        CODIGO_IBGE: 2902500,
      },
      {
        NOME: "Baixa Grande",
        CODIGO_IBGE: 2902609,
      },
      {
        NOME: "Banzaê",
        CODIGO_IBGE: 2902658,
      },
      {
        NOME: "Barra",
        CODIGO_IBGE: 2902708,
      },
      {
        NOME: "Barra da Estiva",
        CODIGO_IBGE: 2902807,
      },
      {
        NOME: "Barra do Choça",
        CODIGO_IBGE: 2902906,
      },
      {
        NOME: "Barra do Mendes",
        CODIGO_IBGE: 2903003,
      },
      {
        NOME: "Barra do Rocha",
        CODIGO_IBGE: 2903102,
      },
      {
        NOME: "Barreiras",
        CODIGO_IBGE: 2903201,
      },
      {
        NOME: "Barro Alto",
        CODIGO_IBGE: 2903235,
      },
      {
        NOME: "Barrocas",
        CODIGO_IBGE: 2903276,
      },
      {
        NOME: "Barro Preto",
        CODIGO_IBGE: 2903300,
      },
      {
        NOME: "Belmonte",
        CODIGO_IBGE: 2903409,
      },
      {
        NOME: "Belo Campo",
        CODIGO_IBGE: 2903508,
      },
      {
        NOME: "Biritinga",
        CODIGO_IBGE: 2903607,
      },
      {
        NOME: "Boa Nova",
        CODIGO_IBGE: 2903706,
      },
      {
        NOME: "Boa Vista do Tupim",
        CODIGO_IBGE: 2903805,
      },
      {
        NOME: "Bom Jesus da Lapa",
        CODIGO_IBGE: 2903904,
      },
      {
        NOME: "Bom Jesus da Serra",
        CODIGO_IBGE: 2903953,
      },
      {
        NOME: "Boninal",
        CODIGO_IBGE: 2904001,
      },
      {
        NOME: "Bonito",
        CODIGO_IBGE: 2904050,
      },
      {
        NOME: "Boquira",
        CODIGO_IBGE: 2904100,
      },
      {
        NOME: "Botuporã",
        CODIGO_IBGE: 2904209,
      },
      {
        NOME: "Brejões",
        CODIGO_IBGE: 2904308,
      },
      {
        NOME: "Brejolândia",
        CODIGO_IBGE: 2904407,
      },
      {
        NOME: "Brotas de Macaúbas",
        CODIGO_IBGE: 2904506,
      },
      {
        NOME: "Brumado",
        CODIGO_IBGE: 2904605,
      },
      {
        NOME: "Buerarema",
        CODIGO_IBGE: 2904704,
      },
      {
        NOME: "Buritirama",
        CODIGO_IBGE: 2904753,
      },
      {
        NOME: "Caatiba",
        CODIGO_IBGE: 2904803,
      },
      {
        NOME: "Cabaceiras do Paraguaçu",
        CODIGO_IBGE: 2904852,
      },
      {
        NOME: "Cachoeira",
        CODIGO_IBGE: 2904902,
      },
      {
        NOME: "Caculé",
        CODIGO_IBGE: 2905008,
      },
      {
        NOME: "Caém",
        CODIGO_IBGE: 2905107,
      },
      {
        NOME: "Caetanos",
        CODIGO_IBGE: 2905156,
      },
      {
        NOME: "Caetité",
        CODIGO_IBGE: 2905206,
      },
      {
        NOME: "Cafarnaum",
        CODIGO_IBGE: 2905305,
      },
      {
        NOME: "Cairu",
        CODIGO_IBGE: 2905404,
      },
      {
        NOME: "Caldeirão Grande",
        CODIGO_IBGE: 2905503,
      },
      {
        NOME: "Camacan",
        CODIGO_IBGE: 2905602,
      },
      {
        NOME: "Camaçari",
        CODIGO_IBGE: 2905701,
      },
      {
        NOME: "Camamu",
        CODIGO_IBGE: 2905800,
      },
      {
        NOME: "Campo Alegre de Lourdes",
        CODIGO_IBGE: 2905909,
      },
      {
        NOME: "Campo Formoso",
        CODIGO_IBGE: 2906006,
      },
      {
        NOME: "Canápolis",
        CODIGO_IBGE: 2906105,
      },
      {
        NOME: "Canarana",
        CODIGO_IBGE: 2906204,
      },
      {
        NOME: "Canavieiras",
        CODIGO_IBGE: 2906303,
      },
      {
        NOME: "Candeal",
        CODIGO_IBGE: 2906402,
      },
      {
        NOME: "Candeias",
        CODIGO_IBGE: 2906501,
      },
      {
        NOME: "Candiba",
        CODIGO_IBGE: 2906600,
      },
      {
        NOME: "Cândido Sales",
        CODIGO_IBGE: 2906709,
      },
      {
        NOME: "Cansanção",
        CODIGO_IBGE: 2906808,
      },
      {
        NOME: "Canudos",
        CODIGO_IBGE: 2906824,
      },
      {
        NOME: "Capela do Alto Alegre",
        CODIGO_IBGE: 2906857,
      },
      {
        NOME: "Capim Grosso",
        CODIGO_IBGE: 2906873,
      },
      {
        NOME: "Caraíbas",
        CODIGO_IBGE: 2906899,
      },
      {
        NOME: "Caravelas",
        CODIGO_IBGE: 2906907,
      },
      {
        NOME: "Cardeal da Silva",
        CODIGO_IBGE: 2907004,
      },
      {
        NOME: "Carinhanha",
        CODIGO_IBGE: 2907103,
      },
      {
        NOME: "Casa Nova",
        CODIGO_IBGE: 2907202,
      },
      {
        NOME: "Castro Alves",
        CODIGO_IBGE: 2907301,
      },
      {
        NOME: "Catolândia",
        CODIGO_IBGE: 2907400,
      },
      {
        NOME: "Catu",
        CODIGO_IBGE: 2907509,
      },
      {
        NOME: "Caturama",
        CODIGO_IBGE: 2907558,
      },
      {
        NOME: "Central",
        CODIGO_IBGE: 2907608,
      },
      {
        NOME: "Chorrochó",
        CODIGO_IBGE: 2907707,
      },
      {
        NOME: "Cícero Dantas",
        CODIGO_IBGE: 2907806,
      },
      {
        NOME: "Cipó",
        CODIGO_IBGE: 2907905,
      },
      {
        NOME: "Coaraci",
        CODIGO_IBGE: 2908002,
      },
      {
        NOME: "Cocos",
        CODIGO_IBGE: 2908101,
      },
      {
        NOME: "Conceição da Feira",
        CODIGO_IBGE: 2908200,
      },
      {
        NOME: "Conceição do Almeida",
        CODIGO_IBGE: 2908309,
      },
      {
        NOME: "Conceição do Coité",
        CODIGO_IBGE: 2908408,
      },
      {
        NOME: "Conceição do Jacuípe",
        CODIGO_IBGE: 2908507,
      },
      {
        NOME: "Conde",
        CODIGO_IBGE: 2908606,
      },
      {
        NOME: "Condeúba",
        CODIGO_IBGE: 2908705,
      },
      {
        NOME: "Contendas do Sincorá",
        CODIGO_IBGE: 2908804,
      },
      {
        NOME: "Coração de Maria",
        CODIGO_IBGE: 2908903,
      },
      {
        NOME: "Cordeiros",
        CODIGO_IBGE: 2909000,
      },
      {
        NOME: "Coribe",
        CODIGO_IBGE: 2909109,
      },
      {
        NOME: "Coronel João Sá",
        CODIGO_IBGE: 2909208,
      },
      {
        NOME: "Correntina",
        CODIGO_IBGE: 2909307,
      },
      {
        NOME: "Cotegipe",
        CODIGO_IBGE: 2909406,
      },
      {
        NOME: "Cravolândia",
        CODIGO_IBGE: 2909505,
      },
      {
        NOME: "Crisópolis",
        CODIGO_IBGE: 2909604,
      },
      {
        NOME: "Cristópolis",
        CODIGO_IBGE: 2909703,
      },
      {
        NOME: "Cruz das Almas",
        CODIGO_IBGE: 2909802,
      },
      {
        NOME: "Curaçá",
        CODIGO_IBGE: 2909901,
      },
      {
        NOME: "Dário Meira",
        CODIGO_IBGE: 2910008,
      },
      {
        NOME: "Dias d'Ávila",
        CODIGO_IBGE: 2910057,
      },
      {
        NOME: "Dom Basílio",
        CODIGO_IBGE: 2910107,
      },
      {
        NOME: "Dom Macedo Costa",
        CODIGO_IBGE: 2910206,
      },
      {
        NOME: "Elísio Medrado",
        CODIGO_IBGE: 2910305,
      },
      {
        NOME: "Encruzilhada",
        CODIGO_IBGE: 2910404,
      },
      {
        NOME: "Entre Rios",
        CODIGO_IBGE: 2910503,
      },
      {
        NOME: "Esplanada",
        CODIGO_IBGE: 2910602,
      },
      {
        NOME: "Euclides da Cunha",
        CODIGO_IBGE: 2910701,
      },
      {
        NOME: "Eunápolis",
        CODIGO_IBGE: 2910727,
      },
      {
        NOME: "Fátima",
        CODIGO_IBGE: 2910750,
      },
      {
        NOME: "Feira da Mata",
        CODIGO_IBGE: 2910776,
      },
      {
        NOME: "Feira de Santana",
        CODIGO_IBGE: 2910800,
      },
      {
        NOME: "Filadélfia",
        CODIGO_IBGE: 2910859,
      },
      {
        NOME: "Firmino Alves",
        CODIGO_IBGE: 2910909,
      },
      {
        NOME: "Floresta Azul",
        CODIGO_IBGE: 2911006,
      },
      {
        NOME: "Formosa do Rio Preto",
        CODIGO_IBGE: 2911105,
      },
      {
        NOME: "Gandu",
        CODIGO_IBGE: 2911204,
      },
      {
        NOME: "Gavião",
        CODIGO_IBGE: 2911253,
      },
      {
        NOME: "Gentio do Ouro",
        CODIGO_IBGE: 2911303,
      },
      {
        NOME: "Glória",
        CODIGO_IBGE: 2911402,
      },
      {
        NOME: "Gongogi",
        CODIGO_IBGE: 2911501,
      },
      {
        NOME: "Governador Mangabeira",
        CODIGO_IBGE: 2911600,
      },
      {
        NOME: "Guajeru",
        CODIGO_IBGE: 2911659,
      },
      {
        NOME: "Guanambi",
        CODIGO_IBGE: 2911709,
      },
      {
        NOME: "Guaratinga",
        CODIGO_IBGE: 2911808,
      },
      {
        NOME: "Heliópolis",
        CODIGO_IBGE: 2911857,
      },
      {
        NOME: "Iaçu",
        CODIGO_IBGE: 2911907,
      },
      {
        NOME: "Ibiassucê",
        CODIGO_IBGE: 2912004,
      },
      {
        NOME: "Ibicaraí",
        CODIGO_IBGE: 2912103,
      },
      {
        NOME: "Ibicoara",
        CODIGO_IBGE: 2912202,
      },
      {
        NOME: "Ibicuí",
        CODIGO_IBGE: 2912301,
      },
      {
        NOME: "Ibipeba",
        CODIGO_IBGE: 2912400,
      },
      {
        NOME: "Ibipitanga",
        CODIGO_IBGE: 2912509,
      },
      {
        NOME: "Ibiquera",
        CODIGO_IBGE: 2912608,
      },
      {
        NOME: "Ibirapitanga",
        CODIGO_IBGE: 2912707,
      },
      {
        NOME: "Ibirapuã",
        CODIGO_IBGE: 2912806,
      },
      {
        NOME: "Ibirataia",
        CODIGO_IBGE: 2912905,
      },
      {
        NOME: "Ibitiara",
        CODIGO_IBGE: 2913002,
      },
      {
        NOME: "Ibititá",
        CODIGO_IBGE: 2913101,
      },
      {
        NOME: "Ibotirama",
        CODIGO_IBGE: 2913200,
      },
      {
        NOME: "Ichu",
        CODIGO_IBGE: 2913309,
      },
      {
        NOME: "Igaporã",
        CODIGO_IBGE: 2913408,
      },
      {
        NOME: "Igrapiúna",
        CODIGO_IBGE: 2913457,
      },
      {
        NOME: "Iguaí",
        CODIGO_IBGE: 2913507,
      },
      {
        NOME: "Ilhéus",
        CODIGO_IBGE: 2913606,
      },
      {
        NOME: "Inhambupe",
        CODIGO_IBGE: 2913705,
      },
      {
        NOME: "Ipecaetá",
        CODIGO_IBGE: 2913804,
      },
      {
        NOME: "Ipiaú",
        CODIGO_IBGE: 2913903,
      },
      {
        NOME: "Ipirá",
        CODIGO_IBGE: 2914000,
      },
      {
        NOME: "Ipupiara",
        CODIGO_IBGE: 2914109,
      },
      {
        NOME: "Irajuba",
        CODIGO_IBGE: 2914208,
      },
      {
        NOME: "Iramaia",
        CODIGO_IBGE: 2914307,
      },
      {
        NOME: "Iraquara",
        CODIGO_IBGE: 2914406,
      },
      {
        NOME: "Irará",
        CODIGO_IBGE: 2914505,
      },
      {
        NOME: "Irecê",
        CODIGO_IBGE: 2914604,
      },
      {
        NOME: "Itabela",
        CODIGO_IBGE: 2914653,
      },
      {
        NOME: "Itaberaba",
        CODIGO_IBGE: 2914703,
      },
      {
        NOME: "Itabuna",
        CODIGO_IBGE: 2914802,
      },
      {
        NOME: "Itacaré",
        CODIGO_IBGE: 2914901,
      },
      {
        NOME: "Itaeté",
        CODIGO_IBGE: 2915007,
      },
      {
        NOME: "Itagi",
        CODIGO_IBGE: 2915106,
      },
      {
        NOME: "Itagibá",
        CODIGO_IBGE: 2915205,
      },
      {
        NOME: "Itagimirim",
        CODIGO_IBGE: 2915304,
      },
      {
        NOME: "Itaguaçu da Bahia",
        CODIGO_IBGE: 2915353,
      },
      {
        NOME: "Itaju do Colônia",
        CODIGO_IBGE: 2915403,
      },
      {
        NOME: "Itajuípe",
        CODIGO_IBGE: 2915502,
      },
      {
        NOME: "Itamaraju",
        CODIGO_IBGE: 2915601,
      },
      {
        NOME: "Itamari",
        CODIGO_IBGE: 2915700,
      },
      {
        NOME: "Itambé",
        CODIGO_IBGE: 2915809,
      },
      {
        NOME: "Itanagra",
        CODIGO_IBGE: 2915908,
      },
      {
        NOME: "Itanhém",
        CODIGO_IBGE: 2916005,
      },
      {
        NOME: "Itaparica",
        CODIGO_IBGE: 2916104,
      },
      {
        NOME: "Itapé",
        CODIGO_IBGE: 2916203,
      },
      {
        NOME: "Itapebi",
        CODIGO_IBGE: 2916302,
      },
      {
        NOME: "Itapetinga",
        CODIGO_IBGE: 2916401,
      },
      {
        NOME: "Itapicuru",
        CODIGO_IBGE: 2916500,
      },
      {
        NOME: "Itapitanga",
        CODIGO_IBGE: 2916609,
      },
      {
        NOME: "Itaquara",
        CODIGO_IBGE: 2916708,
      },
      {
        NOME: "Itarantim",
        CODIGO_IBGE: 2916807,
      },
      {
        NOME: "Itatim",
        CODIGO_IBGE: 2916856,
      },
      {
        NOME: "Itiruçu",
        CODIGO_IBGE: 2916906,
      },
      {
        NOME: "Itiúba",
        CODIGO_IBGE: 2917003,
      },
      {
        NOME: "Itororó",
        CODIGO_IBGE: 2917102,
      },
      {
        NOME: "Ituaçu",
        CODIGO_IBGE: 2917201,
      },
      {
        NOME: "Ituberá",
        CODIGO_IBGE: 2917300,
      },
      {
        NOME: "Iuiu",
        CODIGO_IBGE: 2917334,
      },
      {
        NOME: "Jaborandi",
        CODIGO_IBGE: 2917359,
      },
      {
        NOME: "Jacaraci",
        CODIGO_IBGE: 2917409,
      },
      {
        NOME: "Jacobina",
        CODIGO_IBGE: 2917508,
      },
      {
        NOME: "Jaguaquara",
        CODIGO_IBGE: 2917607,
      },
      {
        NOME: "Jaguarari",
        CODIGO_IBGE: 2917706,
      },
      {
        NOME: "Jaguaripe",
        CODIGO_IBGE: 2917805,
      },
      {
        NOME: "Jandaíra",
        CODIGO_IBGE: 2917904,
      },
      {
        NOME: "Jequié",
        CODIGO_IBGE: 2918001,
      },
      {
        NOME: "Jeremoabo",
        CODIGO_IBGE: 2918100,
      },
      {
        NOME: "Jiquiriçá",
        CODIGO_IBGE: 2918209,
      },
      {
        NOME: "Jitaúna",
        CODIGO_IBGE: 2918308,
      },
      {
        NOME: "João Dourado",
        CODIGO_IBGE: 2918357,
      },
      {
        NOME: "Juazeiro",
        CODIGO_IBGE: 2918407,
      },
      {
        NOME: "Jucuruçu",
        CODIGO_IBGE: 2918456,
      },
      {
        NOME: "Jussara",
        CODIGO_IBGE: 2918506,
      },
      {
        NOME: "Jussari",
        CODIGO_IBGE: 2918555,
      },
      {
        NOME: "Jussiape",
        CODIGO_IBGE: 2918605,
      },
      {
        NOME: "Lafaiete Coutinho",
        CODIGO_IBGE: 2918704,
      },
      {
        NOME: "Lagoa Real",
        CODIGO_IBGE: 2918753,
      },
      {
        NOME: "Laje",
        CODIGO_IBGE: 2918803,
      },
      {
        NOME: "Lajedão",
        CODIGO_IBGE: 2918902,
      },
      {
        NOME: "Lajedinho",
        CODIGO_IBGE: 2919009,
      },
      {
        NOME: "Lajedo do Tabocal",
        CODIGO_IBGE: 2919058,
      },
      {
        NOME: "Lamarão",
        CODIGO_IBGE: 2919108,
      },
      {
        NOME: "Lapão",
        CODIGO_IBGE: 2919157,
      },
      {
        NOME: "Lauro de Freitas",
        CODIGO_IBGE: 2919207,
      },
      {
        NOME: "Lençóis",
        CODIGO_IBGE: 2919306,
      },
      {
        NOME: "Licínio de Almeida",
        CODIGO_IBGE: 2919405,
      },
      {
        NOME: "Livramento de Nossa Senhora",
        CODIGO_IBGE: 2919504,
      },
      {
        NOME: "Luís Eduardo Magalhães",
        CODIGO_IBGE: 2919553,
      },
      {
        NOME: "Macajuba",
        CODIGO_IBGE: 2919603,
      },
      {
        NOME: "Macarani",
        CODIGO_IBGE: 2919702,
      },
      {
        NOME: "Macaúbas",
        CODIGO_IBGE: 2919801,
      },
      {
        NOME: "Macururé",
        CODIGO_IBGE: 2919900,
      },
      {
        NOME: "Madre de Deus",
        CODIGO_IBGE: 2919926,
      },
      {
        NOME: "Maetinga",
        CODIGO_IBGE: 2919959,
      },
      {
        NOME: "Maiquinique",
        CODIGO_IBGE: 2920007,
      },
      {
        NOME: "Mairi",
        CODIGO_IBGE: 2920106,
      },
      {
        NOME: "Malhada",
        CODIGO_IBGE: 2920205,
      },
      {
        NOME: "Malhada de Pedras",
        CODIGO_IBGE: 2920304,
      },
      {
        NOME: "Manoel Vitorino",
        CODIGO_IBGE: 2920403,
      },
      {
        NOME: "Mansidão",
        CODIGO_IBGE: 2920452,
      },
      {
        NOME: "Maracás",
        CODIGO_IBGE: 2920502,
      },
      {
        NOME: "Maragogipe",
        CODIGO_IBGE: 2920601,
      },
      {
        NOME: "Maraú",
        CODIGO_IBGE: 2920700,
      },
      {
        NOME: "Marcionílio Souza",
        CODIGO_IBGE: 2920809,
      },
      {
        NOME: "Mascote",
        CODIGO_IBGE: 2920908,
      },
      {
        NOME: "Mata de São João",
        CODIGO_IBGE: 2921005,
      },
      {
        NOME: "Matina",
        CODIGO_IBGE: 2921054,
      },
      {
        NOME: "Medeiros Neto",
        CODIGO_IBGE: 2921104,
      },
      {
        NOME: "Miguel Calmon",
        CODIGO_IBGE: 2921203,
      },
      {
        NOME: "Milagres",
        CODIGO_IBGE: 2921302,
      },
      {
        NOME: "Mirangaba",
        CODIGO_IBGE: 2921401,
      },
      {
        NOME: "Mirante",
        CODIGO_IBGE: 2921450,
      },
      {
        NOME: "Monte Santo",
        CODIGO_IBGE: 2921500,
      },
      {
        NOME: "Morpará",
        CODIGO_IBGE: 2921609,
      },
      {
        NOME: "Morro do Chapéu",
        CODIGO_IBGE: 2921708,
      },
      {
        NOME: "Mortugaba",
        CODIGO_IBGE: 2921807,
      },
      {
        NOME: "Mucugê",
        CODIGO_IBGE: 2921906,
      },
      {
        NOME: "Mucuri",
        CODIGO_IBGE: 2922003,
      },
      {
        NOME: "Mulungu do Morro",
        CODIGO_IBGE: 2922052,
      },
      {
        NOME: "Mundo Novo",
        CODIGO_IBGE: 2922102,
      },
      {
        NOME: "Muniz Ferreira",
        CODIGO_IBGE: 2922201,
      },
      {
        NOME: "Muquém do São Francisco",
        CODIGO_IBGE: 2922250,
      },
      {
        NOME: "Muritiba",
        CODIGO_IBGE: 2922300,
      },
      {
        NOME: "Mutuípe",
        CODIGO_IBGE: 2922409,
      },
      {
        NOME: "Nazaré",
        CODIGO_IBGE: 2922508,
      },
      {
        NOME: "Nilo Peçanha",
        CODIGO_IBGE: 2922607,
      },
      {
        NOME: "Nordestina",
        CODIGO_IBGE: 2922656,
      },
      {
        NOME: "Nova Canaã",
        CODIGO_IBGE: 2922706,
      },
      {
        NOME: "Nova Fátima",
        CODIGO_IBGE: 2922730,
      },
      {
        NOME: "Nova Ibiá",
        CODIGO_IBGE: 2922755,
      },
      {
        NOME: "Nova Itarana",
        CODIGO_IBGE: 2922805,
      },
      {
        NOME: "Nova Redenção",
        CODIGO_IBGE: 2922854,
      },
      {
        NOME: "Nova Soure",
        CODIGO_IBGE: 2922904,
      },
      {
        NOME: "Nova Viçosa",
        CODIGO_IBGE: 2923001,
      },
      {
        NOME: "Novo Horizonte",
        CODIGO_IBGE: 2923035,
      },
      {
        NOME: "Novo Triunfo",
        CODIGO_IBGE: 2923050,
      },
      {
        NOME: "Olindina",
        CODIGO_IBGE: 2923100,
      },
      {
        NOME: "Oliveira dos Brejinhos",
        CODIGO_IBGE: 2923209,
      },
      {
        NOME: "Ouriçangas",
        CODIGO_IBGE: 2923308,
      },
      {
        NOME: "Ourolândia",
        CODIGO_IBGE: 2923357,
      },
      {
        NOME: "Palmas de Monte Alto",
        CODIGO_IBGE: 2923407,
      },
      {
        NOME: "Palmeiras",
        CODIGO_IBGE: 2923506,
      },
      {
        NOME: "Paramirim",
        CODIGO_IBGE: 2923605,
      },
      {
        NOME: "Paratinga",
        CODIGO_IBGE: 2923704,
      },
      {
        NOME: "Paripiranga",
        CODIGO_IBGE: 2923803,
      },
      {
        NOME: "Pau Brasil",
        CODIGO_IBGE: 2923902,
      },
      {
        NOME: "Paulo Afonso",
        CODIGO_IBGE: 2924009,
      },
      {
        NOME: "Pé de Serra",
        CODIGO_IBGE: 2924058,
      },
      {
        NOME: "Pedrão",
        CODIGO_IBGE: 2924108,
      },
      {
        NOME: "Pedro Alexandre",
        CODIGO_IBGE: 2924207,
      },
      {
        NOME: "Piatã",
        CODIGO_IBGE: 2924306,
      },
      {
        NOME: "Pilão Arcado",
        CODIGO_IBGE: 2924405,
      },
      {
        NOME: "Pindaí",
        CODIGO_IBGE: 2924504,
      },
      {
        NOME: "Pindobaçu",
        CODIGO_IBGE: 2924603,
      },
      {
        NOME: "Pintadas",
        CODIGO_IBGE: 2924652,
      },
      {
        NOME: "Piraí do Norte",
        CODIGO_IBGE: 2924678,
      },
      {
        NOME: "Piripá",
        CODIGO_IBGE: 2924702,
      },
      {
        NOME: "Piritiba",
        CODIGO_IBGE: 2924801,
      },
      {
        NOME: "Planaltino",
        CODIGO_IBGE: 2924900,
      },
      {
        NOME: "Planalto",
        CODIGO_IBGE: 2925006,
      },
      {
        NOME: "Poções",
        CODIGO_IBGE: 2925105,
      },
      {
        NOME: "Pojuca",
        CODIGO_IBGE: 2925204,
      },
      {
        NOME: "Ponto Novo",
        CODIGO_IBGE: 2925253,
      },
      {
        NOME: "Porto Seguro",
        CODIGO_IBGE: 2925303,
      },
      {
        NOME: "Potiraguá",
        CODIGO_IBGE: 2925402,
      },
      {
        NOME: "Prado",
        CODIGO_IBGE: 2925501,
      },
      {
        NOME: "Presidente Dutra",
        CODIGO_IBGE: 2925600,
      },
      {
        NOME: "Presidente Jânio Quadros",
        CODIGO_IBGE: 2925709,
      },
      {
        NOME: "Presidente Tancredo Neves",
        CODIGO_IBGE: 2925758,
      },
      {
        NOME: "Queimadas",
        CODIGO_IBGE: 2925808,
      },
      {
        NOME: "Quijingue",
        CODIGO_IBGE: 2925907,
      },
      {
        NOME: "Quixabeira",
        CODIGO_IBGE: 2925931,
      },
      {
        NOME: "Rafael Jambeiro",
        CODIGO_IBGE: 2925956,
      },
      {
        NOME: "Remanso",
        CODIGO_IBGE: 2926004,
      },
      {
        NOME: "Retirolândia",
        CODIGO_IBGE: 2926103,
      },
      {
        NOME: "Riachão das Neves",
        CODIGO_IBGE: 2926202,
      },
      {
        NOME: "Riachão do Jacuípe",
        CODIGO_IBGE: 2926301,
      },
      {
        NOME: "Riacho de Santana",
        CODIGO_IBGE: 2926400,
      },
      {
        NOME: "Ribeira do Amparo",
        CODIGO_IBGE: 2926509,
      },
      {
        NOME: "Ribeira do Pombal",
        CODIGO_IBGE: 2926608,
      },
      {
        NOME: "Ribeirão do Largo",
        CODIGO_IBGE: 2926657,
      },
      {
        NOME: "Rio de Contas",
        CODIGO_IBGE: 2926707,
      },
      {
        NOME: "Rio do Antônio",
        CODIGO_IBGE: 2926806,
      },
      {
        NOME: "Rio do Pires",
        CODIGO_IBGE: 2926905,
      },
      {
        NOME: "Rio Real",
        CODIGO_IBGE: 2927002,
      },
      {
        NOME: "Rodelas",
        CODIGO_IBGE: 2927101,
      },
      {
        NOME: "Ruy Barbosa",
        CODIGO_IBGE: 2927200,
      },
      {
        NOME: "Salinas da Margarida",
        CODIGO_IBGE: 2927309,
      },
      {
        NOME: "Salvador",
        CODIGO_IBGE: 2927408,
      },
      {
        NOME: "Santa Bárbara",
        CODIGO_IBGE: 2927507,
      },
      {
        NOME: "Santa Brígida",
        CODIGO_IBGE: 2927606,
      },
      {
        NOME: "Santa Cruz Cabrália",
        CODIGO_IBGE: 2927705,
      },
      {
        NOME: "Santa Cruz da Vitória",
        CODIGO_IBGE: 2927804,
      },
      {
        NOME: "Santa Inês",
        CODIGO_IBGE: 2927903,
      },
      {
        NOME: "Santaluz",
        CODIGO_IBGE: 2928000,
      },
      {
        NOME: "Santa Luzia",
        CODIGO_IBGE: 2928059,
      },
      {
        NOME: "Santa Maria da Vitória",
        CODIGO_IBGE: 2928109,
      },
      {
        NOME: "Santana",
        CODIGO_IBGE: 2928208,
      },
      {
        NOME: "Santanópolis",
        CODIGO_IBGE: 2928307,
      },
      {
        NOME: "Santa Rita de Cássia",
        CODIGO_IBGE: 2928406,
      },
      {
        NOME: "Santa Terezinha",
        CODIGO_IBGE: 2928505,
      },
      {
        NOME: "Santo Amaro",
        CODIGO_IBGE: 2928604,
      },
      {
        NOME: "Santo Antônio de Jesus",
        CODIGO_IBGE: 2928703,
      },
      {
        NOME: "Santo Estêvão",
        CODIGO_IBGE: 2928802,
      },
      {
        NOME: "São Desidério",
        CODIGO_IBGE: 2928901,
      },
      {
        NOME: "São Domingos",
        CODIGO_IBGE: 2928950,
      },
      {
        NOME: "São Félix",
        CODIGO_IBGE: 2929008,
      },
      {
        NOME: "São Félix do Coribe",
        CODIGO_IBGE: 2929057,
      },
      {
        NOME: "São Felipe",
        CODIGO_IBGE: 2929107,
      },
      {
        NOME: "São Francisco do Conde",
        CODIGO_IBGE: 2929206,
      },
      {
        NOME: "São Gabriel",
        CODIGO_IBGE: 2929255,
      },
      {
        NOME: "São Gonçalo dos Campos",
        CODIGO_IBGE: 2929305,
      },
      {
        NOME: "São José da Vitória",
        CODIGO_IBGE: 2929354,
      },
      {
        NOME: "São José do Jacuípe",
        CODIGO_IBGE: 2929370,
      },
      {
        NOME: "São Miguel das Matas",
        CODIGO_IBGE: 2929404,
      },
      {
        NOME: "São Sebastião do Passé",
        CODIGO_IBGE: 2929503,
      },
      {
        NOME: "Sapeaçu",
        CODIGO_IBGE: 2929602,
      },
      {
        NOME: "Sátiro Dias",
        CODIGO_IBGE: 2929701,
      },
      {
        NOME: "Saubara",
        CODIGO_IBGE: 2929750,
      },
      {
        NOME: "Saúde",
        CODIGO_IBGE: 2929800,
      },
      {
        NOME: "Seabra",
        CODIGO_IBGE: 2929909,
      },
      {
        NOME: "Sebastião Laranjeiras",
        CODIGO_IBGE: 2930006,
      },
      {
        NOME: "Senhor do Bonfim",
        CODIGO_IBGE: 2930105,
      },
      {
        NOME: "Serra do Ramalho",
        CODIGO_IBGE: 2930154,
      },
      {
        NOME: "Sento Sé",
        CODIGO_IBGE: 2930204,
      },
      {
        NOME: "Serra Dourada",
        CODIGO_IBGE: 2930303,
      },
      {
        NOME: "Serra Preta",
        CODIGO_IBGE: 2930402,
      },
      {
        NOME: "Serrinha",
        CODIGO_IBGE: 2930501,
      },
      {
        NOME: "Serrolândia",
        CODIGO_IBGE: 2930600,
      },
      {
        NOME: "Simões Filho",
        CODIGO_IBGE: 2930709,
      },
      {
        NOME: "Sítio do Mato",
        CODIGO_IBGE: 2930758,
      },
      {
        NOME: "Sítio do Quinto",
        CODIGO_IBGE: 2930766,
      },
      {
        NOME: "Sobradinho",
        CODIGO_IBGE: 2930774,
      },
      {
        NOME: "Souto Soares",
        CODIGO_IBGE: 2930808,
      },
      {
        NOME: "Tabocas do Brejo Velho",
        CODIGO_IBGE: 2930907,
      },
      {
        NOME: "Tanhaçu",
        CODIGO_IBGE: 2931004,
      },
      {
        NOME: "Tanque Novo",
        CODIGO_IBGE: 2931053,
      },
      {
        NOME: "Tanquinho",
        CODIGO_IBGE: 2931103,
      },
      {
        NOME: "Taperoá",
        CODIGO_IBGE: 2931202,
      },
      {
        NOME: "Tapiramutá",
        CODIGO_IBGE: 2931301,
      },
      {
        NOME: "Teixeira de Freitas",
        CODIGO_IBGE: 2931350,
      },
      {
        NOME: "Teodoro Sampaio",
        CODIGO_IBGE: 2931400,
      },
      {
        NOME: "Teofilândia",
        CODIGO_IBGE: 2931509,
      },
      {
        NOME: "Teolândia",
        CODIGO_IBGE: 2931608,
      },
      {
        NOME: "Terra Nova",
        CODIGO_IBGE: 2931707,
      },
      {
        NOME: "Tremedal",
        CODIGO_IBGE: 2931806,
      },
      {
        NOME: "Tucano",
        CODIGO_IBGE: 2931905,
      },
      {
        NOME: "Uauá",
        CODIGO_IBGE: 2932002,
      },
      {
        NOME: "Ubaíra",
        CODIGO_IBGE: 2932101,
      },
      {
        NOME: "Ubaitaba",
        CODIGO_IBGE: 2932200,
      },
      {
        NOME: "Ubatã",
        CODIGO_IBGE: 2932309,
      },
      {
        NOME: "Uibaí",
        CODIGO_IBGE: 2932408,
      },
      {
        NOME: "Umburanas",
        CODIGO_IBGE: 2932457,
      },
      {
        NOME: "Una",
        CODIGO_IBGE: 2932507,
      },
      {
        NOME: "Urandi",
        CODIGO_IBGE: 2932606,
      },
      {
        NOME: "Uruçuca",
        CODIGO_IBGE: 2932705,
      },
      {
        NOME: "Utinga",
        CODIGO_IBGE: 2932804,
      },
      {
        NOME: "Valença",
        CODIGO_IBGE: 2932903,
      },
      {
        NOME: "Valente",
        CODIGO_IBGE: 2933000,
      },
      {
        NOME: "Várzea da Roça",
        CODIGO_IBGE: 2933059,
      },
      {
        NOME: "Várzea do Poço",
        CODIGO_IBGE: 2933109,
      },
      {
        NOME: "Várzea Nova",
        CODIGO_IBGE: 2933158,
      },
      {
        NOME: "Varzedo",
        CODIGO_IBGE: 2933174,
      },
      {
        NOME: "Vera Cruz",
        CODIGO_IBGE: 2933208,
      },
      {
        NOME: "Vereda",
        CODIGO_IBGE: 2933257,
      },
      {
        NOME: "Vitória da Conquista",
        CODIGO_IBGE: 2933307,
      },
      {
        NOME: "Wagner",
        CODIGO_IBGE: 2933406,
      },
      {
        NOME: "Wanderley",
        CODIGO_IBGE: 2933455,
      },
      {
        NOME: "Wenceslau Guimarães",
        CODIGO_IBGE: 2933505,
      },
      {
        NOME: "Xique-Xique",
        CODIGO_IBGE: 2933604,
      },
    ],
    31: [
      {
        NOME: "Abadia dos Dourados",
        CODIGO_IBGE: 3100104,
      },
      {
        NOME: "Abaeté",
        CODIGO_IBGE: 3100203,
      },
      {
        NOME: "Abre Campo",
        CODIGO_IBGE: 3100302,
      },
      {
        NOME: "Acaiaca",
        CODIGO_IBGE: 3100401,
      },
      {
        NOME: "Açucena",
        CODIGO_IBGE: 3100500,
      },
      {
        NOME: "Água Boa",
        CODIGO_IBGE: 3100609,
      },
      {
        NOME: "Água Comprida",
        CODIGO_IBGE: 3100708,
      },
      {
        NOME: "Aguanil",
        CODIGO_IBGE: 3100807,
      },
      {
        NOME: "Águas Formosas",
        CODIGO_IBGE: 3100906,
      },
      {
        NOME: "Águas Vermelhas",
        CODIGO_IBGE: 3101003,
      },
      {
        NOME: "Aimorés",
        CODIGO_IBGE: 3101102,
      },
      {
        NOME: "Aiuruoca",
        CODIGO_IBGE: 3101201,
      },
      {
        NOME: "Alagoa",
        CODIGO_IBGE: 3101300,
      },
      {
        NOME: "Albertina",
        CODIGO_IBGE: 3101409,
      },
      {
        NOME: "Além Paraíba",
        CODIGO_IBGE: 3101508,
      },
      {
        NOME: "Alfenas",
        CODIGO_IBGE: 3101607,
      },
      {
        NOME: "Alfredo Vasconcelos",
        CODIGO_IBGE: 3101631,
      },
      {
        NOME: "Almenara",
        CODIGO_IBGE: 3101706,
      },
      {
        NOME: "Alpercata",
        CODIGO_IBGE: 3101805,
      },
      {
        NOME: "Alpinópolis",
        CODIGO_IBGE: 3101904,
      },
      {
        NOME: "Alterosa",
        CODIGO_IBGE: 3102001,
      },
      {
        NOME: "Alto Caparaó",
        CODIGO_IBGE: 3102050,
      },
      {
        NOME: "Alto Rio Doce",
        CODIGO_IBGE: 3102100,
      },
      {
        NOME: "Alvarenga",
        CODIGO_IBGE: 3102209,
      },
      {
        NOME: "Alvinópolis",
        CODIGO_IBGE: 3102308,
      },
      {
        NOME: "Alvorada de Minas",
        CODIGO_IBGE: 3102407,
      },
      {
        NOME: "Amparo do Serra",
        CODIGO_IBGE: 3102506,
      },
      {
        NOME: "Andradas",
        CODIGO_IBGE: 3102605,
      },
      {
        NOME: "Cachoeira de Pajeú",
        CODIGO_IBGE: 3102704,
      },
      {
        NOME: "Andrelândia",
        CODIGO_IBGE: 3102803,
      },
      {
        NOME: "Angelândia",
        CODIGO_IBGE: 3102852,
      },
      {
        NOME: "Antônio Carlos",
        CODIGO_IBGE: 3102902,
      },
      {
        NOME: "Antônio Dias",
        CODIGO_IBGE: 3103009,
      },
      {
        NOME: "Antônio Prado de Minas",
        CODIGO_IBGE: 3103108,
      },
      {
        NOME: "Araçaí",
        CODIGO_IBGE: 3103207,
      },
      {
        NOME: "Aracitaba",
        CODIGO_IBGE: 3103306,
      },
      {
        NOME: "Araçuaí",
        CODIGO_IBGE: 3103405,
      },
      {
        NOME: "Araguari",
        CODIGO_IBGE: 3103504,
      },
      {
        NOME: "Arantina",
        CODIGO_IBGE: 3103603,
      },
      {
        NOME: "Araponga",
        CODIGO_IBGE: 3103702,
      },
      {
        NOME: "Araporã",
        CODIGO_IBGE: 3103751,
      },
      {
        NOME: "Arapuá",
        CODIGO_IBGE: 3103801,
      },
      {
        NOME: "Araújos",
        CODIGO_IBGE: 3103900,
      },
      {
        NOME: "Araxá",
        CODIGO_IBGE: 3104007,
      },
      {
        NOME: "Arceburgo",
        CODIGO_IBGE: 3104106,
      },
      {
        NOME: "Arcos",
        CODIGO_IBGE: 3104205,
      },
      {
        NOME: "Areado",
        CODIGO_IBGE: 3104304,
      },
      {
        NOME: "Argirita",
        CODIGO_IBGE: 3104403,
      },
      {
        NOME: "Aricanduva",
        CODIGO_IBGE: 3104452,
      },
      {
        NOME: "Arinos",
        CODIGO_IBGE: 3104502,
      },
      {
        NOME: "Astolfo Dutra",
        CODIGO_IBGE: 3104601,
      },
      {
        NOME: "Ataléia",
        CODIGO_IBGE: 3104700,
      },
      {
        NOME: "Augusto de Lima",
        CODIGO_IBGE: 3104809,
      },
      {
        NOME: "Baependi",
        CODIGO_IBGE: 3104908,
      },
      {
        NOME: "Baldim",
        CODIGO_IBGE: 3105004,
      },
      {
        NOME: "Bambuí",
        CODIGO_IBGE: 3105103,
      },
      {
        NOME: "Bandeira",
        CODIGO_IBGE: 3105202,
      },
      {
        NOME: "Bandeira do Sul",
        CODIGO_IBGE: 3105301,
      },
      {
        NOME: "Barão de Cocais",
        CODIGO_IBGE: 3105400,
      },
      {
        NOME: "Barão de Monte Alto",
        CODIGO_IBGE: 3105509,
      },
      {
        NOME: "Barbacena",
        CODIGO_IBGE: 3105608,
      },
      {
        NOME: "Barra Longa",
        CODIGO_IBGE: 3105707,
      },
      {
        NOME: "Barroso",
        CODIGO_IBGE: 3105905,
      },
      {
        NOME: "Bela Vista de Minas",
        CODIGO_IBGE: 3106002,
      },
      {
        NOME: "Belmiro Braga",
        CODIGO_IBGE: 3106101,
      },
      {
        NOME: "Belo Horizonte",
        CODIGO_IBGE: 3106200,
      },
      {
        NOME: "Belo Oriente",
        CODIGO_IBGE: 3106309,
      },
      {
        NOME: "Belo Vale",
        CODIGO_IBGE: 3106408,
      },
      {
        NOME: "Berilo",
        CODIGO_IBGE: 3106507,
      },
      {
        NOME: "Bertópolis",
        CODIGO_IBGE: 3106606,
      },
      {
        NOME: "Berizal",
        CODIGO_IBGE: 3106655,
      },
      {
        NOME: "Betim",
        CODIGO_IBGE: 3106705,
      },
      {
        NOME: "Bias Fortes",
        CODIGO_IBGE: 3106804,
      },
      {
        NOME: "Bicas",
        CODIGO_IBGE: 3106903,
      },
      {
        NOME: "Biquinhas",
        CODIGO_IBGE: 3107000,
      },
      {
        NOME: "Boa Esperança",
        CODIGO_IBGE: 3107109,
      },
      {
        NOME: "Bocaina de Minas",
        CODIGO_IBGE: 3107208,
      },
      {
        NOME: "Bocaiúva",
        CODIGO_IBGE: 3107307,
      },
      {
        NOME: "Bom Despacho",
        CODIGO_IBGE: 3107406,
      },
      {
        NOME: "Bom Jardim de Minas",
        CODIGO_IBGE: 3107505,
      },
      {
        NOME: "Bom Jesus da Penha",
        CODIGO_IBGE: 3107604,
      },
      {
        NOME: "Bom Jesus do Amparo",
        CODIGO_IBGE: 3107703,
      },
      {
        NOME: "Bom Jesus do Galho",
        CODIGO_IBGE: 3107802,
      },
      {
        NOME: "Bom Repouso",
        CODIGO_IBGE: 3107901,
      },
      {
        NOME: "Bom Sucesso",
        CODIGO_IBGE: 3108008,
      },
      {
        NOME: "Bonfim",
        CODIGO_IBGE: 3108107,
      },
      {
        NOME: "Bonfinópolis de Minas",
        CODIGO_IBGE: 3108206,
      },
      {
        NOME: "Bonito de Minas",
        CODIGO_IBGE: 3108255,
      },
      {
        NOME: "Borda da Mata",
        CODIGO_IBGE: 3108305,
      },
      {
        NOME: "Botelhos",
        CODIGO_IBGE: 3108404,
      },
      {
        NOME: "Botumirim",
        CODIGO_IBGE: 3108503,
      },
      {
        NOME: "Brasilândia de Minas",
        CODIGO_IBGE: 3108552,
      },
      {
        NOME: "Brasília de Minas",
        CODIGO_IBGE: 3108602,
      },
      {
        NOME: "Brás Pires",
        CODIGO_IBGE: 3108701,
      },
      {
        NOME: "Braúnas",
        CODIGO_IBGE: 3108800,
      },
      {
        NOME: "Brazópolis",
        CODIGO_IBGE: 3108909,
      },
      {
        NOME: "Brumadinho",
        CODIGO_IBGE: 3109006,
      },
      {
        NOME: "Bueno Brandão",
        CODIGO_IBGE: 3109105,
      },
      {
        NOME: "Buenópolis",
        CODIGO_IBGE: 3109204,
      },
      {
        NOME: "Bugre",
        CODIGO_IBGE: 3109253,
      },
      {
        NOME: "Buritis",
        CODIGO_IBGE: 3109303,
      },
      {
        NOME: "Buritizeiro",
        CODIGO_IBGE: 3109402,
      },
      {
        NOME: "Cabeceira Grande",
        CODIGO_IBGE: 3109451,
      },
      {
        NOME: "Cabo Verde",
        CODIGO_IBGE: 3109501,
      },
      {
        NOME: "Cachoeira da Prata",
        CODIGO_IBGE: 3109600,
      },
      {
        NOME: "Cachoeira de Minas",
        CODIGO_IBGE: 3109709,
      },
      {
        NOME: "Cachoeira Dourada",
        CODIGO_IBGE: 3109808,
      },
      {
        NOME: "Caetanópolis",
        CODIGO_IBGE: 3109907,
      },
      {
        NOME: "Caeté",
        CODIGO_IBGE: 3110004,
      },
      {
        NOME: "Caiana",
        CODIGO_IBGE: 3110103,
      },
      {
        NOME: "Cajuri",
        CODIGO_IBGE: 3110202,
      },
      {
        NOME: "Caldas",
        CODIGO_IBGE: 3110301,
      },
      {
        NOME: "Camacho",
        CODIGO_IBGE: 3110400,
      },
      {
        NOME: "Camanducaia",
        CODIGO_IBGE: 3110509,
      },
      {
        NOME: "Cambuí",
        CODIGO_IBGE: 3110608,
      },
      {
        NOME: "Cambuquira",
        CODIGO_IBGE: 3110707,
      },
      {
        NOME: "Campanário",
        CODIGO_IBGE: 3110806,
      },
      {
        NOME: "Campanha",
        CODIGO_IBGE: 3110905,
      },
      {
        NOME: "Campestre",
        CODIGO_IBGE: 3111002,
      },
      {
        NOME: "Campina Verde",
        CODIGO_IBGE: 3111101,
      },
      {
        NOME: "Campo Azul",
        CODIGO_IBGE: 3111150,
      },
      {
        NOME: "Campo Belo",
        CODIGO_IBGE: 3111200,
      },
      {
        NOME: "Campo do Meio",
        CODIGO_IBGE: 3111309,
      },
      {
        NOME: "Campo Florido",
        CODIGO_IBGE: 3111408,
      },
      {
        NOME: "Campos Altos",
        CODIGO_IBGE: 3111507,
      },
      {
        NOME: "Campos Gerais",
        CODIGO_IBGE: 3111606,
      },
      {
        NOME: "Canaã",
        CODIGO_IBGE: 3111705,
      },
      {
        NOME: "Canápolis",
        CODIGO_IBGE: 3111804,
      },
      {
        NOME: "Cana Verde",
        CODIGO_IBGE: 3111903,
      },
      {
        NOME: "Candeias",
        CODIGO_IBGE: 3112000,
      },
      {
        NOME: "Cantagalo",
        CODIGO_IBGE: 3112059,
      },
      {
        NOME: "Caparaó",
        CODIGO_IBGE: 3112109,
      },
      {
        NOME: "Capela Nova",
        CODIGO_IBGE: 3112208,
      },
      {
        NOME: "Capelinha",
        CODIGO_IBGE: 3112307,
      },
      {
        NOME: "Capetinga",
        CODIGO_IBGE: 3112406,
      },
      {
        NOME: "Capim Branco",
        CODIGO_IBGE: 3112505,
      },
      {
        NOME: "Capinópolis",
        CODIGO_IBGE: 3112604,
      },
      {
        NOME: "Capitão Andrade",
        CODIGO_IBGE: 3112653,
      },
      {
        NOME: "Capitão Enéas",
        CODIGO_IBGE: 3112703,
      },
      {
        NOME: "Capitólio",
        CODIGO_IBGE: 3112802,
      },
      {
        NOME: "Caputira",
        CODIGO_IBGE: 3112901,
      },
      {
        NOME: "Caraí",
        CODIGO_IBGE: 3113008,
      },
      {
        NOME: "Caranaíba",
        CODIGO_IBGE: 3113107,
      },
      {
        NOME: "Carandaí",
        CODIGO_IBGE: 3113206,
      },
      {
        NOME: "Carangola",
        CODIGO_IBGE: 3113305,
      },
      {
        NOME: "Caratinga",
        CODIGO_IBGE: 3113404,
      },
      {
        NOME: "Carbonita",
        CODIGO_IBGE: 3113503,
      },
      {
        NOME: "Careaçu",
        CODIGO_IBGE: 3113602,
      },
      {
        NOME: "Carlos Chagas",
        CODIGO_IBGE: 3113701,
      },
      {
        NOME: "Carmésia",
        CODIGO_IBGE: 3113800,
      },
      {
        NOME: "Carmo da Cachoeira",
        CODIGO_IBGE: 3113909,
      },
      {
        NOME: "Carmo da Mata",
        CODIGO_IBGE: 3114006,
      },
      {
        NOME: "Carmo de Minas",
        CODIGO_IBGE: 3114105,
      },
      {
        NOME: "Carmo do Cajuru",
        CODIGO_IBGE: 3114204,
      },
      {
        NOME: "Carmo do Paranaíba",
        CODIGO_IBGE: 3114303,
      },
      {
        NOME: "Carmo do Rio Claro",
        CODIGO_IBGE: 3114402,
      },
      {
        NOME: "Carmópolis de Minas",
        CODIGO_IBGE: 3114501,
      },
      {
        NOME: "Carneirinho",
        CODIGO_IBGE: 3114550,
      },
      {
        NOME: "Carrancas",
        CODIGO_IBGE: 3114600,
      },
      {
        NOME: "Carvalhópolis",
        CODIGO_IBGE: 3114709,
      },
      {
        NOME: "Carvalhos",
        CODIGO_IBGE: 3114808,
      },
      {
        NOME: "Casa Grande",
        CODIGO_IBGE: 3114907,
      },
      {
        NOME: "Cascalho Rico",
        CODIGO_IBGE: 3115003,
      },
      {
        NOME: "Cássia",
        CODIGO_IBGE: 3115102,
      },
      {
        NOME: "Conceição da Barra de Minas",
        CODIGO_IBGE: 3115201,
      },
      {
        NOME: "Cataguases",
        CODIGO_IBGE: 3115300,
      },
      {
        NOME: "Catas Altas",
        CODIGO_IBGE: 3115359,
      },
      {
        NOME: "Catas Altas da Noruega",
        CODIGO_IBGE: 3115409,
      },
      {
        NOME: "Catuji",
        CODIGO_IBGE: 3115458,
      },
      {
        NOME: "Catuti",
        CODIGO_IBGE: 3115474,
      },
      {
        NOME: "Caxambu",
        CODIGO_IBGE: 3115508,
      },
      {
        NOME: "Cedro do Abaeté",
        CODIGO_IBGE: 3115607,
      },
      {
        NOME: "Central de Minas",
        CODIGO_IBGE: 3115706,
      },
      {
        NOME: "Centralina",
        CODIGO_IBGE: 3115805,
      },
      {
        NOME: "Chácara",
        CODIGO_IBGE: 3115904,
      },
      {
        NOME: "Chalé",
        CODIGO_IBGE: 3116001,
      },
      {
        NOME: "Chapada do Norte",
        CODIGO_IBGE: 3116100,
      },
      {
        NOME: "Chapada Gaúcha",
        CODIGO_IBGE: 3116159,
      },
      {
        NOME: "Chiador",
        CODIGO_IBGE: 3116209,
      },
      {
        NOME: "Cipotânea",
        CODIGO_IBGE: 3116308,
      },
      {
        NOME: "Claraval",
        CODIGO_IBGE: 3116407,
      },
      {
        NOME: "Claro dos Poções",
        CODIGO_IBGE: 3116506,
      },
      {
        NOME: "Cláudio",
        CODIGO_IBGE: 3116605,
      },
      {
        NOME: "Coimbra",
        CODIGO_IBGE: 3116704,
      },
      {
        NOME: "Coluna",
        CODIGO_IBGE: 3116803,
      },
      {
        NOME: "Comendador Gomes",
        CODIGO_IBGE: 3116902,
      },
      {
        NOME: "Comercinho",
        CODIGO_IBGE: 3117009,
      },
      {
        NOME: "Conceição da Aparecida",
        CODIGO_IBGE: 3117108,
      },
      {
        NOME: "Conceição das Pedras",
        CODIGO_IBGE: 3117207,
      },
      {
        NOME: "Conceição das Alagoas",
        CODIGO_IBGE: 3117306,
      },
      {
        NOME: "Conceição de Ipanema",
        CODIGO_IBGE: 3117405,
      },
      {
        NOME: "Conceição do Mato Dentro",
        CODIGO_IBGE: 3117504,
      },
      {
        NOME: "Conceição do Pará",
        CODIGO_IBGE: 3117603,
      },
      {
        NOME: "Conceição do Rio Verde",
        CODIGO_IBGE: 3117702,
      },
      {
        NOME: "Conceição dos Ouros",
        CODIGO_IBGE: 3117801,
      },
      {
        NOME: "Cônego Marinho",
        CODIGO_IBGE: 3117836,
      },
      {
        NOME: "Confins",
        CODIGO_IBGE: 3117876,
      },
      {
        NOME: "Congonhal",
        CODIGO_IBGE: 3117900,
      },
      {
        NOME: "Congonhas",
        CODIGO_IBGE: 3118007,
      },
      {
        NOME: "Congonhas do Norte",
        CODIGO_IBGE: 3118106,
      },
      {
        NOME: "Conquista",
        CODIGO_IBGE: 3118205,
      },
      {
        NOME: "Conselheiro Lafaiete",
        CODIGO_IBGE: 3118304,
      },
      {
        NOME: "Conselheiro Pena",
        CODIGO_IBGE: 3118403,
      },
      {
        NOME: "Consolação",
        CODIGO_IBGE: 3118502,
      },
      {
        NOME: "Contagem",
        CODIGO_IBGE: 3118601,
      },
      {
        NOME: "Coqueiral",
        CODIGO_IBGE: 3118700,
      },
      {
        NOME: "Coração de Jesus",
        CODIGO_IBGE: 3118809,
      },
      {
        NOME: "Cordisburgo",
        CODIGO_IBGE: 3118908,
      },
      {
        NOME: "Cordislândia",
        CODIGO_IBGE: 3119005,
      },
      {
        NOME: "Corinto",
        CODIGO_IBGE: 3119104,
      },
      {
        NOME: "Coroaci",
        CODIGO_IBGE: 3119203,
      },
      {
        NOME: "Coromandel",
        CODIGO_IBGE: 3119302,
      },
      {
        NOME: "Coronel Fabriciano",
        CODIGO_IBGE: 3119401,
      },
      {
        NOME: "Coronel Murta",
        CODIGO_IBGE: 3119500,
      },
      {
        NOME: "Coronel Pacheco",
        CODIGO_IBGE: 3119609,
      },
      {
        NOME: "Coronel Xavier Chaves",
        CODIGO_IBGE: 3119708,
      },
      {
        NOME: "Córrego Danta",
        CODIGO_IBGE: 3119807,
      },
      {
        NOME: "Córrego do Bom Jesus",
        CODIGO_IBGE: 3119906,
      },
      {
        NOME: "Córrego Fundo",
        CODIGO_IBGE: 3119955,
      },
      {
        NOME: "Córrego Novo",
        CODIGO_IBGE: 3120003,
      },
      {
        NOME: "Couto de Magalhães de Minas",
        CODIGO_IBGE: 3120102,
      },
      {
        NOME: "Crisólita",
        CODIGO_IBGE: 3120151,
      },
      {
        NOME: "Cristais",
        CODIGO_IBGE: 3120201,
      },
      {
        NOME: "Cristália",
        CODIGO_IBGE: 3120300,
      },
      {
        NOME: "Cristiano Otoni",
        CODIGO_IBGE: 3120409,
      },
      {
        NOME: "Cristina",
        CODIGO_IBGE: 3120508,
      },
      {
        NOME: "Crucilândia",
        CODIGO_IBGE: 3120607,
      },
      {
        NOME: "Cruzeiro da Fortaleza",
        CODIGO_IBGE: 3120706,
      },
      {
        NOME: "Cruzília",
        CODIGO_IBGE: 3120805,
      },
      {
        NOME: "Cuparaque",
        CODIGO_IBGE: 3120839,
      },
      {
        NOME: "Curral de Dentro",
        CODIGO_IBGE: 3120870,
      },
      {
        NOME: "Curvelo",
        CODIGO_IBGE: 3120904,
      },
      {
        NOME: "Datas",
        CODIGO_IBGE: 3121001,
      },
      {
        NOME: "Delfim Moreira",
        CODIGO_IBGE: 3121100,
      },
      {
        NOME: "Delfinópolis",
        CODIGO_IBGE: 3121209,
      },
      {
        NOME: "Delta",
        CODIGO_IBGE: 3121258,
      },
      {
        NOME: "Descoberto",
        CODIGO_IBGE: 3121308,
      },
      {
        NOME: "Desterro de Entre Rios",
        CODIGO_IBGE: 3121407,
      },
      {
        NOME: "Desterro do Melo",
        CODIGO_IBGE: 3121506,
      },
      {
        NOME: "Diamantina",
        CODIGO_IBGE: 3121605,
      },
      {
        NOME: "Diogo de Vasconcelos",
        CODIGO_IBGE: 3121704,
      },
      {
        NOME: "Dionísio",
        CODIGO_IBGE: 3121803,
      },
      {
        NOME: "Divinésia",
        CODIGO_IBGE: 3121902,
      },
      {
        NOME: "Divino",
        CODIGO_IBGE: 3122009,
      },
      {
        NOME: "Divino das Laranjeiras",
        CODIGO_IBGE: 3122108,
      },
      {
        NOME: "Divinolândia de Minas",
        CODIGO_IBGE: 3122207,
      },
      {
        NOME: "Divinópolis",
        CODIGO_IBGE: 3122306,
      },
      {
        NOME: "Divisa Alegre",
        CODIGO_IBGE: 3122355,
      },
      {
        NOME: "Divisa Nova",
        CODIGO_IBGE: 3122405,
      },
      {
        NOME: "Divisópolis",
        CODIGO_IBGE: 3122454,
      },
      {
        NOME: "Dom Bosco",
        CODIGO_IBGE: 3122470,
      },
      {
        NOME: "Dom Cavati",
        CODIGO_IBGE: 3122504,
      },
      {
        NOME: "Dom Joaquim",
        CODIGO_IBGE: 3122603,
      },
      {
        NOME: "Dom Silvério",
        CODIGO_IBGE: 3122702,
      },
      {
        NOME: "Dom Viçoso",
        CODIGO_IBGE: 3122801,
      },
      {
        NOME: "Dona Eusébia",
        CODIGO_IBGE: 3122900,
      },
      {
        NOME: "Dores de Campos",
        CODIGO_IBGE: 3123007,
      },
      {
        NOME: "Dores de Guanhães",
        CODIGO_IBGE: 3123106,
      },
      {
        NOME: "Dores do Indaiá",
        CODIGO_IBGE: 3123205,
      },
      {
        NOME: "Dores do Turvo",
        CODIGO_IBGE: 3123304,
      },
      {
        NOME: "Doresópolis",
        CODIGO_IBGE: 3123403,
      },
      {
        NOME: "Douradoquara",
        CODIGO_IBGE: 3123502,
      },
      {
        NOME: "Durandé",
        CODIGO_IBGE: 3123528,
      },
      {
        NOME: "Elói Mendes",
        CODIGO_IBGE: 3123601,
      },
      {
        NOME: "Engenheiro Caldas",
        CODIGO_IBGE: 3123700,
      },
      {
        NOME: "Engenheiro Navarro",
        CODIGO_IBGE: 3123809,
      },
      {
        NOME: "Entre Folhas",
        CODIGO_IBGE: 3123858,
      },
      {
        NOME: "Entre Rios de Minas",
        CODIGO_IBGE: 3123908,
      },
      {
        NOME: "Ervália",
        CODIGO_IBGE: 3124005,
      },
      {
        NOME: "Esmeraldas",
        CODIGO_IBGE: 3124104,
      },
      {
        NOME: "Espera Feliz",
        CODIGO_IBGE: 3124203,
      },
      {
        NOME: "Espinosa",
        CODIGO_IBGE: 3124302,
      },
      {
        NOME: "Espírito Santo do Dourado",
        CODIGO_IBGE: 3124401,
      },
      {
        NOME: "Estiva",
        CODIGO_IBGE: 3124500,
      },
      {
        NOME: "Estrela Dalva",
        CODIGO_IBGE: 3124609,
      },
      {
        NOME: "Estrela do Indaiá",
        CODIGO_IBGE: 3124708,
      },
      {
        NOME: "Estrela do Sul",
        CODIGO_IBGE: 3124807,
      },
      {
        NOME: "Eugenópolis",
        CODIGO_IBGE: 3124906,
      },
      {
        NOME: "Ewbank da Câmara",
        CODIGO_IBGE: 3125002,
      },
      {
        NOME: "Extrema",
        CODIGO_IBGE: 3125101,
      },
      {
        NOME: "Fama",
        CODIGO_IBGE: 3125200,
      },
      {
        NOME: "Faria Lemos",
        CODIGO_IBGE: 3125309,
      },
      {
        NOME: "Felício dos Santos",
        CODIGO_IBGE: 3125408,
      },
      {
        NOME: "São Gonçalo do Rio Preto",
        CODIGO_IBGE: 3125507,
      },
      {
        NOME: "Felisburgo",
        CODIGO_IBGE: 3125606,
      },
      {
        NOME: "Felixlândia",
        CODIGO_IBGE: 3125705,
      },
      {
        NOME: "Fernandes Tourinho",
        CODIGO_IBGE: 3125804,
      },
      {
        NOME: "Ferros",
        CODIGO_IBGE: 3125903,
      },
      {
        NOME: "Fervedouro",
        CODIGO_IBGE: 3125952,
      },
      {
        NOME: "Florestal",
        CODIGO_IBGE: 3126000,
      },
      {
        NOME: "Formiga",
        CODIGO_IBGE: 3126109,
      },
      {
        NOME: "Formoso",
        CODIGO_IBGE: 3126208,
      },
      {
        NOME: "Fortaleza de Minas",
        CODIGO_IBGE: 3126307,
      },
      {
        NOME: "Fortuna de Minas",
        CODIGO_IBGE: 3126406,
      },
      {
        NOME: "Francisco Badaró",
        CODIGO_IBGE: 3126505,
      },
      {
        NOME: "Francisco Dumont",
        CODIGO_IBGE: 3126604,
      },
      {
        NOME: "Francisco Sá",
        CODIGO_IBGE: 3126703,
      },
      {
        NOME: "Franciscópolis",
        CODIGO_IBGE: 3126752,
      },
      {
        NOME: "Frei Gaspar",
        CODIGO_IBGE: 3126802,
      },
      {
        NOME: "Frei Inocêncio",
        CODIGO_IBGE: 3126901,
      },
      {
        NOME: "Frei Lagonegro",
        CODIGO_IBGE: 3126950,
      },
      {
        NOME: "Fronteira",
        CODIGO_IBGE: 3127008,
      },
      {
        NOME: "Fronteira dos Vales",
        CODIGO_IBGE: 3127057,
      },
      {
        NOME: "Fruta de Leite",
        CODIGO_IBGE: 3127073,
      },
      {
        NOME: "Frutal",
        CODIGO_IBGE: 3127107,
      },
      {
        NOME: "Funilândia",
        CODIGO_IBGE: 3127206,
      },
      {
        NOME: "Galiléia",
        CODIGO_IBGE: 3127305,
      },
      {
        NOME: "Gameleiras",
        CODIGO_IBGE: 3127339,
      },
      {
        NOME: "Glaucilândia",
        CODIGO_IBGE: 3127354,
      },
      {
        NOME: "Goiabeira",
        CODIGO_IBGE: 3127370,
      },
      {
        NOME: "Goianá",
        CODIGO_IBGE: 3127388,
      },
      {
        NOME: "Gonçalves",
        CODIGO_IBGE: 3127404,
      },
      {
        NOME: "Gonzaga",
        CODIGO_IBGE: 3127503,
      },
      {
        NOME: "Gouveia",
        CODIGO_IBGE: 3127602,
      },
      {
        NOME: "Governador Valadares",
        CODIGO_IBGE: 3127701,
      },
      {
        NOME: "Grão Mogol",
        CODIGO_IBGE: 3127800,
      },
      {
        NOME: "Grupiara",
        CODIGO_IBGE: 3127909,
      },
      {
        NOME: "Guanhães",
        CODIGO_IBGE: 3128006,
      },
      {
        NOME: "Guapé",
        CODIGO_IBGE: 3128105,
      },
      {
        NOME: "Guaraciaba",
        CODIGO_IBGE: 3128204,
      },
      {
        NOME: "Guaraciama",
        CODIGO_IBGE: 3128253,
      },
      {
        NOME: "Guaranésia",
        CODIGO_IBGE: 3128303,
      },
      {
        NOME: "Guarani",
        CODIGO_IBGE: 3128402,
      },
      {
        NOME: "Guarará",
        CODIGO_IBGE: 3128501,
      },
      {
        NOME: "Guarda-Mor",
        CODIGO_IBGE: 3128600,
      },
      {
        NOME: "Guaxupé",
        CODIGO_IBGE: 3128709,
      },
      {
        NOME: "Guidoval",
        CODIGO_IBGE: 3128808,
      },
      {
        NOME: "Guimarânia",
        CODIGO_IBGE: 3128907,
      },
      {
        NOME: "Guiricema",
        CODIGO_IBGE: 3129004,
      },
      {
        NOME: "Gurinhatã",
        CODIGO_IBGE: 3129103,
      },
      {
        NOME: "Heliodora",
        CODIGO_IBGE: 3129202,
      },
      {
        NOME: "Iapu",
        CODIGO_IBGE: 3129301,
      },
      {
        NOME: "Ibertioga",
        CODIGO_IBGE: 3129400,
      },
      {
        NOME: "Ibiá",
        CODIGO_IBGE: 3129509,
      },
      {
        NOME: "Ibiaí",
        CODIGO_IBGE: 3129608,
      },
      {
        NOME: "Ibiracatu",
        CODIGO_IBGE: 3129657,
      },
      {
        NOME: "Ibiraci",
        CODIGO_IBGE: 3129707,
      },
      {
        NOME: "Ibirité",
        CODIGO_IBGE: 3129806,
      },
      {
        NOME: "Ibitiúra de Minas",
        CODIGO_IBGE: 3129905,
      },
      {
        NOME: "Ibituruna",
        CODIGO_IBGE: 3130002,
      },
      {
        NOME: "Icaraí de Minas",
        CODIGO_IBGE: 3130051,
      },
      {
        NOME: "Igarapé",
        CODIGO_IBGE: 3130101,
      },
      {
        NOME: "Igaratinga",
        CODIGO_IBGE: 3130200,
      },
      {
        NOME: "Iguatama",
        CODIGO_IBGE: 3130309,
      },
      {
        NOME: "Ijaci",
        CODIGO_IBGE: 3130408,
      },
      {
        NOME: "Ilicínea",
        CODIGO_IBGE: 3130507,
      },
      {
        NOME: "Imbé de Minas",
        CODIGO_IBGE: 3130556,
      },
      {
        NOME: "Inconfidentes",
        CODIGO_IBGE: 3130606,
      },
      {
        NOME: "Indaiabira",
        CODIGO_IBGE: 3130655,
      },
      {
        NOME: "Indianópolis",
        CODIGO_IBGE: 3130705,
      },
      {
        NOME: "Ingaí",
        CODIGO_IBGE: 3130804,
      },
      {
        NOME: "Inhapim",
        CODIGO_IBGE: 3130903,
      },
      {
        NOME: "Inhaúma",
        CODIGO_IBGE: 3131000,
      },
      {
        NOME: "Inimutaba",
        CODIGO_IBGE: 3131109,
      },
      {
        NOME: "Ipaba",
        CODIGO_IBGE: 3131158,
      },
      {
        NOME: "Ipanema",
        CODIGO_IBGE: 3131208,
      },
      {
        NOME: "Ipatinga",
        CODIGO_IBGE: 3131307,
      },
      {
        NOME: "Ipiaçu",
        CODIGO_IBGE: 3131406,
      },
      {
        NOME: "Ipuiúna",
        CODIGO_IBGE: 3131505,
      },
      {
        NOME: "Iraí de Minas",
        CODIGO_IBGE: 3131604,
      },
      {
        NOME: "Itabira",
        CODIGO_IBGE: 3131703,
      },
      {
        NOME: "Itabirinha",
        CODIGO_IBGE: 3131802,
      },
      {
        NOME: "Itabirito",
        CODIGO_IBGE: 3131901,
      },
      {
        NOME: "Itacambira",
        CODIGO_IBGE: 3132008,
      },
      {
        NOME: "Itacarambi",
        CODIGO_IBGE: 3132107,
      },
      {
        NOME: "Itaguara",
        CODIGO_IBGE: 3132206,
      },
      {
        NOME: "Itaipé",
        CODIGO_IBGE: 3132305,
      },
      {
        NOME: "Itajubá",
        CODIGO_IBGE: 3132404,
      },
      {
        NOME: "Itamarandiba",
        CODIGO_IBGE: 3132503,
      },
      {
        NOME: "Itamarati de Minas",
        CODIGO_IBGE: 3132602,
      },
      {
        NOME: "Itambacuri",
        CODIGO_IBGE: 3132701,
      },
      {
        NOME: "Itambé do Mato Dentro",
        CODIGO_IBGE: 3132800,
      },
      {
        NOME: "Itamogi",
        CODIGO_IBGE: 3132909,
      },
      {
        NOME: "Itamonte",
        CODIGO_IBGE: 3133006,
      },
      {
        NOME: "Itanhandu",
        CODIGO_IBGE: 3133105,
      },
      {
        NOME: "Itanhomi",
        CODIGO_IBGE: 3133204,
      },
      {
        NOME: "Itaobim",
        CODIGO_IBGE: 3133303,
      },
      {
        NOME: "Itapagipe",
        CODIGO_IBGE: 3133402,
      },
      {
        NOME: "Itapecerica",
        CODIGO_IBGE: 3133501,
      },
      {
        NOME: "Itapeva",
        CODIGO_IBGE: 3133600,
      },
      {
        NOME: "Itatiaiuçu",
        CODIGO_IBGE: 3133709,
      },
      {
        NOME: "Itaú de Minas",
        CODIGO_IBGE: 3133758,
      },
      {
        NOME: "Itaúna",
        CODIGO_IBGE: 3133808,
      },
      {
        NOME: "Itaverava",
        CODIGO_IBGE: 3133907,
      },
      {
        NOME: "Itinga",
        CODIGO_IBGE: 3134004,
      },
      {
        NOME: "Itueta",
        CODIGO_IBGE: 3134103,
      },
      {
        NOME: "Ituiutaba",
        CODIGO_IBGE: 3134202,
      },
      {
        NOME: "Itumirim",
        CODIGO_IBGE: 3134301,
      },
      {
        NOME: "Iturama",
        CODIGO_IBGE: 3134400,
      },
      {
        NOME: "Itutinga",
        CODIGO_IBGE: 3134509,
      },
      {
        NOME: "Jaboticatubas",
        CODIGO_IBGE: 3134608,
      },
      {
        NOME: "Jacinto",
        CODIGO_IBGE: 3134707,
      },
      {
        NOME: "Jacuí",
        CODIGO_IBGE: 3134806,
      },
      {
        NOME: "Jacutinga",
        CODIGO_IBGE: 3134905,
      },
      {
        NOME: "Jaguaraçu",
        CODIGO_IBGE: 3135001,
      },
      {
        NOME: "Jaíba",
        CODIGO_IBGE: 3135050,
      },
      {
        NOME: "Jampruca",
        CODIGO_IBGE: 3135076,
      },
      {
        NOME: "Janaúba",
        CODIGO_IBGE: 3135100,
      },
      {
        NOME: "Januária",
        CODIGO_IBGE: 3135209,
      },
      {
        NOME: "Japaraíba",
        CODIGO_IBGE: 3135308,
      },
      {
        NOME: "Japonvar",
        CODIGO_IBGE: 3135357,
      },
      {
        NOME: "Jeceaba",
        CODIGO_IBGE: 3135407,
      },
      {
        NOME: "Jenipapo de Minas",
        CODIGO_IBGE: 3135456,
      },
      {
        NOME: "Jequeri",
        CODIGO_IBGE: 3135506,
      },
      {
        NOME: "Jequitaí",
        CODIGO_IBGE: 3135605,
      },
      {
        NOME: "Jequitibá",
        CODIGO_IBGE: 3135704,
      },
      {
        NOME: "Jequitinhonha",
        CODIGO_IBGE: 3135803,
      },
      {
        NOME: "Jesuânia",
        CODIGO_IBGE: 3135902,
      },
      {
        NOME: "Joaíma",
        CODIGO_IBGE: 3136009,
      },
      {
        NOME: "Joanésia",
        CODIGO_IBGE: 3136108,
      },
      {
        NOME: "João Monlevade",
        CODIGO_IBGE: 3136207,
      },
      {
        NOME: "João Pinheiro",
        CODIGO_IBGE: 3136306,
      },
      {
        NOME: "Joaquim Felício",
        CODIGO_IBGE: 3136405,
      },
      {
        NOME: "Jordânia",
        CODIGO_IBGE: 3136504,
      },
      {
        NOME: "José Gonçalves de Minas",
        CODIGO_IBGE: 3136520,
      },
      {
        NOME: "José Raydan",
        CODIGO_IBGE: 3136553,
      },
      {
        NOME: "Josenópolis",
        CODIGO_IBGE: 3136579,
      },
      {
        NOME: "Nova União",
        CODIGO_IBGE: 3136603,
      },
      {
        NOME: "Juatuba",
        CODIGO_IBGE: 3136652,
      },
      {
        NOME: "Juiz de Fora",
        CODIGO_IBGE: 3136702,
      },
      {
        NOME: "Juramento",
        CODIGO_IBGE: 3136801,
      },
      {
        NOME: "Juruaia",
        CODIGO_IBGE: 3136900,
      },
      {
        NOME: "Juvenília",
        CODIGO_IBGE: 3136959,
      },
      {
        NOME: "Ladainha",
        CODIGO_IBGE: 3137007,
      },
      {
        NOME: "Lagamar",
        CODIGO_IBGE: 3137106,
      },
      {
        NOME: "Lagoa da Prata",
        CODIGO_IBGE: 3137205,
      },
      {
        NOME: "Lagoa dos Patos",
        CODIGO_IBGE: 3137304,
      },
      {
        NOME: "Lagoa Dourada",
        CODIGO_IBGE: 3137403,
      },
      {
        NOME: "Lagoa Formosa",
        CODIGO_IBGE: 3137502,
      },
      {
        NOME: "Lagoa Grande",
        CODIGO_IBGE: 3137536,
      },
      {
        NOME: "Lagoa Santa",
        CODIGO_IBGE: 3137601,
      },
      {
        NOME: "Lajinha",
        CODIGO_IBGE: 3137700,
      },
      {
        NOME: "Lambari",
        CODIGO_IBGE: 3137809,
      },
      {
        NOME: "Lamim",
        CODIGO_IBGE: 3137908,
      },
      {
        NOME: "Laranjal",
        CODIGO_IBGE: 3138005,
      },
      {
        NOME: "Lassance",
        CODIGO_IBGE: 3138104,
      },
      {
        NOME: "Lavras",
        CODIGO_IBGE: 3138203,
      },
      {
        NOME: "Leandro Ferreira",
        CODIGO_IBGE: 3138302,
      },
      {
        NOME: "Leme do Prado",
        CODIGO_IBGE: 3138351,
      },
      {
        NOME: "Leopoldina",
        CODIGO_IBGE: 3138401,
      },
      {
        NOME: "Liberdade",
        CODIGO_IBGE: 3138500,
      },
      {
        NOME: "Lima Duarte",
        CODIGO_IBGE: 3138609,
      },
      {
        NOME: "Limeira do Oeste",
        CODIGO_IBGE: 3138625,
      },
      {
        NOME: "Lontra",
        CODIGO_IBGE: 3138658,
      },
      {
        NOME: "Luisburgo",
        CODIGO_IBGE: 3138674,
      },
      {
        NOME: "Luislândia",
        CODIGO_IBGE: 3138682,
      },
      {
        NOME: "Luminárias",
        CODIGO_IBGE: 3138708,
      },
      {
        NOME: "Luz",
        CODIGO_IBGE: 3138807,
      },
      {
        NOME: "Machacalis",
        CODIGO_IBGE: 3138906,
      },
      {
        NOME: "Machado",
        CODIGO_IBGE: 3139003,
      },
      {
        NOME: "Madre de Deus de Minas",
        CODIGO_IBGE: 3139102,
      },
      {
        NOME: "Malacacheta",
        CODIGO_IBGE: 3139201,
      },
      {
        NOME: "Mamonas",
        CODIGO_IBGE: 3139250,
      },
      {
        NOME: "Manga",
        CODIGO_IBGE: 3139300,
      },
      {
        NOME: "Manhuaçu",
        CODIGO_IBGE: 3139409,
      },
      {
        NOME: "Manhumirim",
        CODIGO_IBGE: 3139508,
      },
      {
        NOME: "Mantena",
        CODIGO_IBGE: 3139607,
      },
      {
        NOME: "Maravilhas",
        CODIGO_IBGE: 3139706,
      },
      {
        NOME: "Mar de Espanha",
        CODIGO_IBGE: 3139805,
      },
      {
        NOME: "Maria da Fé",
        CODIGO_IBGE: 3139904,
      },
      {
        NOME: "Mariana",
        CODIGO_IBGE: 3140001,
      },
      {
        NOME: "Marilac",
        CODIGO_IBGE: 3140100,
      },
      {
        NOME: "Mário Campos",
        CODIGO_IBGE: 3140159,
      },
      {
        NOME: "Maripá de Minas",
        CODIGO_IBGE: 3140209,
      },
      {
        NOME: "Marliéria",
        CODIGO_IBGE: 3140308,
      },
      {
        NOME: "Marmelópolis",
        CODIGO_IBGE: 3140407,
      },
      {
        NOME: "Martinho Campos",
        CODIGO_IBGE: 3140506,
      },
      {
        NOME: "Martins Soares",
        CODIGO_IBGE: 3140530,
      },
      {
        NOME: "Mata Verde",
        CODIGO_IBGE: 3140555,
      },
      {
        NOME: "Materlândia",
        CODIGO_IBGE: 3140605,
      },
      {
        NOME: "Mateus Leme",
        CODIGO_IBGE: 3140704,
      },
      {
        NOME: "Matias Barbosa",
        CODIGO_IBGE: 3140803,
      },
      {
        NOME: "Matias Cardoso",
        CODIGO_IBGE: 3140852,
      },
      {
        NOME: "Matipó",
        CODIGO_IBGE: 3140902,
      },
      {
        NOME: "Mato Verde",
        CODIGO_IBGE: 3141009,
      },
      {
        NOME: "Matozinhos",
        CODIGO_IBGE: 3141108,
      },
      {
        NOME: "Matutina",
        CODIGO_IBGE: 3141207,
      },
      {
        NOME: "Medeiros",
        CODIGO_IBGE: 3141306,
      },
      {
        NOME: "Medina",
        CODIGO_IBGE: 3141405,
      },
      {
        NOME: "Mendes Pimentel",
        CODIGO_IBGE: 3141504,
      },
      {
        NOME: "Mercês",
        CODIGO_IBGE: 3141603,
      },
      {
        NOME: "Mesquita",
        CODIGO_IBGE: 3141702,
      },
      {
        NOME: "Minas Novas",
        CODIGO_IBGE: 3141801,
      },
      {
        NOME: "Minduri",
        CODIGO_IBGE: 3141900,
      },
      {
        NOME: "Mirabela",
        CODIGO_IBGE: 3142007,
      },
      {
        NOME: "Miradouro",
        CODIGO_IBGE: 3142106,
      },
      {
        NOME: "Miraí",
        CODIGO_IBGE: 3142205,
      },
      {
        NOME: "Miravânia",
        CODIGO_IBGE: 3142254,
      },
      {
        NOME: "Moeda",
        CODIGO_IBGE: 3142304,
      },
      {
        NOME: "Moema",
        CODIGO_IBGE: 3142403,
      },
      {
        NOME: "Monjolos",
        CODIGO_IBGE: 3142502,
      },
      {
        NOME: "Monsenhor Paulo",
        CODIGO_IBGE: 3142601,
      },
      {
        NOME: "Montalvânia",
        CODIGO_IBGE: 3142700,
      },
      {
        NOME: "Monte Alegre de Minas",
        CODIGO_IBGE: 3142809,
      },
      {
        NOME: "Monte Azul",
        CODIGO_IBGE: 3142908,
      },
      {
        NOME: "Monte Belo",
        CODIGO_IBGE: 3143005,
      },
      {
        NOME: "Monte Carmelo",
        CODIGO_IBGE: 3143104,
      },
      {
        NOME: "Monte Formoso",
        CODIGO_IBGE: 3143153,
      },
      {
        NOME: "Monte Santo de Minas",
        CODIGO_IBGE: 3143203,
      },
      {
        NOME: "Montes Claros",
        CODIGO_IBGE: 3143302,
      },
      {
        NOME: "Monte Sião",
        CODIGO_IBGE: 3143401,
      },
      {
        NOME: "Montezuma",
        CODIGO_IBGE: 3143450,
      },
      {
        NOME: "Morada Nova de Minas",
        CODIGO_IBGE: 3143500,
      },
      {
        NOME: "Morro da Garça",
        CODIGO_IBGE: 3143609,
      },
      {
        NOME: "Morro do Pilar",
        CODIGO_IBGE: 3143708,
      },
      {
        NOME: "Munhoz",
        CODIGO_IBGE: 3143807,
      },
      {
        NOME: "Muriaé",
        CODIGO_IBGE: 3143906,
      },
      {
        NOME: "Mutum",
        CODIGO_IBGE: 3144003,
      },
      {
        NOME: "Muzambinho",
        CODIGO_IBGE: 3144102,
      },
      {
        NOME: "Nacip Raydan",
        CODIGO_IBGE: 3144201,
      },
      {
        NOME: "Nanuque",
        CODIGO_IBGE: 3144300,
      },
      {
        NOME: "Naque",
        CODIGO_IBGE: 3144359,
      },
      {
        NOME: "Natalândia",
        CODIGO_IBGE: 3144375,
      },
      {
        NOME: "Natércia",
        CODIGO_IBGE: 3144409,
      },
      {
        NOME: "Nazareno",
        CODIGO_IBGE: 3144508,
      },
      {
        NOME: "Nepomuceno",
        CODIGO_IBGE: 3144607,
      },
      {
        NOME: "Ninheira",
        CODIGO_IBGE: 3144656,
      },
      {
        NOME: "Nova Belém",
        CODIGO_IBGE: 3144672,
      },
      {
        NOME: "Nova Era",
        CODIGO_IBGE: 3144706,
      },
      {
        NOME: "Nova Lima",
        CODIGO_IBGE: 3144805,
      },
      {
        NOME: "Nova Módica",
        CODIGO_IBGE: 3144904,
      },
      {
        NOME: "Nova Ponte",
        CODIGO_IBGE: 3145000,
      },
      {
        NOME: "Nova Porteirinha",
        CODIGO_IBGE: 3145059,
      },
      {
        NOME: "Nova Resende",
        CODIGO_IBGE: 3145109,
      },
      {
        NOME: "Nova Serrana",
        CODIGO_IBGE: 3145208,
      },
      {
        NOME: "Novo Cruzeiro",
        CODIGO_IBGE: 3145307,
      },
      {
        NOME: "Novo Oriente de Minas",
        CODIGO_IBGE: 3145356,
      },
      {
        NOME: "Novorizonte",
        CODIGO_IBGE: 3145372,
      },
      {
        NOME: "Olaria",
        CODIGO_IBGE: 3145406,
      },
      {
        NOME: "Olhos-d'Água",
        CODIGO_IBGE: 3145455,
      },
      {
        NOME: "Olímpio Noronha",
        CODIGO_IBGE: 3145505,
      },
      {
        NOME: "Oliveira",
        CODIGO_IBGE: 3145604,
      },
      {
        NOME: "Oliveira Fortes",
        CODIGO_IBGE: 3145703,
      },
      {
        NOME: "Onça de Pitangui",
        CODIGO_IBGE: 3145802,
      },
      {
        NOME: "Oratórios",
        CODIGO_IBGE: 3145851,
      },
      {
        NOME: "Orizânia",
        CODIGO_IBGE: 3145877,
      },
      {
        NOME: "Ouro Branco",
        CODIGO_IBGE: 3145901,
      },
      {
        NOME: "Ouro Fino",
        CODIGO_IBGE: 3146008,
      },
      {
        NOME: "Ouro Preto",
        CODIGO_IBGE: 3146107,
      },
      {
        NOME: "Ouro Verde de Minas",
        CODIGO_IBGE: 3146206,
      },
      {
        NOME: "Padre Carvalho",
        CODIGO_IBGE: 3146255,
      },
      {
        NOME: "Padre Paraíso",
        CODIGO_IBGE: 3146305,
      },
      {
        NOME: "Paineiras",
        CODIGO_IBGE: 3146404,
      },
      {
        NOME: "Pains",
        CODIGO_IBGE: 3146503,
      },
      {
        NOME: "Pai Pedro",
        CODIGO_IBGE: 3146552,
      },
      {
        NOME: "Paiva",
        CODIGO_IBGE: 3146602,
      },
      {
        NOME: "Palma",
        CODIGO_IBGE: 3146701,
      },
      {
        NOME: "Palmópolis",
        CODIGO_IBGE: 3146750,
      },
      {
        NOME: "Papagaios",
        CODIGO_IBGE: 3146909,
      },
      {
        NOME: "Paracatu",
        CODIGO_IBGE: 3147006,
      },
      {
        NOME: "Pará de Minas",
        CODIGO_IBGE: 3147105,
      },
      {
        NOME: "Paraguaçu",
        CODIGO_IBGE: 3147204,
      },
      {
        NOME: "Paraisópolis",
        CODIGO_IBGE: 3147303,
      },
      {
        NOME: "Paraopeba",
        CODIGO_IBGE: 3147402,
      },
      {
        NOME: "Passabém",
        CODIGO_IBGE: 3147501,
      },
      {
        NOME: "Passa Quatro",
        CODIGO_IBGE: 3147600,
      },
      {
        NOME: "Passa Tempo",
        CODIGO_IBGE: 3147709,
      },
      {
        NOME: "Passa Vinte",
        CODIGO_IBGE: 3147808,
      },
      {
        NOME: "Passos",
        CODIGO_IBGE: 3147907,
      },
      {
        NOME: "Patis",
        CODIGO_IBGE: 3147956,
      },
      {
        NOME: "Patos de Minas",
        CODIGO_IBGE: 3148004,
      },
      {
        NOME: "Patrocínio",
        CODIGO_IBGE: 3148103,
      },
      {
        NOME: "Patrocínio do Muriaé",
        CODIGO_IBGE: 3148202,
      },
      {
        NOME: "Paula Cândido",
        CODIGO_IBGE: 3148301,
      },
      {
        NOME: "Paulistas",
        CODIGO_IBGE: 3148400,
      },
      {
        NOME: "Pavão",
        CODIGO_IBGE: 3148509,
      },
      {
        NOME: "Peçanha",
        CODIGO_IBGE: 3148608,
      },
      {
        NOME: "Pedra Azul",
        CODIGO_IBGE: 3148707,
      },
      {
        NOME: "Pedra Bonita",
        CODIGO_IBGE: 3148756,
      },
      {
        NOME: "Pedra do Anta",
        CODIGO_IBGE: 3148806,
      },
      {
        NOME: "Pedra do Indaiá",
        CODIGO_IBGE: 3148905,
      },
      {
        NOME: "Pedra Dourada",
        CODIGO_IBGE: 3149002,
      },
      {
        NOME: "Pedralva",
        CODIGO_IBGE: 3149101,
      },
      {
        NOME: "Pedras de Maria da Cruz",
        CODIGO_IBGE: 3149150,
      },
      {
        NOME: "Pedrinópolis",
        CODIGO_IBGE: 3149200,
      },
      {
        NOME: "Pedro Leopoldo",
        CODIGO_IBGE: 3149309,
      },
      {
        NOME: "Pedro Teixeira",
        CODIGO_IBGE: 3149408,
      },
      {
        NOME: "Pequeri",
        CODIGO_IBGE: 3149507,
      },
      {
        NOME: "Pequi",
        CODIGO_IBGE: 3149606,
      },
      {
        NOME: "Perdigão",
        CODIGO_IBGE: 3149705,
      },
      {
        NOME: "Perdizes",
        CODIGO_IBGE: 3149804,
      },
      {
        NOME: "Perdões",
        CODIGO_IBGE: 3149903,
      },
      {
        NOME: "Periquito",
        CODIGO_IBGE: 3149952,
      },
      {
        NOME: "Pescador",
        CODIGO_IBGE: 3150000,
      },
      {
        NOME: "Piau",
        CODIGO_IBGE: 3150109,
      },
      {
        NOME: "Piedade de Caratinga",
        CODIGO_IBGE: 3150158,
      },
      {
        NOME: "Piedade de Ponte Nova",
        CODIGO_IBGE: 3150208,
      },
      {
        NOME: "Piedade do Rio Grande",
        CODIGO_IBGE: 3150307,
      },
      {
        NOME: "Piedade dos Gerais",
        CODIGO_IBGE: 3150406,
      },
      {
        NOME: "Pimenta",
        CODIGO_IBGE: 3150505,
      },
      {
        NOME: "Pingo d'Água",
        CODIGO_IBGE: 3150539,
      },
      {
        NOME: "Pintópolis",
        CODIGO_IBGE: 3150570,
      },
      {
        NOME: "Piracema",
        CODIGO_IBGE: 3150604,
      },
      {
        NOME: "Pirajuba",
        CODIGO_IBGE: 3150703,
      },
      {
        NOME: "Piranga",
        CODIGO_IBGE: 3150802,
      },
      {
        NOME: "Piranguçu",
        CODIGO_IBGE: 3150901,
      },
      {
        NOME: "Piranguinho",
        CODIGO_IBGE: 3151008,
      },
      {
        NOME: "Pirapetinga",
        CODIGO_IBGE: 3151107,
      },
      {
        NOME: "Pirapora",
        CODIGO_IBGE: 3151206,
      },
      {
        NOME: "Piraúba",
        CODIGO_IBGE: 3151305,
      },
      {
        NOME: "Pitangui",
        CODIGO_IBGE: 3151404,
      },
      {
        NOME: "Piumhi",
        CODIGO_IBGE: 3151503,
      },
      {
        NOME: "Planura",
        CODIGO_IBGE: 3151602,
      },
      {
        NOME: "Poço Fundo",
        CODIGO_IBGE: 3151701,
      },
      {
        NOME: "Poços de Caldas",
        CODIGO_IBGE: 3151800,
      },
      {
        NOME: "Pocrane",
        CODIGO_IBGE: 3151909,
      },
      {
        NOME: "Pompéu",
        CODIGO_IBGE: 3152006,
      },
      {
        NOME: "Ponte Nova",
        CODIGO_IBGE: 3152105,
      },
      {
        NOME: "Ponto Chique",
        CODIGO_IBGE: 3152131,
      },
      {
        NOME: "Ponto dos Volantes",
        CODIGO_IBGE: 3152170,
      },
      {
        NOME: "Porteirinha",
        CODIGO_IBGE: 3152204,
      },
      {
        NOME: "Porto Firme",
        CODIGO_IBGE: 3152303,
      },
      {
        NOME: "Poté",
        CODIGO_IBGE: 3152402,
      },
      {
        NOME: "Pouso Alegre",
        CODIGO_IBGE: 3152501,
      },
      {
        NOME: "Pouso Alto",
        CODIGO_IBGE: 3152600,
      },
      {
        NOME: "Prados",
        CODIGO_IBGE: 3152709,
      },
      {
        NOME: "Prata",
        CODIGO_IBGE: 3152808,
      },
      {
        NOME: "Pratápolis",
        CODIGO_IBGE: 3152907,
      },
      {
        NOME: "Pratinha",
        CODIGO_IBGE: 3153004,
      },
      {
        NOME: "Presidente Bernardes",
        CODIGO_IBGE: 3153103,
      },
      {
        NOME: "Presidente Juscelino",
        CODIGO_IBGE: 3153202,
      },
      {
        NOME: "Presidente Kubitschek",
        CODIGO_IBGE: 3153301,
      },
      {
        NOME: "Presidente Olegário",
        CODIGO_IBGE: 3153400,
      },
      {
        NOME: "Alto Jequitibá",
        CODIGO_IBGE: 3153509,
      },
      {
        NOME: "Prudente de Morais",
        CODIGO_IBGE: 3153608,
      },
      {
        NOME: "Quartel Geral",
        CODIGO_IBGE: 3153707,
      },
      {
        NOME: "Queluzito",
        CODIGO_IBGE: 3153806,
      },
      {
        NOME: "Raposos",
        CODIGO_IBGE: 3153905,
      },
      {
        NOME: "Raul Soares",
        CODIGO_IBGE: 3154002,
      },
      {
        NOME: "Recreio",
        CODIGO_IBGE: 3154101,
      },
      {
        NOME: "Reduto",
        CODIGO_IBGE: 3154150,
      },
      {
        NOME: "Resende Costa",
        CODIGO_IBGE: 3154200,
      },
      {
        NOME: "Resplendor",
        CODIGO_IBGE: 3154309,
      },
      {
        NOME: "Ressaquinha",
        CODIGO_IBGE: 3154408,
      },
      {
        NOME: "Riachinho",
        CODIGO_IBGE: 3154457,
      },
      {
        NOME: "Riacho dos Machados",
        CODIGO_IBGE: 3154507,
      },
      {
        NOME: "Ribeirão das Neves",
        CODIGO_IBGE: 3154606,
      },
      {
        NOME: "Ribeirão Vermelho",
        CODIGO_IBGE: 3154705,
      },
      {
        NOME: "Rio Acima",
        CODIGO_IBGE: 3154804,
      },
      {
        NOME: "Rio Casca",
        CODIGO_IBGE: 3154903,
      },
      {
        NOME: "Rio Doce",
        CODIGO_IBGE: 3155009,
      },
      {
        NOME: "Rio do Prado",
        CODIGO_IBGE: 3155108,
      },
      {
        NOME: "Rio Espera",
        CODIGO_IBGE: 3155207,
      },
      {
        NOME: "Rio Manso",
        CODIGO_IBGE: 3155306,
      },
      {
        NOME: "Rio Novo",
        CODIGO_IBGE: 3155405,
      },
      {
        NOME: "Rio Paranaíba",
        CODIGO_IBGE: 3155504,
      },
      {
        NOME: "Rio Pardo de Minas",
        CODIGO_IBGE: 3155603,
      },
      {
        NOME: "Rio Piracicaba",
        CODIGO_IBGE: 3155702,
      },
      {
        NOME: "Rio Pomba",
        CODIGO_IBGE: 3155801,
      },
      {
        NOME: "Rio Preto",
        CODIGO_IBGE: 3155900,
      },
      {
        NOME: "Rio Vermelho",
        CODIGO_IBGE: 3156007,
      },
      {
        NOME: "Ritápolis",
        CODIGO_IBGE: 3156106,
      },
      {
        NOME: "Rochedo de Minas",
        CODIGO_IBGE: 3156205,
      },
      {
        NOME: "Rodeiro",
        CODIGO_IBGE: 3156304,
      },
      {
        NOME: "Romaria",
        CODIGO_IBGE: 3156403,
      },
      {
        NOME: "Rosário da Limeira",
        CODIGO_IBGE: 3156452,
      },
      {
        NOME: "Rubelita",
        CODIGO_IBGE: 3156502,
      },
      {
        NOME: "Rubim",
        CODIGO_IBGE: 3156601,
      },
      {
        NOME: "Sabará",
        CODIGO_IBGE: 3156700,
      },
      {
        NOME: "Sabinópolis",
        CODIGO_IBGE: 3156809,
      },
      {
        NOME: "Sacramento",
        CODIGO_IBGE: 3156908,
      },
      {
        NOME: "Salinas",
        CODIGO_IBGE: 3157005,
      },
      {
        NOME: "Salto da Divisa",
        CODIGO_IBGE: 3157104,
      },
      {
        NOME: "Santa Bárbara",
        CODIGO_IBGE: 3157203,
      },
      {
        NOME: "Santa Bárbara do Leste",
        CODIGO_IBGE: 3157252,
      },
      {
        NOME: "Santa Bárbara do Monte Verde",
        CODIGO_IBGE: 3157278,
      },
      {
        NOME: "Santa Bárbara do Tugúrio",
        CODIGO_IBGE: 3157302,
      },
      {
        NOME: "Santa Cruz de Minas",
        CODIGO_IBGE: 3157336,
      },
      {
        NOME: "Santa Cruz de Salinas",
        CODIGO_IBGE: 3157377,
      },
      {
        NOME: "Santa Cruz do Escalvado",
        CODIGO_IBGE: 3157401,
      },
      {
        NOME: "Santa Efigênia de Minas",
        CODIGO_IBGE: 3157500,
      },
      {
        NOME: "Santa Fé de Minas",
        CODIGO_IBGE: 3157609,
      },
      {
        NOME: "Santa Helena de Minas",
        CODIGO_IBGE: 3157658,
      },
      {
        NOME: "Santa Juliana",
        CODIGO_IBGE: 3157708,
      },
      {
        NOME: "Santa Luzia",
        CODIGO_IBGE: 3157807,
      },
      {
        NOME: "Santa Margarida",
        CODIGO_IBGE: 3157906,
      },
      {
        NOME: "Santa Maria de Itabira",
        CODIGO_IBGE: 3158003,
      },
      {
        NOME: "Santa Maria do Salto",
        CODIGO_IBGE: 3158102,
      },
      {
        NOME: "Santa Maria do Suaçuí",
        CODIGO_IBGE: 3158201,
      },
      {
        NOME: "Santana da Vargem",
        CODIGO_IBGE: 3158300,
      },
      {
        NOME: "Santana de Cataguases",
        CODIGO_IBGE: 3158409,
      },
      {
        NOME: "Santana de Pirapama",
        CODIGO_IBGE: 3158508,
      },
      {
        NOME: "Santana do Deserto",
        CODIGO_IBGE: 3158607,
      },
      {
        NOME: "Santana do Garambéu",
        CODIGO_IBGE: 3158706,
      },
      {
        NOME: "Santana do Jacaré",
        CODIGO_IBGE: 3158805,
      },
      {
        NOME: "Santana do Manhuaçu",
        CODIGO_IBGE: 3158904,
      },
      {
        NOME: "Santana do Paraíso",
        CODIGO_IBGE: 3158953,
      },
      {
        NOME: "Santana do Riacho",
        CODIGO_IBGE: 3159001,
      },
      {
        NOME: "Santana dos Montes",
        CODIGO_IBGE: 3159100,
      },
      {
        NOME: "Santa Rita de Caldas",
        CODIGO_IBGE: 3159209,
      },
      {
        NOME: "Santa Rita de Jacutinga",
        CODIGO_IBGE: 3159308,
      },
      {
        NOME: "Santa Rita de Minas",
        CODIGO_IBGE: 3159357,
      },
      {
        NOME: "Santa Rita de Ibitipoca",
        CODIGO_IBGE: 3159407,
      },
      {
        NOME: "Santa Rita do Itueto",
        CODIGO_IBGE: 3159506,
      },
      {
        NOME: "Santa Rita do Sapucaí",
        CODIGO_IBGE: 3159605,
      },
      {
        NOME: "Santa Rosa da Serra",
        CODIGO_IBGE: 3159704,
      },
      {
        NOME: "Santa Vitória",
        CODIGO_IBGE: 3159803,
      },
      {
        NOME: "Santo Antônio do Amparo",
        CODIGO_IBGE: 3159902,
      },
      {
        NOME: "Santo Antônio do Aventureiro",
        CODIGO_IBGE: 3160009,
      },
      {
        NOME: "Santo Antônio do Grama",
        CODIGO_IBGE: 3160108,
      },
      {
        NOME: "Santo Antônio do Itambé",
        CODIGO_IBGE: 3160207,
      },
      {
        NOME: "Santo Antônio do Jacinto",
        CODIGO_IBGE: 3160306,
      },
      {
        NOME: "Santo Antônio do Monte",
        CODIGO_IBGE: 3160405,
      },
      {
        NOME: "Santo Antônio do Retiro",
        CODIGO_IBGE: 3160454,
      },
      {
        NOME: "Santo Antônio do Rio Abaixo",
        CODIGO_IBGE: 3160504,
      },
      {
        NOME: "Santo Hipólito",
        CODIGO_IBGE: 3160603,
      },
      {
        NOME: "Santos Dumont",
        CODIGO_IBGE: 3160702,
      },
      {
        NOME: "São Bento Abade",
        CODIGO_IBGE: 3160801,
      },
      {
        NOME: "São Brás do Suaçuí",
        CODIGO_IBGE: 3160900,
      },
      {
        NOME: "São Domingos das Dores",
        CODIGO_IBGE: 3160959,
      },
      {
        NOME: "São Domingos do Prata",
        CODIGO_IBGE: 3161007,
      },
      {
        NOME: "São Félix de Minas",
        CODIGO_IBGE: 3161056,
      },
      {
        NOME: "São Francisco",
        CODIGO_IBGE: 3161106,
      },
      {
        NOME: "São Francisco de Paula",
        CODIGO_IBGE: 3161205,
      },
      {
        NOME: "São Francisco de Sales",
        CODIGO_IBGE: 3161304,
      },
      {
        NOME: "São Francisco do Glória",
        CODIGO_IBGE: 3161403,
      },
      {
        NOME: "São Geraldo",
        CODIGO_IBGE: 3161502,
      },
      {
        NOME: "São Geraldo da Piedade",
        CODIGO_IBGE: 3161601,
      },
      {
        NOME: "São Geraldo do Baixio",
        CODIGO_IBGE: 3161650,
      },
      {
        NOME: "São Gonçalo do Abaeté",
        CODIGO_IBGE: 3161700,
      },
      {
        NOME: "São Gonçalo do Pará",
        CODIGO_IBGE: 3161809,
      },
      {
        NOME: "São Gonçalo do Rio Abaixo",
        CODIGO_IBGE: 3161908,
      },
      {
        NOME: "São Gonçalo do Sapucaí",
        CODIGO_IBGE: 3162005,
      },
      {
        NOME: "São Gotardo",
        CODIGO_IBGE: 3162104,
      },
      {
        NOME: "São João Batista do Glória",
        CODIGO_IBGE: 3162203,
      },
      {
        NOME: "São João da Lagoa",
        CODIGO_IBGE: 3162252,
      },
      {
        NOME: "São João da Mata",
        CODIGO_IBGE: 3162302,
      },
      {
        NOME: "São João da Ponte",
        CODIGO_IBGE: 3162401,
      },
      {
        NOME: "São João das Missões",
        CODIGO_IBGE: 3162450,
      },
      {
        NOME: "São João del Rei",
        CODIGO_IBGE: 3162500,
      },
      {
        NOME: "São João do Manhuaçu",
        CODIGO_IBGE: 3162559,
      },
      {
        NOME: "São João do Manteninha",
        CODIGO_IBGE: 3162575,
      },
      {
        NOME: "São João do Oriente",
        CODIGO_IBGE: 3162609,
      },
      {
        NOME: "São João do Pacuí",
        CODIGO_IBGE: 3162658,
      },
      {
        NOME: "São João do Paraíso",
        CODIGO_IBGE: 3162708,
      },
      {
        NOME: "São João Evangelista",
        CODIGO_IBGE: 3162807,
      },
      {
        NOME: "São João Nepomuceno",
        CODIGO_IBGE: 3162906,
      },
      {
        NOME: "São Joaquim de Bicas",
        CODIGO_IBGE: 3162922,
      },
      {
        NOME: "São José da Barra",
        CODIGO_IBGE: 3162948,
      },
      {
        NOME: "São José da Lapa",
        CODIGO_IBGE: 3162955,
      },
      {
        NOME: "São José da Safira",
        CODIGO_IBGE: 3163003,
      },
      {
        NOME: "São José da Varginha",
        CODIGO_IBGE: 3163102,
      },
      {
        NOME: "São José do Alegre",
        CODIGO_IBGE: 3163201,
      },
      {
        NOME: "São José do Divino",
        CODIGO_IBGE: 3163300,
      },
      {
        NOME: "São José do Goiabal",
        CODIGO_IBGE: 3163409,
      },
      {
        NOME: "São José do Jacuri",
        CODIGO_IBGE: 3163508,
      },
      {
        NOME: "São José do Mantimento",
        CODIGO_IBGE: 3163607,
      },
      {
        NOME: "São Lourenço",
        CODIGO_IBGE: 3163706,
      },
      {
        NOME: "São Miguel do Anta",
        CODIGO_IBGE: 3163805,
      },
      {
        NOME: "São Pedro da União",
        CODIGO_IBGE: 3163904,
      },
      {
        NOME: "São Pedro dos Ferros",
        CODIGO_IBGE: 3164001,
      },
      {
        NOME: "São Pedro do Suaçuí",
        CODIGO_IBGE: 3164100,
      },
      {
        NOME: "São Romão",
        CODIGO_IBGE: 3164209,
      },
      {
        NOME: "São Roque de Minas",
        CODIGO_IBGE: 3164308,
      },
      {
        NOME: "São Sebastião da Bela Vista",
        CODIGO_IBGE: 3164407,
      },
      {
        NOME: "São Sebastião da Vargem Alegre",
        CODIGO_IBGE: 3164431,
      },
      {
        NOME: "São Sebastião do Anta",
        CODIGO_IBGE: 3164472,
      },
      {
        NOME: "São Sebastião do Maranhão",
        CODIGO_IBGE: 3164506,
      },
      {
        NOME: "São Sebastião do Oeste",
        CODIGO_IBGE: 3164605,
      },
      {
        NOME: "São Sebastião do Paraíso",
        CODIGO_IBGE: 3164704,
      },
      {
        NOME: "São Sebastião do Rio Preto",
        CODIGO_IBGE: 3164803,
      },
      {
        NOME: "São Sebastião do Rio Verde",
        CODIGO_IBGE: 3164902,
      },
      {
        NOME: "São Tiago",
        CODIGO_IBGE: 3165008,
      },
      {
        NOME: "São Tomás de Aquino",
        CODIGO_IBGE: 3165107,
      },
      {
        NOME: "São Thomé das Letras",
        CODIGO_IBGE: 3165206,
      },
      {
        NOME: "São Vicente de Minas",
        CODIGO_IBGE: 3165305,
      },
      {
        NOME: "Sapucaí-Mirim",
        CODIGO_IBGE: 3165404,
      },
      {
        NOME: "Sardoá",
        CODIGO_IBGE: 3165503,
      },
      {
        NOME: "Sarzedo",
        CODIGO_IBGE: 3165537,
      },
      {
        NOME: "Setubinha",
        CODIGO_IBGE: 3165552,
      },
      {
        NOME: "Sem-Peixe",
        CODIGO_IBGE: 3165560,
      },
      {
        NOME: "Senador Amaral",
        CODIGO_IBGE: 3165578,
      },
      {
        NOME: "Senador Cortes",
        CODIGO_IBGE: 3165602,
      },
      {
        NOME: "Senador Firmino",
        CODIGO_IBGE: 3165701,
      },
      {
        NOME: "Senador José Bento",
        CODIGO_IBGE: 3165800,
      },
      {
        NOME: "Senador Modestino Gonçalves",
        CODIGO_IBGE: 3165909,
      },
      {
        NOME: "Senhora de Oliveira",
        CODIGO_IBGE: 3166006,
      },
      {
        NOME: "Senhora do Porto",
        CODIGO_IBGE: 3166105,
      },
      {
        NOME: "Senhora dos Remédios",
        CODIGO_IBGE: 3166204,
      },
      {
        NOME: "Sericita",
        CODIGO_IBGE: 3166303,
      },
      {
        NOME: "Seritinga",
        CODIGO_IBGE: 3166402,
      },
      {
        NOME: "Serra Azul de Minas",
        CODIGO_IBGE: 3166501,
      },
      {
        NOME: "Serra da Saudade",
        CODIGO_IBGE: 3166600,
      },
      {
        NOME: "Serra dos Aimorés",
        CODIGO_IBGE: 3166709,
      },
      {
        NOME: "Serra do Salitre",
        CODIGO_IBGE: 3166808,
      },
      {
        NOME: "Serrania",
        CODIGO_IBGE: 3166907,
      },
      {
        NOME: "Serranópolis de Minas",
        CODIGO_IBGE: 3166956,
      },
      {
        NOME: "Serranos",
        CODIGO_IBGE: 3167004,
      },
      {
        NOME: "Serro",
        CODIGO_IBGE: 3167103,
      },
      {
        NOME: "Sete Lagoas",
        CODIGO_IBGE: 3167202,
      },
      {
        NOME: "Silveirânia",
        CODIGO_IBGE: 3167301,
      },
      {
        NOME: "Silvianópolis",
        CODIGO_IBGE: 3167400,
      },
      {
        NOME: "Simão Pereira",
        CODIGO_IBGE: 3167509,
      },
      {
        NOME: "Simonésia",
        CODIGO_IBGE: 3167608,
      },
      {
        NOME: "Sobrália",
        CODIGO_IBGE: 3167707,
      },
      {
        NOME: "Soledade de Minas",
        CODIGO_IBGE: 3167806,
      },
      {
        NOME: "Tabuleiro",
        CODIGO_IBGE: 3167905,
      },
      {
        NOME: "Taiobeiras",
        CODIGO_IBGE: 3168002,
      },
      {
        NOME: "Taparuba",
        CODIGO_IBGE: 3168051,
      },
      {
        NOME: "Tapira",
        CODIGO_IBGE: 3168101,
      },
      {
        NOME: "Tapiraí",
        CODIGO_IBGE: 3168200,
      },
      {
        NOME: "Taquaraçu de Minas",
        CODIGO_IBGE: 3168309,
      },
      {
        NOME: "Tarumirim",
        CODIGO_IBGE: 3168408,
      },
      {
        NOME: "Teixeiras",
        CODIGO_IBGE: 3168507,
      },
      {
        NOME: "Teófilo Otoni",
        CODIGO_IBGE: 3168606,
      },
      {
        NOME: "Timóteo",
        CODIGO_IBGE: 3168705,
      },
      {
        NOME: "Tiradentes",
        CODIGO_IBGE: 3168804,
      },
      {
        NOME: "Tiros",
        CODIGO_IBGE: 3168903,
      },
      {
        NOME: "Tocantins",
        CODIGO_IBGE: 3169000,
      },
      {
        NOME: "Tocos do Moji",
        CODIGO_IBGE: 3169059,
      },
      {
        NOME: "Toledo",
        CODIGO_IBGE: 3169109,
      },
      {
        NOME: "Tombos",
        CODIGO_IBGE: 3169208,
      },
      {
        NOME: "Três Corações",
        CODIGO_IBGE: 3169307,
      },
      {
        NOME: "Três Marias",
        CODIGO_IBGE: 3169356,
      },
      {
        NOME: "Três Pontas",
        CODIGO_IBGE: 3169406,
      },
      {
        NOME: "Tumiritinga",
        CODIGO_IBGE: 3169505,
      },
      {
        NOME: "Tupaciguara",
        CODIGO_IBGE: 3169604,
      },
      {
        NOME: "Turmalina",
        CODIGO_IBGE: 3169703,
      },
      {
        NOME: "Turvolândia",
        CODIGO_IBGE: 3169802,
      },
      {
        NOME: "Ubá",
        CODIGO_IBGE: 3169901,
      },
      {
        NOME: "Ubaí",
        CODIGO_IBGE: 3170008,
      },
      {
        NOME: "Ubaporanga",
        CODIGO_IBGE: 3170057,
      },
      {
        NOME: "Uberaba",
        CODIGO_IBGE: 3170107,
      },
      {
        NOME: "Uberlândia",
        CODIGO_IBGE: 3170206,
      },
      {
        NOME: "Umburatiba",
        CODIGO_IBGE: 3170305,
      },
      {
        NOME: "Unaí",
        CODIGO_IBGE: 3170404,
      },
      {
        NOME: "União de Minas",
        CODIGO_IBGE: 3170438,
      },
      {
        NOME: "Uruana de Minas",
        CODIGO_IBGE: 3170479,
      },
      {
        NOME: "Urucânia",
        CODIGO_IBGE: 3170503,
      },
      {
        NOME: "Urucuia",
        CODIGO_IBGE: 3170529,
      },
      {
        NOME: "Vargem Alegre",
        CODIGO_IBGE: 3170578,
      },
      {
        NOME: "Vargem Bonita",
        CODIGO_IBGE: 3170602,
      },
      {
        NOME: "Vargem Grande do Rio Pardo",
        CODIGO_IBGE: 3170651,
      },
      {
        NOME: "Varginha",
        CODIGO_IBGE: 3170701,
      },
      {
        NOME: "Varjão de Minas",
        CODIGO_IBGE: 3170750,
      },
      {
        NOME: "Várzea da Palma",
        CODIGO_IBGE: 3170800,
      },
      {
        NOME: "Varzelândia",
        CODIGO_IBGE: 3170909,
      },
      {
        NOME: "Vazante",
        CODIGO_IBGE: 3171006,
      },
      {
        NOME: "Verdelândia",
        CODIGO_IBGE: 3171030,
      },
      {
        NOME: "Veredinha",
        CODIGO_IBGE: 3171071,
      },
      {
        NOME: "Veríssimo",
        CODIGO_IBGE: 3171105,
      },
      {
        NOME: "Vermelho Novo",
        CODIGO_IBGE: 3171154,
      },
      {
        NOME: "Vespasiano",
        CODIGO_IBGE: 3171204,
      },
      {
        NOME: "Viçosa",
        CODIGO_IBGE: 3171303,
      },
      {
        NOME: "Vieiras",
        CODIGO_IBGE: 3171402,
      },
      {
        NOME: "Mathias Lobato",
        CODIGO_IBGE: 3171501,
      },
      {
        NOME: "Virgem da Lapa",
        CODIGO_IBGE: 3171600,
      },
      {
        NOME: "Virgínia",
        CODIGO_IBGE: 3171709,
      },
      {
        NOME: "Virginópolis",
        CODIGO_IBGE: 3171808,
      },
      {
        NOME: "Virgolândia",
        CODIGO_IBGE: 3171907,
      },
      {
        NOME: "Visconde do Rio Branco",
        CODIGO_IBGE: 3172004,
      },
      {
        NOME: "Volta Grande",
        CODIGO_IBGE: 3172103,
      },
      {
        NOME: "Wenceslau Braz",
        CODIGO_IBGE: 3172202,
      },
    ],
    32: [
      {
        NOME: "Afonso Cláudio",
        CODIGO_IBGE: 3200102,
      },
      {
        NOME: "Águia Branca",
        CODIGO_IBGE: 3200136,
      },
      {
        NOME: "Água Doce do Norte",
        CODIGO_IBGE: 3200169,
      },
      {
        NOME: "Alegre",
        CODIGO_IBGE: 3200201,
      },
      {
        NOME: "Alfredo Chaves",
        CODIGO_IBGE: 3200300,
      },
      {
        NOME: "Alto Rio Novo",
        CODIGO_IBGE: 3200359,
      },
      {
        NOME: "Anchieta",
        CODIGO_IBGE: 3200409,
      },
      {
        NOME: "Apiacá",
        CODIGO_IBGE: 3200508,
      },
      {
        NOME: "Aracruz",
        CODIGO_IBGE: 3200607,
      },
      {
        NOME: "Atílio Vivacqua",
        CODIGO_IBGE: 3200706,
      },
      {
        NOME: "Baixo Guandu",
        CODIGO_IBGE: 3200805,
      },
      {
        NOME: "Barra de São Francisco",
        CODIGO_IBGE: 3200904,
      },
      {
        NOME: "Boa Esperança",
        CODIGO_IBGE: 3201001,
      },
      {
        NOME: "Bom Jesus do Norte",
        CODIGO_IBGE: 3201100,
      },
      {
        NOME: "Brejetuba",
        CODIGO_IBGE: 3201159,
      },
      {
        NOME: "Cachoeiro de Itapemirim",
        CODIGO_IBGE: 3201209,
      },
      {
        NOME: "Cariacica",
        CODIGO_IBGE: 3201308,
      },
      {
        NOME: "Castelo",
        CODIGO_IBGE: 3201407,
      },
      {
        NOME: "Colatina",
        CODIGO_IBGE: 3201506,
      },
      {
        NOME: "Conceição da Barra",
        CODIGO_IBGE: 3201605,
      },
      {
        NOME: "Conceição do Castelo",
        CODIGO_IBGE: 3201704,
      },
      {
        NOME: "Divino de São Lourenço",
        CODIGO_IBGE: 3201803,
      },
      {
        NOME: "Domingos Martins",
        CODIGO_IBGE: 3201902,
      },
      {
        NOME: "Dores do Rio Preto",
        CODIGO_IBGE: 3202009,
      },
      {
        NOME: "Ecoporanga",
        CODIGO_IBGE: 3202108,
      },
      {
        NOME: "Fundão",
        CODIGO_IBGE: 3202207,
      },
      {
        NOME: "Governador Lindenberg",
        CODIGO_IBGE: 3202256,
      },
      {
        NOME: "Guaçuí",
        CODIGO_IBGE: 3202306,
      },
      {
        NOME: "Guarapari",
        CODIGO_IBGE: 3202405,
      },
      {
        NOME: "Ibatiba",
        CODIGO_IBGE: 3202454,
      },
      {
        NOME: "Ibiraçu",
        CODIGO_IBGE: 3202504,
      },
      {
        NOME: "Ibitirama",
        CODIGO_IBGE: 3202553,
      },
      {
        NOME: "Iconha",
        CODIGO_IBGE: 3202603,
      },
      {
        NOME: "Irupi",
        CODIGO_IBGE: 3202652,
      },
      {
        NOME: "Itaguaçu",
        CODIGO_IBGE: 3202702,
      },
      {
        NOME: "Itapemirim",
        CODIGO_IBGE: 3202801,
      },
      {
        NOME: "Itarana",
        CODIGO_IBGE: 3202900,
      },
      {
        NOME: "Iúna",
        CODIGO_IBGE: 3203007,
      },
      {
        NOME: "Jaguaré",
        CODIGO_IBGE: 3203056,
      },
      {
        NOME: "Jerônimo Monteiro",
        CODIGO_IBGE: 3203106,
      },
      {
        NOME: "João Neiva",
        CODIGO_IBGE: 3203130,
      },
      {
        NOME: "Laranja da Terra",
        CODIGO_IBGE: 3203163,
      },
      {
        NOME: "Linhares",
        CODIGO_IBGE: 3203205,
      },
      {
        NOME: "Mantenópolis",
        CODIGO_IBGE: 3203304,
      },
      {
        NOME: "Marataízes",
        CODIGO_IBGE: 3203320,
      },
      {
        NOME: "Marechal Floriano",
        CODIGO_IBGE: 3203346,
      },
      {
        NOME: "Marilândia",
        CODIGO_IBGE: 3203353,
      },
      {
        NOME: "Mimoso do Sul",
        CODIGO_IBGE: 3203403,
      },
      {
        NOME: "Montanha",
        CODIGO_IBGE: 3203502,
      },
      {
        NOME: "Mucurici",
        CODIGO_IBGE: 3203601,
      },
      {
        NOME: "Muniz Freire",
        CODIGO_IBGE: 3203700,
      },
      {
        NOME: "Muqui",
        CODIGO_IBGE: 3203809,
      },
      {
        NOME: "Nova Venécia",
        CODIGO_IBGE: 3203908,
      },
      {
        NOME: "Pancas",
        CODIGO_IBGE: 3204005,
      },
      {
        NOME: "Pedro Canário",
        CODIGO_IBGE: 3204054,
      },
      {
        NOME: "Pinheiros",
        CODIGO_IBGE: 3204104,
      },
      {
        NOME: "Piúma",
        CODIGO_IBGE: 3204203,
      },
      {
        NOME: "Ponto Belo",
        CODIGO_IBGE: 3204252,
      },
      {
        NOME: "Presidente Kennedy",
        CODIGO_IBGE: 3204302,
      },
      {
        NOME: "Rio Bananal",
        CODIGO_IBGE: 3204351,
      },
      {
        NOME: "Rio Novo do Sul",
        CODIGO_IBGE: 3204401,
      },
      {
        NOME: "Santa Leopoldina",
        CODIGO_IBGE: 3204500,
      },
      {
        NOME: "Santa Maria de Jetibá",
        CODIGO_IBGE: 3204559,
      },
      {
        NOME: "Santa Teresa",
        CODIGO_IBGE: 3204609,
      },
      {
        NOME: "São Domingos do Norte",
        CODIGO_IBGE: 3204658,
      },
      {
        NOME: "São Gabriel da Palha",
        CODIGO_IBGE: 3204708,
      },
      {
        NOME: "São José do Calçado",
        CODIGO_IBGE: 3204807,
      },
      {
        NOME: "São Mateus",
        CODIGO_IBGE: 3204906,
      },
      {
        NOME: "São Roque do Canaã",
        CODIGO_IBGE: 3204955,
      },
      {
        NOME: "Serra",
        CODIGO_IBGE: 3205002,
      },
      {
        NOME: "Sooretama",
        CODIGO_IBGE: 3205010,
      },
      {
        NOME: "Vargem Alta",
        CODIGO_IBGE: 3205036,
      },
      {
        NOME: "Venda Nova do Imigrante",
        CODIGO_IBGE: 3205069,
      },
      {
        NOME: "Viana",
        CODIGO_IBGE: 3205101,
      },
      {
        NOME: "Vila Pavão",
        CODIGO_IBGE: 3205150,
      },
      {
        NOME: "Vila Valério",
        CODIGO_IBGE: 3205176,
      },
      {
        NOME: "Vila Velha",
        CODIGO_IBGE: 3205200,
      },
      {
        NOME: "Vitória",
        CODIGO_IBGE: 3205309,
      },
    ],
    33: [
      {
        NOME: "Angra dos Reis",
        CODIGO_IBGE: 3300100,
      },
      {
        NOME: "Aperibé",
        CODIGO_IBGE: 3300159,
      },
      {
        NOME: "Araruama",
        CODIGO_IBGE: 3300209,
      },
      {
        NOME: "Areal",
        CODIGO_IBGE: 3300225,
      },
      {
        NOME: "Armação dos Búzios",
        CODIGO_IBGE: 3300233,
      },
      {
        NOME: "Arraial do Cabo",
        CODIGO_IBGE: 3300258,
      },
      {
        NOME: "Barra do Piraí",
        CODIGO_IBGE: 3300308,
      },
      {
        NOME: "Barra Mansa",
        CODIGO_IBGE: 3300407,
      },
      {
        NOME: "Belford Roxo",
        CODIGO_IBGE: 3300456,
      },
      {
        NOME: "Bom Jardim",
        CODIGO_IBGE: 3300506,
      },
      {
        NOME: "Bom Jesus do Itabapoana",
        CODIGO_IBGE: 3300605,
      },
      {
        NOME: "Cabo Frio",
        CODIGO_IBGE: 3300704,
      },
      {
        NOME: "Cachoeiras de Macacu",
        CODIGO_IBGE: 3300803,
      },
      {
        NOME: "Cambuci",
        CODIGO_IBGE: 3300902,
      },
      {
        NOME: "Carapebus",
        CODIGO_IBGE: 3300936,
      },
      {
        NOME: "Comendador Levy Gasparian",
        CODIGO_IBGE: 3300951,
      },
      {
        NOME: "Campos dos Goytacazes",
        CODIGO_IBGE: 3301009,
      },
      {
        NOME: "Cantagalo",
        CODIGO_IBGE: 3301108,
      },
      {
        NOME: "Cardoso Moreira",
        CODIGO_IBGE: 3301157,
      },
      {
        NOME: "Carmo",
        CODIGO_IBGE: 3301207,
      },
      {
        NOME: "Casimiro de Abreu",
        CODIGO_IBGE: 3301306,
      },
      {
        NOME: "Conceição de Macabu",
        CODIGO_IBGE: 3301405,
      },
      {
        NOME: "Cordeiro",
        CODIGO_IBGE: 3301504,
      },
      {
        NOME: "Duas Barras",
        CODIGO_IBGE: 3301603,
      },
      {
        NOME: "Duque de Caxias",
        CODIGO_IBGE: 3301702,
      },
      {
        NOME: "Engenheiro Paulo de Frontin",
        CODIGO_IBGE: 3301801,
      },
      {
        NOME: "Guapimirim",
        CODIGO_IBGE: 3301850,
      },
      {
        NOME: "Iguaba Grande",
        CODIGO_IBGE: 3301876,
      },
      {
        NOME: "Itaboraí",
        CODIGO_IBGE: 3301900,
      },
      {
        NOME: "Itaguaí",
        CODIGO_IBGE: 3302007,
      },
      {
        NOME: "Italva",
        CODIGO_IBGE: 3302056,
      },
      {
        NOME: "Itaocara",
        CODIGO_IBGE: 3302106,
      },
      {
        NOME: "Itaperuna",
        CODIGO_IBGE: 3302205,
      },
      {
        NOME: "Itatiaia",
        CODIGO_IBGE: 3302254,
      },
      {
        NOME: "Japeri",
        CODIGO_IBGE: 3302270,
      },
      {
        NOME: "Laje do Muriaé",
        CODIGO_IBGE: 3302304,
      },
      {
        NOME: "Macaé",
        CODIGO_IBGE: 3302403,
      },
      {
        NOME: "Macuco",
        CODIGO_IBGE: 3302452,
      },
      {
        NOME: "Magé",
        CODIGO_IBGE: 3302502,
      },
      {
        NOME: "Mangaratiba",
        CODIGO_IBGE: 3302601,
      },
      {
        NOME: "Maricá",
        CODIGO_IBGE: 3302700,
      },
      {
        NOME: "Mendes",
        CODIGO_IBGE: 3302809,
      },
      {
        NOME: "Mesquita",
        CODIGO_IBGE: 3302858,
      },
      {
        NOME: "Miguel Pereira",
        CODIGO_IBGE: 3302908,
      },
      {
        NOME: "Miracema",
        CODIGO_IBGE: 3303005,
      },
      {
        NOME: "Natividade",
        CODIGO_IBGE: 3303104,
      },
      {
        NOME: "Nilópolis",
        CODIGO_IBGE: 3303203,
      },
      {
        NOME: "Niterói",
        CODIGO_IBGE: 3303302,
      },
      {
        NOME: "Nova Friburgo",
        CODIGO_IBGE: 3303401,
      },
      {
        NOME: "Nova Iguaçu",
        CODIGO_IBGE: 3303500,
      },
      {
        NOME: "Paracambi",
        CODIGO_IBGE: 3303609,
      },
      {
        NOME: "Paraíba do Sul",
        CODIGO_IBGE: 3303708,
      },
      {
        NOME: "Paraty",
        CODIGO_IBGE: 3303807,
      },
      {
        NOME: "Paty do Alferes",
        CODIGO_IBGE: 3303856,
      },
      {
        NOME: "Petrópolis",
        CODIGO_IBGE: 3303906,
      },
      {
        NOME: "Pinheiral",
        CODIGO_IBGE: 3303955,
      },
      {
        NOME: "Piraí",
        CODIGO_IBGE: 3304003,
      },
      {
        NOME: "Porciúncula",
        CODIGO_IBGE: 3304102,
      },
      {
        NOME: "Porto Real",
        CODIGO_IBGE: 3304110,
      },
      {
        NOME: "Quatis",
        CODIGO_IBGE: 3304128,
      },
      {
        NOME: "Queimados",
        CODIGO_IBGE: 3304144,
      },
      {
        NOME: "Quissamã",
        CODIGO_IBGE: 3304151,
      },
      {
        NOME: "Resende",
        CODIGO_IBGE: 3304201,
      },
      {
        NOME: "Rio Bonito",
        CODIGO_IBGE: 3304300,
      },
      {
        NOME: "Rio Claro",
        CODIGO_IBGE: 3304409,
      },
      {
        NOME: "Rio das Flores",
        CODIGO_IBGE: 3304508,
      },
      {
        NOME: "Rio das Ostras",
        CODIGO_IBGE: 3304524,
      },
      {
        NOME: "Rio de Janeiro",
        CODIGO_IBGE: 3304557,
      },
      {
        NOME: "Santa Maria Madalena",
        CODIGO_IBGE: 3304607,
      },
      {
        NOME: "Santo Antônio de Pádua",
        CODIGO_IBGE: 3304706,
      },
      {
        NOME: "São Francisco de Itabapoana",
        CODIGO_IBGE: 3304755,
      },
      {
        NOME: "São Fidélis",
        CODIGO_IBGE: 3304805,
      },
      {
        NOME: "São Gonçalo",
        CODIGO_IBGE: 3304904,
      },
      {
        NOME: "São João da Barra",
        CODIGO_IBGE: 3305000,
      },
      {
        NOME: "São João de Meriti",
        CODIGO_IBGE: 3305109,
      },
      {
        NOME: "São José de Ubá",
        CODIGO_IBGE: 3305133,
      },
      {
        NOME: "São José do Vale do Rio Preto",
        CODIGO_IBGE: 3305158,
      },
      {
        NOME: "São Pedro da Aldeia",
        CODIGO_IBGE: 3305208,
      },
      {
        NOME: "São Sebastião do Alto",
        CODIGO_IBGE: 3305307,
      },
      {
        NOME: "Sapucaia",
        CODIGO_IBGE: 3305406,
      },
      {
        NOME: "Saquarema",
        CODIGO_IBGE: 3305505,
      },
      {
        NOME: "Seropédica",
        CODIGO_IBGE: 3305554,
      },
      {
        NOME: "Silva Jardim",
        CODIGO_IBGE: 3305604,
      },
      {
        NOME: "Sumidouro",
        CODIGO_IBGE: 3305703,
      },
      {
        NOME: "Tanguá",
        CODIGO_IBGE: 3305752,
      },
      {
        NOME: "Teresópolis",
        CODIGO_IBGE: 3305802,
      },
      {
        NOME: "Trajano de Moraes",
        CODIGO_IBGE: 3305901,
      },
      {
        NOME: "Três Rios",
        CODIGO_IBGE: 3306008,
      },
      {
        NOME: "Valença",
        CODIGO_IBGE: 3306107,
      },
      {
        NOME: "Varre-Sai",
        CODIGO_IBGE: 3306156,
      },
      {
        NOME: "Vassouras",
        CODIGO_IBGE: 3306206,
      },
      {
        NOME: "Volta Redonda",
        CODIGO_IBGE: 3306305,
      },
    ],
    35: [
      {
        NOME: "Adamantina",
        CODIGO_IBGE: 3500105,
      },
      {
        NOME: "Adolfo",
        CODIGO_IBGE: 3500204,
      },
      {
        NOME: "Aguaí",
        CODIGO_IBGE: 3500303,
      },
      {
        NOME: "Águas da Prata",
        CODIGO_IBGE: 3500402,
      },
      {
        NOME: "Águas de Lindóia",
        CODIGO_IBGE: 3500501,
      },
      {
        NOME: "Águas de Santa Bárbara",
        CODIGO_IBGE: 3500550,
      },
      {
        NOME: "Águas de São Pedro",
        CODIGO_IBGE: 3500600,
      },
      {
        NOME: "Agudos",
        CODIGO_IBGE: 3500709,
      },
      {
        NOME: "Alambari",
        CODIGO_IBGE: 3500758,
      },
      {
        NOME: "Alfredo Marcondes",
        CODIGO_IBGE: 3500808,
      },
      {
        NOME: "Altair",
        CODIGO_IBGE: 3500907,
      },
      {
        NOME: "Altinópolis",
        CODIGO_IBGE: 3501004,
      },
      {
        NOME: "Alto Alegre",
        CODIGO_IBGE: 3501103,
      },
      {
        NOME: "Alumínio",
        CODIGO_IBGE: 3501152,
      },
      {
        NOME: "Álvares Florence",
        CODIGO_IBGE: 3501202,
      },
      {
        NOME: "Álvares Machado",
        CODIGO_IBGE: 3501301,
      },
      {
        NOME: "Álvaro de Carvalho",
        CODIGO_IBGE: 3501400,
      },
      {
        NOME: "Alvinlândia",
        CODIGO_IBGE: 3501509,
      },
      {
        NOME: "Americana",
        CODIGO_IBGE: 3501608,
      },
      {
        NOME: "Américo Brasiliense",
        CODIGO_IBGE: 3501707,
      },
      {
        NOME: "Américo de Campos",
        CODIGO_IBGE: 3501806,
      },
      {
        NOME: "Amparo",
        CODIGO_IBGE: 3501905,
      },
      {
        NOME: "Analândia",
        CODIGO_IBGE: 3502002,
      },
      {
        NOME: "Andradina",
        CODIGO_IBGE: 3502101,
      },
      {
        NOME: "Angatuba",
        CODIGO_IBGE: 3502200,
      },
      {
        NOME: "Anhembi",
        CODIGO_IBGE: 3502309,
      },
      {
        NOME: "Anhumas",
        CODIGO_IBGE: 3502408,
      },
      {
        NOME: "Aparecida",
        CODIGO_IBGE: 3502507,
      },
      {
        NOME: "Aparecida d'Oeste",
        CODIGO_IBGE: 3502606,
      },
      {
        NOME: "Apiaí",
        CODIGO_IBGE: 3502705,
      },
      {
        NOME: "Araçariguama",
        CODIGO_IBGE: 3502754,
      },
      {
        NOME: "Araçatuba",
        CODIGO_IBGE: 3502804,
      },
      {
        NOME: "Araçoiaba da Serra",
        CODIGO_IBGE: 3502903,
      },
      {
        NOME: "Aramina",
        CODIGO_IBGE: 3503000,
      },
      {
        NOME: "Arandu",
        CODIGO_IBGE: 3503109,
      },
      {
        NOME: "Arapeí",
        CODIGO_IBGE: 3503158,
      },
      {
        NOME: "Araraquara",
        CODIGO_IBGE: 3503208,
      },
      {
        NOME: "Araras",
        CODIGO_IBGE: 3503307,
      },
      {
        NOME: "Arco-Íris",
        CODIGO_IBGE: 3503356,
      },
      {
        NOME: "Arealva",
        CODIGO_IBGE: 3503406,
      },
      {
        NOME: "Areias",
        CODIGO_IBGE: 3503505,
      },
      {
        NOME: "Areiópolis",
        CODIGO_IBGE: 3503604,
      },
      {
        NOME: "Ariranha",
        CODIGO_IBGE: 3503703,
      },
      {
        NOME: "Artur Nogueira",
        CODIGO_IBGE: 3503802,
      },
      {
        NOME: "Arujá",
        CODIGO_IBGE: 3503901,
      },
      {
        NOME: "Aspásia",
        CODIGO_IBGE: 3503950,
      },
      {
        NOME: "Assis",
        CODIGO_IBGE: 3504008,
      },
      {
        NOME: "Atibaia",
        CODIGO_IBGE: 3504107,
      },
      {
        NOME: "Auriflama",
        CODIGO_IBGE: 3504206,
      },
      {
        NOME: "Avaí",
        CODIGO_IBGE: 3504305,
      },
      {
        NOME: "Avanhandava",
        CODIGO_IBGE: 3504404,
      },
      {
        NOME: "Avaré",
        CODIGO_IBGE: 3504503,
      },
      {
        NOME: "Bady Bassitt",
        CODIGO_IBGE: 3504602,
      },
      {
        NOME: "Balbinos",
        CODIGO_IBGE: 3504701,
      },
      {
        NOME: "Bálsamo",
        CODIGO_IBGE: 3504800,
      },
      {
        NOME: "Bananal",
        CODIGO_IBGE: 3504909,
      },
      {
        NOME: "Barão de Antonina",
        CODIGO_IBGE: 3505005,
      },
      {
        NOME: "Barbosa",
        CODIGO_IBGE: 3505104,
      },
      {
        NOME: "Bariri",
        CODIGO_IBGE: 3505203,
      },
      {
        NOME: "Barra Bonita",
        CODIGO_IBGE: 3505302,
      },
      {
        NOME: "Barra do Chapéu",
        CODIGO_IBGE: 3505351,
      },
      {
        NOME: "Barra do Turvo",
        CODIGO_IBGE: 3505401,
      },
      {
        NOME: "Barretos",
        CODIGO_IBGE: 3505500,
      },
      {
        NOME: "Barrinha",
        CODIGO_IBGE: 3505609,
      },
      {
        NOME: "Barueri",
        CODIGO_IBGE: 3505708,
      },
      {
        NOME: "Bastos",
        CODIGO_IBGE: 3505807,
      },
      {
        NOME: "Batatais",
        CODIGO_IBGE: 3505906,
      },
      {
        NOME: "Bauru",
        CODIGO_IBGE: 3506003,
      },
      {
        NOME: "Bebedouro",
        CODIGO_IBGE: 3506102,
      },
      {
        NOME: "Bento de Abreu",
        CODIGO_IBGE: 3506201,
      },
      {
        NOME: "Bernardino de Campos",
        CODIGO_IBGE: 3506300,
      },
      {
        NOME: "Bertioga",
        CODIGO_IBGE: 3506359,
      },
      {
        NOME: "Bilac",
        CODIGO_IBGE: 3506409,
      },
      {
        NOME: "Birigui",
        CODIGO_IBGE: 3506508,
      },
      {
        NOME: "Biritiba Mirim",
        CODIGO_IBGE: 3506607,
      },
      {
        NOME: "Boa Esperança do Sul",
        CODIGO_IBGE: 3506706,
      },
      {
        NOME: "Bocaina",
        CODIGO_IBGE: 3506805,
      },
      {
        NOME: "Bofete",
        CODIGO_IBGE: 3506904,
      },
      {
        NOME: "Boituva",
        CODIGO_IBGE: 3507001,
      },
      {
        NOME: "Bom Jesus dos Perdões",
        CODIGO_IBGE: 3507100,
      },
      {
        NOME: "Bom Sucesso de Itararé",
        CODIGO_IBGE: 3507159,
      },
      {
        NOME: "Borá",
        CODIGO_IBGE: 3507209,
      },
      {
        NOME: "Boracéia",
        CODIGO_IBGE: 3507308,
      },
      {
        NOME: "Borborema",
        CODIGO_IBGE: 3507407,
      },
      {
        NOME: "Borebi",
        CODIGO_IBGE: 3507456,
      },
      {
        NOME: "Botucatu",
        CODIGO_IBGE: 3507506,
      },
      {
        NOME: "Bragança Paulista",
        CODIGO_IBGE: 3507605,
      },
      {
        NOME: "Braúna",
        CODIGO_IBGE: 3507704,
      },
      {
        NOME: "Brejo Alegre",
        CODIGO_IBGE: 3507753,
      },
      {
        NOME: "Brodowski",
        CODIGO_IBGE: 3507803,
      },
      {
        NOME: "Brotas",
        CODIGO_IBGE: 3507902,
      },
      {
        NOME: "Buri",
        CODIGO_IBGE: 3508009,
      },
      {
        NOME: "Buritama",
        CODIGO_IBGE: 3508108,
      },
      {
        NOME: "Buritizal",
        CODIGO_IBGE: 3508207,
      },
      {
        NOME: "Cabrália Paulista",
        CODIGO_IBGE: 3508306,
      },
      {
        NOME: "Cabreúva",
        CODIGO_IBGE: 3508405,
      },
      {
        NOME: "Caçapava",
        CODIGO_IBGE: 3508504,
      },
      {
        NOME: "Cachoeira Paulista",
        CODIGO_IBGE: 3508603,
      },
      {
        NOME: "Caconde",
        CODIGO_IBGE: 3508702,
      },
      {
        NOME: "Cafelândia",
        CODIGO_IBGE: 3508801,
      },
      {
        NOME: "Caiabu",
        CODIGO_IBGE: 3508900,
      },
      {
        NOME: "Caieiras",
        CODIGO_IBGE: 3509007,
      },
      {
        NOME: "Caiuá",
        CODIGO_IBGE: 3509106,
      },
      {
        NOME: "Cajamar",
        CODIGO_IBGE: 3509205,
      },
      {
        NOME: "Cajati",
        CODIGO_IBGE: 3509254,
      },
      {
        NOME: "Cajobi",
        CODIGO_IBGE: 3509304,
      },
      {
        NOME: "Cajuru",
        CODIGO_IBGE: 3509403,
      },
      {
        NOME: "Campina do Monte Alegre",
        CODIGO_IBGE: 3509452,
      },
      {
        NOME: "Campinas",
        CODIGO_IBGE: 3509502,
      },
      {
        NOME: "Campo Limpo Paulista",
        CODIGO_IBGE: 3509601,
      },
      {
        NOME: "Campos do Jordão",
        CODIGO_IBGE: 3509700,
      },
      {
        NOME: "Campos Novos Paulista",
        CODIGO_IBGE: 3509809,
      },
      {
        NOME: "Cananéia",
        CODIGO_IBGE: 3509908,
      },
      {
        NOME: "Canas",
        CODIGO_IBGE: 3509957,
      },
      {
        NOME: "Cândido Mota",
        CODIGO_IBGE: 3510005,
      },
      {
        NOME: "Cândido Rodrigues",
        CODIGO_IBGE: 3510104,
      },
      {
        NOME: "Canitar",
        CODIGO_IBGE: 3510153,
      },
      {
        NOME: "Capão Bonito",
        CODIGO_IBGE: 3510203,
      },
      {
        NOME: "Capela do Alto",
        CODIGO_IBGE: 3510302,
      },
      {
        NOME: "Capivari",
        CODIGO_IBGE: 3510401,
      },
      {
        NOME: "Caraguatatuba",
        CODIGO_IBGE: 3510500,
      },
      {
        NOME: "Carapicuíba",
        CODIGO_IBGE: 3510609,
      },
      {
        NOME: "Cardoso",
        CODIGO_IBGE: 3510708,
      },
      {
        NOME: "Casa Branca",
        CODIGO_IBGE: 3510807,
      },
      {
        NOME: "Cássia dos Coqueiros",
        CODIGO_IBGE: 3510906,
      },
      {
        NOME: "Castilho",
        CODIGO_IBGE: 3511003,
      },
      {
        NOME: "Catanduva",
        CODIGO_IBGE: 3511102,
      },
      {
        NOME: "Catiguá",
        CODIGO_IBGE: 3511201,
      },
      {
        NOME: "Cedral",
        CODIGO_IBGE: 3511300,
      },
      {
        NOME: "Cerqueira César",
        CODIGO_IBGE: 3511409,
      },
      {
        NOME: "Cerquilho",
        CODIGO_IBGE: 3511508,
      },
      {
        NOME: "Cesário Lange",
        CODIGO_IBGE: 3511607,
      },
      {
        NOME: "Charqueada",
        CODIGO_IBGE: 3511706,
      },
      {
        NOME: "Clementina",
        CODIGO_IBGE: 3511904,
      },
      {
        NOME: "Colina",
        CODIGO_IBGE: 3512001,
      },
      {
        NOME: "Colômbia",
        CODIGO_IBGE: 3512100,
      },
      {
        NOME: "Conchal",
        CODIGO_IBGE: 3512209,
      },
      {
        NOME: "Conchas",
        CODIGO_IBGE: 3512308,
      },
      {
        NOME: "Cordeirópolis",
        CODIGO_IBGE: 3512407,
      },
      {
        NOME: "Coroados",
        CODIGO_IBGE: 3512506,
      },
      {
        NOME: "Coronel Macedo",
        CODIGO_IBGE: 3512605,
      },
      {
        NOME: "Corumbataí",
        CODIGO_IBGE: 3512704,
      },
      {
        NOME: "Cosmópolis",
        CODIGO_IBGE: 3512803,
      },
      {
        NOME: "Cosmorama",
        CODIGO_IBGE: 3512902,
      },
      {
        NOME: "Cotia",
        CODIGO_IBGE: 3513009,
      },
      {
        NOME: "Cravinhos",
        CODIGO_IBGE: 3513108,
      },
      {
        NOME: "Cristais Paulista",
        CODIGO_IBGE: 3513207,
      },
      {
        NOME: "Cruzália",
        CODIGO_IBGE: 3513306,
      },
      {
        NOME: "Cruzeiro",
        CODIGO_IBGE: 3513405,
      },
      {
        NOME: "Cubatão",
        CODIGO_IBGE: 3513504,
      },
      {
        NOME: "Cunha",
        CODIGO_IBGE: 3513603,
      },
      {
        NOME: "Descalvado",
        CODIGO_IBGE: 3513702,
      },
      {
        NOME: "Diadema",
        CODIGO_IBGE: 3513801,
      },
      {
        NOME: "Dirce Reis",
        CODIGO_IBGE: 3513850,
      },
      {
        NOME: "Divinolândia",
        CODIGO_IBGE: 3513900,
      },
      {
        NOME: "Dobrada",
        CODIGO_IBGE: 3514007,
      },
      {
        NOME: "Dois Córregos",
        CODIGO_IBGE: 3514106,
      },
      {
        NOME: "Dolcinópolis",
        CODIGO_IBGE: 3514205,
      },
      {
        NOME: "Dourado",
        CODIGO_IBGE: 3514304,
      },
      {
        NOME: "Dracena",
        CODIGO_IBGE: 3514403,
      },
      {
        NOME: "Duartina",
        CODIGO_IBGE: 3514502,
      },
      {
        NOME: "Dumont",
        CODIGO_IBGE: 3514601,
      },
      {
        NOME: "Echaporã",
        CODIGO_IBGE: 3514700,
      },
      {
        NOME: "Eldorado",
        CODIGO_IBGE: 3514809,
      },
      {
        NOME: "Elias Fausto",
        CODIGO_IBGE: 3514908,
      },
      {
        NOME: "Elisiário",
        CODIGO_IBGE: 3514924,
      },
      {
        NOME: "Embaúba",
        CODIGO_IBGE: 3514957,
      },
      {
        NOME: "Embu das Artes",
        CODIGO_IBGE: 3515004,
      },
      {
        NOME: "Embu-Guaçu",
        CODIGO_IBGE: 3515103,
      },
      {
        NOME: "Emilianópolis",
        CODIGO_IBGE: 3515129,
      },
      {
        NOME: "Engenheiro Coelho",
        CODIGO_IBGE: 3515152,
      },
      {
        NOME: "Espírito Santo do Pinhal",
        CODIGO_IBGE: 3515186,
      },
      {
        NOME: "Espírito Santo do Turvo",
        CODIGO_IBGE: 3515194,
      },
      {
        NOME: "Estrela d'Oeste",
        CODIGO_IBGE: 3515202,
      },
      {
        NOME: "Estrela do Norte",
        CODIGO_IBGE: 3515301,
      },
      {
        NOME: "Euclides da Cunha Paulista",
        CODIGO_IBGE: 3515350,
      },
      {
        NOME: "Fartura",
        CODIGO_IBGE: 3515400,
      },
      {
        NOME: "Fernandópolis",
        CODIGO_IBGE: 3515509,
      },
      {
        NOME: "Fernando Prestes",
        CODIGO_IBGE: 3515608,
      },
      {
        NOME: "Fernão",
        CODIGO_IBGE: 3515657,
      },
      {
        NOME: "Ferraz de Vasconcelos",
        CODIGO_IBGE: 3515707,
      },
      {
        NOME: "Flora Rica",
        CODIGO_IBGE: 3515806,
      },
      {
        NOME: "Floreal",
        CODIGO_IBGE: 3515905,
      },
      {
        NOME: "Flórida Paulista",
        CODIGO_IBGE: 3516002,
      },
      {
        NOME: "Florínea",
        CODIGO_IBGE: 3516101,
      },
      {
        NOME: "Franca",
        CODIGO_IBGE: 3516200,
      },
      {
        NOME: "Francisco Morato",
        CODIGO_IBGE: 3516309,
      },
      {
        NOME: "Franco da Rocha",
        CODIGO_IBGE: 3516408,
      },
      {
        NOME: "Gabriel Monteiro",
        CODIGO_IBGE: 3516507,
      },
      {
        NOME: "Gália",
        CODIGO_IBGE: 3516606,
      },
      {
        NOME: "Garça",
        CODIGO_IBGE: 3516705,
      },
      {
        NOME: "Gastão Vidigal",
        CODIGO_IBGE: 3516804,
      },
      {
        NOME: "Gavião Peixoto",
        CODIGO_IBGE: 3516853,
      },
      {
        NOME: "General Salgado",
        CODIGO_IBGE: 3516903,
      },
      {
        NOME: "Getulina",
        CODIGO_IBGE: 3517000,
      },
      {
        NOME: "Glicério",
        CODIGO_IBGE: 3517109,
      },
      {
        NOME: "Guaiçara",
        CODIGO_IBGE: 3517208,
      },
      {
        NOME: "Guaimbê",
        CODIGO_IBGE: 3517307,
      },
      {
        NOME: "Guaíra",
        CODIGO_IBGE: 3517406,
      },
      {
        NOME: "Guapiaçu",
        CODIGO_IBGE: 3517505,
      },
      {
        NOME: "Guapiara",
        CODIGO_IBGE: 3517604,
      },
      {
        NOME: "Guará",
        CODIGO_IBGE: 3517703,
      },
      {
        NOME: "Guaraçaí",
        CODIGO_IBGE: 3517802,
      },
      {
        NOME: "Guaraci",
        CODIGO_IBGE: 3517901,
      },
      {
        NOME: "Guarani d'Oeste",
        CODIGO_IBGE: 3518008,
      },
      {
        NOME: "Guarantã",
        CODIGO_IBGE: 3518107,
      },
      {
        NOME: "Guararapes",
        CODIGO_IBGE: 3518206,
      },
      {
        NOME: "Guararema",
        CODIGO_IBGE: 3518305,
      },
      {
        NOME: "Guaratinguetá",
        CODIGO_IBGE: 3518404,
      },
      {
        NOME: "Guareí",
        CODIGO_IBGE: 3518503,
      },
      {
        NOME: "Guariba",
        CODIGO_IBGE: 3518602,
      },
      {
        NOME: "Guarujá",
        CODIGO_IBGE: 3518701,
      },
      {
        NOME: "Guarulhos",
        CODIGO_IBGE: 3518800,
      },
      {
        NOME: "Guatapará",
        CODIGO_IBGE: 3518859,
      },
      {
        NOME: "Guzolândia",
        CODIGO_IBGE: 3518909,
      },
      {
        NOME: "Herculândia",
        CODIGO_IBGE: 3519006,
      },
      {
        NOME: "Holambra",
        CODIGO_IBGE: 3519055,
      },
      {
        NOME: "Hortolândia",
        CODIGO_IBGE: 3519071,
      },
      {
        NOME: "Iacanga",
        CODIGO_IBGE: 3519105,
      },
      {
        NOME: "Iacri",
        CODIGO_IBGE: 3519204,
      },
      {
        NOME: "Iaras",
        CODIGO_IBGE: 3519253,
      },
      {
        NOME: "Ibaté",
        CODIGO_IBGE: 3519303,
      },
      {
        NOME: "Ibirá",
        CODIGO_IBGE: 3519402,
      },
      {
        NOME: "Ibirarema",
        CODIGO_IBGE: 3519501,
      },
      {
        NOME: "Ibitinga",
        CODIGO_IBGE: 3519600,
      },
      {
        NOME: "Ibiúna",
        CODIGO_IBGE: 3519709,
      },
      {
        NOME: "Icém",
        CODIGO_IBGE: 3519808,
      },
      {
        NOME: "Iepê",
        CODIGO_IBGE: 3519907,
      },
      {
        NOME: "Igaraçu do Tietê",
        CODIGO_IBGE: 3520004,
      },
      {
        NOME: "Igarapava",
        CODIGO_IBGE: 3520103,
      },
      {
        NOME: "Igaratá",
        CODIGO_IBGE: 3520202,
      },
      {
        NOME: "Iguape",
        CODIGO_IBGE: 3520301,
      },
      {
        NOME: "Ilhabela",
        CODIGO_IBGE: 3520400,
      },
      {
        NOME: "Ilha Comprida",
        CODIGO_IBGE: 3520426,
      },
      {
        NOME: "Ilha Solteira",
        CODIGO_IBGE: 3520442,
      },
      {
        NOME: "Indaiatuba",
        CODIGO_IBGE: 3520509,
      },
      {
        NOME: "Indiana",
        CODIGO_IBGE: 3520608,
      },
      {
        NOME: "Indiaporã",
        CODIGO_IBGE: 3520707,
      },
      {
        NOME: "Inúbia Paulista",
        CODIGO_IBGE: 3520806,
      },
      {
        NOME: "Ipaussu",
        CODIGO_IBGE: 3520905,
      },
      {
        NOME: "Iperó",
        CODIGO_IBGE: 3521002,
      },
      {
        NOME: "Ipeúna",
        CODIGO_IBGE: 3521101,
      },
      {
        NOME: "Ipiguá",
        CODIGO_IBGE: 3521150,
      },
      {
        NOME: "Iporanga",
        CODIGO_IBGE: 3521200,
      },
      {
        NOME: "Ipuã",
        CODIGO_IBGE: 3521309,
      },
      {
        NOME: "Iracemápolis",
        CODIGO_IBGE: 3521408,
      },
      {
        NOME: "Irapuã",
        CODIGO_IBGE: 3521507,
      },
      {
        NOME: "Irapuru",
        CODIGO_IBGE: 3521606,
      },
      {
        NOME: "Itaberá",
        CODIGO_IBGE: 3521705,
      },
      {
        NOME: "Itaí",
        CODIGO_IBGE: 3521804,
      },
      {
        NOME: "Itajobi",
        CODIGO_IBGE: 3521903,
      },
      {
        NOME: "Itaju",
        CODIGO_IBGE: 3522000,
      },
      {
        NOME: "Itanhaém",
        CODIGO_IBGE: 3522109,
      },
      {
        NOME: "Itaoca",
        CODIGO_IBGE: 3522158,
      },
      {
        NOME: "Itapecerica da Serra",
        CODIGO_IBGE: 3522208,
      },
      {
        NOME: "Itapetininga",
        CODIGO_IBGE: 3522307,
      },
      {
        NOME: "Itapeva",
        CODIGO_IBGE: 3522406,
      },
      {
        NOME: "Itapevi",
        CODIGO_IBGE: 3522505,
      },
      {
        NOME: "Itapira",
        CODIGO_IBGE: 3522604,
      },
      {
        NOME: "Itapirapuã Paulista",
        CODIGO_IBGE: 3522653,
      },
      {
        NOME: "Itápolis",
        CODIGO_IBGE: 3522703,
      },
      {
        NOME: "Itaporanga",
        CODIGO_IBGE: 3522802,
      },
      {
        NOME: "Itapuí",
        CODIGO_IBGE: 3522901,
      },
      {
        NOME: "Itapura",
        CODIGO_IBGE: 3523008,
      },
      {
        NOME: "Itaquaquecetuba",
        CODIGO_IBGE: 3523107,
      },
      {
        NOME: "Itararé",
        CODIGO_IBGE: 3523206,
      },
      {
        NOME: "Itariri",
        CODIGO_IBGE: 3523305,
      },
      {
        NOME: "Itatiba",
        CODIGO_IBGE: 3523404,
      },
      {
        NOME: "Itatinga",
        CODIGO_IBGE: 3523503,
      },
      {
        NOME: "Itirapina",
        CODIGO_IBGE: 3523602,
      },
      {
        NOME: "Itirapuã",
        CODIGO_IBGE: 3523701,
      },
      {
        NOME: "Itobi",
        CODIGO_IBGE: 3523800,
      },
      {
        NOME: "Itu",
        CODIGO_IBGE: 3523909,
      },
      {
        NOME: "Itupeva",
        CODIGO_IBGE: 3524006,
      },
      {
        NOME: "Ituverava",
        CODIGO_IBGE: 3524105,
      },
      {
        NOME: "Jaborandi",
        CODIGO_IBGE: 3524204,
      },
      {
        NOME: "Jaboticabal",
        CODIGO_IBGE: 3524303,
      },
      {
        NOME: "Jacareí",
        CODIGO_IBGE: 3524402,
      },
      {
        NOME: "Jaci",
        CODIGO_IBGE: 3524501,
      },
      {
        NOME: "Jacupiranga",
        CODIGO_IBGE: 3524600,
      },
      {
        NOME: "Jaguariúna",
        CODIGO_IBGE: 3524709,
      },
      {
        NOME: "Jales",
        CODIGO_IBGE: 3524808,
      },
      {
        NOME: "Jambeiro",
        CODIGO_IBGE: 3524907,
      },
      {
        NOME: "Jandira",
        CODIGO_IBGE: 3525003,
      },
      {
        NOME: "Jardinópolis",
        CODIGO_IBGE: 3525102,
      },
      {
        NOME: "Jarinu",
        CODIGO_IBGE: 3525201,
      },
      {
        NOME: "Jaú",
        CODIGO_IBGE: 3525300,
      },
      {
        NOME: "Jeriquara",
        CODIGO_IBGE: 3525409,
      },
      {
        NOME: "Joanópolis",
        CODIGO_IBGE: 3525508,
      },
      {
        NOME: "João Ramalho",
        CODIGO_IBGE: 3525607,
      },
      {
        NOME: "José Bonifácio",
        CODIGO_IBGE: 3525706,
      },
      {
        NOME: "Júlio Mesquita",
        CODIGO_IBGE: 3525805,
      },
      {
        NOME: "Jumirim",
        CODIGO_IBGE: 3525854,
      },
      {
        NOME: "Jundiaí",
        CODIGO_IBGE: 3525904,
      },
      {
        NOME: "Junqueirópolis",
        CODIGO_IBGE: 3526001,
      },
      {
        NOME: "Juquiá",
        CODIGO_IBGE: 3526100,
      },
      {
        NOME: "Juquitiba",
        CODIGO_IBGE: 3526209,
      },
      {
        NOME: "Lagoinha",
        CODIGO_IBGE: 3526308,
      },
      {
        NOME: "Laranjal Paulista",
        CODIGO_IBGE: 3526407,
      },
      {
        NOME: "Lavínia",
        CODIGO_IBGE: 3526506,
      },
      {
        NOME: "Lavrinhas",
        CODIGO_IBGE: 3526605,
      },
      {
        NOME: "Leme",
        CODIGO_IBGE: 3526704,
      },
      {
        NOME: "Lençóis Paulista",
        CODIGO_IBGE: 3526803,
      },
      {
        NOME: "Limeira",
        CODIGO_IBGE: 3526902,
      },
      {
        NOME: "Lindóia",
        CODIGO_IBGE: 3527009,
      },
      {
        NOME: "Lins",
        CODIGO_IBGE: 3527108,
      },
      {
        NOME: "Lorena",
        CODIGO_IBGE: 3527207,
      },
      {
        NOME: "Lourdes",
        CODIGO_IBGE: 3527256,
      },
      {
        NOME: "Louveira",
        CODIGO_IBGE: 3527306,
      },
      {
        NOME: "Lucélia",
        CODIGO_IBGE: 3527405,
      },
      {
        NOME: "Lucianópolis",
        CODIGO_IBGE: 3527504,
      },
      {
        NOME: "Luís Antônio",
        CODIGO_IBGE: 3527603,
      },
      {
        NOME: "Luiziânia",
        CODIGO_IBGE: 3527702,
      },
      {
        NOME: "Lupércio",
        CODIGO_IBGE: 3527801,
      },
      {
        NOME: "Lutécia",
        CODIGO_IBGE: 3527900,
      },
      {
        NOME: "Macatuba",
        CODIGO_IBGE: 3528007,
      },
      {
        NOME: "Macaubal",
        CODIGO_IBGE: 3528106,
      },
      {
        NOME: "Macedônia",
        CODIGO_IBGE: 3528205,
      },
      {
        NOME: "Magda",
        CODIGO_IBGE: 3528304,
      },
      {
        NOME: "Mairinque",
        CODIGO_IBGE: 3528403,
      },
      {
        NOME: "Mairiporã",
        CODIGO_IBGE: 3528502,
      },
      {
        NOME: "Manduri",
        CODIGO_IBGE: 3528601,
      },
      {
        NOME: "Marabá Paulista",
        CODIGO_IBGE: 3528700,
      },
      {
        NOME: "Maracaí",
        CODIGO_IBGE: 3528809,
      },
      {
        NOME: "Marapoama",
        CODIGO_IBGE: 3528858,
      },
      {
        NOME: "Mariápolis",
        CODIGO_IBGE: 3528908,
      },
      {
        NOME: "Marília",
        CODIGO_IBGE: 3529005,
      },
      {
        NOME: "Marinópolis",
        CODIGO_IBGE: 3529104,
      },
      {
        NOME: "Martinópolis",
        CODIGO_IBGE: 3529203,
      },
      {
        NOME: "Matão",
        CODIGO_IBGE: 3529302,
      },
      {
        NOME: "Mauá",
        CODIGO_IBGE: 3529401,
      },
      {
        NOME: "Mendonça",
        CODIGO_IBGE: 3529500,
      },
      {
        NOME: "Meridiano",
        CODIGO_IBGE: 3529609,
      },
      {
        NOME: "Mesópolis",
        CODIGO_IBGE: 3529658,
      },
      {
        NOME: "Miguelópolis",
        CODIGO_IBGE: 3529708,
      },
      {
        NOME: "Mineiros do Tietê",
        CODIGO_IBGE: 3529807,
      },
      {
        NOME: "Miracatu",
        CODIGO_IBGE: 3529906,
      },
      {
        NOME: "Mira Estrela",
        CODIGO_IBGE: 3530003,
      },
      {
        NOME: "Mirandópolis",
        CODIGO_IBGE: 3530102,
      },
      {
        NOME: "Mirante do Paranapanema",
        CODIGO_IBGE: 3530201,
      },
      {
        NOME: "Mirassol",
        CODIGO_IBGE: 3530300,
      },
      {
        NOME: "Mirassolândia",
        CODIGO_IBGE: 3530409,
      },
      {
        NOME: "Mococa",
        CODIGO_IBGE: 3530508,
      },
      {
        NOME: "Mogi das Cruzes",
        CODIGO_IBGE: 3530607,
      },
      {
        NOME: "Mogi Guaçu",
        CODIGO_IBGE: 3530706,
      },
      {
        NOME: "Mogi Mirim",
        CODIGO_IBGE: 3530805,
      },
      {
        NOME: "Mombuca",
        CODIGO_IBGE: 3530904,
      },
      {
        NOME: "Monções",
        CODIGO_IBGE: 3531001,
      },
      {
        NOME: "Mongaguá",
        CODIGO_IBGE: 3531100,
      },
      {
        NOME: "Monte Alegre do Sul",
        CODIGO_IBGE: 3531209,
      },
      {
        NOME: "Monte Alto",
        CODIGO_IBGE: 3531308,
      },
      {
        NOME: "Monte Aprazível",
        CODIGO_IBGE: 3531407,
      },
      {
        NOME: "Monte Azul Paulista",
        CODIGO_IBGE: 3531506,
      },
      {
        NOME: "Monte Castelo",
        CODIGO_IBGE: 3531605,
      },
      {
        NOME: "Monteiro Lobato",
        CODIGO_IBGE: 3531704,
      },
      {
        NOME: "Monte Mor",
        CODIGO_IBGE: 3531803,
      },
      {
        NOME: "Morro Agudo",
        CODIGO_IBGE: 3531902,
      },
      {
        NOME: "Morungaba",
        CODIGO_IBGE: 3532009,
      },
      {
        NOME: "Motuca",
        CODIGO_IBGE: 3532058,
      },
      {
        NOME: "Murutinga do Sul",
        CODIGO_IBGE: 3532108,
      },
      {
        NOME: "Nantes",
        CODIGO_IBGE: 3532157,
      },
      {
        NOME: "Narandiba",
        CODIGO_IBGE: 3532207,
      },
      {
        NOME: "Natividade da Serra",
        CODIGO_IBGE: 3532306,
      },
      {
        NOME: "Nazaré Paulista",
        CODIGO_IBGE: 3532405,
      },
      {
        NOME: "Neves Paulista",
        CODIGO_IBGE: 3532504,
      },
      {
        NOME: "Nhandeara",
        CODIGO_IBGE: 3532603,
      },
      {
        NOME: "Nipoã",
        CODIGO_IBGE: 3532702,
      },
      {
        NOME: "Nova Aliança",
        CODIGO_IBGE: 3532801,
      },
      {
        NOME: "Nova Campina",
        CODIGO_IBGE: 3532827,
      },
      {
        NOME: "Nova Canaã Paulista",
        CODIGO_IBGE: 3532843,
      },
      {
        NOME: "Nova Castilho",
        CODIGO_IBGE: 3532868,
      },
      {
        NOME: "Nova Europa",
        CODIGO_IBGE: 3532900,
      },
      {
        NOME: "Nova Granada",
        CODIGO_IBGE: 3533007,
      },
      {
        NOME: "Nova Guataporanga",
        CODIGO_IBGE: 3533106,
      },
      {
        NOME: "Nova Independência",
        CODIGO_IBGE: 3533205,
      },
      {
        NOME: "Novais",
        CODIGO_IBGE: 3533254,
      },
      {
        NOME: "Nova Luzitânia",
        CODIGO_IBGE: 3533304,
      },
      {
        NOME: "Nova Odessa",
        CODIGO_IBGE: 3533403,
      },
      {
        NOME: "Novo Horizonte",
        CODIGO_IBGE: 3533502,
      },
      {
        NOME: "Nuporanga",
        CODIGO_IBGE: 3533601,
      },
      {
        NOME: "Ocauçu",
        CODIGO_IBGE: 3533700,
      },
      {
        NOME: "Óleo",
        CODIGO_IBGE: 3533809,
      },
      {
        NOME: "Olímpia",
        CODIGO_IBGE: 3533908,
      },
      {
        NOME: "Onda Verde",
        CODIGO_IBGE: 3534005,
      },
      {
        NOME: "Oriente",
        CODIGO_IBGE: 3534104,
      },
      {
        NOME: "Orindiúva",
        CODIGO_IBGE: 3534203,
      },
      {
        NOME: "Orlândia",
        CODIGO_IBGE: 3534302,
      },
      {
        NOME: "Osasco",
        CODIGO_IBGE: 3534401,
      },
      {
        NOME: "Oscar Bressane",
        CODIGO_IBGE: 3534500,
      },
      {
        NOME: "Osvaldo Cruz",
        CODIGO_IBGE: 3534609,
      },
      {
        NOME: "Ourinhos",
        CODIGO_IBGE: 3534708,
      },
      {
        NOME: "Ouroeste",
        CODIGO_IBGE: 3534757,
      },
      {
        NOME: "Ouro Verde",
        CODIGO_IBGE: 3534807,
      },
      {
        NOME: "Pacaembu",
        CODIGO_IBGE: 3534906,
      },
      {
        NOME: "Palestina",
        CODIGO_IBGE: 3535002,
      },
      {
        NOME: "Palmares Paulista",
        CODIGO_IBGE: 3535101,
      },
      {
        NOME: "Palmeira d'Oeste",
        CODIGO_IBGE: 3535200,
      },
      {
        NOME: "Palmital",
        CODIGO_IBGE: 3535309,
      },
      {
        NOME: "Panorama",
        CODIGO_IBGE: 3535408,
      },
      {
        NOME: "Paraguaçu Paulista",
        CODIGO_IBGE: 3535507,
      },
      {
        NOME: "Paraibuna",
        CODIGO_IBGE: 3535606,
      },
      {
        NOME: "Paraíso",
        CODIGO_IBGE: 3535705,
      },
      {
        NOME: "Paranapanema",
        CODIGO_IBGE: 3535804,
      },
      {
        NOME: "Paranapuã",
        CODIGO_IBGE: 3535903,
      },
      {
        NOME: "Parapuã",
        CODIGO_IBGE: 3536000,
      },
      {
        NOME: "Pardinho",
        CODIGO_IBGE: 3536109,
      },
      {
        NOME: "Pariquera-Açu",
        CODIGO_IBGE: 3536208,
      },
      {
        NOME: "Parisi",
        CODIGO_IBGE: 3536257,
      },
      {
        NOME: "Patrocínio Paulista",
        CODIGO_IBGE: 3536307,
      },
      {
        NOME: "Paulicéia",
        CODIGO_IBGE: 3536406,
      },
      {
        NOME: "Paulínia",
        CODIGO_IBGE: 3536505,
      },
      {
        NOME: "Paulistânia",
        CODIGO_IBGE: 3536570,
      },
      {
        NOME: "Paulo de Faria",
        CODIGO_IBGE: 3536604,
      },
      {
        NOME: "Pederneiras",
        CODIGO_IBGE: 3536703,
      },
      {
        NOME: "Pedra Bela",
        CODIGO_IBGE: 3536802,
      },
      {
        NOME: "Pedranópolis",
        CODIGO_IBGE: 3536901,
      },
      {
        NOME: "Pedregulho",
        CODIGO_IBGE: 3537008,
      },
      {
        NOME: "Pedreira",
        CODIGO_IBGE: 3537107,
      },
      {
        NOME: "Pedrinhas Paulista",
        CODIGO_IBGE: 3537156,
      },
      {
        NOME: "Pedro de Toledo",
        CODIGO_IBGE: 3537206,
      },
      {
        NOME: "Penápolis",
        CODIGO_IBGE: 3537305,
      },
      {
        NOME: "Pereira Barreto",
        CODIGO_IBGE: 3537404,
      },
      {
        NOME: "Pereiras",
        CODIGO_IBGE: 3537503,
      },
      {
        NOME: "Peruíbe",
        CODIGO_IBGE: 3537602,
      },
      {
        NOME: "Piacatu",
        CODIGO_IBGE: 3537701,
      },
      {
        NOME: "Piedade",
        CODIGO_IBGE: 3537800,
      },
      {
        NOME: "Pilar do Sul",
        CODIGO_IBGE: 3537909,
      },
      {
        NOME: "Pindamonhangaba",
        CODIGO_IBGE: 3538006,
      },
      {
        NOME: "Pindorama",
        CODIGO_IBGE: 3538105,
      },
      {
        NOME: "Pinhalzinho",
        CODIGO_IBGE: 3538204,
      },
      {
        NOME: "Piquerobi",
        CODIGO_IBGE: 3538303,
      },
      {
        NOME: "Piquete",
        CODIGO_IBGE: 3538501,
      },
      {
        NOME: "Piracaia",
        CODIGO_IBGE: 3538600,
      },
      {
        NOME: "Piracicaba",
        CODIGO_IBGE: 3538709,
      },
      {
        NOME: "Piraju",
        CODIGO_IBGE: 3538808,
      },
      {
        NOME: "Pirajuí",
        CODIGO_IBGE: 3538907,
      },
      {
        NOME: "Pirangi",
        CODIGO_IBGE: 3539004,
      },
      {
        NOME: "Pirapora do Bom Jesus",
        CODIGO_IBGE: 3539103,
      },
      {
        NOME: "Pirapozinho",
        CODIGO_IBGE: 3539202,
      },
      {
        NOME: "Pirassununga",
        CODIGO_IBGE: 3539301,
      },
      {
        NOME: "Piratininga",
        CODIGO_IBGE: 3539400,
      },
      {
        NOME: "Pitangueiras",
        CODIGO_IBGE: 3539509,
      },
      {
        NOME: "Planalto",
        CODIGO_IBGE: 3539608,
      },
      {
        NOME: "Platina",
        CODIGO_IBGE: 3539707,
      },
      {
        NOME: "Poá",
        CODIGO_IBGE: 3539806,
      },
      {
        NOME: "Poloni",
        CODIGO_IBGE: 3539905,
      },
      {
        NOME: "Pompéia",
        CODIGO_IBGE: 3540002,
      },
      {
        NOME: "Pongaí",
        CODIGO_IBGE: 3540101,
      },
      {
        NOME: "Pontal",
        CODIGO_IBGE: 3540200,
      },
      {
        NOME: "Pontalinda",
        CODIGO_IBGE: 3540259,
      },
      {
        NOME: "Pontes Gestal",
        CODIGO_IBGE: 3540309,
      },
      {
        NOME: "Populina",
        CODIGO_IBGE: 3540408,
      },
      {
        NOME: "Porangaba",
        CODIGO_IBGE: 3540507,
      },
      {
        NOME: "Porto Feliz",
        CODIGO_IBGE: 3540606,
      },
      {
        NOME: "Porto Ferreira",
        CODIGO_IBGE: 3540705,
      },
      {
        NOME: "Potim",
        CODIGO_IBGE: 3540754,
      },
      {
        NOME: "Potirendaba",
        CODIGO_IBGE: 3540804,
      },
      {
        NOME: "Pracinha",
        CODIGO_IBGE: 3540853,
      },
      {
        NOME: "Pradópolis",
        CODIGO_IBGE: 3540903,
      },
      {
        NOME: "Praia Grande",
        CODIGO_IBGE: 3541000,
      },
      {
        NOME: "Pratânia",
        CODIGO_IBGE: 3541059,
      },
      {
        NOME: "Presidente Alves",
        CODIGO_IBGE: 3541109,
      },
      {
        NOME: "Presidente Bernardes",
        CODIGO_IBGE: 3541208,
      },
      {
        NOME: "Presidente Epitácio",
        CODIGO_IBGE: 3541307,
      },
      {
        NOME: "Presidente Prudente",
        CODIGO_IBGE: 3541406,
      },
      {
        NOME: "Presidente Venceslau",
        CODIGO_IBGE: 3541505,
      },
      {
        NOME: "Promissão",
        CODIGO_IBGE: 3541604,
      },
      {
        NOME: "Quadra",
        CODIGO_IBGE: 3541653,
      },
      {
        NOME: "Quatá",
        CODIGO_IBGE: 3541703,
      },
      {
        NOME: "Queiroz",
        CODIGO_IBGE: 3541802,
      },
      {
        NOME: "Queluz",
        CODIGO_IBGE: 3541901,
      },
      {
        NOME: "Quintana",
        CODIGO_IBGE: 3542008,
      },
      {
        NOME: "Rafard",
        CODIGO_IBGE: 3542107,
      },
      {
        NOME: "Rancharia",
        CODIGO_IBGE: 3542206,
      },
      {
        NOME: "Redenção da Serra",
        CODIGO_IBGE: 3542305,
      },
      {
        NOME: "Regente Feijó",
        CODIGO_IBGE: 3542404,
      },
      {
        NOME: "Reginópolis",
        CODIGO_IBGE: 3542503,
      },
      {
        NOME: "Registro",
        CODIGO_IBGE: 3542602,
      },
      {
        NOME: "Restinga",
        CODIGO_IBGE: 3542701,
      },
      {
        NOME: "Ribeira",
        CODIGO_IBGE: 3542800,
      },
      {
        NOME: "Ribeirão Bonito",
        CODIGO_IBGE: 3542909,
      },
      {
        NOME: "Ribeirão Branco",
        CODIGO_IBGE: 3543006,
      },
      {
        NOME: "Ribeirão Corrente",
        CODIGO_IBGE: 3543105,
      },
      {
        NOME: "Ribeirão do Sul",
        CODIGO_IBGE: 3543204,
      },
      {
        NOME: "Ribeirão dos Índios",
        CODIGO_IBGE: 3543238,
      },
      {
        NOME: "Ribeirão Grande",
        CODIGO_IBGE: 3543253,
      },
      {
        NOME: "Ribeirão Pires",
        CODIGO_IBGE: 3543303,
      },
      {
        NOME: "Ribeirão Preto",
        CODIGO_IBGE: 3543402,
      },
      {
        NOME: "Riversul",
        CODIGO_IBGE: 3543501,
      },
      {
        NOME: "Rifaina",
        CODIGO_IBGE: 3543600,
      },
      {
        NOME: "Rincão",
        CODIGO_IBGE: 3543709,
      },
      {
        NOME: "Rinópolis",
        CODIGO_IBGE: 3543808,
      },
      {
        NOME: "Rio Claro",
        CODIGO_IBGE: 3543907,
      },
      {
        NOME: "Rio das Pedras",
        CODIGO_IBGE: 3544004,
      },
      {
        NOME: "Rio Grande da Serra",
        CODIGO_IBGE: 3544103,
      },
      {
        NOME: "Riolândia",
        CODIGO_IBGE: 3544202,
      },
      {
        NOME: "Rosana",
        CODIGO_IBGE: 3544251,
      },
      {
        NOME: "Roseira",
        CODIGO_IBGE: 3544301,
      },
      {
        NOME: "Rubiácea",
        CODIGO_IBGE: 3544400,
      },
      {
        NOME: "Rubinéia",
        CODIGO_IBGE: 3544509,
      },
      {
        NOME: "Sabino",
        CODIGO_IBGE: 3544608,
      },
      {
        NOME: "Sagres",
        CODIGO_IBGE: 3544707,
      },
      {
        NOME: "Sales",
        CODIGO_IBGE: 3544806,
      },
      {
        NOME: "Sales Oliveira",
        CODIGO_IBGE: 3544905,
      },
      {
        NOME: "Salesópolis",
        CODIGO_IBGE: 3545001,
      },
      {
        NOME: "Salmourão",
        CODIGO_IBGE: 3545100,
      },
      {
        NOME: "Saltinho",
        CODIGO_IBGE: 3545159,
      },
      {
        NOME: "Salto",
        CODIGO_IBGE: 3545209,
      },
      {
        NOME: "Salto de Pirapora",
        CODIGO_IBGE: 3545308,
      },
      {
        NOME: "Salto Grande",
        CODIGO_IBGE: 3545407,
      },
      {
        NOME: "Sandovalina",
        CODIGO_IBGE: 3545506,
      },
      {
        NOME: "Santa Adélia",
        CODIGO_IBGE: 3545605,
      },
      {
        NOME: "Santa Albertina",
        CODIGO_IBGE: 3545704,
      },
      {
        NOME: "Santa Bárbara d'Oeste",
        CODIGO_IBGE: 3545803,
      },
      {
        NOME: "Santa Branca",
        CODIGO_IBGE: 3546009,
      },
      {
        NOME: "Santa Clara d'Oeste",
        CODIGO_IBGE: 3546108,
      },
      {
        NOME: "Santa Cruz da Conceição",
        CODIGO_IBGE: 3546207,
      },
      {
        NOME: "Santa Cruz da Esperança",
        CODIGO_IBGE: 3546256,
      },
      {
        NOME: "Santa Cruz das Palmeiras",
        CODIGO_IBGE: 3546306,
      },
      {
        NOME: "Santa Cruz do Rio Pardo",
        CODIGO_IBGE: 3546405,
      },
      {
        NOME: "Santa Ernestina",
        CODIGO_IBGE: 3546504,
      },
      {
        NOME: "Santa Fé do Sul",
        CODIGO_IBGE: 3546603,
      },
      {
        NOME: "Santa Gertrudes",
        CODIGO_IBGE: 3546702,
      },
      {
        NOME: "Santa Isabel",
        CODIGO_IBGE: 3546801,
      },
      {
        NOME: "Santa Lúcia",
        CODIGO_IBGE: 3546900,
      },
      {
        NOME: "Santa Maria da Serra",
        CODIGO_IBGE: 3547007,
      },
      {
        NOME: "Santa Mercedes",
        CODIGO_IBGE: 3547106,
      },
      {
        NOME: "Santana da Ponte Pensa",
        CODIGO_IBGE: 3547205,
      },
      {
        NOME: "Santana de Parnaíba",
        CODIGO_IBGE: 3547304,
      },
      {
        NOME: "Santa Rita d'Oeste",
        CODIGO_IBGE: 3547403,
      },
      {
        NOME: "Santa Rita do Passa Quatro",
        CODIGO_IBGE: 3547502,
      },
      {
        NOME: "Santa Rosa de Viterbo",
        CODIGO_IBGE: 3547601,
      },
      {
        NOME: "Santa Salete",
        CODIGO_IBGE: 3547650,
      },
      {
        NOME: "Santo Anastácio",
        CODIGO_IBGE: 3547700,
      },
      {
        NOME: "Santo André",
        CODIGO_IBGE: 3547809,
      },
      {
        NOME: "Santo Antônio da Alegria",
        CODIGO_IBGE: 3547908,
      },
      {
        NOME: "Santo Antônio de Posse",
        CODIGO_IBGE: 3548005,
      },
      {
        NOME: "Santo Antônio do Aracanguá",
        CODIGO_IBGE: 3548054,
      },
      {
        NOME: "Santo Antônio do Jardim",
        CODIGO_IBGE: 3548104,
      },
      {
        NOME: "Santo Antônio do Pinhal",
        CODIGO_IBGE: 3548203,
      },
      {
        NOME: "Santo Expedito",
        CODIGO_IBGE: 3548302,
      },
      {
        NOME: "Santópolis do Aguapeí",
        CODIGO_IBGE: 3548401,
      },
      {
        NOME: "Santos",
        CODIGO_IBGE: 3548500,
      },
      {
        NOME: "São Bento do Sapucaí",
        CODIGO_IBGE: 3548609,
      },
      {
        NOME: "São Bernardo do Campo",
        CODIGO_IBGE: 3548708,
      },
      {
        NOME: "São Caetano do Sul",
        CODIGO_IBGE: 3548807,
      },
      {
        NOME: "São Carlos",
        CODIGO_IBGE: 3548906,
      },
      {
        NOME: "São Francisco",
        CODIGO_IBGE: 3549003,
      },
      {
        NOME: "São João da Boa Vista",
        CODIGO_IBGE: 3549102,
      },
      {
        NOME: "São João das Duas Pontes",
        CODIGO_IBGE: 3549201,
      },
      {
        NOME: "São João de Iracema",
        CODIGO_IBGE: 3549250,
      },
      {
        NOME: "São João do Pau d'Alho",
        CODIGO_IBGE: 3549300,
      },
      {
        NOME: "São Joaquim da Barra",
        CODIGO_IBGE: 3549409,
      },
      {
        NOME: "São José da Bela Vista",
        CODIGO_IBGE: 3549508,
      },
      {
        NOME: "São José do Barreiro",
        CODIGO_IBGE: 3549607,
      },
      {
        NOME: "São José do Rio Pardo",
        CODIGO_IBGE: 3549706,
      },
      {
        NOME: "São José do Rio Preto",
        CODIGO_IBGE: 3549805,
      },
      {
        NOME: "São José dos Campos",
        CODIGO_IBGE: 3549904,
      },
      {
        NOME: "São Lourenço da Serra",
        CODIGO_IBGE: 3549953,
      },
      {
        NOME: "São Luiz do Paraitinga",
        CODIGO_IBGE: 3550001,
      },
      {
        NOME: "São Manuel",
        CODIGO_IBGE: 3550100,
      },
      {
        NOME: "São Miguel Arcanjo",
        CODIGO_IBGE: 3550209,
      },
      {
        NOME: "São Paulo",
        CODIGO_IBGE: 3550308,
      },
      {
        NOME: "São Pedro",
        CODIGO_IBGE: 3550407,
      },
      {
        NOME: "São Pedro do Turvo",
        CODIGO_IBGE: 3550506,
      },
      {
        NOME: "São Roque",
        CODIGO_IBGE: 3550605,
      },
      {
        NOME: "São Sebastião",
        CODIGO_IBGE: 3550704,
      },
      {
        NOME: "São Sebastião da Grama",
        CODIGO_IBGE: 3550803,
      },
      {
        NOME: "São Simão",
        CODIGO_IBGE: 3550902,
      },
      {
        NOME: "São Vicente",
        CODIGO_IBGE: 3551009,
      },
      {
        NOME: "Sarapuí",
        CODIGO_IBGE: 3551108,
      },
      {
        NOME: "Sarutaiá",
        CODIGO_IBGE: 3551207,
      },
      {
        NOME: "Sebastianópolis do Sul",
        CODIGO_IBGE: 3551306,
      },
      {
        NOME: "Serra Azul",
        CODIGO_IBGE: 3551405,
      },
      {
        NOME: "Serrana",
        CODIGO_IBGE: 3551504,
      },
      {
        NOME: "Serra Negra",
        CODIGO_IBGE: 3551603,
      },
      {
        NOME: "Sertãozinho",
        CODIGO_IBGE: 3551702,
      },
      {
        NOME: "Sete Barras",
        CODIGO_IBGE: 3551801,
      },
      {
        NOME: "Severínia",
        CODIGO_IBGE: 3551900,
      },
      {
        NOME: "Silveiras",
        CODIGO_IBGE: 3552007,
      },
      {
        NOME: "Socorro",
        CODIGO_IBGE: 3552106,
      },
      {
        NOME: "Sorocaba",
        CODIGO_IBGE: 3552205,
      },
      {
        NOME: "Sud Mennucci",
        CODIGO_IBGE: 3552304,
      },
      {
        NOME: "Sumaré",
        CODIGO_IBGE: 3552403,
      },
      {
        NOME: "Suzano",
        CODIGO_IBGE: 3552502,
      },
      {
        NOME: "Suzanápolis",
        CODIGO_IBGE: 3552551,
      },
      {
        NOME: "Tabapuã",
        CODIGO_IBGE: 3552601,
      },
      {
        NOME: "Tabatinga",
        CODIGO_IBGE: 3552700,
      },
      {
        NOME: "Taboão da Serra",
        CODIGO_IBGE: 3552809,
      },
      {
        NOME: "Taciba",
        CODIGO_IBGE: 3552908,
      },
      {
        NOME: "Taguaí",
        CODIGO_IBGE: 3553005,
      },
      {
        NOME: "Taiaçu",
        CODIGO_IBGE: 3553104,
      },
      {
        NOME: "Taiúva",
        CODIGO_IBGE: 3553203,
      },
      {
        NOME: "Tambaú",
        CODIGO_IBGE: 3553302,
      },
      {
        NOME: "Tanabi",
        CODIGO_IBGE: 3553401,
      },
      {
        NOME: "Tapiraí",
        CODIGO_IBGE: 3553500,
      },
      {
        NOME: "Tapiratiba",
        CODIGO_IBGE: 3553609,
      },
      {
        NOME: "Taquaral",
        CODIGO_IBGE: 3553658,
      },
      {
        NOME: "Taquaritinga",
        CODIGO_IBGE: 3553708,
      },
      {
        NOME: "Taquarituba",
        CODIGO_IBGE: 3553807,
      },
      {
        NOME: "Taquarivaí",
        CODIGO_IBGE: 3553856,
      },
      {
        NOME: "Tarabai",
        CODIGO_IBGE: 3553906,
      },
      {
        NOME: "Tarumã",
        CODIGO_IBGE: 3553955,
      },
      {
        NOME: "Tatuí",
        CODIGO_IBGE: 3554003,
      },
      {
        NOME: "Taubaté",
        CODIGO_IBGE: 3554102,
      },
      {
        NOME: "Tejupá",
        CODIGO_IBGE: 3554201,
      },
      {
        NOME: "Teodoro Sampaio",
        CODIGO_IBGE: 3554300,
      },
      {
        NOME: "Terra Roxa",
        CODIGO_IBGE: 3554409,
      },
      {
        NOME: "Tietê",
        CODIGO_IBGE: 3554508,
      },
      {
        NOME: "Timburi",
        CODIGO_IBGE: 3554607,
      },
      {
        NOME: "Torre de Pedra",
        CODIGO_IBGE: 3554656,
      },
      {
        NOME: "Torrinha",
        CODIGO_IBGE: 3554706,
      },
      {
        NOME: "Trabiju",
        CODIGO_IBGE: 3554755,
      },
      {
        NOME: "Tremembé",
        CODIGO_IBGE: 3554805,
      },
      {
        NOME: "Três Fronteiras",
        CODIGO_IBGE: 3554904,
      },
      {
        NOME: "Tuiuti",
        CODIGO_IBGE: 3554953,
      },
      {
        NOME: "Tupã",
        CODIGO_IBGE: 3555000,
      },
      {
        NOME: "Tupi Paulista",
        CODIGO_IBGE: 3555109,
      },
      {
        NOME: "Turiúba",
        CODIGO_IBGE: 3555208,
      },
      {
        NOME: "Turmalina",
        CODIGO_IBGE: 3555307,
      },
      {
        NOME: "Ubarana",
        CODIGO_IBGE: 3555356,
      },
      {
        NOME: "Ubatuba",
        CODIGO_IBGE: 3555406,
      },
      {
        NOME: "Ubirajara",
        CODIGO_IBGE: 3555505,
      },
      {
        NOME: "Uchoa",
        CODIGO_IBGE: 3555604,
      },
      {
        NOME: "União Paulista",
        CODIGO_IBGE: 3555703,
      },
      {
        NOME: "Urânia",
        CODIGO_IBGE: 3555802,
      },
      {
        NOME: "Uru",
        CODIGO_IBGE: 3555901,
      },
      {
        NOME: "Urupês",
        CODIGO_IBGE: 3556008,
      },
      {
        NOME: "Valentim Gentil",
        CODIGO_IBGE: 3556107,
      },
      {
        NOME: "Valinhos",
        CODIGO_IBGE: 3556206,
      },
      {
        NOME: "Valparaíso",
        CODIGO_IBGE: 3556305,
      },
      {
        NOME: "Vargem",
        CODIGO_IBGE: 3556354,
      },
      {
        NOME: "Vargem Grande do Sul",
        CODIGO_IBGE: 3556404,
      },
      {
        NOME: "Vargem Grande Paulista",
        CODIGO_IBGE: 3556453,
      },
      {
        NOME: "Várzea Paulista",
        CODIGO_IBGE: 3556503,
      },
      {
        NOME: "Vera Cruz",
        CODIGO_IBGE: 3556602,
      },
      {
        NOME: "Vinhedo",
        CODIGO_IBGE: 3556701,
      },
      {
        NOME: "Viradouro",
        CODIGO_IBGE: 3556800,
      },
      {
        NOME: "Vista Alegre do Alto",
        CODIGO_IBGE: 3556909,
      },
      {
        NOME: "Vitória Brasil",
        CODIGO_IBGE: 3556958,
      },
      {
        NOME: "Votorantim",
        CODIGO_IBGE: 3557006,
      },
      {
        NOME: "Votuporanga",
        CODIGO_IBGE: 3557105,
      },
      {
        NOME: "Zacarias",
        CODIGO_IBGE: 3557154,
      },
      {
        NOME: "Chavantes",
        CODIGO_IBGE: 3557204,
      },
      {
        NOME: "Estiva Gerbi",
        CODIGO_IBGE: 3557303,
      },
    ],
    41: [
      {
        NOME: "Abatiá",
        CODIGO_IBGE: 4100103,
      },
      {
        NOME: "Adrianópolis",
        CODIGO_IBGE: 4100202,
      },
      {
        NOME: "Agudos do Sul",
        CODIGO_IBGE: 4100301,
      },
      {
        NOME: "Almirante Tamandaré",
        CODIGO_IBGE: 4100400,
      },
      {
        NOME: "Altamira do Paraná",
        CODIGO_IBGE: 4100459,
      },
      {
        NOME: "Altônia",
        CODIGO_IBGE: 4100509,
      },
      {
        NOME: "Alto Paraná",
        CODIGO_IBGE: 4100608,
      },
      {
        NOME: "Alto Piquiri",
        CODIGO_IBGE: 4100707,
      },
      {
        NOME: "Alvorada do Sul",
        CODIGO_IBGE: 4100806,
      },
      {
        NOME: "Amaporã",
        CODIGO_IBGE: 4100905,
      },
      {
        NOME: "Ampére",
        CODIGO_IBGE: 4101002,
      },
      {
        NOME: "Anahy",
        CODIGO_IBGE: 4101051,
      },
      {
        NOME: "Andirá",
        CODIGO_IBGE: 4101101,
      },
      {
        NOME: "Ângulo",
        CODIGO_IBGE: 4101150,
      },
      {
        NOME: "Antonina",
        CODIGO_IBGE: 4101200,
      },
      {
        NOME: "Antônio Olinto",
        CODIGO_IBGE: 4101309,
      },
      {
        NOME: "Apucarana",
        CODIGO_IBGE: 4101408,
      },
      {
        NOME: "Arapongas",
        CODIGO_IBGE: 4101507,
      },
      {
        NOME: "Arapoti",
        CODIGO_IBGE: 4101606,
      },
      {
        NOME: "Arapuã",
        CODIGO_IBGE: 4101655,
      },
      {
        NOME: "Araruna",
        CODIGO_IBGE: 4101705,
      },
      {
        NOME: "Araucária",
        CODIGO_IBGE: 4101804,
      },
      {
        NOME: "Ariranha do Ivaí",
        CODIGO_IBGE: 4101853,
      },
      {
        NOME: "Assaí",
        CODIGO_IBGE: 4101903,
      },
      {
        NOME: "Assis Chateaubriand",
        CODIGO_IBGE: 4102000,
      },
      {
        NOME: "Astorga",
        CODIGO_IBGE: 4102109,
      },
      {
        NOME: "Atalaia",
        CODIGO_IBGE: 4102208,
      },
      {
        NOME: "Balsa Nova",
        CODIGO_IBGE: 4102307,
      },
      {
        NOME: "Bandeirantes",
        CODIGO_IBGE: 4102406,
      },
      {
        NOME: "Barbosa Ferraz",
        CODIGO_IBGE: 4102505,
      },
      {
        NOME: "Barracão",
        CODIGO_IBGE: 4102604,
      },
      {
        NOME: "Barra do Jacaré",
        CODIGO_IBGE: 4102703,
      },
      {
        NOME: "Bela Vista da Caroba",
        CODIGO_IBGE: 4102752,
      },
      {
        NOME: "Bela Vista do Paraíso",
        CODIGO_IBGE: 4102802,
      },
      {
        NOME: "Bituruna",
        CODIGO_IBGE: 4102901,
      },
      {
        NOME: "Boa Esperança",
        CODIGO_IBGE: 4103008,
      },
      {
        NOME: "Boa Esperança do Iguaçu",
        CODIGO_IBGE: 4103024,
      },
      {
        NOME: "Boa Ventura de São Roque",
        CODIGO_IBGE: 4103040,
      },
      {
        NOME: "Boa Vista da Aparecida",
        CODIGO_IBGE: 4103057,
      },
      {
        NOME: "Bocaiúva do Sul",
        CODIGO_IBGE: 4103107,
      },
      {
        NOME: "Bom Jesus do Sul",
        CODIGO_IBGE: 4103156,
      },
      {
        NOME: "Bom Sucesso",
        CODIGO_IBGE: 4103206,
      },
      {
        NOME: "Bom Sucesso do Sul",
        CODIGO_IBGE: 4103222,
      },
      {
        NOME: "Borrazópolis",
        CODIGO_IBGE: 4103305,
      },
      {
        NOME: "Braganey",
        CODIGO_IBGE: 4103354,
      },
      {
        NOME: "Brasilândia do Sul",
        CODIGO_IBGE: 4103370,
      },
      {
        NOME: "Cafeara",
        CODIGO_IBGE: 4103404,
      },
      {
        NOME: "Cafelândia",
        CODIGO_IBGE: 4103453,
      },
      {
        NOME: "Cafezal do Sul",
        CODIGO_IBGE: 4103479,
      },
      {
        NOME: "Califórnia",
        CODIGO_IBGE: 4103503,
      },
      {
        NOME: "Cambará",
        CODIGO_IBGE: 4103602,
      },
      {
        NOME: "Cambé",
        CODIGO_IBGE: 4103701,
      },
      {
        NOME: "Cambira",
        CODIGO_IBGE: 4103800,
      },
      {
        NOME: "Campina da Lagoa",
        CODIGO_IBGE: 4103909,
      },
      {
        NOME: "Campina do Simão",
        CODIGO_IBGE: 4103958,
      },
      {
        NOME: "Campina Grande do Sul",
        CODIGO_IBGE: 4104006,
      },
      {
        NOME: "Campo Bonito",
        CODIGO_IBGE: 4104055,
      },
      {
        NOME: "Campo do Tenente",
        CODIGO_IBGE: 4104105,
      },
      {
        NOME: "Campo Largo",
        CODIGO_IBGE: 4104204,
      },
      {
        NOME: "Campo Magro",
        CODIGO_IBGE: 4104253,
      },
      {
        NOME: "Campo Mourão",
        CODIGO_IBGE: 4104303,
      },
      {
        NOME: "Cândido de Abreu",
        CODIGO_IBGE: 4104402,
      },
      {
        NOME: "Candói",
        CODIGO_IBGE: 4104428,
      },
      {
        NOME: "Cantagalo",
        CODIGO_IBGE: 4104451,
      },
      {
        NOME: "Capanema",
        CODIGO_IBGE: 4104501,
      },
      {
        NOME: "Capitão Leônidas Marques",
        CODIGO_IBGE: 4104600,
      },
      {
        NOME: "Carambeí",
        CODIGO_IBGE: 4104659,
      },
      {
        NOME: "Carlópolis",
        CODIGO_IBGE: 4104709,
      },
      {
        NOME: "Cascavel",
        CODIGO_IBGE: 4104808,
      },
      {
        NOME: "Castro",
        CODIGO_IBGE: 4104907,
      },
      {
        NOME: "Catanduvas",
        CODIGO_IBGE: 4105003,
      },
      {
        NOME: "Centenário do Sul",
        CODIGO_IBGE: 4105102,
      },
      {
        NOME: "Cerro Azul",
        CODIGO_IBGE: 4105201,
      },
      {
        NOME: "Céu Azul",
        CODIGO_IBGE: 4105300,
      },
      {
        NOME: "Chopinzinho",
        CODIGO_IBGE: 4105409,
      },
      {
        NOME: "Cianorte",
        CODIGO_IBGE: 4105508,
      },
      {
        NOME: "Cidade Gaúcha",
        CODIGO_IBGE: 4105607,
      },
      {
        NOME: "Clevelândia",
        CODIGO_IBGE: 4105706,
      },
      {
        NOME: "Colombo",
        CODIGO_IBGE: 4105805,
      },
      {
        NOME: "Colorado",
        CODIGO_IBGE: 4105904,
      },
      {
        NOME: "Congonhinhas",
        CODIGO_IBGE: 4106001,
      },
      {
        NOME: "Conselheiro Mairinck",
        CODIGO_IBGE: 4106100,
      },
      {
        NOME: "Contenda",
        CODIGO_IBGE: 4106209,
      },
      {
        NOME: "Corbélia",
        CODIGO_IBGE: 4106308,
      },
      {
        NOME: "Cornélio Procópio",
        CODIGO_IBGE: 4106407,
      },
      {
        NOME: "Coronel Domingos Soares",
        CODIGO_IBGE: 4106456,
      },
      {
        NOME: "Coronel Vivida",
        CODIGO_IBGE: 4106506,
      },
      {
        NOME: "Corumbataí do Sul",
        CODIGO_IBGE: 4106555,
      },
      {
        NOME: "Cruzeiro do Iguaçu",
        CODIGO_IBGE: 4106571,
      },
      {
        NOME: "Cruzeiro do Oeste",
        CODIGO_IBGE: 4106605,
      },
      {
        NOME: "Cruzeiro do Sul",
        CODIGO_IBGE: 4106704,
      },
      {
        NOME: "Cruz Machado",
        CODIGO_IBGE: 4106803,
      },
      {
        NOME: "Cruzmaltina",
        CODIGO_IBGE: 4106852,
      },
      {
        NOME: "Curitiba",
        CODIGO_IBGE: 4106902,
      },
      {
        NOME: "Curiúva",
        CODIGO_IBGE: 4107009,
      },
      {
        NOME: "Diamante do Norte",
        CODIGO_IBGE: 4107108,
      },
      {
        NOME: "Diamante do Sul",
        CODIGO_IBGE: 4107124,
      },
      {
        NOME: "Diamante D'Oeste",
        CODIGO_IBGE: 4107157,
      },
      {
        NOME: "Dois Vizinhos",
        CODIGO_IBGE: 4107207,
      },
      {
        NOME: "Douradina",
        CODIGO_IBGE: 4107256,
      },
      {
        NOME: "Doutor Camargo",
        CODIGO_IBGE: 4107306,
      },
      {
        NOME: "Enéas Marques",
        CODIGO_IBGE: 4107405,
      },
      {
        NOME: "Engenheiro Beltrão",
        CODIGO_IBGE: 4107504,
      },
      {
        NOME: "Esperança Nova",
        CODIGO_IBGE: 4107520,
      },
      {
        NOME: "Entre Rios do Oeste",
        CODIGO_IBGE: 4107538,
      },
      {
        NOME: "Espigão Alto do Iguaçu",
        CODIGO_IBGE: 4107546,
      },
      {
        NOME: "Farol",
        CODIGO_IBGE: 4107553,
      },
      {
        NOME: "Faxinal",
        CODIGO_IBGE: 4107603,
      },
      {
        NOME: "Fazenda Rio Grande",
        CODIGO_IBGE: 4107652,
      },
      {
        NOME: "Fênix",
        CODIGO_IBGE: 4107702,
      },
      {
        NOME: "Fernandes Pinheiro",
        CODIGO_IBGE: 4107736,
      },
      {
        NOME: "Figueira",
        CODIGO_IBGE: 4107751,
      },
      {
        NOME: "Floraí",
        CODIGO_IBGE: 4107801,
      },
      {
        NOME: "Flor da Serra do Sul",
        CODIGO_IBGE: 4107850,
      },
      {
        NOME: "Floresta",
        CODIGO_IBGE: 4107900,
      },
      {
        NOME: "Florestópolis",
        CODIGO_IBGE: 4108007,
      },
      {
        NOME: "Flórida",
        CODIGO_IBGE: 4108106,
      },
      {
        NOME: "Formosa do Oeste",
        CODIGO_IBGE: 4108205,
      },
      {
        NOME: "Foz do Iguaçu",
        CODIGO_IBGE: 4108304,
      },
      {
        NOME: "Francisco Alves",
        CODIGO_IBGE: 4108320,
      },
      {
        NOME: "Francisco Beltrão",
        CODIGO_IBGE: 4108403,
      },
      {
        NOME: "Foz do Jordão",
        CODIGO_IBGE: 4108452,
      },
      {
        NOME: "General Carneiro",
        CODIGO_IBGE: 4108502,
      },
      {
        NOME: "Godoy Moreira",
        CODIGO_IBGE: 4108551,
      },
      {
        NOME: "Goioerê",
        CODIGO_IBGE: 4108601,
      },
      {
        NOME: "Goioxim",
        CODIGO_IBGE: 4108650,
      },
      {
        NOME: "Grandes Rios",
        CODIGO_IBGE: 4108700,
      },
      {
        NOME: "Guaíra",
        CODIGO_IBGE: 4108809,
      },
      {
        NOME: "Guairaçá",
        CODIGO_IBGE: 4108908,
      },
      {
        NOME: "Guamiranga",
        CODIGO_IBGE: 4108957,
      },
      {
        NOME: "Guapirama",
        CODIGO_IBGE: 4109005,
      },
      {
        NOME: "Guaporema",
        CODIGO_IBGE: 4109104,
      },
      {
        NOME: "Guaraci",
        CODIGO_IBGE: 4109203,
      },
      {
        NOME: "Guaraniaçu",
        CODIGO_IBGE: 4109302,
      },
      {
        NOME: "Guarapuava",
        CODIGO_IBGE: 4109401,
      },
      {
        NOME: "Guaraqueçaba",
        CODIGO_IBGE: 4109500,
      },
      {
        NOME: "Guaratuba",
        CODIGO_IBGE: 4109609,
      },
      {
        NOME: "Honório Serpa",
        CODIGO_IBGE: 4109658,
      },
      {
        NOME: "Ibaiti",
        CODIGO_IBGE: 4109708,
      },
      {
        NOME: "Ibema",
        CODIGO_IBGE: 4109757,
      },
      {
        NOME: "Ibiporã",
        CODIGO_IBGE: 4109807,
      },
      {
        NOME: "Icaraíma",
        CODIGO_IBGE: 4109906,
      },
      {
        NOME: "Iguaraçu",
        CODIGO_IBGE: 4110003,
      },
      {
        NOME: "Iguatu",
        CODIGO_IBGE: 4110052,
      },
      {
        NOME: "Imbaú",
        CODIGO_IBGE: 4110078,
      },
      {
        NOME: "Imbituva",
        CODIGO_IBGE: 4110102,
      },
      {
        NOME: "Inácio Martins",
        CODIGO_IBGE: 4110201,
      },
      {
        NOME: "Inajá",
        CODIGO_IBGE: 4110300,
      },
      {
        NOME: "Indianópolis",
        CODIGO_IBGE: 4110409,
      },
      {
        NOME: "Ipiranga",
        CODIGO_IBGE: 4110508,
      },
      {
        NOME: "Iporã",
        CODIGO_IBGE: 4110607,
      },
      {
        NOME: "Iracema do Oeste",
        CODIGO_IBGE: 4110656,
      },
      {
        NOME: "Irati",
        CODIGO_IBGE: 4110706,
      },
      {
        NOME: "Iretama",
        CODIGO_IBGE: 4110805,
      },
      {
        NOME: "Itaguajé",
        CODIGO_IBGE: 4110904,
      },
      {
        NOME: "Itaipulândia",
        CODIGO_IBGE: 4110953,
      },
      {
        NOME: "Itambaracá",
        CODIGO_IBGE: 4111001,
      },
      {
        NOME: "Itambé",
        CODIGO_IBGE: 4111100,
      },
      {
        NOME: "Itapejara d'Oeste",
        CODIGO_IBGE: 4111209,
      },
      {
        NOME: "Itaperuçu",
        CODIGO_IBGE: 4111258,
      },
      {
        NOME: "Itaúna do Sul",
        CODIGO_IBGE: 4111308,
      },
      {
        NOME: "Ivaí",
        CODIGO_IBGE: 4111407,
      },
      {
        NOME: "Ivaiporã",
        CODIGO_IBGE: 4111506,
      },
      {
        NOME: "Ivaté",
        CODIGO_IBGE: 4111555,
      },
      {
        NOME: "Ivatuba",
        CODIGO_IBGE: 4111605,
      },
      {
        NOME: "Jaboti",
        CODIGO_IBGE: 4111704,
      },
      {
        NOME: "Jacarezinho",
        CODIGO_IBGE: 4111803,
      },
      {
        NOME: "Jaguapitã",
        CODIGO_IBGE: 4111902,
      },
      {
        NOME: "Jaguariaíva",
        CODIGO_IBGE: 4112009,
      },
      {
        NOME: "Jandaia do Sul",
        CODIGO_IBGE: 4112108,
      },
      {
        NOME: "Janiópolis",
        CODIGO_IBGE: 4112207,
      },
      {
        NOME: "Japira",
        CODIGO_IBGE: 4112306,
      },
      {
        NOME: "Japurá",
        CODIGO_IBGE: 4112405,
      },
      {
        NOME: "Jardim Alegre",
        CODIGO_IBGE: 4112504,
      },
      {
        NOME: "Jardim Olinda",
        CODIGO_IBGE: 4112603,
      },
      {
        NOME: "Jataizinho",
        CODIGO_IBGE: 4112702,
      },
      {
        NOME: "Jesuítas",
        CODIGO_IBGE: 4112751,
      },
      {
        NOME: "Joaquim Távora",
        CODIGO_IBGE: 4112801,
      },
      {
        NOME: "Jundiaí do Sul",
        CODIGO_IBGE: 4112900,
      },
      {
        NOME: "Juranda",
        CODIGO_IBGE: 4112959,
      },
      {
        NOME: "Jussara",
        CODIGO_IBGE: 4113007,
      },
      {
        NOME: "Kaloré",
        CODIGO_IBGE: 4113106,
      },
      {
        NOME: "Lapa",
        CODIGO_IBGE: 4113205,
      },
      {
        NOME: "Laranjal",
        CODIGO_IBGE: 4113254,
      },
      {
        NOME: "Laranjeiras do Sul",
        CODIGO_IBGE: 4113304,
      },
      {
        NOME: "Leópolis",
        CODIGO_IBGE: 4113403,
      },
      {
        NOME: "Lidianópolis",
        CODIGO_IBGE: 4113429,
      },
      {
        NOME: "Lindoeste",
        CODIGO_IBGE: 4113452,
      },
      {
        NOME: "Loanda",
        CODIGO_IBGE: 4113502,
      },
      {
        NOME: "Lobato",
        CODIGO_IBGE: 4113601,
      },
      {
        NOME: "Londrina",
        CODIGO_IBGE: 4113700,
      },
      {
        NOME: "Luiziana",
        CODIGO_IBGE: 4113734,
      },
      {
        NOME: "Lunardelli",
        CODIGO_IBGE: 4113759,
      },
      {
        NOME: "Lupionópolis",
        CODIGO_IBGE: 4113809,
      },
      {
        NOME: "Mallet",
        CODIGO_IBGE: 4113908,
      },
      {
        NOME: "Mamborê",
        CODIGO_IBGE: 4114005,
      },
      {
        NOME: "Mandaguaçu",
        CODIGO_IBGE: 4114104,
      },
      {
        NOME: "Mandaguari",
        CODIGO_IBGE: 4114203,
      },
      {
        NOME: "Mandirituba",
        CODIGO_IBGE: 4114302,
      },
      {
        NOME: "Manfrinópolis",
        CODIGO_IBGE: 4114351,
      },
      {
        NOME: "Mangueirinha",
        CODIGO_IBGE: 4114401,
      },
      {
        NOME: "Manoel Ribas",
        CODIGO_IBGE: 4114500,
      },
      {
        NOME: "Marechal Cândido Rondon",
        CODIGO_IBGE: 4114609,
      },
      {
        NOME: "Maria Helena",
        CODIGO_IBGE: 4114708,
      },
      {
        NOME: "Marialva",
        CODIGO_IBGE: 4114807,
      },
      {
        NOME: "Marilândia do Sul",
        CODIGO_IBGE: 4114906,
      },
      {
        NOME: "Marilena",
        CODIGO_IBGE: 4115002,
      },
      {
        NOME: "Mariluz",
        CODIGO_IBGE: 4115101,
      },
      {
        NOME: "Maringá",
        CODIGO_IBGE: 4115200,
      },
      {
        NOME: "Mariópolis",
        CODIGO_IBGE: 4115309,
      },
      {
        NOME: "Maripá",
        CODIGO_IBGE: 4115358,
      },
      {
        NOME: "Marmeleiro",
        CODIGO_IBGE: 4115408,
      },
      {
        NOME: "Marquinho",
        CODIGO_IBGE: 4115457,
      },
      {
        NOME: "Marumbi",
        CODIGO_IBGE: 4115507,
      },
      {
        NOME: "Matelândia",
        CODIGO_IBGE: 4115606,
      },
      {
        NOME: "Matinhos",
        CODIGO_IBGE: 4115705,
      },
      {
        NOME: "Mato Rico",
        CODIGO_IBGE: 4115739,
      },
      {
        NOME: "Mauá da Serra",
        CODIGO_IBGE: 4115754,
      },
      {
        NOME: "Medianeira",
        CODIGO_IBGE: 4115804,
      },
      {
        NOME: "Mercedes",
        CODIGO_IBGE: 4115853,
      },
      {
        NOME: "Mirador",
        CODIGO_IBGE: 4115903,
      },
      {
        NOME: "Miraselva",
        CODIGO_IBGE: 4116000,
      },
      {
        NOME: "Missal",
        CODIGO_IBGE: 4116059,
      },
      {
        NOME: "Moreira Sales",
        CODIGO_IBGE: 4116109,
      },
      {
        NOME: "Morretes",
        CODIGO_IBGE: 4116208,
      },
      {
        NOME: "Munhoz de Melo",
        CODIGO_IBGE: 4116307,
      },
      {
        NOME: "Nossa Senhora das Graças",
        CODIGO_IBGE: 4116406,
      },
      {
        NOME: "Nova Aliança do Ivaí",
        CODIGO_IBGE: 4116505,
      },
      {
        NOME: "Nova América da Colina",
        CODIGO_IBGE: 4116604,
      },
      {
        NOME: "Nova Aurora",
        CODIGO_IBGE: 4116703,
      },
      {
        NOME: "Nova Cantu",
        CODIGO_IBGE: 4116802,
      },
      {
        NOME: "Nova Esperança",
        CODIGO_IBGE: 4116901,
      },
      {
        NOME: "Nova Esperança do Sudoeste",
        CODIGO_IBGE: 4116950,
      },
      {
        NOME: "Nova Fátima",
        CODIGO_IBGE: 4117008,
      },
      {
        NOME: "Nova Laranjeiras",
        CODIGO_IBGE: 4117057,
      },
      {
        NOME: "Nova Londrina",
        CODIGO_IBGE: 4117107,
      },
      {
        NOME: "Nova Olímpia",
        CODIGO_IBGE: 4117206,
      },
      {
        NOME: "Nova Santa Bárbara",
        CODIGO_IBGE: 4117214,
      },
      {
        NOME: "Nova Santa Rosa",
        CODIGO_IBGE: 4117222,
      },
      {
        NOME: "Nova Prata do Iguaçu",
        CODIGO_IBGE: 4117255,
      },
      {
        NOME: "Nova Tebas",
        CODIGO_IBGE: 4117271,
      },
      {
        NOME: "Novo Itacolomi",
        CODIGO_IBGE: 4117297,
      },
      {
        NOME: "Ortigueira",
        CODIGO_IBGE: 4117305,
      },
      {
        NOME: "Ourizona",
        CODIGO_IBGE: 4117404,
      },
      {
        NOME: "Ouro Verde do Oeste",
        CODIGO_IBGE: 4117453,
      },
      {
        NOME: "Paiçandu",
        CODIGO_IBGE: 4117503,
      },
      {
        NOME: "Palmas",
        CODIGO_IBGE: 4117602,
      },
      {
        NOME: "Palmeira",
        CODIGO_IBGE: 4117701,
      },
      {
        NOME: "Palmital",
        CODIGO_IBGE: 4117800,
      },
      {
        NOME: "Palotina",
        CODIGO_IBGE: 4117909,
      },
      {
        NOME: "Paraíso do Norte",
        CODIGO_IBGE: 4118006,
      },
      {
        NOME: "Paranacity",
        CODIGO_IBGE: 4118105,
      },
      {
        NOME: "Paranaguá",
        CODIGO_IBGE: 4118204,
      },
      {
        NOME: "Paranapoema",
        CODIGO_IBGE: 4118303,
      },
      {
        NOME: "Paranavaí",
        CODIGO_IBGE: 4118402,
      },
      {
        NOME: "Pato Bragado",
        CODIGO_IBGE: 4118451,
      },
      {
        NOME: "Pato Branco",
        CODIGO_IBGE: 4118501,
      },
      {
        NOME: "Paula Freitas",
        CODIGO_IBGE: 4118600,
      },
      {
        NOME: "Paulo Frontin",
        CODIGO_IBGE: 4118709,
      },
      {
        NOME: "Peabiru",
        CODIGO_IBGE: 4118808,
      },
      {
        NOME: "Perobal",
        CODIGO_IBGE: 4118857,
      },
      {
        NOME: "Pérola",
        CODIGO_IBGE: 4118907,
      },
      {
        NOME: "Pérola d'Oeste",
        CODIGO_IBGE: 4119004,
      },
      {
        NOME: "Piên",
        CODIGO_IBGE: 4119103,
      },
      {
        NOME: "Pinhais",
        CODIGO_IBGE: 4119152,
      },
      {
        NOME: "Pinhalão",
        CODIGO_IBGE: 4119202,
      },
      {
        NOME: "Pinhal de São Bento",
        CODIGO_IBGE: 4119251,
      },
      {
        NOME: "Pinhão",
        CODIGO_IBGE: 4119301,
      },
      {
        NOME: "Piraí do Sul",
        CODIGO_IBGE: 4119400,
      },
      {
        NOME: "Piraquara",
        CODIGO_IBGE: 4119509,
      },
      {
        NOME: "Pitanga",
        CODIGO_IBGE: 4119608,
      },
      {
        NOME: "Pitangueiras",
        CODIGO_IBGE: 4119657,
      },
      {
        NOME: "Planaltina do Paraná",
        CODIGO_IBGE: 4119707,
      },
      {
        NOME: "Planalto",
        CODIGO_IBGE: 4119806,
      },
      {
        NOME: "Ponta Grossa",
        CODIGO_IBGE: 4119905,
      },
      {
        NOME: "Pontal do Paraná",
        CODIGO_IBGE: 4119954,
      },
      {
        NOME: "Porecatu",
        CODIGO_IBGE: 4120002,
      },
      {
        NOME: "Porto Amazonas",
        CODIGO_IBGE: 4120101,
      },
      {
        NOME: "Porto Barreiro",
        CODIGO_IBGE: 4120150,
      },
      {
        NOME: "Porto Rico",
        CODIGO_IBGE: 4120200,
      },
      {
        NOME: "Porto Vitória",
        CODIGO_IBGE: 4120309,
      },
      {
        NOME: "Prado Ferreira",
        CODIGO_IBGE: 4120333,
      },
      {
        NOME: "Pranchita",
        CODIGO_IBGE: 4120358,
      },
      {
        NOME: "Presidente Castelo Branco",
        CODIGO_IBGE: 4120408,
      },
      {
        NOME: "Primeiro de Maio",
        CODIGO_IBGE: 4120507,
      },
      {
        NOME: "Prudentópolis",
        CODIGO_IBGE: 4120606,
      },
      {
        NOME: "Quarto Centenário",
        CODIGO_IBGE: 4120655,
      },
      {
        NOME: "Quatiguá",
        CODIGO_IBGE: 4120705,
      },
      {
        NOME: "Quatro Barras",
        CODIGO_IBGE: 4120804,
      },
      {
        NOME: "Quatro Pontes",
        CODIGO_IBGE: 4120853,
      },
      {
        NOME: "Quedas do Iguaçu",
        CODIGO_IBGE: 4120903,
      },
      {
        NOME: "Querência do Norte",
        CODIGO_IBGE: 4121000,
      },
      {
        NOME: "Quinta do Sol",
        CODIGO_IBGE: 4121109,
      },
      {
        NOME: "Quitandinha",
        CODIGO_IBGE: 4121208,
      },
      {
        NOME: "Ramilândia",
        CODIGO_IBGE: 4121257,
      },
      {
        NOME: "Rancho Alegre",
        CODIGO_IBGE: 4121307,
      },
      {
        NOME: "Rancho Alegre D'Oeste",
        CODIGO_IBGE: 4121356,
      },
      {
        NOME: "Realeza",
        CODIGO_IBGE: 4121406,
      },
      {
        NOME: "Rebouças",
        CODIGO_IBGE: 4121505,
      },
      {
        NOME: "Renascença",
        CODIGO_IBGE: 4121604,
      },
      {
        NOME: "Reserva",
        CODIGO_IBGE: 4121703,
      },
      {
        NOME: "Reserva do Iguaçu",
        CODIGO_IBGE: 4121752,
      },
      {
        NOME: "Ribeirão Claro",
        CODIGO_IBGE: 4121802,
      },
      {
        NOME: "Ribeirão do Pinhal",
        CODIGO_IBGE: 4121901,
      },
      {
        NOME: "Rio Azul",
        CODIGO_IBGE: 4122008,
      },
      {
        NOME: "Rio Bom",
        CODIGO_IBGE: 4122107,
      },
      {
        NOME: "Rio Bonito do Iguaçu",
        CODIGO_IBGE: 4122156,
      },
      {
        NOME: "Rio Branco do Ivaí",
        CODIGO_IBGE: 4122172,
      },
      {
        NOME: "Rio Branco do Sul",
        CODIGO_IBGE: 4122206,
      },
      {
        NOME: "Rio Negro",
        CODIGO_IBGE: 4122305,
      },
      {
        NOME: "Rolândia",
        CODIGO_IBGE: 4122404,
      },
      {
        NOME: "Roncador",
        CODIGO_IBGE: 4122503,
      },
      {
        NOME: "Rondon",
        CODIGO_IBGE: 4122602,
      },
      {
        NOME: "Rosário do Ivaí",
        CODIGO_IBGE: 4122651,
      },
      {
        NOME: "Sabáudia",
        CODIGO_IBGE: 4122701,
      },
      {
        NOME: "Salgado Filho",
        CODIGO_IBGE: 4122800,
      },
      {
        NOME: "Salto do Itararé",
        CODIGO_IBGE: 4122909,
      },
      {
        NOME: "Salto do Lontra",
        CODIGO_IBGE: 4123006,
      },
      {
        NOME: "Santa Amélia",
        CODIGO_IBGE: 4123105,
      },
      {
        NOME: "Santa Cecília do Pavão",
        CODIGO_IBGE: 4123204,
      },
      {
        NOME: "Santa Cruz de Monte Castelo",
        CODIGO_IBGE: 4123303,
      },
      {
        NOME: "Santa Fé",
        CODIGO_IBGE: 4123402,
      },
      {
        NOME: "Santa Helena",
        CODIGO_IBGE: 4123501,
      },
      {
        NOME: "Santa Inês",
        CODIGO_IBGE: 4123600,
      },
      {
        NOME: "Santa Isabel do Ivaí",
        CODIGO_IBGE: 4123709,
      },
      {
        NOME: "Santa Izabel do Oeste",
        CODIGO_IBGE: 4123808,
      },
      {
        NOME: "Santa Lúcia",
        CODIGO_IBGE: 4123824,
      },
      {
        NOME: "Santa Maria do Oeste",
        CODIGO_IBGE: 4123857,
      },
      {
        NOME: "Santa Mariana",
        CODIGO_IBGE: 4123907,
      },
      {
        NOME: "Santa Mônica",
        CODIGO_IBGE: 4123956,
      },
      {
        NOME: "Santana do Itararé",
        CODIGO_IBGE: 4124004,
      },
      {
        NOME: "Santa Tereza do Oeste",
        CODIGO_IBGE: 4124020,
      },
      {
        NOME: "Santa Terezinha de Itaipu",
        CODIGO_IBGE: 4124053,
      },
      {
        NOME: "Santo Antônio da Platina",
        CODIGO_IBGE: 4124103,
      },
      {
        NOME: "Santo Antônio do Caiuá",
        CODIGO_IBGE: 4124202,
      },
      {
        NOME: "Santo Antônio do Paraíso",
        CODIGO_IBGE: 4124301,
      },
      {
        NOME: "Santo Antônio do Sudoeste",
        CODIGO_IBGE: 4124400,
      },
      {
        NOME: "Santo Inácio",
        CODIGO_IBGE: 4124509,
      },
      {
        NOME: "São Carlos do Ivaí",
        CODIGO_IBGE: 4124608,
      },
      {
        NOME: "São Jerônimo da Serra",
        CODIGO_IBGE: 4124707,
      },
      {
        NOME: "São João",
        CODIGO_IBGE: 4124806,
      },
      {
        NOME: "São João do Caiuá",
        CODIGO_IBGE: 4124905,
      },
      {
        NOME: "São João do Ivaí",
        CODIGO_IBGE: 4125001,
      },
      {
        NOME: "São João do Triunfo",
        CODIGO_IBGE: 4125100,
      },
      {
        NOME: "São Jorge d'Oeste",
        CODIGO_IBGE: 4125209,
      },
      {
        NOME: "São Jorge do Ivaí",
        CODIGO_IBGE: 4125308,
      },
      {
        NOME: "São Jorge do Patrocínio",
        CODIGO_IBGE: 4125357,
      },
      {
        NOME: "São José da Boa Vista",
        CODIGO_IBGE: 4125407,
      },
      {
        NOME: "São José das Palmeiras",
        CODIGO_IBGE: 4125456,
      },
      {
        NOME: "São José dos Pinhais",
        CODIGO_IBGE: 4125506,
      },
      {
        NOME: "São Manoel do Paraná",
        CODIGO_IBGE: 4125555,
      },
      {
        NOME: "São Mateus do Sul",
        CODIGO_IBGE: 4125605,
      },
      {
        NOME: "São Miguel do Iguaçu",
        CODIGO_IBGE: 4125704,
      },
      {
        NOME: "São Pedro do Iguaçu",
        CODIGO_IBGE: 4125753,
      },
      {
        NOME: "São Pedro do Ivaí",
        CODIGO_IBGE: 4125803,
      },
      {
        NOME: "São Pedro do Paraná",
        CODIGO_IBGE: 4125902,
      },
      {
        NOME: "São Sebastião da Amoreira",
        CODIGO_IBGE: 4126009,
      },
      {
        NOME: "São Tomé",
        CODIGO_IBGE: 4126108,
      },
      {
        NOME: "Sapopema",
        CODIGO_IBGE: 4126207,
      },
      {
        NOME: "Sarandi",
        CODIGO_IBGE: 4126256,
      },
      {
        NOME: "Saudade do Iguaçu",
        CODIGO_IBGE: 4126272,
      },
      {
        NOME: "Sengés",
        CODIGO_IBGE: 4126306,
      },
      {
        NOME: "Serranópolis do Iguaçu",
        CODIGO_IBGE: 4126355,
      },
      {
        NOME: "Sertaneja",
        CODIGO_IBGE: 4126405,
      },
      {
        NOME: "Sertanópolis",
        CODIGO_IBGE: 4126504,
      },
      {
        NOME: "Siqueira Campos",
        CODIGO_IBGE: 4126603,
      },
      {
        NOME: "Sulina",
        CODIGO_IBGE: 4126652,
      },
      {
        NOME: "Tamarana",
        CODIGO_IBGE: 4126678,
      },
      {
        NOME: "Tamboara",
        CODIGO_IBGE: 4126702,
      },
      {
        NOME: "Tapejara",
        CODIGO_IBGE: 4126801,
      },
      {
        NOME: "Tapira",
        CODIGO_IBGE: 4126900,
      },
      {
        NOME: "Teixeira Soares",
        CODIGO_IBGE: 4127007,
      },
      {
        NOME: "Telêmaco Borba",
        CODIGO_IBGE: 4127106,
      },
      {
        NOME: "Terra Boa",
        CODIGO_IBGE: 4127205,
      },
      {
        NOME: "Terra Rica",
        CODIGO_IBGE: 4127304,
      },
      {
        NOME: "Terra Roxa",
        CODIGO_IBGE: 4127403,
      },
      {
        NOME: "Tibagi",
        CODIGO_IBGE: 4127502,
      },
      {
        NOME: "Tijucas do Sul",
        CODIGO_IBGE: 4127601,
      },
      {
        NOME: "Toledo",
        CODIGO_IBGE: 4127700,
      },
      {
        NOME: "Tomazina",
        CODIGO_IBGE: 4127809,
      },
      {
        NOME: "Três Barras do Paraná",
        CODIGO_IBGE: 4127858,
      },
      {
        NOME: "Tunas do Paraná",
        CODIGO_IBGE: 4127882,
      },
      {
        NOME: "Tuneiras do Oeste",
        CODIGO_IBGE: 4127908,
      },
      {
        NOME: "Tupãssi",
        CODIGO_IBGE: 4127957,
      },
      {
        NOME: "Turvo",
        CODIGO_IBGE: 4127965,
      },
      {
        NOME: "Ubiratã",
        CODIGO_IBGE: 4128005,
      },
      {
        NOME: "Umuarama",
        CODIGO_IBGE: 4128104,
      },
      {
        NOME: "União da Vitória",
        CODIGO_IBGE: 4128203,
      },
      {
        NOME: "Uniflor",
        CODIGO_IBGE: 4128302,
      },
      {
        NOME: "Uraí",
        CODIGO_IBGE: 4128401,
      },
      {
        NOME: "Wenceslau Braz",
        CODIGO_IBGE: 4128500,
      },
      {
        NOME: "Ventania",
        CODIGO_IBGE: 4128534,
      },
      {
        NOME: "Vera Cruz do Oeste",
        CODIGO_IBGE: 4128559,
      },
      {
        NOME: "Verê",
        CODIGO_IBGE: 4128609,
      },
      {
        NOME: "Alto Paraíso",
        CODIGO_IBGE: 4128625,
      },
      {
        NOME: "Doutor Ulysses",
        CODIGO_IBGE: 4128633,
      },
      {
        NOME: "Virmond",
        CODIGO_IBGE: 4128658,
      },
      {
        NOME: "Vitorino",
        CODIGO_IBGE: 4128708,
      },
      {
        NOME: "Xambrê",
        CODIGO_IBGE: 4128807,
      },
    ],
    42: [
      {
        NOME: "Abdon Batista",
        CODIGO_IBGE: 4200051,
      },
      {
        NOME: "Abelardo Luz",
        CODIGO_IBGE: 4200101,
      },
      {
        NOME: "Agrolândia",
        CODIGO_IBGE: 4200200,
      },
      {
        NOME: "Agronômica",
        CODIGO_IBGE: 4200309,
      },
      {
        NOME: "Água Doce",
        CODIGO_IBGE: 4200408,
      },
      {
        NOME: "Águas de Chapecó",
        CODIGO_IBGE: 4200507,
      },
      {
        NOME: "Águas Frias",
        CODIGO_IBGE: 4200556,
      },
      {
        NOME: "Águas Mornas",
        CODIGO_IBGE: 4200606,
      },
      {
        NOME: "Alfredo Wagner",
        CODIGO_IBGE: 4200705,
      },
      {
        NOME: "Alto Bela Vista",
        CODIGO_IBGE: 4200754,
      },
      {
        NOME: "Anchieta",
        CODIGO_IBGE: 4200804,
      },
      {
        NOME: "Angelina",
        CODIGO_IBGE: 4200903,
      },
      {
        NOME: "Anita Garibaldi",
        CODIGO_IBGE: 4201000,
      },
      {
        NOME: "Anitápolis",
        CODIGO_IBGE: 4201109,
      },
      {
        NOME: "Antônio Carlos",
        CODIGO_IBGE: 4201208,
      },
      {
        NOME: "Apiúna",
        CODIGO_IBGE: 4201257,
      },
      {
        NOME: "Arabutã",
        CODIGO_IBGE: 4201273,
      },
      {
        NOME: "Araquari",
        CODIGO_IBGE: 4201307,
      },
      {
        NOME: "Araranguá",
        CODIGO_IBGE: 4201406,
      },
      {
        NOME: "Armazém",
        CODIGO_IBGE: 4201505,
      },
      {
        NOME: "Arroio Trinta",
        CODIGO_IBGE: 4201604,
      },
      {
        NOME: "Arvoredo",
        CODIGO_IBGE: 4201653,
      },
      {
        NOME: "Ascurra",
        CODIGO_IBGE: 4201703,
      },
      {
        NOME: "Atalanta",
        CODIGO_IBGE: 4201802,
      },
      {
        NOME: "Aurora",
        CODIGO_IBGE: 4201901,
      },
      {
        NOME: "Balneário Arroio do Silva",
        CODIGO_IBGE: 4201950,
      },
      {
        NOME: "Balneário Camboriú",
        CODIGO_IBGE: 4202008,
      },
      {
        NOME: "Balneário Barra do Sul",
        CODIGO_IBGE: 4202057,
      },
      {
        NOME: "Balneário Gaivota",
        CODIGO_IBGE: 4202073,
      },
      {
        NOME: "Bandeirante",
        CODIGO_IBGE: 4202081,
      },
      {
        NOME: "Barra Bonita",
        CODIGO_IBGE: 4202099,
      },
      {
        NOME: "Barra Velha",
        CODIGO_IBGE: 4202107,
      },
      {
        NOME: "Bela Vista do Toldo",
        CODIGO_IBGE: 4202131,
      },
      {
        NOME: "Belmonte",
        CODIGO_IBGE: 4202156,
      },
      {
        NOME: "Benedito Novo",
        CODIGO_IBGE: 4202206,
      },
      {
        NOME: "Biguaçu",
        CODIGO_IBGE: 4202305,
      },
      {
        NOME: "Blumenau",
        CODIGO_IBGE: 4202404,
      },
      {
        NOME: "Bocaina do Sul",
        CODIGO_IBGE: 4202438,
      },
      {
        NOME: "Bombinhas",
        CODIGO_IBGE: 4202453,
      },
      {
        NOME: "Bom Jardim da Serra",
        CODIGO_IBGE: 4202503,
      },
      {
        NOME: "Bom Jesus",
        CODIGO_IBGE: 4202537,
      },
      {
        NOME: "Bom Jesus do Oeste",
        CODIGO_IBGE: 4202578,
      },
      {
        NOME: "Bom Retiro",
        CODIGO_IBGE: 4202602,
      },
      {
        NOME: "Botuverá",
        CODIGO_IBGE: 4202701,
      },
      {
        NOME: "Braço do Norte",
        CODIGO_IBGE: 4202800,
      },
      {
        NOME: "Braço do Trombudo",
        CODIGO_IBGE: 4202859,
      },
      {
        NOME: "Brunópolis",
        CODIGO_IBGE: 4202875,
      },
      {
        NOME: "Brusque",
        CODIGO_IBGE: 4202909,
      },
      {
        NOME: "Caçador",
        CODIGO_IBGE: 4203006,
      },
      {
        NOME: "Caibi",
        CODIGO_IBGE: 4203105,
      },
      {
        NOME: "Calmon",
        CODIGO_IBGE: 4203154,
      },
      {
        NOME: "Camboriú",
        CODIGO_IBGE: 4203204,
      },
      {
        NOME: "Capão Alto",
        CODIGO_IBGE: 4203253,
      },
      {
        NOME: "Campo Alegre",
        CODIGO_IBGE: 4203303,
      },
      {
        NOME: "Campo Belo do Sul",
        CODIGO_IBGE: 4203402,
      },
      {
        NOME: "Campo Erê",
        CODIGO_IBGE: 4203501,
      },
      {
        NOME: "Campos Novos",
        CODIGO_IBGE: 4203600,
      },
      {
        NOME: "Canelinha",
        CODIGO_IBGE: 4203709,
      },
      {
        NOME: "Canoinhas",
        CODIGO_IBGE: 4203808,
      },
      {
        NOME: "Capinzal",
        CODIGO_IBGE: 4203907,
      },
      {
        NOME: "Capivari de Baixo",
        CODIGO_IBGE: 4203956,
      },
      {
        NOME: "Catanduvas",
        CODIGO_IBGE: 4204004,
      },
      {
        NOME: "Caxambu do Sul",
        CODIGO_IBGE: 4204103,
      },
      {
        NOME: "Celso Ramos",
        CODIGO_IBGE: 4204152,
      },
      {
        NOME: "Cerro Negro",
        CODIGO_IBGE: 4204178,
      },
      {
        NOME: "Chapadão do Lageado",
        CODIGO_IBGE: 4204194,
      },
      {
        NOME: "Chapecó",
        CODIGO_IBGE: 4204202,
      },
      {
        NOME: "Cocal do Sul",
        CODIGO_IBGE: 4204251,
      },
      {
        NOME: "Concórdia",
        CODIGO_IBGE: 4204301,
      },
      {
        NOME: "Cordilheira Alta",
        CODIGO_IBGE: 4204350,
      },
      {
        NOME: "Coronel Freitas",
        CODIGO_IBGE: 4204400,
      },
      {
        NOME: "Coronel Martins",
        CODIGO_IBGE: 4204459,
      },
      {
        NOME: "Corupá",
        CODIGO_IBGE: 4204509,
      },
      {
        NOME: "Correia Pinto",
        CODIGO_IBGE: 4204558,
      },
      {
        NOME: "Criciúma",
        CODIGO_IBGE: 4204608,
      },
      {
        NOME: "Cunha Porã",
        CODIGO_IBGE: 4204707,
      },
      {
        NOME: "Cunhataí",
        CODIGO_IBGE: 4204756,
      },
      {
        NOME: "Curitibanos",
        CODIGO_IBGE: 4204806,
      },
      {
        NOME: "Descanso",
        CODIGO_IBGE: 4204905,
      },
      {
        NOME: "Dionísio Cerqueira",
        CODIGO_IBGE: 4205001,
      },
      {
        NOME: "Dona Emma",
        CODIGO_IBGE: 4205100,
      },
      {
        NOME: "Doutor Pedrinho",
        CODIGO_IBGE: 4205159,
      },
      {
        NOME: "Entre Rios",
        CODIGO_IBGE: 4205175,
      },
      {
        NOME: "Ermo",
        CODIGO_IBGE: 4205191,
      },
      {
        NOME: "Erval Velho",
        CODIGO_IBGE: 4205209,
      },
      {
        NOME: "Faxinal dos Guedes",
        CODIGO_IBGE: 4205308,
      },
      {
        NOME: "Flor do Sertão",
        CODIGO_IBGE: 4205357,
      },
      {
        NOME: "Florianópolis",
        CODIGO_IBGE: 4205407,
      },
      {
        NOME: "Formosa do Sul",
        CODIGO_IBGE: 4205431,
      },
      {
        NOME: "Forquilhinha",
        CODIGO_IBGE: 4205456,
      },
      {
        NOME: "Fraiburgo",
        CODIGO_IBGE: 4205506,
      },
      {
        NOME: "Frei Rogério",
        CODIGO_IBGE: 4205555,
      },
      {
        NOME: "Galvão",
        CODIGO_IBGE: 4205605,
      },
      {
        NOME: "Garopaba",
        CODIGO_IBGE: 4205704,
      },
      {
        NOME: "Garuva",
        CODIGO_IBGE: 4205803,
      },
      {
        NOME: "Gaspar",
        CODIGO_IBGE: 4205902,
      },
      {
        NOME: "Governador Celso Ramos",
        CODIGO_IBGE: 4206009,
      },
      {
        NOME: "Grão Pará",
        CODIGO_IBGE: 4206108,
      },
      {
        NOME: "Gravatal",
        CODIGO_IBGE: 4206207,
      },
      {
        NOME: "Guabiruba",
        CODIGO_IBGE: 4206306,
      },
      {
        NOME: "Guaraciaba",
        CODIGO_IBGE: 4206405,
      },
      {
        NOME: "Guaramirim",
        CODIGO_IBGE: 4206504,
      },
      {
        NOME: "Guarujá do Sul",
        CODIGO_IBGE: 4206603,
      },
      {
        NOME: "Guatambú",
        CODIGO_IBGE: 4206652,
      },
      {
        NOME: "Herval d'Oeste",
        CODIGO_IBGE: 4206702,
      },
      {
        NOME: "Ibiam",
        CODIGO_IBGE: 4206751,
      },
      {
        NOME: "Ibicaré",
        CODIGO_IBGE: 4206801,
      },
      {
        NOME: "Ibirama",
        CODIGO_IBGE: 4206900,
      },
      {
        NOME: "Içara",
        CODIGO_IBGE: 4207007,
      },
      {
        NOME: "Ilhota",
        CODIGO_IBGE: 4207106,
      },
      {
        NOME: "Imaruí",
        CODIGO_IBGE: 4207205,
      },
      {
        NOME: "Imbituba",
        CODIGO_IBGE: 4207304,
      },
      {
        NOME: "Imbuia",
        CODIGO_IBGE: 4207403,
      },
      {
        NOME: "Indaial",
        CODIGO_IBGE: 4207502,
      },
      {
        NOME: "Iomerê",
        CODIGO_IBGE: 4207577,
      },
      {
        NOME: "Ipira",
        CODIGO_IBGE: 4207601,
      },
      {
        NOME: "Iporã do Oeste",
        CODIGO_IBGE: 4207650,
      },
      {
        NOME: "Ipuaçu",
        CODIGO_IBGE: 4207684,
      },
      {
        NOME: "Ipumirim",
        CODIGO_IBGE: 4207700,
      },
      {
        NOME: "Iraceminha",
        CODIGO_IBGE: 4207759,
      },
      {
        NOME: "Irani",
        CODIGO_IBGE: 4207809,
      },
      {
        NOME: "Irati",
        CODIGO_IBGE: 4207858,
      },
      {
        NOME: "Irineópolis",
        CODIGO_IBGE: 4207908,
      },
      {
        NOME: "Itá",
        CODIGO_IBGE: 4208005,
      },
      {
        NOME: "Itaiópolis",
        CODIGO_IBGE: 4208104,
      },
      {
        NOME: "Itajaí",
        CODIGO_IBGE: 4208203,
      },
      {
        NOME: "Itapema",
        CODIGO_IBGE: 4208302,
      },
      {
        NOME: "Itapiranga",
        CODIGO_IBGE: 4208401,
      },
      {
        NOME: "Itapoá",
        CODIGO_IBGE: 4208450,
      },
      {
        NOME: "Ituporanga",
        CODIGO_IBGE: 4208500,
      },
      {
        NOME: "Jaborá",
        CODIGO_IBGE: 4208609,
      },
      {
        NOME: "Jacinto Machado",
        CODIGO_IBGE: 4208708,
      },
      {
        NOME: "Jaguaruna",
        CODIGO_IBGE: 4208807,
      },
      {
        NOME: "Jaraguá do Sul",
        CODIGO_IBGE: 4208906,
      },
      {
        NOME: "Jardinópolis",
        CODIGO_IBGE: 4208955,
      },
      {
        NOME: "Joaçaba",
        CODIGO_IBGE: 4209003,
      },
      {
        NOME: "Joinville",
        CODIGO_IBGE: 4209102,
      },
      {
        NOME: "José Boiteux",
        CODIGO_IBGE: 4209151,
      },
      {
        NOME: "Jupiá",
        CODIGO_IBGE: 4209177,
      },
      {
        NOME: "Lacerdópolis",
        CODIGO_IBGE: 4209201,
      },
      {
        NOME: "Lages",
        CODIGO_IBGE: 4209300,
      },
      {
        NOME: "Laguna",
        CODIGO_IBGE: 4209409,
      },
      {
        NOME: "Lajeado Grande",
        CODIGO_IBGE: 4209458,
      },
      {
        NOME: "Laurentino",
        CODIGO_IBGE: 4209508,
      },
      {
        NOME: "Lauro Müller",
        CODIGO_IBGE: 4209607,
      },
      {
        NOME: "Lebon Régis",
        CODIGO_IBGE: 4209706,
      },
      {
        NOME: "Leoberto Leal",
        CODIGO_IBGE: 4209805,
      },
      {
        NOME: "Lindóia do Sul",
        CODIGO_IBGE: 4209854,
      },
      {
        NOME: "Lontras",
        CODIGO_IBGE: 4209904,
      },
      {
        NOME: "Luiz Alves",
        CODIGO_IBGE: 4210001,
      },
      {
        NOME: "Luzerna",
        CODIGO_IBGE: 4210035,
      },
      {
        NOME: "Macieira",
        CODIGO_IBGE: 4210050,
      },
      {
        NOME: "Mafra",
        CODIGO_IBGE: 4210100,
      },
      {
        NOME: "Major Gercino",
        CODIGO_IBGE: 4210209,
      },
      {
        NOME: "Major Vieira",
        CODIGO_IBGE: 4210308,
      },
      {
        NOME: "Maracajá",
        CODIGO_IBGE: 4210407,
      },
      {
        NOME: "Maravilha",
        CODIGO_IBGE: 4210506,
      },
      {
        NOME: "Marema",
        CODIGO_IBGE: 4210555,
      },
      {
        NOME: "Massaranduba",
        CODIGO_IBGE: 4210605,
      },
      {
        NOME: "Matos Costa",
        CODIGO_IBGE: 4210704,
      },
      {
        NOME: "Meleiro",
        CODIGO_IBGE: 4210803,
      },
      {
        NOME: "Mirim Doce",
        CODIGO_IBGE: 4210852,
      },
      {
        NOME: "Modelo",
        CODIGO_IBGE: 4210902,
      },
      {
        NOME: "Mondaí",
        CODIGO_IBGE: 4211009,
      },
      {
        NOME: "Monte Carlo",
        CODIGO_IBGE: 4211058,
      },
      {
        NOME: "Monte Castelo",
        CODIGO_IBGE: 4211108,
      },
      {
        NOME: "Morro da Fumaça",
        CODIGO_IBGE: 4211207,
      },
      {
        NOME: "Morro Grande",
        CODIGO_IBGE: 4211256,
      },
      {
        NOME: "Navegantes",
        CODIGO_IBGE: 4211306,
      },
      {
        NOME: "Nova Erechim",
        CODIGO_IBGE: 4211405,
      },
      {
        NOME: "Nova Itaberaba",
        CODIGO_IBGE: 4211454,
      },
      {
        NOME: "Nova Trento",
        CODIGO_IBGE: 4211504,
      },
      {
        NOME: "Nova Veneza",
        CODIGO_IBGE: 4211603,
      },
      {
        NOME: "Novo Horizonte",
        CODIGO_IBGE: 4211652,
      },
      {
        NOME: "Orleans",
        CODIGO_IBGE: 4211702,
      },
      {
        NOME: "Otacílio Costa",
        CODIGO_IBGE: 4211751,
      },
      {
        NOME: "Ouro",
        CODIGO_IBGE: 4211801,
      },
      {
        NOME: "Ouro Verde",
        CODIGO_IBGE: 4211850,
      },
      {
        NOME: "Paial",
        CODIGO_IBGE: 4211876,
      },
      {
        NOME: "Painel",
        CODIGO_IBGE: 4211892,
      },
      {
        NOME: "Palhoça",
        CODIGO_IBGE: 4211900,
      },
      {
        NOME: "Palma Sola",
        CODIGO_IBGE: 4212007,
      },
      {
        NOME: "Palmeira",
        CODIGO_IBGE: 4212056,
      },
      {
        NOME: "Palmitos",
        CODIGO_IBGE: 4212106,
      },
      {
        NOME: "Papanduva",
        CODIGO_IBGE: 4212205,
      },
      {
        NOME: "Paraíso",
        CODIGO_IBGE: 4212239,
      },
      {
        NOME: "Passo de Torres",
        CODIGO_IBGE: 4212254,
      },
      {
        NOME: "Passos Maia",
        CODIGO_IBGE: 4212270,
      },
      {
        NOME: "Paulo Lopes",
        CODIGO_IBGE: 4212304,
      },
      {
        NOME: "Pedras Grandes",
        CODIGO_IBGE: 4212403,
      },
      {
        NOME: "Penha",
        CODIGO_IBGE: 4212502,
      },
      {
        NOME: "Peritiba",
        CODIGO_IBGE: 4212601,
      },
      {
        NOME: "Pescaria Brava",
        CODIGO_IBGE: 4212650,
      },
      {
        NOME: "Petrolândia",
        CODIGO_IBGE: 4212700,
      },
      {
        NOME: "Balneário Piçarras",
        CODIGO_IBGE: 4212809,
      },
      {
        NOME: "Pinhalzinho",
        CODIGO_IBGE: 4212908,
      },
      {
        NOME: "Pinheiro Preto",
        CODIGO_IBGE: 4213005,
      },
      {
        NOME: "Piratuba",
        CODIGO_IBGE: 4213104,
      },
      {
        NOME: "Planalto Alegre",
        CODIGO_IBGE: 4213153,
      },
      {
        NOME: "Pomerode",
        CODIGO_IBGE: 4213203,
      },
      {
        NOME: "Ponte Alta",
        CODIGO_IBGE: 4213302,
      },
      {
        NOME: "Ponte Alta do Norte",
        CODIGO_IBGE: 4213351,
      },
      {
        NOME: "Ponte Serrada",
        CODIGO_IBGE: 4213401,
      },
      {
        NOME: "Porto Belo",
        CODIGO_IBGE: 4213500,
      },
      {
        NOME: "Porto União",
        CODIGO_IBGE: 4213609,
      },
      {
        NOME: "Pouso Redondo",
        CODIGO_IBGE: 4213708,
      },
      {
        NOME: "Praia Grande",
        CODIGO_IBGE: 4213807,
      },
      {
        NOME: "Presidente Castello Branco",
        CODIGO_IBGE: 4213906,
      },
      {
        NOME: "Presidente Getúlio",
        CODIGO_IBGE: 4214003,
      },
      {
        NOME: "Presidente Nereu",
        CODIGO_IBGE: 4214102,
      },
      {
        NOME: "Princesa",
        CODIGO_IBGE: 4214151,
      },
      {
        NOME: "Quilombo",
        CODIGO_IBGE: 4214201,
      },
      {
        NOME: "Rancho Queimado",
        CODIGO_IBGE: 4214300,
      },
      {
        NOME: "Rio das Antas",
        CODIGO_IBGE: 4214409,
      },
      {
        NOME: "Rio do Campo",
        CODIGO_IBGE: 4214508,
      },
      {
        NOME: "Rio do Oeste",
        CODIGO_IBGE: 4214607,
      },
      {
        NOME: "Rio dos Cedros",
        CODIGO_IBGE: 4214706,
      },
      {
        NOME: "Rio do Sul",
        CODIGO_IBGE: 4214805,
      },
      {
        NOME: "Rio Fortuna",
        CODIGO_IBGE: 4214904,
      },
      {
        NOME: "Rio Negrinho",
        CODIGO_IBGE: 4215000,
      },
      {
        NOME: "Rio Rufino",
        CODIGO_IBGE: 4215059,
      },
      {
        NOME: "Riqueza",
        CODIGO_IBGE: 4215075,
      },
      {
        NOME: "Rodeio",
        CODIGO_IBGE: 4215109,
      },
      {
        NOME: "Romelândia",
        CODIGO_IBGE: 4215208,
      },
      {
        NOME: "Salete",
        CODIGO_IBGE: 4215307,
      },
      {
        NOME: "Saltinho",
        CODIGO_IBGE: 4215356,
      },
      {
        NOME: "Salto Veloso",
        CODIGO_IBGE: 4215406,
      },
      {
        NOME: "Sangão",
        CODIGO_IBGE: 4215455,
      },
      {
        NOME: "Santa Cecília",
        CODIGO_IBGE: 4215505,
      },
      {
        NOME: "Santa Helena",
        CODIGO_IBGE: 4215554,
      },
      {
        NOME: "Santa Rosa de Lima",
        CODIGO_IBGE: 4215604,
      },
      {
        NOME: "Santa Rosa do Sul",
        CODIGO_IBGE: 4215653,
      },
      {
        NOME: "Santa Terezinha",
        CODIGO_IBGE: 4215679,
      },
      {
        NOME: "Santa Terezinha do Progresso",
        CODIGO_IBGE: 4215687,
      },
      {
        NOME: "Santiago do Sul",
        CODIGO_IBGE: 4215695,
      },
      {
        NOME: "Santo Amaro da Imperatriz",
        CODIGO_IBGE: 4215703,
      },
      {
        NOME: "São Bernardino",
        CODIGO_IBGE: 4215752,
      },
      {
        NOME: "São Bento do Sul",
        CODIGO_IBGE: 4215802,
      },
      {
        NOME: "São Bonifácio",
        CODIGO_IBGE: 4215901,
      },
      {
        NOME: "São Carlos",
        CODIGO_IBGE: 4216008,
      },
      {
        NOME: "São Cristóvão do Sul",
        CODIGO_IBGE: 4216057,
      },
      {
        NOME: "São Domingos",
        CODIGO_IBGE: 4216107,
      },
      {
        NOME: "São Francisco do Sul",
        CODIGO_IBGE: 4216206,
      },
      {
        NOME: "São João do Oeste",
        CODIGO_IBGE: 4216255,
      },
      {
        NOME: "São João Batista",
        CODIGO_IBGE: 4216305,
      },
      {
        NOME: "São João do Itaperiú",
        CODIGO_IBGE: 4216354,
      },
      {
        NOME: "São João do Sul",
        CODIGO_IBGE: 4216404,
      },
      {
        NOME: "São Joaquim",
        CODIGO_IBGE: 4216503,
      },
      {
        NOME: "São José",
        CODIGO_IBGE: 4216602,
      },
      {
        NOME: "São José do Cedro",
        CODIGO_IBGE: 4216701,
      },
      {
        NOME: "São José do Cerrito",
        CODIGO_IBGE: 4216800,
      },
      {
        NOME: "São Lourenço do Oeste",
        CODIGO_IBGE: 4216909,
      },
      {
        NOME: "São Ludgero",
        CODIGO_IBGE: 4217006,
      },
      {
        NOME: "São Martinho",
        CODIGO_IBGE: 4217105,
      },
      {
        NOME: "São Miguel da Boa Vista",
        CODIGO_IBGE: 4217154,
      },
      {
        NOME: "São Miguel do Oeste",
        CODIGO_IBGE: 4217204,
      },
      {
        NOME: "São Pedro de Alcântara",
        CODIGO_IBGE: 4217253,
      },
      {
        NOME: "Saudades",
        CODIGO_IBGE: 4217303,
      },
      {
        NOME: "Schroeder",
        CODIGO_IBGE: 4217402,
      },
      {
        NOME: "Seara",
        CODIGO_IBGE: 4217501,
      },
      {
        NOME: "Serra Alta",
        CODIGO_IBGE: 4217550,
      },
      {
        NOME: "Siderópolis",
        CODIGO_IBGE: 4217600,
      },
      {
        NOME: "Sombrio",
        CODIGO_IBGE: 4217709,
      },
      {
        NOME: "Sul Brasil",
        CODIGO_IBGE: 4217758,
      },
      {
        NOME: "Taió",
        CODIGO_IBGE: 4217808,
      },
      {
        NOME: "Tangará",
        CODIGO_IBGE: 4217907,
      },
      {
        NOME: "Tigrinhos",
        CODIGO_IBGE: 4217956,
      },
      {
        NOME: "Tijucas",
        CODIGO_IBGE: 4218004,
      },
      {
        NOME: "Timbé do Sul",
        CODIGO_IBGE: 4218103,
      },
      {
        NOME: "Timbó",
        CODIGO_IBGE: 4218202,
      },
      {
        NOME: "Timbó Grande",
        CODIGO_IBGE: 4218251,
      },
      {
        NOME: "Três Barras",
        CODIGO_IBGE: 4218301,
      },
      {
        NOME: "Treviso",
        CODIGO_IBGE: 4218350,
      },
      {
        NOME: "Treze de Maio",
        CODIGO_IBGE: 4218400,
      },
      {
        NOME: "Treze Tílias",
        CODIGO_IBGE: 4218509,
      },
      {
        NOME: "Trombudo Central",
        CODIGO_IBGE: 4218608,
      },
      {
        NOME: "Tubarão",
        CODIGO_IBGE: 4218707,
      },
      {
        NOME: "Tunápolis",
        CODIGO_IBGE: 4218756,
      },
      {
        NOME: "Turvo",
        CODIGO_IBGE: 4218806,
      },
      {
        NOME: "União do Oeste",
        CODIGO_IBGE: 4218855,
      },
      {
        NOME: "Urubici",
        CODIGO_IBGE: 4218905,
      },
      {
        NOME: "Urupema",
        CODIGO_IBGE: 4218954,
      },
      {
        NOME: "Urussanga",
        CODIGO_IBGE: 4219002,
      },
      {
        NOME: "Vargeão",
        CODIGO_IBGE: 4219101,
      },
      {
        NOME: "Vargem",
        CODIGO_IBGE: 4219150,
      },
      {
        NOME: "Vargem Bonita",
        CODIGO_IBGE: 4219176,
      },
      {
        NOME: "Vidal Ramos",
        CODIGO_IBGE: 4219200,
      },
      {
        NOME: "Videira",
        CODIGO_IBGE: 4219309,
      },
      {
        NOME: "Vitor Meireles",
        CODIGO_IBGE: 4219358,
      },
      {
        NOME: "Witmarsum",
        CODIGO_IBGE: 4219408,
      },
      {
        NOME: "Xanxerê",
        CODIGO_IBGE: 4219507,
      },
      {
        NOME: "Xavantina",
        CODIGO_IBGE: 4219606,
      },
      {
        NOME: "Xaxim",
        CODIGO_IBGE: 4219705,
      },
      {
        NOME: "Zortéa",
        CODIGO_IBGE: 4219853,
      },
      {
        NOME: "Balneário Rincão",
        CODIGO_IBGE: 4220000,
      },
    ],
    43: [
      {
        NOME: "Aceguá",
        CODIGO_IBGE: 4300034,
      },
      {
        NOME: "Água Santa",
        CODIGO_IBGE: 4300059,
      },
      {
        NOME: "Agudo",
        CODIGO_IBGE: 4300109,
      },
      {
        NOME: "Ajuricaba",
        CODIGO_IBGE: 4300208,
      },
      {
        NOME: "Alecrim",
        CODIGO_IBGE: 4300307,
      },
      {
        NOME: "Alegrete",
        CODIGO_IBGE: 4300406,
      },
      {
        NOME: "Alegria",
        CODIGO_IBGE: 4300455,
      },
      {
        NOME: "Almirante Tamandaré do Sul",
        CODIGO_IBGE: 4300471,
      },
      {
        NOME: "Alpestre",
        CODIGO_IBGE: 4300505,
      },
      {
        NOME: "Alto Alegre",
        CODIGO_IBGE: 4300554,
      },
      {
        NOME: "Alto Feliz",
        CODIGO_IBGE: 4300570,
      },
      {
        NOME: "Alvorada",
        CODIGO_IBGE: 4300604,
      },
      {
        NOME: "Amaral Ferrador",
        CODIGO_IBGE: 4300638,
      },
      {
        NOME: "Ametista do Sul",
        CODIGO_IBGE: 4300646,
      },
      {
        NOME: "André da Rocha",
        CODIGO_IBGE: 4300661,
      },
      {
        NOME: "Anta Gorda",
        CODIGO_IBGE: 4300703,
      },
      {
        NOME: "Antônio Prado",
        CODIGO_IBGE: 4300802,
      },
      {
        NOME: "Arambaré",
        CODIGO_IBGE: 4300851,
      },
      {
        NOME: "Araricá",
        CODIGO_IBGE: 4300877,
      },
      {
        NOME: "Aratiba",
        CODIGO_IBGE: 4300901,
      },
      {
        NOME: "Arroio do Meio",
        CODIGO_IBGE: 4301008,
      },
      {
        NOME: "Arroio do Sal",
        CODIGO_IBGE: 4301057,
      },
      {
        NOME: "Arroio do Padre",
        CODIGO_IBGE: 4301073,
      },
      {
        NOME: "Arroio dos Ratos",
        CODIGO_IBGE: 4301107,
      },
      {
        NOME: "Arroio do Tigre",
        CODIGO_IBGE: 4301206,
      },
      {
        NOME: "Arroio Grande",
        CODIGO_IBGE: 4301305,
      },
      {
        NOME: "Arvorezinha",
        CODIGO_IBGE: 4301404,
      },
      {
        NOME: "Augusto Pestana",
        CODIGO_IBGE: 4301503,
      },
      {
        NOME: "Áurea",
        CODIGO_IBGE: 4301552,
      },
      {
        NOME: "Bagé",
        CODIGO_IBGE: 4301602,
      },
      {
        NOME: "Balneário Pinhal",
        CODIGO_IBGE: 4301636,
      },
      {
        NOME: "Barão",
        CODIGO_IBGE: 4301651,
      },
      {
        NOME: "Barão de Cotegipe",
        CODIGO_IBGE: 4301701,
      },
      {
        NOME: "Barão do Triunfo",
        CODIGO_IBGE: 4301750,
      },
      {
        NOME: "Barracão",
        CODIGO_IBGE: 4301800,
      },
      {
        NOME: "Barra do Guarita",
        CODIGO_IBGE: 4301859,
      },
      {
        NOME: "Barra do Quaraí",
        CODIGO_IBGE: 4301875,
      },
      {
        NOME: "Barra do Ribeiro",
        CODIGO_IBGE: 4301909,
      },
      {
        NOME: "Barra do Rio Azul",
        CODIGO_IBGE: 4301925,
      },
      {
        NOME: "Barra Funda",
        CODIGO_IBGE: 4301958,
      },
      {
        NOME: "Barros Cassal",
        CODIGO_IBGE: 4302006,
      },
      {
        NOME: "Benjamin Constant do Sul",
        CODIGO_IBGE: 4302055,
      },
      {
        NOME: "Bento Gonçalves",
        CODIGO_IBGE: 4302105,
      },
      {
        NOME: "Boa Vista das Missões",
        CODIGO_IBGE: 4302154,
      },
      {
        NOME: "Boa Vista do Buricá",
        CODIGO_IBGE: 4302204,
      },
      {
        NOME: "Boa Vista do Cadeado",
        CODIGO_IBGE: 4302220,
      },
      {
        NOME: "Boa Vista do Incra",
        CODIGO_IBGE: 4302238,
      },
      {
        NOME: "Boa Vista do Sul",
        CODIGO_IBGE: 4302253,
      },
      {
        NOME: "Bom Jesus",
        CODIGO_IBGE: 4302303,
      },
      {
        NOME: "Bom Princípio",
        CODIGO_IBGE: 4302352,
      },
      {
        NOME: "Bom Progresso",
        CODIGO_IBGE: 4302378,
      },
      {
        NOME: "Bom Retiro do Sul",
        CODIGO_IBGE: 4302402,
      },
      {
        NOME: "Boqueirão do Leão",
        CODIGO_IBGE: 4302451,
      },
      {
        NOME: "Bossoroca",
        CODIGO_IBGE: 4302501,
      },
      {
        NOME: "Bozano",
        CODIGO_IBGE: 4302584,
      },
      {
        NOME: "Braga",
        CODIGO_IBGE: 4302600,
      },
      {
        NOME: "Brochier",
        CODIGO_IBGE: 4302659,
      },
      {
        NOME: "Butiá",
        CODIGO_IBGE: 4302709,
      },
      {
        NOME: "Caçapava do Sul",
        CODIGO_IBGE: 4302808,
      },
      {
        NOME: "Cacequi",
        CODIGO_IBGE: 4302907,
      },
      {
        NOME: "Cachoeira do Sul",
        CODIGO_IBGE: 4303004,
      },
      {
        NOME: "Cachoeirinha",
        CODIGO_IBGE: 4303103,
      },
      {
        NOME: "Cacique Doble",
        CODIGO_IBGE: 4303202,
      },
      {
        NOME: "Caibaté",
        CODIGO_IBGE: 4303301,
      },
      {
        NOME: "Caiçara",
        CODIGO_IBGE: 4303400,
      },
      {
        NOME: "Camaquã",
        CODIGO_IBGE: 4303509,
      },
      {
        NOME: "Camargo",
        CODIGO_IBGE: 4303558,
      },
      {
        NOME: "Cambará do Sul",
        CODIGO_IBGE: 4303608,
      },
      {
        NOME: "Campestre da Serra",
        CODIGO_IBGE: 4303673,
      },
      {
        NOME: "Campina das Missões",
        CODIGO_IBGE: 4303707,
      },
      {
        NOME: "Campinas do Sul",
        CODIGO_IBGE: 4303806,
      },
      {
        NOME: "Campo Bom",
        CODIGO_IBGE: 4303905,
      },
      {
        NOME: "Campo Novo",
        CODIGO_IBGE: 4304002,
      },
      {
        NOME: "Campos Borges",
        CODIGO_IBGE: 4304101,
      },
      {
        NOME: "Candelária",
        CODIGO_IBGE: 4304200,
      },
      {
        NOME: "Cândido Godói",
        CODIGO_IBGE: 4304309,
      },
      {
        NOME: "Candiota",
        CODIGO_IBGE: 4304358,
      },
      {
        NOME: "Canela",
        CODIGO_IBGE: 4304408,
      },
      {
        NOME: "Canguçu",
        CODIGO_IBGE: 4304507,
      },
      {
        NOME: "Canoas",
        CODIGO_IBGE: 4304606,
      },
      {
        NOME: "Canudos do Vale",
        CODIGO_IBGE: 4304614,
      },
      {
        NOME: "Capão Bonito do Sul",
        CODIGO_IBGE: 4304622,
      },
      {
        NOME: "Capão da Canoa",
        CODIGO_IBGE: 4304630,
      },
      {
        NOME: "Capão do Cipó",
        CODIGO_IBGE: 4304655,
      },
      {
        NOME: "Capão do Leão",
        CODIGO_IBGE: 4304663,
      },
      {
        NOME: "Capivari do Sul",
        CODIGO_IBGE: 4304671,
      },
      {
        NOME: "Capela de Santana",
        CODIGO_IBGE: 4304689,
      },
      {
        NOME: "Capitão",
        CODIGO_IBGE: 4304697,
      },
      {
        NOME: "Carazinho",
        CODIGO_IBGE: 4304705,
      },
      {
        NOME: "Caraá",
        CODIGO_IBGE: 4304713,
      },
      {
        NOME: "Carlos Barbosa",
        CODIGO_IBGE: 4304804,
      },
      {
        NOME: "Carlos Gomes",
        CODIGO_IBGE: 4304853,
      },
      {
        NOME: "Casca",
        CODIGO_IBGE: 4304903,
      },
      {
        NOME: "Caseiros",
        CODIGO_IBGE: 4304952,
      },
      {
        NOME: "Catuípe",
        CODIGO_IBGE: 4305009,
      },
      {
        NOME: "Caxias do Sul",
        CODIGO_IBGE: 4305108,
      },
      {
        NOME: "Centenário",
        CODIGO_IBGE: 4305116,
      },
      {
        NOME: "Cerrito",
        CODIGO_IBGE: 4305124,
      },
      {
        NOME: "Cerro Branco",
        CODIGO_IBGE: 4305132,
      },
      {
        NOME: "Cerro Grande",
        CODIGO_IBGE: 4305157,
      },
      {
        NOME: "Cerro Grande do Sul",
        CODIGO_IBGE: 4305173,
      },
      {
        NOME: "Cerro Largo",
        CODIGO_IBGE: 4305207,
      },
      {
        NOME: "Chapada",
        CODIGO_IBGE: 4305306,
      },
      {
        NOME: "Charqueadas",
        CODIGO_IBGE: 4305355,
      },
      {
        NOME: "Charrua",
        CODIGO_IBGE: 4305371,
      },
      {
        NOME: "Chiapetta",
        CODIGO_IBGE: 4305405,
      },
      {
        NOME: "Chuí",
        CODIGO_IBGE: 4305439,
      },
      {
        NOME: "Chuvisca",
        CODIGO_IBGE: 4305447,
      },
      {
        NOME: "Cidreira",
        CODIGO_IBGE: 4305454,
      },
      {
        NOME: "Ciríaco",
        CODIGO_IBGE: 4305504,
      },
      {
        NOME: "Colinas",
        CODIGO_IBGE: 4305587,
      },
      {
        NOME: "Colorado",
        CODIGO_IBGE: 4305603,
      },
      {
        NOME: "Condor",
        CODIGO_IBGE: 4305702,
      },
      {
        NOME: "Constantina",
        CODIGO_IBGE: 4305801,
      },
      {
        NOME: "Coqueiro Baixo",
        CODIGO_IBGE: 4305835,
      },
      {
        NOME: "Coqueiros do Sul",
        CODIGO_IBGE: 4305850,
      },
      {
        NOME: "Coronel Barros",
        CODIGO_IBGE: 4305871,
      },
      {
        NOME: "Coronel Bicaco",
        CODIGO_IBGE: 4305900,
      },
      {
        NOME: "Coronel Pilar",
        CODIGO_IBGE: 4305934,
      },
      {
        NOME: "Cotiporã",
        CODIGO_IBGE: 4305959,
      },
      {
        NOME: "Coxilha",
        CODIGO_IBGE: 4305975,
      },
      {
        NOME: "Crissiumal",
        CODIGO_IBGE: 4306007,
      },
      {
        NOME: "Cristal",
        CODIGO_IBGE: 4306056,
      },
      {
        NOME: "Cristal do Sul",
        CODIGO_IBGE: 4306072,
      },
      {
        NOME: "Cruz Alta",
        CODIGO_IBGE: 4306106,
      },
      {
        NOME: "Cruzaltense",
        CODIGO_IBGE: 4306130,
      },
      {
        NOME: "Cruzeiro do Sul",
        CODIGO_IBGE: 4306205,
      },
      {
        NOME: "David Canabarro",
        CODIGO_IBGE: 4306304,
      },
      {
        NOME: "Derrubadas",
        CODIGO_IBGE: 4306320,
      },
      {
        NOME: "Dezesseis de Novembro",
        CODIGO_IBGE: 4306353,
      },
      {
        NOME: "Dilermando de Aguiar",
        CODIGO_IBGE: 4306379,
      },
      {
        NOME: "Dois Irmãos",
        CODIGO_IBGE: 4306403,
      },
      {
        NOME: "Dois Irmãos das Missões",
        CODIGO_IBGE: 4306429,
      },
      {
        NOME: "Dois Lajeados",
        CODIGO_IBGE: 4306452,
      },
      {
        NOME: "Dom Feliciano",
        CODIGO_IBGE: 4306502,
      },
      {
        NOME: "Dom Pedro de Alcântara",
        CODIGO_IBGE: 4306551,
      },
      {
        NOME: "Dom Pedrito",
        CODIGO_IBGE: 4306601,
      },
      {
        NOME: "Dona Francisca",
        CODIGO_IBGE: 4306700,
      },
      {
        NOME: "Doutor Maurício Cardoso",
        CODIGO_IBGE: 4306734,
      },
      {
        NOME: "Doutor Ricardo",
        CODIGO_IBGE: 4306759,
      },
      {
        NOME: "Eldorado do Sul",
        CODIGO_IBGE: 4306767,
      },
      {
        NOME: "Encantado",
        CODIGO_IBGE: 4306809,
      },
      {
        NOME: "Encruzilhada do Sul",
        CODIGO_IBGE: 4306908,
      },
      {
        NOME: "Engenho Velho",
        CODIGO_IBGE: 4306924,
      },
      {
        NOME: "Entre-Ijuís",
        CODIGO_IBGE: 4306932,
      },
      {
        NOME: "Entre Rios do Sul",
        CODIGO_IBGE: 4306957,
      },
      {
        NOME: "Erebango",
        CODIGO_IBGE: 4306973,
      },
      {
        NOME: "Erechim",
        CODIGO_IBGE: 4307005,
      },
      {
        NOME: "Ernestina",
        CODIGO_IBGE: 4307054,
      },
      {
        NOME: "Herval",
        CODIGO_IBGE: 4307104,
      },
      {
        NOME: "Erval Grande",
        CODIGO_IBGE: 4307203,
      },
      {
        NOME: "Erval Seco",
        CODIGO_IBGE: 4307302,
      },
      {
        NOME: "Esmeralda",
        CODIGO_IBGE: 4307401,
      },
      {
        NOME: "Esperança do Sul",
        CODIGO_IBGE: 4307450,
      },
      {
        NOME: "Espumoso",
        CODIGO_IBGE: 4307500,
      },
      {
        NOME: "Estação",
        CODIGO_IBGE: 4307559,
      },
      {
        NOME: "Estância Velha",
        CODIGO_IBGE: 4307609,
      },
      {
        NOME: "Esteio",
        CODIGO_IBGE: 4307708,
      },
      {
        NOME: "Estrela",
        CODIGO_IBGE: 4307807,
      },
      {
        NOME: "Estrela Velha",
        CODIGO_IBGE: 4307815,
      },
      {
        NOME: "Eugênio de Castro",
        CODIGO_IBGE: 4307831,
      },
      {
        NOME: "Fagundes Varela",
        CODIGO_IBGE: 4307864,
      },
      {
        NOME: "Farroupilha",
        CODIGO_IBGE: 4307906,
      },
      {
        NOME: "Faxinal do Soturno",
        CODIGO_IBGE: 4308003,
      },
      {
        NOME: "Faxinalzinho",
        CODIGO_IBGE: 4308052,
      },
      {
        NOME: "Fazenda Vilanova",
        CODIGO_IBGE: 4308078,
      },
      {
        NOME: "Feliz",
        CODIGO_IBGE: 4308102,
      },
      {
        NOME: "Flores da Cunha",
        CODIGO_IBGE: 4308201,
      },
      {
        NOME: "Floriano Peixoto",
        CODIGO_IBGE: 4308250,
      },
      {
        NOME: "Fontoura Xavier",
        CODIGO_IBGE: 4308300,
      },
      {
        NOME: "Formigueiro",
        CODIGO_IBGE: 4308409,
      },
      {
        NOME: "Forquetinha",
        CODIGO_IBGE: 4308433,
      },
      {
        NOME: "Fortaleza dos Valos",
        CODIGO_IBGE: 4308458,
      },
      {
        NOME: "Frederico Westphalen",
        CODIGO_IBGE: 4308508,
      },
      {
        NOME: "Garibaldi",
        CODIGO_IBGE: 4308607,
      },
      {
        NOME: "Garruchos",
        CODIGO_IBGE: 4308656,
      },
      {
        NOME: "Gaurama",
        CODIGO_IBGE: 4308706,
      },
      {
        NOME: "General Câmara",
        CODIGO_IBGE: 4308805,
      },
      {
        NOME: "Gentil",
        CODIGO_IBGE: 4308854,
      },
      {
        NOME: "Getúlio Vargas",
        CODIGO_IBGE: 4308904,
      },
      {
        NOME: "Giruá",
        CODIGO_IBGE: 4309001,
      },
      {
        NOME: "Glorinha",
        CODIGO_IBGE: 4309050,
      },
      {
        NOME: "Gramado",
        CODIGO_IBGE: 4309100,
      },
      {
        NOME: "Gramado dos Loureiros",
        CODIGO_IBGE: 4309126,
      },
      {
        NOME: "Gramado Xavier",
        CODIGO_IBGE: 4309159,
      },
      {
        NOME: "Gravataí",
        CODIGO_IBGE: 4309209,
      },
      {
        NOME: "Guabiju",
        CODIGO_IBGE: 4309258,
      },
      {
        NOME: "Guaíba",
        CODIGO_IBGE: 4309308,
      },
      {
        NOME: "Guaporé",
        CODIGO_IBGE: 4309407,
      },
      {
        NOME: "Guarani das Missões",
        CODIGO_IBGE: 4309506,
      },
      {
        NOME: "Harmonia",
        CODIGO_IBGE: 4309555,
      },
      {
        NOME: "Herveiras",
        CODIGO_IBGE: 4309571,
      },
      {
        NOME: "Horizontina",
        CODIGO_IBGE: 4309605,
      },
      {
        NOME: "Hulha Negra",
        CODIGO_IBGE: 4309654,
      },
      {
        NOME: "Humaitá",
        CODIGO_IBGE: 4309704,
      },
      {
        NOME: "Ibarama",
        CODIGO_IBGE: 4309753,
      },
      {
        NOME: "Ibiaçá",
        CODIGO_IBGE: 4309803,
      },
      {
        NOME: "Ibiraiaras",
        CODIGO_IBGE: 4309902,
      },
      {
        NOME: "Ibirapuitã",
        CODIGO_IBGE: 4309951,
      },
      {
        NOME: "Ibirubá",
        CODIGO_IBGE: 4310009,
      },
      {
        NOME: "Igrejinha",
        CODIGO_IBGE: 4310108,
      },
      {
        NOME: "Ijuí",
        CODIGO_IBGE: 4310207,
      },
      {
        NOME: "Ilópolis",
        CODIGO_IBGE: 4310306,
      },
      {
        NOME: "Imbé",
        CODIGO_IBGE: 4310330,
      },
      {
        NOME: "Imigrante",
        CODIGO_IBGE: 4310363,
      },
      {
        NOME: "Independência",
        CODIGO_IBGE: 4310405,
      },
      {
        NOME: "Inhacorá",
        CODIGO_IBGE: 4310413,
      },
      {
        NOME: "Ipê",
        CODIGO_IBGE: 4310439,
      },
      {
        NOME: "Ipiranga do Sul",
        CODIGO_IBGE: 4310462,
      },
      {
        NOME: "Iraí",
        CODIGO_IBGE: 4310504,
      },
      {
        NOME: "Itaara",
        CODIGO_IBGE: 4310538,
      },
      {
        NOME: "Itacurubi",
        CODIGO_IBGE: 4310553,
      },
      {
        NOME: "Itapuca",
        CODIGO_IBGE: 4310579,
      },
      {
        NOME: "Itaqui",
        CODIGO_IBGE: 4310603,
      },
      {
        NOME: "Itati",
        CODIGO_IBGE: 4310652,
      },
      {
        NOME: "Itatiba do Sul",
        CODIGO_IBGE: 4310702,
      },
      {
        NOME: "Ivorá",
        CODIGO_IBGE: 4310751,
      },
      {
        NOME: "Ivoti",
        CODIGO_IBGE: 4310801,
      },
      {
        NOME: "Jaboticaba",
        CODIGO_IBGE: 4310850,
      },
      {
        NOME: "Jacuizinho",
        CODIGO_IBGE: 4310876,
      },
      {
        NOME: "Jacutinga",
        CODIGO_IBGE: 4310900,
      },
      {
        NOME: "Jaguarão",
        CODIGO_IBGE: 4311007,
      },
      {
        NOME: "Jaguari",
        CODIGO_IBGE: 4311106,
      },
      {
        NOME: "Jaquirana",
        CODIGO_IBGE: 4311122,
      },
      {
        NOME: "Jari",
        CODIGO_IBGE: 4311130,
      },
      {
        NOME: "Jóia",
        CODIGO_IBGE: 4311155,
      },
      {
        NOME: "Júlio de Castilhos",
        CODIGO_IBGE: 4311205,
      },
      {
        NOME: "Lagoa Bonita do Sul",
        CODIGO_IBGE: 4311239,
      },
      {
        NOME: "Lagoão",
        CODIGO_IBGE: 4311254,
      },
      {
        NOME: "Lagoa dos Três Cantos",
        CODIGO_IBGE: 4311270,
      },
      {
        NOME: "Lagoa Vermelha",
        CODIGO_IBGE: 4311304,
      },
      {
        NOME: "Lajeado",
        CODIGO_IBGE: 4311403,
      },
      {
        NOME: "Lajeado do Bugre",
        CODIGO_IBGE: 4311429,
      },
      {
        NOME: "Lavras do Sul",
        CODIGO_IBGE: 4311502,
      },
      {
        NOME: "Liberato Salzano",
        CODIGO_IBGE: 4311601,
      },
      {
        NOME: "Lindolfo Collor",
        CODIGO_IBGE: 4311627,
      },
      {
        NOME: "Linha Nova",
        CODIGO_IBGE: 4311643,
      },
      {
        NOME: "Machadinho",
        CODIGO_IBGE: 4311700,
      },
      {
        NOME: "Maçambará",
        CODIGO_IBGE: 4311718,
      },
      {
        NOME: "Mampituba",
        CODIGO_IBGE: 4311734,
      },
      {
        NOME: "Manoel Viana",
        CODIGO_IBGE: 4311759,
      },
      {
        NOME: "Maquiné",
        CODIGO_IBGE: 4311775,
      },
      {
        NOME: "Maratá",
        CODIGO_IBGE: 4311791,
      },
      {
        NOME: "Marau",
        CODIGO_IBGE: 4311809,
      },
      {
        NOME: "Marcelino Ramos",
        CODIGO_IBGE: 4311908,
      },
      {
        NOME: "Mariana Pimentel",
        CODIGO_IBGE: 4311981,
      },
      {
        NOME: "Mariano Moro",
        CODIGO_IBGE: 4312005,
      },
      {
        NOME: "Marques de Souza",
        CODIGO_IBGE: 4312054,
      },
      {
        NOME: "Mata",
        CODIGO_IBGE: 4312104,
      },
      {
        NOME: "Mato Castelhano",
        CODIGO_IBGE: 4312138,
      },
      {
        NOME: "Mato Leitão",
        CODIGO_IBGE: 4312153,
      },
      {
        NOME: "Mato Queimado",
        CODIGO_IBGE: 4312179,
      },
      {
        NOME: "Maximiliano de Almeida",
        CODIGO_IBGE: 4312203,
      },
      {
        NOME: "Minas do Leão",
        CODIGO_IBGE: 4312252,
      },
      {
        NOME: "Miraguaí",
        CODIGO_IBGE: 4312302,
      },
      {
        NOME: "Montauri",
        CODIGO_IBGE: 4312351,
      },
      {
        NOME: "Monte Alegre dos Campos",
        CODIGO_IBGE: 4312377,
      },
      {
        NOME: "Monte Belo do Sul",
        CODIGO_IBGE: 4312385,
      },
      {
        NOME: "Montenegro",
        CODIGO_IBGE: 4312401,
      },
      {
        NOME: "Mormaço",
        CODIGO_IBGE: 4312427,
      },
      {
        NOME: "Morrinhos do Sul",
        CODIGO_IBGE: 4312443,
      },
      {
        NOME: "Morro Redondo",
        CODIGO_IBGE: 4312450,
      },
      {
        NOME: "Morro Reuter",
        CODIGO_IBGE: 4312476,
      },
      {
        NOME: "Mostardas",
        CODIGO_IBGE: 4312500,
      },
      {
        NOME: "Muçum",
        CODIGO_IBGE: 4312609,
      },
      {
        NOME: "Muitos Capões",
        CODIGO_IBGE: 4312617,
      },
      {
        NOME: "Muliterno",
        CODIGO_IBGE: 4312625,
      },
      {
        NOME: "Não-Me-Toque",
        CODIGO_IBGE: 4312658,
      },
      {
        NOME: "Nicolau Vergueiro",
        CODIGO_IBGE: 4312674,
      },
      {
        NOME: "Nonoai",
        CODIGO_IBGE: 4312708,
      },
      {
        NOME: "Nova Alvorada",
        CODIGO_IBGE: 4312757,
      },
      {
        NOME: "Nova Araçá",
        CODIGO_IBGE: 4312807,
      },
      {
        NOME: "Nova Bassano",
        CODIGO_IBGE: 4312906,
      },
      {
        NOME: "Nova Boa Vista",
        CODIGO_IBGE: 4312955,
      },
      {
        NOME: "Nova Bréscia",
        CODIGO_IBGE: 4313003,
      },
      {
        NOME: "Nova Candelária",
        CODIGO_IBGE: 4313011,
      },
      {
        NOME: "Nova Esperança do Sul",
        CODIGO_IBGE: 4313037,
      },
      {
        NOME: "Nova Hartz",
        CODIGO_IBGE: 4313060,
      },
      {
        NOME: "Nova Pádua",
        CODIGO_IBGE: 4313086,
      },
      {
        NOME: "Nova Palma",
        CODIGO_IBGE: 4313102,
      },
      {
        NOME: "Nova Petrópolis",
        CODIGO_IBGE: 4313201,
      },
      {
        NOME: "Nova Prata",
        CODIGO_IBGE: 4313300,
      },
      {
        NOME: "Nova Ramada",
        CODIGO_IBGE: 4313334,
      },
      {
        NOME: "Nova Roma do Sul",
        CODIGO_IBGE: 4313359,
      },
      {
        NOME: "Nova Santa Rita",
        CODIGO_IBGE: 4313375,
      },
      {
        NOME: "Novo Cabrais",
        CODIGO_IBGE: 4313391,
      },
      {
        NOME: "Novo Hamburgo",
        CODIGO_IBGE: 4313409,
      },
      {
        NOME: "Novo Machado",
        CODIGO_IBGE: 4313425,
      },
      {
        NOME: "Novo Tiradentes",
        CODIGO_IBGE: 4313441,
      },
      {
        NOME: "Novo Xingu",
        CODIGO_IBGE: 4313466,
      },
      {
        NOME: "Novo Barreiro",
        CODIGO_IBGE: 4313490,
      },
      {
        NOME: "Osório",
        CODIGO_IBGE: 4313508,
      },
      {
        NOME: "Paim Filho",
        CODIGO_IBGE: 4313607,
      },
      {
        NOME: "Palmares do Sul",
        CODIGO_IBGE: 4313656,
      },
      {
        NOME: "Palmeira das Missões",
        CODIGO_IBGE: 4313706,
      },
      {
        NOME: "Palmitinho",
        CODIGO_IBGE: 4313805,
      },
      {
        NOME: "Panambi",
        CODIGO_IBGE: 4313904,
      },
      {
        NOME: "Pantano Grande",
        CODIGO_IBGE: 4313953,
      },
      {
        NOME: "Paraí",
        CODIGO_IBGE: 4314001,
      },
      {
        NOME: "Paraíso do Sul",
        CODIGO_IBGE: 4314027,
      },
      {
        NOME: "Pareci Novo",
        CODIGO_IBGE: 4314035,
      },
      {
        NOME: "Parobé",
        CODIGO_IBGE: 4314050,
      },
      {
        NOME: "Passa Sete",
        CODIGO_IBGE: 4314068,
      },
      {
        NOME: "Passo do Sobrado",
        CODIGO_IBGE: 4314076,
      },
      {
        NOME: "Passo Fundo",
        CODIGO_IBGE: 4314100,
      },
      {
        NOME: "Paulo Bento",
        CODIGO_IBGE: 4314134,
      },
      {
        NOME: "Paverama",
        CODIGO_IBGE: 4314159,
      },
      {
        NOME: "Pedras Altas",
        CODIGO_IBGE: 4314175,
      },
      {
        NOME: "Pedro Osório",
        CODIGO_IBGE: 4314209,
      },
      {
        NOME: "Pejuçara",
        CODIGO_IBGE: 4314308,
      },
      {
        NOME: "Pelotas",
        CODIGO_IBGE: 4314407,
      },
      {
        NOME: "Picada Café",
        CODIGO_IBGE: 4314423,
      },
      {
        NOME: "Pinhal",
        CODIGO_IBGE: 4314456,
      },
      {
        NOME: "Pinhal da Serra",
        CODIGO_IBGE: 4314464,
      },
      {
        NOME: "Pinhal Grande",
        CODIGO_IBGE: 4314472,
      },
      {
        NOME: "Pinheirinho do Vale",
        CODIGO_IBGE: 4314498,
      },
      {
        NOME: "Pinheiro Machado",
        CODIGO_IBGE: 4314506,
      },
      {
        NOME: "Pinto Bandeira",
        CODIGO_IBGE: 4314548,
      },
      {
        NOME: "Pirapó",
        CODIGO_IBGE: 4314555,
      },
      {
        NOME: "Piratini",
        CODIGO_IBGE: 4314605,
      },
      {
        NOME: "Planalto",
        CODIGO_IBGE: 4314704,
      },
      {
        NOME: "Poço das Antas",
        CODIGO_IBGE: 4314753,
      },
      {
        NOME: "Pontão",
        CODIGO_IBGE: 4314779,
      },
      {
        NOME: "Ponte Preta",
        CODIGO_IBGE: 4314787,
      },
      {
        NOME: "Portão",
        CODIGO_IBGE: 4314803,
      },
      {
        NOME: "Porto Alegre",
        CODIGO_IBGE: 4314902,
      },
      {
        NOME: "Porto Lucena",
        CODIGO_IBGE: 4315008,
      },
      {
        NOME: "Porto Mauá",
        CODIGO_IBGE: 4315057,
      },
      {
        NOME: "Porto Vera Cruz",
        CODIGO_IBGE: 4315073,
      },
      {
        NOME: "Porto Xavier",
        CODIGO_IBGE: 4315107,
      },
      {
        NOME: "Pouso Novo",
        CODIGO_IBGE: 4315131,
      },
      {
        NOME: "Presidente Lucena",
        CODIGO_IBGE: 4315149,
      },
      {
        NOME: "Progresso",
        CODIGO_IBGE: 4315156,
      },
      {
        NOME: "Protásio Alves",
        CODIGO_IBGE: 4315172,
      },
      {
        NOME: "Putinga",
        CODIGO_IBGE: 4315206,
      },
      {
        NOME: "Quaraí",
        CODIGO_IBGE: 4315305,
      },
      {
        NOME: "Quatro Irmãos",
        CODIGO_IBGE: 4315313,
      },
      {
        NOME: "Quevedos",
        CODIGO_IBGE: 4315321,
      },
      {
        NOME: "Quinze de Novembro",
        CODIGO_IBGE: 4315354,
      },
      {
        NOME: "Redentora",
        CODIGO_IBGE: 4315404,
      },
      {
        NOME: "Relvado",
        CODIGO_IBGE: 4315453,
      },
      {
        NOME: "Restinga Sêca",
        CODIGO_IBGE: 4315503,
      },
      {
        NOME: "Rio dos Índios",
        CODIGO_IBGE: 4315552,
      },
      {
        NOME: "Rio Grande",
        CODIGO_IBGE: 4315602,
      },
      {
        NOME: "Rio Pardo",
        CODIGO_IBGE: 4315701,
      },
      {
        NOME: "Riozinho",
        CODIGO_IBGE: 4315750,
      },
      {
        NOME: "Roca Sales",
        CODIGO_IBGE: 4315800,
      },
      {
        NOME: "Rodeio Bonito",
        CODIGO_IBGE: 4315909,
      },
      {
        NOME: "Rolador",
        CODIGO_IBGE: 4315958,
      },
      {
        NOME: "Rolante",
        CODIGO_IBGE: 4316006,
      },
      {
        NOME: "Ronda Alta",
        CODIGO_IBGE: 4316105,
      },
      {
        NOME: "Rondinha",
        CODIGO_IBGE: 4316204,
      },
      {
        NOME: "Roque Gonzales",
        CODIGO_IBGE: 4316303,
      },
      {
        NOME: "Rosário do Sul",
        CODIGO_IBGE: 4316402,
      },
      {
        NOME: "Sagrada Família",
        CODIGO_IBGE: 4316428,
      },
      {
        NOME: "Saldanha Marinho",
        CODIGO_IBGE: 4316436,
      },
      {
        NOME: "Salto do Jacuí",
        CODIGO_IBGE: 4316451,
      },
      {
        NOME: "Salvador das Missões",
        CODIGO_IBGE: 4316477,
      },
      {
        NOME: "Salvador do Sul",
        CODIGO_IBGE: 4316501,
      },
      {
        NOME: "Sananduva",
        CODIGO_IBGE: 4316600,
      },
      {
        NOME: "Santa Bárbara do Sul",
        CODIGO_IBGE: 4316709,
      },
      {
        NOME: "Santa Cecília do Sul",
        CODIGO_IBGE: 4316733,
      },
      {
        NOME: "Santa Clara do Sul",
        CODIGO_IBGE: 4316758,
      },
      {
        NOME: "Santa Cruz do Sul",
        CODIGO_IBGE: 4316808,
      },
      {
        NOME: "Santa Maria",
        CODIGO_IBGE: 4316907,
      },
      {
        NOME: "Santa Maria do Herval",
        CODIGO_IBGE: 4316956,
      },
      {
        NOME: "Santa Margarida do Sul",
        CODIGO_IBGE: 4316972,
      },
      {
        NOME: "Santana da Boa Vista",
        CODIGO_IBGE: 4317004,
      },
      {
        NOME: "Sant'Ana do Livramento",
        CODIGO_IBGE: 4317103,
      },
      {
        NOME: "Santa Rosa",
        CODIGO_IBGE: 4317202,
      },
      {
        NOME: "Santa Tereza",
        CODIGO_IBGE: 4317251,
      },
      {
        NOME: "Santa Vitória do Palmar",
        CODIGO_IBGE: 4317301,
      },
      {
        NOME: "Santiago",
        CODIGO_IBGE: 4317400,
      },
      {
        NOME: "Santo Ângelo",
        CODIGO_IBGE: 4317509,
      },
      {
        NOME: "Santo Antônio do Palma",
        CODIGO_IBGE: 4317558,
      },
      {
        NOME: "Santo Antônio da Patrulha",
        CODIGO_IBGE: 4317608,
      },
      {
        NOME: "Santo Antônio das Missões",
        CODIGO_IBGE: 4317707,
      },
      {
        NOME: "Santo Antônio do Planalto",
        CODIGO_IBGE: 4317756,
      },
      {
        NOME: "Santo Augusto",
        CODIGO_IBGE: 4317806,
      },
      {
        NOME: "Santo Cristo",
        CODIGO_IBGE: 4317905,
      },
      {
        NOME: "Santo Expedito do Sul",
        CODIGO_IBGE: 4317954,
      },
      {
        NOME: "São Borja",
        CODIGO_IBGE: 4318002,
      },
      {
        NOME: "São Domingos do Sul",
        CODIGO_IBGE: 4318051,
      },
      {
        NOME: "São Francisco de Assis",
        CODIGO_IBGE: 4318101,
      },
      {
        NOME: "São Francisco de Paula",
        CODIGO_IBGE: 4318200,
      },
      {
        NOME: "São Gabriel",
        CODIGO_IBGE: 4318309,
      },
      {
        NOME: "São Jerônimo",
        CODIGO_IBGE: 4318408,
      },
      {
        NOME: "São João da Urtiga",
        CODIGO_IBGE: 4318424,
      },
      {
        NOME: "São João do Polêsine",
        CODIGO_IBGE: 4318432,
      },
      {
        NOME: "São Jorge",
        CODIGO_IBGE: 4318440,
      },
      {
        NOME: "São José das Missões",
        CODIGO_IBGE: 4318457,
      },
      {
        NOME: "São José do Herval",
        CODIGO_IBGE: 4318465,
      },
      {
        NOME: "São José do Hortêncio",
        CODIGO_IBGE: 4318481,
      },
      {
        NOME: "São José do Inhacorá",
        CODIGO_IBGE: 4318499,
      },
      {
        NOME: "São José do Norte",
        CODIGO_IBGE: 4318507,
      },
      {
        NOME: "São José do Ouro",
        CODIGO_IBGE: 4318606,
      },
      {
        NOME: "São José do Sul",
        CODIGO_IBGE: 4318614,
      },
      {
        NOME: "São José dos Ausentes",
        CODIGO_IBGE: 4318622,
      },
      {
        NOME: "São Leopoldo",
        CODIGO_IBGE: 4318705,
      },
      {
        NOME: "São Lourenço do Sul",
        CODIGO_IBGE: 4318804,
      },
      {
        NOME: "São Luiz Gonzaga",
        CODIGO_IBGE: 4318903,
      },
      {
        NOME: "São Marcos",
        CODIGO_IBGE: 4319000,
      },
      {
        NOME: "São Martinho",
        CODIGO_IBGE: 4319109,
      },
      {
        NOME: "São Martinho da Serra",
        CODIGO_IBGE: 4319125,
      },
      {
        NOME: "São Miguel das Missões",
        CODIGO_IBGE: 4319158,
      },
      {
        NOME: "São Nicolau",
        CODIGO_IBGE: 4319208,
      },
      {
        NOME: "São Paulo das Missões",
        CODIGO_IBGE: 4319307,
      },
      {
        NOME: "São Pedro da Serra",
        CODIGO_IBGE: 4319356,
      },
      {
        NOME: "São Pedro das Missões",
        CODIGO_IBGE: 4319364,
      },
      {
        NOME: "São Pedro do Butiá",
        CODIGO_IBGE: 4319372,
      },
      {
        NOME: "São Pedro do Sul",
        CODIGO_IBGE: 4319406,
      },
      {
        NOME: "São Sebastião do Caí",
        CODIGO_IBGE: 4319505,
      },
      {
        NOME: "São Sepé",
        CODIGO_IBGE: 4319604,
      },
      {
        NOME: "São Valentim",
        CODIGO_IBGE: 4319703,
      },
      {
        NOME: "São Valentim do Sul",
        CODIGO_IBGE: 4319711,
      },
      {
        NOME: "São Valério do Sul",
        CODIGO_IBGE: 4319737,
      },
      {
        NOME: "São Vendelino",
        CODIGO_IBGE: 4319752,
      },
      {
        NOME: "São Vicente do Sul",
        CODIGO_IBGE: 4319802,
      },
      {
        NOME: "Sapiranga",
        CODIGO_IBGE: 4319901,
      },
      {
        NOME: "Sapucaia do Sul",
        CODIGO_IBGE: 4320008,
      },
      {
        NOME: "Sarandi",
        CODIGO_IBGE: 4320107,
      },
      {
        NOME: "Seberi",
        CODIGO_IBGE: 4320206,
      },
      {
        NOME: "Sede Nova",
        CODIGO_IBGE: 4320230,
      },
      {
        NOME: "Segredo",
        CODIGO_IBGE: 4320263,
      },
      {
        NOME: "Selbach",
        CODIGO_IBGE: 4320305,
      },
      {
        NOME: "Senador Salgado Filho",
        CODIGO_IBGE: 4320321,
      },
      {
        NOME: "Sentinela do Sul",
        CODIGO_IBGE: 4320354,
      },
      {
        NOME: "Serafina Corrêa",
        CODIGO_IBGE: 4320404,
      },
      {
        NOME: "Sério",
        CODIGO_IBGE: 4320453,
      },
      {
        NOME: "Sertão",
        CODIGO_IBGE: 4320503,
      },
      {
        NOME: "Sertão Santana",
        CODIGO_IBGE: 4320552,
      },
      {
        NOME: "Sete de Setembro",
        CODIGO_IBGE: 4320578,
      },
      {
        NOME: "Severiano de Almeida",
        CODIGO_IBGE: 4320602,
      },
      {
        NOME: "Silveira Martins",
        CODIGO_IBGE: 4320651,
      },
      {
        NOME: "Sinimbu",
        CODIGO_IBGE: 4320677,
      },
      {
        NOME: "Sobradinho",
        CODIGO_IBGE: 4320701,
      },
      {
        NOME: "Soledade",
        CODIGO_IBGE: 4320800,
      },
      {
        NOME: "Tabaí",
        CODIGO_IBGE: 4320859,
      },
      {
        NOME: "Tapejara",
        CODIGO_IBGE: 4320909,
      },
      {
        NOME: "Tapera",
        CODIGO_IBGE: 4321006,
      },
      {
        NOME: "Tapes",
        CODIGO_IBGE: 4321105,
      },
      {
        NOME: "Taquara",
        CODIGO_IBGE: 4321204,
      },
      {
        NOME: "Taquari",
        CODIGO_IBGE: 4321303,
      },
      {
        NOME: "Taquaruçu do Sul",
        CODIGO_IBGE: 4321329,
      },
      {
        NOME: "Tavares",
        CODIGO_IBGE: 4321352,
      },
      {
        NOME: "Tenente Portela",
        CODIGO_IBGE: 4321402,
      },
      {
        NOME: "Terra de Areia",
        CODIGO_IBGE: 4321436,
      },
      {
        NOME: "Teutônia",
        CODIGO_IBGE: 4321451,
      },
      {
        NOME: "Tio Hugo",
        CODIGO_IBGE: 4321469,
      },
      {
        NOME: "Tiradentes do Sul",
        CODIGO_IBGE: 4321477,
      },
      {
        NOME: "Toropi",
        CODIGO_IBGE: 4321493,
      },
      {
        NOME: "Torres",
        CODIGO_IBGE: 4321501,
      },
      {
        NOME: "Tramandaí",
        CODIGO_IBGE: 4321600,
      },
      {
        NOME: "Travesseiro",
        CODIGO_IBGE: 4321626,
      },
      {
        NOME: "Três Arroios",
        CODIGO_IBGE: 4321634,
      },
      {
        NOME: "Três Cachoeiras",
        CODIGO_IBGE: 4321667,
      },
      {
        NOME: "Três Coroas",
        CODIGO_IBGE: 4321709,
      },
      {
        NOME: "Três de Maio",
        CODIGO_IBGE: 4321808,
      },
      {
        NOME: "Três Forquilhas",
        CODIGO_IBGE: 4321832,
      },
      {
        NOME: "Três Palmeiras",
        CODIGO_IBGE: 4321857,
      },
      {
        NOME: "Três Passos",
        CODIGO_IBGE: 4321907,
      },
      {
        NOME: "Trindade do Sul",
        CODIGO_IBGE: 4321956,
      },
      {
        NOME: "Triunfo",
        CODIGO_IBGE: 4322004,
      },
      {
        NOME: "Tucunduva",
        CODIGO_IBGE: 4322103,
      },
      {
        NOME: "Tunas",
        CODIGO_IBGE: 4322152,
      },
      {
        NOME: "Tupanci do Sul",
        CODIGO_IBGE: 4322186,
      },
      {
        NOME: "Tupanciretã",
        CODIGO_IBGE: 4322202,
      },
      {
        NOME: "Tupandi",
        CODIGO_IBGE: 4322251,
      },
      {
        NOME: "Tuparendi",
        CODIGO_IBGE: 4322301,
      },
      {
        NOME: "Turuçu",
        CODIGO_IBGE: 4322327,
      },
      {
        NOME: "Ubiretama",
        CODIGO_IBGE: 4322343,
      },
      {
        NOME: "União da Serra",
        CODIGO_IBGE: 4322350,
      },
      {
        NOME: "Unistalda",
        CODIGO_IBGE: 4322376,
      },
      {
        NOME: "Uruguaiana",
        CODIGO_IBGE: 4322400,
      },
      {
        NOME: "Vacaria",
        CODIGO_IBGE: 4322509,
      },
      {
        NOME: "Vale Verde",
        CODIGO_IBGE: 4322525,
      },
      {
        NOME: "Vale do Sol",
        CODIGO_IBGE: 4322533,
      },
      {
        NOME: "Vale Real",
        CODIGO_IBGE: 4322541,
      },
      {
        NOME: "Vanini",
        CODIGO_IBGE: 4322558,
      },
      {
        NOME: "Venâncio Aires",
        CODIGO_IBGE: 4322608,
      },
      {
        NOME: "Vera Cruz",
        CODIGO_IBGE: 4322707,
      },
      {
        NOME: "Veranópolis",
        CODIGO_IBGE: 4322806,
      },
      {
        NOME: "Vespasiano Corrêa",
        CODIGO_IBGE: 4322855,
      },
      {
        NOME: "Viadutos",
        CODIGO_IBGE: 4322905,
      },
      {
        NOME: "Viamão",
        CODIGO_IBGE: 4323002,
      },
      {
        NOME: "Vicente Dutra",
        CODIGO_IBGE: 4323101,
      },
      {
        NOME: "Victor Graeff",
        CODIGO_IBGE: 4323200,
      },
      {
        NOME: "Vila Flores",
        CODIGO_IBGE: 4323309,
      },
      {
        NOME: "Vila Lângaro",
        CODIGO_IBGE: 4323358,
      },
      {
        NOME: "Vila Maria",
        CODIGO_IBGE: 4323408,
      },
      {
        NOME: "Vila Nova do Sul",
        CODIGO_IBGE: 4323457,
      },
      {
        NOME: "Vista Alegre",
        CODIGO_IBGE: 4323507,
      },
      {
        NOME: "Vista Alegre do Prata",
        CODIGO_IBGE: 4323606,
      },
      {
        NOME: "Vista Gaúcha",
        CODIGO_IBGE: 4323705,
      },
      {
        NOME: "Vitória das Missões",
        CODIGO_IBGE: 4323754,
      },
      {
        NOME: "Westfália",
        CODIGO_IBGE: 4323770,
      },
      {
        NOME: "Xangri-lá",
        CODIGO_IBGE: 4323804,
      },
    ],
    50: [
      {
        NOME: "Água Clara",
        CODIGO_IBGE: 5000203,
      },
      {
        NOME: "Alcinópolis",
        CODIGO_IBGE: 5000252,
      },
      {
        NOME: "Amambai",
        CODIGO_IBGE: 5000609,
      },
      {
        NOME: "Anastácio",
        CODIGO_IBGE: 5000708,
      },
      {
        NOME: "Anaurilândia",
        CODIGO_IBGE: 5000807,
      },
      {
        NOME: "Angélica",
        CODIGO_IBGE: 5000856,
      },
      {
        NOME: "Antônio João",
        CODIGO_IBGE: 5000906,
      },
      {
        NOME: "Aparecida do Taboado",
        CODIGO_IBGE: 5001003,
      },
      {
        NOME: "Aquidauana",
        CODIGO_IBGE: 5001102,
      },
      {
        NOME: "Aral Moreira",
        CODIGO_IBGE: 5001243,
      },
      {
        NOME: "Bandeirantes",
        CODIGO_IBGE: 5001508,
      },
      {
        NOME: "Bataguassu",
        CODIGO_IBGE: 5001904,
      },
      {
        NOME: "Batayporã",
        CODIGO_IBGE: 5002001,
      },
      {
        NOME: "Bela Vista",
        CODIGO_IBGE: 5002100,
      },
      {
        NOME: "Bodoquena",
        CODIGO_IBGE: 5002159,
      },
      {
        NOME: "Bonito",
        CODIGO_IBGE: 5002209,
      },
      {
        NOME: "Brasilândia",
        CODIGO_IBGE: 5002308,
      },
      {
        NOME: "Caarapó",
        CODIGO_IBGE: 5002407,
      },
      {
        NOME: "Camapuã",
        CODIGO_IBGE: 5002605,
      },
      {
        NOME: "Campo Grande",
        CODIGO_IBGE: 5002704,
      },
      {
        NOME: "Caracol",
        CODIGO_IBGE: 5002803,
      },
      {
        NOME: "Cassilândia",
        CODIGO_IBGE: 5002902,
      },
      {
        NOME: "Chapadão do Sul",
        CODIGO_IBGE: 5002951,
      },
      {
        NOME: "Corguinho",
        CODIGO_IBGE: 5003108,
      },
      {
        NOME: "Coronel Sapucaia",
        CODIGO_IBGE: 5003157,
      },
      {
        NOME: "Corumbá",
        CODIGO_IBGE: 5003207,
      },
      {
        NOME: "Costa Rica",
        CODIGO_IBGE: 5003256,
      },
      {
        NOME: "Coxim",
        CODIGO_IBGE: 5003306,
      },
      {
        NOME: "Deodápolis",
        CODIGO_IBGE: 5003454,
      },
      {
        NOME: "Dois Irmãos do Buriti",
        CODIGO_IBGE: 5003488,
      },
      {
        NOME: "Douradina",
        CODIGO_IBGE: 5003504,
      },
      {
        NOME: "Dourados",
        CODIGO_IBGE: 5003702,
      },
      {
        NOME: "Eldorado",
        CODIGO_IBGE: 5003751,
      },
      {
        NOME: "Fátima do Sul",
        CODIGO_IBGE: 5003801,
      },
      {
        NOME: "Figueirão",
        CODIGO_IBGE: 5003900,
      },
      {
        NOME: "Glória de Dourados",
        CODIGO_IBGE: 5004007,
      },
      {
        NOME: "Guia Lopes da Laguna",
        CODIGO_IBGE: 5004106,
      },
      {
        NOME: "Iguatemi",
        CODIGO_IBGE: 5004304,
      },
      {
        NOME: "Inocência",
        CODIGO_IBGE: 5004403,
      },
      {
        NOME: "Itaporã",
        CODIGO_IBGE: 5004502,
      },
      {
        NOME: "Itaquiraí",
        CODIGO_IBGE: 5004601,
      },
      {
        NOME: "Ivinhema",
        CODIGO_IBGE: 5004700,
      },
      {
        NOME: "Japorã",
        CODIGO_IBGE: 5004809,
      },
      {
        NOME: "Jaraguari",
        CODIGO_IBGE: 5004908,
      },
      {
        NOME: "Jardim",
        CODIGO_IBGE: 5005004,
      },
      {
        NOME: "Jateí",
        CODIGO_IBGE: 5005103,
      },
      {
        NOME: "Juti",
        CODIGO_IBGE: 5005152,
      },
      {
        NOME: "Ladário",
        CODIGO_IBGE: 5005202,
      },
      {
        NOME: "Laguna Carapã",
        CODIGO_IBGE: 5005251,
      },
      {
        NOME: "Maracaju",
        CODIGO_IBGE: 5005400,
      },
      {
        NOME: "Miranda",
        CODIGO_IBGE: 5005608,
      },
      {
        NOME: "Mundo Novo",
        CODIGO_IBGE: 5005681,
      },
      {
        NOME: "Naviraí",
        CODIGO_IBGE: 5005707,
      },
      {
        NOME: "Nioaque",
        CODIGO_IBGE: 5005806,
      },
      {
        NOME: "Nova Alvorada do Sul",
        CODIGO_IBGE: 5006002,
      },
      {
        NOME: "Nova Andradina",
        CODIGO_IBGE: 5006200,
      },
      {
        NOME: "Novo Horizonte do Sul",
        CODIGO_IBGE: 5006259,
      },
      {
        NOME: "Paraíso das Águas",
        CODIGO_IBGE: 5006275,
      },
      {
        NOME: "Paranaíba",
        CODIGO_IBGE: 5006309,
      },
      {
        NOME: "Paranhos",
        CODIGO_IBGE: 5006358,
      },
      {
        NOME: "Pedro Gomes",
        CODIGO_IBGE: 5006408,
      },
      {
        NOME: "Ponta Porã",
        CODIGO_IBGE: 5006606,
      },
      {
        NOME: "Porto Murtinho",
        CODIGO_IBGE: 5006903,
      },
      {
        NOME: "Ribas do Rio Pardo",
        CODIGO_IBGE: 5007109,
      },
      {
        NOME: "Rio Brilhante",
        CODIGO_IBGE: 5007208,
      },
      {
        NOME: "Rio Negro",
        CODIGO_IBGE: 5007307,
      },
      {
        NOME: "Rio Verde de Mato Grosso",
        CODIGO_IBGE: 5007406,
      },
      {
        NOME: "Rochedo",
        CODIGO_IBGE: 5007505,
      },
      {
        NOME: "Santa Rita do Pardo",
        CODIGO_IBGE: 5007554,
      },
      {
        NOME: "São Gabriel do Oeste",
        CODIGO_IBGE: 5007695,
      },
      {
        NOME: "Sete Quedas",
        CODIGO_IBGE: 5007703,
      },
      {
        NOME: "Selvíria",
        CODIGO_IBGE: 5007802,
      },
      {
        NOME: "Sidrolândia",
        CODIGO_IBGE: 5007901,
      },
      {
        NOME: "Sonora",
        CODIGO_IBGE: 5007935,
      },
      {
        NOME: "Tacuru",
        CODIGO_IBGE: 5007950,
      },
      {
        NOME: "Taquarussu",
        CODIGO_IBGE: 5007976,
      },
      {
        NOME: "Terenos",
        CODIGO_IBGE: 5008008,
      },
      {
        NOME: "Três Lagoas",
        CODIGO_IBGE: 5008305,
      },
      {
        NOME: "Vicentina",
        CODIGO_IBGE: 5008404,
      },
    ],
    51: [
      {
        NOME: "Acorizal",
        CODIGO_IBGE: 5100102,
      },
      {
        NOME: "Água Boa",
        CODIGO_IBGE: 5100201,
      },
      {
        NOME: "Alta Floresta",
        CODIGO_IBGE: 5100250,
      },
      {
        NOME: "Alto Araguaia",
        CODIGO_IBGE: 5100300,
      },
      {
        NOME: "Alto Boa Vista",
        CODIGO_IBGE: 5100359,
      },
      {
        NOME: "Alto Garças",
        CODIGO_IBGE: 5100409,
      },
      {
        NOME: "Alto Paraguai",
        CODIGO_IBGE: 5100508,
      },
      {
        NOME: "Alto Taquari",
        CODIGO_IBGE: 5100607,
      },
      {
        NOME: "Apiacás",
        CODIGO_IBGE: 5100805,
      },
      {
        NOME: "Araguaiana",
        CODIGO_IBGE: 5101001,
      },
      {
        NOME: "Araguainha",
        CODIGO_IBGE: 5101209,
      },
      {
        NOME: "Araputanga",
        CODIGO_IBGE: 5101258,
      },
      {
        NOME: "Arenápolis",
        CODIGO_IBGE: 5101308,
      },
      {
        NOME: "Aripuanã",
        CODIGO_IBGE: 5101407,
      },
      {
        NOME: "Barão de Melgaço",
        CODIGO_IBGE: 5101605,
      },
      {
        NOME: "Barra do Bugres",
        CODIGO_IBGE: 5101704,
      },
      {
        NOME: "Barra do Garças",
        CODIGO_IBGE: 5101803,
      },
      {
        NOME: "Bom Jesus do Araguaia",
        CODIGO_IBGE: 5101852,
      },
      {
        NOME: "Brasnorte",
        CODIGO_IBGE: 5101902,
      },
      {
        NOME: "Cáceres",
        CODIGO_IBGE: 5102504,
      },
      {
        NOME: "Campinápolis",
        CODIGO_IBGE: 5102603,
      },
      {
        NOME: "Campo Novo do Parecis",
        CODIGO_IBGE: 5102637,
      },
      {
        NOME: "Campo Verde",
        CODIGO_IBGE: 5102678,
      },
      {
        NOME: "Campos de Júlio",
        CODIGO_IBGE: 5102686,
      },
      {
        NOME: "Canabrava do Norte",
        CODIGO_IBGE: 5102694,
      },
      {
        NOME: "Canarana",
        CODIGO_IBGE: 5102702,
      },
      {
        NOME: "Carlinda",
        CODIGO_IBGE: 5102793,
      },
      {
        NOME: "Castanheira",
        CODIGO_IBGE: 5102850,
      },
      {
        NOME: "Chapada dos Guimarães",
        CODIGO_IBGE: 5103007,
      },
      {
        NOME: "Cláudia",
        CODIGO_IBGE: 5103056,
      },
      {
        NOME: "Cocalinho",
        CODIGO_IBGE: 5103106,
      },
      {
        NOME: "Colíder",
        CODIGO_IBGE: 5103205,
      },
      {
        NOME: "Colniza",
        CODIGO_IBGE: 5103254,
      },
      {
        NOME: "Comodoro",
        CODIGO_IBGE: 5103304,
      },
      {
        NOME: "Confresa",
        CODIGO_IBGE: 5103353,
      },
      {
        NOME: "Conquista D'Oeste",
        CODIGO_IBGE: 5103361,
      },
      {
        NOME: "Cotriguaçu",
        CODIGO_IBGE: 5103379,
      },
      {
        NOME: "Cuiabá",
        CODIGO_IBGE: 5103403,
      },
      {
        NOME: "Curvelândia",
        CODIGO_IBGE: 5103437,
      },
      {
        NOME: "Denise",
        CODIGO_IBGE: 5103452,
      },
      {
        NOME: "Diamantino",
        CODIGO_IBGE: 5103502,
      },
      {
        NOME: "Dom Aquino",
        CODIGO_IBGE: 5103601,
      },
      {
        NOME: "Feliz Natal",
        CODIGO_IBGE: 5103700,
      },
      {
        NOME: "Figueirópolis D'Oeste",
        CODIGO_IBGE: 5103809,
      },
      {
        NOME: "Gaúcha do Norte",
        CODIGO_IBGE: 5103858,
      },
      {
        NOME: "General Carneiro",
        CODIGO_IBGE: 5103908,
      },
      {
        NOME: "Glória D'Oeste",
        CODIGO_IBGE: 5103957,
      },
      {
        NOME: "Guarantã do Norte",
        CODIGO_IBGE: 5104104,
      },
      {
        NOME: "Guiratinga",
        CODIGO_IBGE: 5104203,
      },
      {
        NOME: "Indiavaí",
        CODIGO_IBGE: 5104500,
      },
      {
        NOME: "Ipiranga do Norte",
        CODIGO_IBGE: 5104526,
      },
      {
        NOME: "Itanhangá",
        CODIGO_IBGE: 5104542,
      },
      {
        NOME: "Itaúba",
        CODIGO_IBGE: 5104559,
      },
      {
        NOME: "Itiquira",
        CODIGO_IBGE: 5104609,
      },
      {
        NOME: "Jaciara",
        CODIGO_IBGE: 5104807,
      },
      {
        NOME: "Jangada",
        CODIGO_IBGE: 5104906,
      },
      {
        NOME: "Jauru",
        CODIGO_IBGE: 5105002,
      },
      {
        NOME: "Juara",
        CODIGO_IBGE: 5105101,
      },
      {
        NOME: "Juína",
        CODIGO_IBGE: 5105150,
      },
      {
        NOME: "Juruena",
        CODIGO_IBGE: 5105176,
      },
      {
        NOME: "Juscimeira",
        CODIGO_IBGE: 5105200,
      },
      {
        NOME: "Lambari D'Oeste",
        CODIGO_IBGE: 5105234,
      },
      {
        NOME: "Lucas do Rio Verde",
        CODIGO_IBGE: 5105259,
      },
      {
        NOME: "Luciara",
        CODIGO_IBGE: 5105309,
      },
      {
        NOME: "Vila Bela da Santíssima Trindade",
        CODIGO_IBGE: 5105507,
      },
      {
        NOME: "Marcelândia",
        CODIGO_IBGE: 5105580,
      },
      {
        NOME: "Matupá",
        CODIGO_IBGE: 5105606,
      },
      {
        NOME: "Mirassol d'Oeste",
        CODIGO_IBGE: 5105622,
      },
      {
        NOME: "Nobres",
        CODIGO_IBGE: 5105903,
      },
      {
        NOME: "Nortelândia",
        CODIGO_IBGE: 5106000,
      },
      {
        NOME: "Nossa Senhora do Livramento",
        CODIGO_IBGE: 5106109,
      },
      {
        NOME: "Nova Bandeirantes",
        CODIGO_IBGE: 5106158,
      },
      {
        NOME: "Nova Nazaré",
        CODIGO_IBGE: 5106174,
      },
      {
        NOME: "Nova Lacerda",
        CODIGO_IBGE: 5106182,
      },
      {
        NOME: "Nova Santa Helena",
        CODIGO_IBGE: 5106190,
      },
      {
        NOME: "Nova Brasilândia",
        CODIGO_IBGE: 5106208,
      },
      {
        NOME: "Nova Canaã do Norte",
        CODIGO_IBGE: 5106216,
      },
      {
        NOME: "Nova Mutum",
        CODIGO_IBGE: 5106224,
      },
      {
        NOME: "Nova Olímpia",
        CODIGO_IBGE: 5106232,
      },
      {
        NOME: "Nova Ubiratã",
        CODIGO_IBGE: 5106240,
      },
      {
        NOME: "Nova Xavantina",
        CODIGO_IBGE: 5106257,
      },
      {
        NOME: "Novo Mundo",
        CODIGO_IBGE: 5106265,
      },
      {
        NOME: "Novo Horizonte do Norte",
        CODIGO_IBGE: 5106273,
      },
      {
        NOME: "Novo São Joaquim",
        CODIGO_IBGE: 5106281,
      },
      {
        NOME: "Paranaíta",
        CODIGO_IBGE: 5106299,
      },
      {
        NOME: "Paranatinga",
        CODIGO_IBGE: 5106307,
      },
      {
        NOME: "Novo Santo Antônio",
        CODIGO_IBGE: 5106315,
      },
      {
        NOME: "Pedra Preta",
        CODIGO_IBGE: 5106372,
      },
      {
        NOME: "Peixoto de Azevedo",
        CODIGO_IBGE: 5106422,
      },
      {
        NOME: "Planalto da Serra",
        CODIGO_IBGE: 5106455,
      },
      {
        NOME: "Poconé",
        CODIGO_IBGE: 5106505,
      },
      {
        NOME: "Pontal do Araguaia",
        CODIGO_IBGE: 5106653,
      },
      {
        NOME: "Ponte Branca",
        CODIGO_IBGE: 5106703,
      },
      {
        NOME: "Pontes e Lacerda",
        CODIGO_IBGE: 5106752,
      },
      {
        NOME: "Porto Alegre do Norte",
        CODIGO_IBGE: 5106778,
      },
      {
        NOME: "Porto dos Gaúchos",
        CODIGO_IBGE: 5106802,
      },
      {
        NOME: "Porto Esperidião",
        CODIGO_IBGE: 5106828,
      },
      {
        NOME: "Porto Estrela",
        CODIGO_IBGE: 5106851,
      },
      {
        NOME: "Poxoréu",
        CODIGO_IBGE: 5107008,
      },
      {
        NOME: "Primavera do Leste",
        CODIGO_IBGE: 5107040,
      },
      {
        NOME: "Querência",
        CODIGO_IBGE: 5107065,
      },
      {
        NOME: "São José dos Quatro Marcos",
        CODIGO_IBGE: 5107107,
      },
      {
        NOME: "Reserva do Cabaçal",
        CODIGO_IBGE: 5107156,
      },
      {
        NOME: "Ribeirão Cascalheira",
        CODIGO_IBGE: 5107180,
      },
      {
        NOME: "Ribeirãozinho",
        CODIGO_IBGE: 5107198,
      },
      {
        NOME: "Rio Branco",
        CODIGO_IBGE: 5107206,
      },
      {
        NOME: "Santa Carmem",
        CODIGO_IBGE: 5107248,
      },
      {
        NOME: "Santo Afonso",
        CODIGO_IBGE: 5107263,
      },
      {
        NOME: "São José do Povo",
        CODIGO_IBGE: 5107297,
      },
      {
        NOME: "São José do Rio Claro",
        CODIGO_IBGE: 5107305,
      },
      {
        NOME: "São José do Xingu",
        CODIGO_IBGE: 5107354,
      },
      {
        NOME: "São Pedro da Cipa",
        CODIGO_IBGE: 5107404,
      },
      {
        NOME: "Rondolândia",
        CODIGO_IBGE: 5107578,
      },
      {
        NOME: "Rondonópolis",
        CODIGO_IBGE: 5107602,
      },
      {
        NOME: "Rosário Oeste",
        CODIGO_IBGE: 5107701,
      },
      {
        NOME: "Santa Cruz do Xingu",
        CODIGO_IBGE: 5107743,
      },
      {
        NOME: "Salto do Céu",
        CODIGO_IBGE: 5107750,
      },
      {
        NOME: "Santa Rita do Trivelato",
        CODIGO_IBGE: 5107768,
      },
      {
        NOME: "Santa Terezinha",
        CODIGO_IBGE: 5107776,
      },
      {
        NOME: "Santo Antônio do Leste",
        CODIGO_IBGE: 5107792,
      },
      {
        NOME: "Santo Antônio do Leverger",
        CODIGO_IBGE: 5107800,
      },
      {
        NOME: "São Félix do Araguaia",
        CODIGO_IBGE: 5107859,
      },
      {
        NOME: "Sapezal",
        CODIGO_IBGE: 5107875,
      },
      {
        NOME: "Serra Nova Dourada",
        CODIGO_IBGE: 5107883,
      },
      {
        NOME: "Sinop",
        CODIGO_IBGE: 5107909,
      },
      {
        NOME: "Sorriso",
        CODIGO_IBGE: 5107925,
      },
      {
        NOME: "Tabaporã",
        CODIGO_IBGE: 5107941,
      },
      {
        NOME: "Tangará da Serra",
        CODIGO_IBGE: 5107958,
      },
      {
        NOME: "Tapurah",
        CODIGO_IBGE: 5108006,
      },
      {
        NOME: "Terra Nova do Norte",
        CODIGO_IBGE: 5108055,
      },
      {
        NOME: "Tesouro",
        CODIGO_IBGE: 5108105,
      },
      {
        NOME: "Torixoréu",
        CODIGO_IBGE: 5108204,
      },
      {
        NOME: "União do Sul",
        CODIGO_IBGE: 5108303,
      },
      {
        NOME: "Vale de São Domingos",
        CODIGO_IBGE: 5108352,
      },
      {
        NOME: "Várzea Grande",
        CODIGO_IBGE: 5108402,
      },
      {
        NOME: "Vera",
        CODIGO_IBGE: 5108501,
      },
      {
        NOME: "Vila Rica",
        CODIGO_IBGE: 5108600,
      },
      {
        NOME: "Nova Guarita",
        CODIGO_IBGE: 5108808,
      },
      {
        NOME: "Nova Marilândia",
        CODIGO_IBGE: 5108857,
      },
      {
        NOME: "Nova Maringá",
        CODIGO_IBGE: 5108907,
      },
      {
        NOME: "Nova Monte Verde",
        CODIGO_IBGE: 5108956,
      },
    ],
    52: [
      {
        NOME: "Abadia de Goiás",
        CODIGO_IBGE: 5200050,
      },
      {
        NOME: "Abadiânia",
        CODIGO_IBGE: 5200100,
      },
      {
        NOME: "Acreúna",
        CODIGO_IBGE: 5200134,
      },
      {
        NOME: "Adelândia",
        CODIGO_IBGE: 5200159,
      },
      {
        NOME: "Água Fria de Goiás",
        CODIGO_IBGE: 5200175,
      },
      {
        NOME: "Água Limpa",
        CODIGO_IBGE: 5200209,
      },
      {
        NOME: "Águas Lindas de Goiás",
        CODIGO_IBGE: 5200258,
      },
      {
        NOME: "Alexânia",
        CODIGO_IBGE: 5200308,
      },
      {
        NOME: "Aloândia",
        CODIGO_IBGE: 5200506,
      },
      {
        NOME: "Alto Horizonte",
        CODIGO_IBGE: 5200555,
      },
      {
        NOME: "Alto Paraíso de Goiás",
        CODIGO_IBGE: 5200605,
      },
      {
        NOME: "Alvorada do Norte",
        CODIGO_IBGE: 5200803,
      },
      {
        NOME: "Amaralina",
        CODIGO_IBGE: 5200829,
      },
      {
        NOME: "Americano do Brasil",
        CODIGO_IBGE: 5200852,
      },
      {
        NOME: "Amorinópolis",
        CODIGO_IBGE: 5200902,
      },
      {
        NOME: "Anápolis",
        CODIGO_IBGE: 5201108,
      },
      {
        NOME: "Anhanguera",
        CODIGO_IBGE: 5201207,
      },
      {
        NOME: "Anicuns",
        CODIGO_IBGE: 5201306,
      },
      {
        NOME: "Aparecida de Goiânia",
        CODIGO_IBGE: 5201405,
      },
      {
        NOME: "Aparecida do Rio Doce",
        CODIGO_IBGE: 5201454,
      },
      {
        NOME: "Aporé",
        CODIGO_IBGE: 5201504,
      },
      {
        NOME: "Araçu",
        CODIGO_IBGE: 5201603,
      },
      {
        NOME: "Aragarças",
        CODIGO_IBGE: 5201702,
      },
      {
        NOME: "Aragoiânia",
        CODIGO_IBGE: 5201801,
      },
      {
        NOME: "Araguapaz",
        CODIGO_IBGE: 5202155,
      },
      {
        NOME: "Arenópolis",
        CODIGO_IBGE: 5202353,
      },
      {
        NOME: "Aruanã",
        CODIGO_IBGE: 5202502,
      },
      {
        NOME: "Aurilândia",
        CODIGO_IBGE: 5202601,
      },
      {
        NOME: "Avelinópolis",
        CODIGO_IBGE: 5202809,
      },
      {
        NOME: "Baliza",
        CODIGO_IBGE: 5203104,
      },
      {
        NOME: "Barro Alto",
        CODIGO_IBGE: 5203203,
      },
      {
        NOME: "Bela Vista de Goiás",
        CODIGO_IBGE: 5203302,
      },
      {
        NOME: "Bom Jardim de Goiás",
        CODIGO_IBGE: 5203401,
      },
      {
        NOME: "Bom Jesus de Goiás",
        CODIGO_IBGE: 5203500,
      },
      {
        NOME: "Bonfinópolis",
        CODIGO_IBGE: 5203559,
      },
      {
        NOME: "Bonópolis",
        CODIGO_IBGE: 5203575,
      },
      {
        NOME: "Brazabrantes",
        CODIGO_IBGE: 5203609,
      },
      {
        NOME: "Britânia",
        CODIGO_IBGE: 5203807,
      },
      {
        NOME: "Buriti Alegre",
        CODIGO_IBGE: 5203906,
      },
      {
        NOME: "Buriti de Goiás",
        CODIGO_IBGE: 5203939,
      },
      {
        NOME: "Buritinópolis",
        CODIGO_IBGE: 5203962,
      },
      {
        NOME: "Cabeceiras",
        CODIGO_IBGE: 5204003,
      },
      {
        NOME: "Cachoeira Alta",
        CODIGO_IBGE: 5204102,
      },
      {
        NOME: "Cachoeira de Goiás",
        CODIGO_IBGE: 5204201,
      },
      {
        NOME: "Cachoeira Dourada",
        CODIGO_IBGE: 5204250,
      },
      {
        NOME: "Caçu",
        CODIGO_IBGE: 5204300,
      },
      {
        NOME: "Caiapônia",
        CODIGO_IBGE: 5204409,
      },
      {
        NOME: "Caldas Novas",
        CODIGO_IBGE: 5204508,
      },
      {
        NOME: "Caldazinha",
        CODIGO_IBGE: 5204557,
      },
      {
        NOME: "Campestre de Goiás",
        CODIGO_IBGE: 5204607,
      },
      {
        NOME: "Campinaçu",
        CODIGO_IBGE: 5204656,
      },
      {
        NOME: "Campinorte",
        CODIGO_IBGE: 5204706,
      },
      {
        NOME: "Campo Alegre de Goiás",
        CODIGO_IBGE: 5204805,
      },
      {
        NOME: "Campo Limpo de Goiás",
        CODIGO_IBGE: 5204854,
      },
      {
        NOME: "Campos Belos",
        CODIGO_IBGE: 5204904,
      },
      {
        NOME: "Campos Verdes",
        CODIGO_IBGE: 5204953,
      },
      {
        NOME: "Carmo do Rio Verde",
        CODIGO_IBGE: 5205000,
      },
      {
        NOME: "Castelândia",
        CODIGO_IBGE: 5205059,
      },
      {
        NOME: "Catalão",
        CODIGO_IBGE: 5205109,
      },
      {
        NOME: "Caturaí",
        CODIGO_IBGE: 5205208,
      },
      {
        NOME: "Cavalcante",
        CODIGO_IBGE: 5205307,
      },
      {
        NOME: "Ceres",
        CODIGO_IBGE: 5205406,
      },
      {
        NOME: "Cezarina",
        CODIGO_IBGE: 5205455,
      },
      {
        NOME: "Chapadão do Céu",
        CODIGO_IBGE: 5205471,
      },
      {
        NOME: "Cidade Ocidental",
        CODIGO_IBGE: 5205497,
      },
      {
        NOME: "Cocalzinho de Goiás",
        CODIGO_IBGE: 5205513,
      },
      {
        NOME: "Colinas do Sul",
        CODIGO_IBGE: 5205521,
      },
      {
        NOME: "Córrego do Ouro",
        CODIGO_IBGE: 5205703,
      },
      {
        NOME: "Corumbá de Goiás",
        CODIGO_IBGE: 5205802,
      },
      {
        NOME: "Corumbaíba",
        CODIGO_IBGE: 5205901,
      },
      {
        NOME: "Cristalina",
        CODIGO_IBGE: 5206206,
      },
      {
        NOME: "Cristianópolis",
        CODIGO_IBGE: 5206305,
      },
      {
        NOME: "Crixás",
        CODIGO_IBGE: 5206404,
      },
      {
        NOME: "Cromínia",
        CODIGO_IBGE: 5206503,
      },
      {
        NOME: "Cumari",
        CODIGO_IBGE: 5206602,
      },
      {
        NOME: "Damianópolis",
        CODIGO_IBGE: 5206701,
      },
      {
        NOME: "Damolândia",
        CODIGO_IBGE: 5206800,
      },
      {
        NOME: "Davinópolis",
        CODIGO_IBGE: 5206909,
      },
      {
        NOME: "Diorama",
        CODIGO_IBGE: 5207105,
      },
      {
        NOME: "Doverlândia",
        CODIGO_IBGE: 5207253,
      },
      {
        NOME: "Edealina",
        CODIGO_IBGE: 5207352,
      },
      {
        NOME: "Edéia",
        CODIGO_IBGE: 5207402,
      },
      {
        NOME: "Estrela do Norte",
        CODIGO_IBGE: 5207501,
      },
      {
        NOME: "Faina",
        CODIGO_IBGE: 5207535,
      },
      {
        NOME: "Fazenda Nova",
        CODIGO_IBGE: 5207600,
      },
      {
        NOME: "Firminópolis",
        CODIGO_IBGE: 5207808,
      },
      {
        NOME: "Flores de Goiás",
        CODIGO_IBGE: 5207907,
      },
      {
        NOME: "Formosa",
        CODIGO_IBGE: 5208004,
      },
      {
        NOME: "Formoso",
        CODIGO_IBGE: 5208103,
      },
      {
        NOME: "Gameleira de Goiás",
        CODIGO_IBGE: 5208152,
      },
      {
        NOME: "Divinópolis de Goiás",
        CODIGO_IBGE: 5208301,
      },
      {
        NOME: "Goianápolis",
        CODIGO_IBGE: 5208400,
      },
      {
        NOME: "Goiandira",
        CODIGO_IBGE: 5208509,
      },
      {
        NOME: "Goianésia",
        CODIGO_IBGE: 5208608,
      },
      {
        NOME: "Goiânia",
        CODIGO_IBGE: 5208707,
      },
      {
        NOME: "Goianira",
        CODIGO_IBGE: 5208806,
      },
      {
        NOME: "Goiás",
        CODIGO_IBGE: 5208905,
      },
      {
        NOME: "Goiatuba",
        CODIGO_IBGE: 5209101,
      },
      {
        NOME: "Gouvelândia",
        CODIGO_IBGE: 5209150,
      },
      {
        NOME: "Guapó",
        CODIGO_IBGE: 5209200,
      },
      {
        NOME: "Guaraíta",
        CODIGO_IBGE: 5209291,
      },
      {
        NOME: "Guarani de Goiás",
        CODIGO_IBGE: 5209408,
      },
      {
        NOME: "Guarinos",
        CODIGO_IBGE: 5209457,
      },
      {
        NOME: "Heitoraí",
        CODIGO_IBGE: 5209606,
      },
      {
        NOME: "Hidrolândia",
        CODIGO_IBGE: 5209705,
      },
      {
        NOME: "Hidrolina",
        CODIGO_IBGE: 5209804,
      },
      {
        NOME: "Iaciara",
        CODIGO_IBGE: 5209903,
      },
      {
        NOME: "Inaciolândia",
        CODIGO_IBGE: 5209937,
      },
      {
        NOME: "Indiara",
        CODIGO_IBGE: 5209952,
      },
      {
        NOME: "Inhumas",
        CODIGO_IBGE: 5210000,
      },
      {
        NOME: "Ipameri",
        CODIGO_IBGE: 5210109,
      },
      {
        NOME: "Ipiranga de Goiás",
        CODIGO_IBGE: 5210158,
      },
      {
        NOME: "Iporá",
        CODIGO_IBGE: 5210208,
      },
      {
        NOME: "Israelândia",
        CODIGO_IBGE: 5210307,
      },
      {
        NOME: "Itaberaí",
        CODIGO_IBGE: 5210406,
      },
      {
        NOME: "Itaguari",
        CODIGO_IBGE: 5210562,
      },
      {
        NOME: "Itaguaru",
        CODIGO_IBGE: 5210604,
      },
      {
        NOME: "Itajá",
        CODIGO_IBGE: 5210802,
      },
      {
        NOME: "Itapaci",
        CODIGO_IBGE: 5210901,
      },
      {
        NOME: "Itapirapuã",
        CODIGO_IBGE: 5211008,
      },
      {
        NOME: "Itapuranga",
        CODIGO_IBGE: 5211206,
      },
      {
        NOME: "Itarumã",
        CODIGO_IBGE: 5211305,
      },
      {
        NOME: "Itauçu",
        CODIGO_IBGE: 5211404,
      },
      {
        NOME: "Itumbiara",
        CODIGO_IBGE: 5211503,
      },
      {
        NOME: "Ivolândia",
        CODIGO_IBGE: 5211602,
      },
      {
        NOME: "Jandaia",
        CODIGO_IBGE: 5211701,
      },
      {
        NOME: "Jaraguá",
        CODIGO_IBGE: 5211800,
      },
      {
        NOME: "Jataí",
        CODIGO_IBGE: 5211909,
      },
      {
        NOME: "Jaupaci",
        CODIGO_IBGE: 5212006,
      },
      {
        NOME: "Jesúpolis",
        CODIGO_IBGE: 5212055,
      },
      {
        NOME: "Joviânia",
        CODIGO_IBGE: 5212105,
      },
      {
        NOME: "Jussara",
        CODIGO_IBGE: 5212204,
      },
      {
        NOME: "Lagoa Santa",
        CODIGO_IBGE: 5212253,
      },
      {
        NOME: "Leopoldo de Bulhões",
        CODIGO_IBGE: 5212303,
      },
      {
        NOME: "Luziânia",
        CODIGO_IBGE: 5212501,
      },
      {
        NOME: "Mairipotaba",
        CODIGO_IBGE: 5212600,
      },
      {
        NOME: "Mambaí",
        CODIGO_IBGE: 5212709,
      },
      {
        NOME: "Mara Rosa",
        CODIGO_IBGE: 5212808,
      },
      {
        NOME: "Marzagão",
        CODIGO_IBGE: 5212907,
      },
      {
        NOME: "Matrinchã",
        CODIGO_IBGE: 5212956,
      },
      {
        NOME: "Maurilândia",
        CODIGO_IBGE: 5213004,
      },
      {
        NOME: "Mimoso de Goiás",
        CODIGO_IBGE: 5213053,
      },
      {
        NOME: "Minaçu",
        CODIGO_IBGE: 5213087,
      },
      {
        NOME: "Mineiros",
        CODIGO_IBGE: 5213103,
      },
      {
        NOME: "Moiporá",
        CODIGO_IBGE: 5213400,
      },
      {
        NOME: "Monte Alegre de Goiás",
        CODIGO_IBGE: 5213509,
      },
      {
        NOME: "Montes Claros de Goiás",
        CODIGO_IBGE: 5213707,
      },
      {
        NOME: "Montividiu",
        CODIGO_IBGE: 5213756,
      },
      {
        NOME: "Montividiu do Norte",
        CODIGO_IBGE: 5213772,
      },
      {
        NOME: "Morrinhos",
        CODIGO_IBGE: 5213806,
      },
      {
        NOME: "Morro Agudo de Goiás",
        CODIGO_IBGE: 5213855,
      },
      {
        NOME: "Mossâmedes",
        CODIGO_IBGE: 5213905,
      },
      {
        NOME: "Mozarlândia",
        CODIGO_IBGE: 5214002,
      },
      {
        NOME: "Mundo Novo",
        CODIGO_IBGE: 5214051,
      },
      {
        NOME: "Mutunópolis",
        CODIGO_IBGE: 5214101,
      },
      {
        NOME: "Nazário",
        CODIGO_IBGE: 5214408,
      },
      {
        NOME: "Nerópolis",
        CODIGO_IBGE: 5214507,
      },
      {
        NOME: "Niquelândia",
        CODIGO_IBGE: 5214606,
      },
      {
        NOME: "Nova América",
        CODIGO_IBGE: 5214705,
      },
      {
        NOME: "Nova Aurora",
        CODIGO_IBGE: 5214804,
      },
      {
        NOME: "Nova Crixás",
        CODIGO_IBGE: 5214838,
      },
      {
        NOME: "Nova Glória",
        CODIGO_IBGE: 5214861,
      },
      {
        NOME: "Nova Iguaçu de Goiás",
        CODIGO_IBGE: 5214879,
      },
      {
        NOME: "Nova Roma",
        CODIGO_IBGE: 5214903,
      },
      {
        NOME: "Nova Veneza",
        CODIGO_IBGE: 5215009,
      },
      {
        NOME: "Novo Brasil",
        CODIGO_IBGE: 5215207,
      },
      {
        NOME: "Novo Gama",
        CODIGO_IBGE: 5215231,
      },
      {
        NOME: "Novo Planalto",
        CODIGO_IBGE: 5215256,
      },
      {
        NOME: "Orizona",
        CODIGO_IBGE: 5215306,
      },
      {
        NOME: "Ouro Verde de Goiás",
        CODIGO_IBGE: 5215405,
      },
      {
        NOME: "Ouvidor",
        CODIGO_IBGE: 5215504,
      },
      {
        NOME: "Padre Bernardo",
        CODIGO_IBGE: 5215603,
      },
      {
        NOME: "Palestina de Goiás",
        CODIGO_IBGE: 5215652,
      },
      {
        NOME: "Palmeiras de Goiás",
        CODIGO_IBGE: 5215702,
      },
      {
        NOME: "Palmelo",
        CODIGO_IBGE: 5215801,
      },
      {
        NOME: "Palminópolis",
        CODIGO_IBGE: 5215900,
      },
      {
        NOME: "Panamá",
        CODIGO_IBGE: 5216007,
      },
      {
        NOME: "Paranaiguara",
        CODIGO_IBGE: 5216304,
      },
      {
        NOME: "Paraúna",
        CODIGO_IBGE: 5216403,
      },
      {
        NOME: "Perolândia",
        CODIGO_IBGE: 5216452,
      },
      {
        NOME: "Petrolina de Goiás",
        CODIGO_IBGE: 5216809,
      },
      {
        NOME: "Pilar de Goiás",
        CODIGO_IBGE: 5216908,
      },
      {
        NOME: "Piracanjuba",
        CODIGO_IBGE: 5217104,
      },
      {
        NOME: "Piranhas",
        CODIGO_IBGE: 5217203,
      },
      {
        NOME: "Pirenópolis",
        CODIGO_IBGE: 5217302,
      },
      {
        NOME: "Pires do Rio",
        CODIGO_IBGE: 5217401,
      },
      {
        NOME: "Planaltina",
        CODIGO_IBGE: 5217609,
      },
      {
        NOME: "Pontalina",
        CODIGO_IBGE: 5217708,
      },
      {
        NOME: "Porangatu",
        CODIGO_IBGE: 5218003,
      },
      {
        NOME: "Porteirão",
        CODIGO_IBGE: 5218052,
      },
      {
        NOME: "Portelândia",
        CODIGO_IBGE: 5218102,
      },
      {
        NOME: "Posse",
        CODIGO_IBGE: 5218300,
      },
      {
        NOME: "Professor Jamil",
        CODIGO_IBGE: 5218391,
      },
      {
        NOME: "Quirinópolis",
        CODIGO_IBGE: 5218508,
      },
      {
        NOME: "Rialma",
        CODIGO_IBGE: 5218607,
      },
      {
        NOME: "Rianápolis",
        CODIGO_IBGE: 5218706,
      },
      {
        NOME: "Rio Quente",
        CODIGO_IBGE: 5218789,
      },
      {
        NOME: "Rio Verde",
        CODIGO_IBGE: 5218805,
      },
      {
        NOME: "Rubiataba",
        CODIGO_IBGE: 5218904,
      },
      {
        NOME: "Sanclerlândia",
        CODIGO_IBGE: 5219001,
      },
      {
        NOME: "Santa Bárbara de Goiás",
        CODIGO_IBGE: 5219100,
      },
      {
        NOME: "Santa Cruz de Goiás",
        CODIGO_IBGE: 5219209,
      },
      {
        NOME: "Santa Fé de Goiás",
        CODIGO_IBGE: 5219258,
      },
      {
        NOME: "Santa Helena de Goiás",
        CODIGO_IBGE: 5219308,
      },
      {
        NOME: "Santa Isabel",
        CODIGO_IBGE: 5219357,
      },
      {
        NOME: "Santa Rita do Araguaia",
        CODIGO_IBGE: 5219407,
      },
      {
        NOME: "Santa Rita do Novo Destino",
        CODIGO_IBGE: 5219456,
      },
      {
        NOME: "Santa Rosa de Goiás",
        CODIGO_IBGE: 5219506,
      },
      {
        NOME: "Santa Tereza de Goiás",
        CODIGO_IBGE: 5219605,
      },
      {
        NOME: "Santa Terezinha de Goiás",
        CODIGO_IBGE: 5219704,
      },
      {
        NOME: "Santo Antônio da Barra",
        CODIGO_IBGE: 5219712,
      },
      {
        NOME: "Santo Antônio de Goiás",
        CODIGO_IBGE: 5219738,
      },
      {
        NOME: "Santo Antônio do Descoberto",
        CODIGO_IBGE: 5219753,
      },
      {
        NOME: "São Domingos",
        CODIGO_IBGE: 5219803,
      },
      {
        NOME: "São Francisco de Goiás",
        CODIGO_IBGE: 5219902,
      },
      {
        NOME: "São João d'Aliança",
        CODIGO_IBGE: 5220009,
      },
      {
        NOME: "São João da Paraúna",
        CODIGO_IBGE: 5220058,
      },
      {
        NOME: "São Luís de Montes Belos",
        CODIGO_IBGE: 5220108,
      },
      {
        NOME: "São Luiz do Norte",
        CODIGO_IBGE: 5220157,
      },
      {
        NOME: "São Miguel do Araguaia",
        CODIGO_IBGE: 5220207,
      },
      {
        NOME: "São Miguel do Passa Quatro",
        CODIGO_IBGE: 5220264,
      },
      {
        NOME: "São Patrício",
        CODIGO_IBGE: 5220280,
      },
      {
        NOME: "São Simão",
        CODIGO_IBGE: 5220405,
      },
      {
        NOME: "Senador Canedo",
        CODIGO_IBGE: 5220454,
      },
      {
        NOME: "Serranópolis",
        CODIGO_IBGE: 5220504,
      },
      {
        NOME: "Silvânia",
        CODIGO_IBGE: 5220603,
      },
      {
        NOME: "Simolândia",
        CODIGO_IBGE: 5220686,
      },
      {
        NOME: "Sítio d'Abadia",
        CODIGO_IBGE: 5220702,
      },
      {
        NOME: "Taquaral de Goiás",
        CODIGO_IBGE: 5221007,
      },
      {
        NOME: "Teresina de Goiás",
        CODIGO_IBGE: 5221080,
      },
      {
        NOME: "Terezópolis de Goiás",
        CODIGO_IBGE: 5221197,
      },
      {
        NOME: "Três Ranchos",
        CODIGO_IBGE: 5221304,
      },
      {
        NOME: "Trindade",
        CODIGO_IBGE: 5221403,
      },
      {
        NOME: "Trombas",
        CODIGO_IBGE: 5221452,
      },
      {
        NOME: "Turvânia",
        CODIGO_IBGE: 5221502,
      },
      {
        NOME: "Turvelândia",
        CODIGO_IBGE: 5221551,
      },
      {
        NOME: "Uirapuru",
        CODIGO_IBGE: 5221577,
      },
      {
        NOME: "Uruaçu",
        CODIGO_IBGE: 5221601,
      },
      {
        NOME: "Uruana",
        CODIGO_IBGE: 5221700,
      },
      {
        NOME: "Urutaí",
        CODIGO_IBGE: 5221809,
      },
      {
        NOME: "Valparaíso de Goiás",
        CODIGO_IBGE: 5221858,
      },
      {
        NOME: "Varjão",
        CODIGO_IBGE: 5221908,
      },
      {
        NOME: "Vianópolis",
        CODIGO_IBGE: 5222005,
      },
      {
        NOME: "Vicentinópolis",
        CODIGO_IBGE: 5222054,
      },
      {
        NOME: "Vila Boa",
        CODIGO_IBGE: 5222203,
      },
      {
        NOME: "Vila Propício",
        CODIGO_IBGE: 5222302,
      },
    ],
    53: [
      {
        NOME: "Brasília",
        CODIGO_IBGE: 5300108,
      },
    ],
  },
};

module.exports = {
  estadosCompleto: estados,
  cidadesCompleto: cidades,
};
