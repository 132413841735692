import React, { useCallback, useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  cpfMask,
  validaCpf,
  postAutenticado,
  getAutenticado,
  formataValorReais,
  mascaraPhone,
  mascaraCEP,
  formataDataHora,
} from "js_utils/util.js";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Table from "components/Table/Table.js";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { MonetizationOn } from "@material-ui/icons";

import { TextField } from "@material-ui/core";
import FichaFinanceiraCliente from "./FichaFinanceiraCliente";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};
const { cidadesCompleto, estadosCompleto } = require("../../variables/geo");
const estados = estadosCompleto.data;
const logout = require("../../js_utils/logout");
const useStyles = makeStyles(styles);

export default function Clientes() {
  const { API_URL } = require("../../consts");
  const path = useLocation();

  /* edicao de local */
  // prettier-ignore
  const [cidadesEstadoEdicao, setCidadesEstadoEdicao] = useState(
    cidadesCompleto.data[31],
  );
  // prettier-ignore
  const [cidadeSelecionadaEdicao, setCidadeSelecionadaEdicao] = useState(
    cidadesCompleto.data[31][0],
  );
  const [estadoSelecionadoEdicao, setEstadoEdicao] = useState(estados[31]);

  const cidadeChangeEdicao = (value) => {
    if (value) {
      setCidadeSelecionadaEdicao(value);
    } else clienteEdit.COD_CIDADE = null;
  };

  const estadoEdicaoChange = (value) => {
    if (value && value.CODIGO_IBGE) {
      setCidadesEstadoEdicao(cidadesCompleto.data[value.CODIGO_IBGE]);
      setCidadeSelecionadaEdicao(cidadesCompleto.data[value.CODIGO_IBGE][0]);
      clienteEdit.COD_CIDADE =
        cidadesCompleto.data[value.CODIGO_IBGE][0].CODIGO_IBGE;
      const selec = estados.find((item) => {
        return item.CODIGO_IBGE === value.CODIGO_IBGE;
      });
      setEstadoEdicao(selec);
      clienteEdit.COD_ESTADO = value.CODIGO_IBGE;
    } else {
      setEstadoEdicao(estados[31]);
      setCidadesEstadoEdicao(cidadesCompleto.data[31]);
      setCidadeSelecionadaEdicao(cidadesCompleto.data[31][0]);
    }
  };

  /* fim edicao */

  let [mensagemErro, setMensagemErro] = useState("");

  const [cpf, setCpf] = useState("");
  const [limite, setLimite] = useState(0);
  const [alerta, setAlerta] = useState(false);
  const [observacao, setObservacao] = useState("");
  const [clientes, setClientes] = useState([]);
  const [clienteEdit, setClientEdit] = useState(null);

  const showNotification = () => {
    if (!alerta) {
      setAlerta(true);
      setTimeout(function () {
        setAlerta(false);
      }, 6000);
    }
  };
  const [cor, setCor] = useState("warning");

  const carregaClientes = async () => {
    try {
      const resultados = await getAutenticado(API_URL + "clientes");
      const clis = resultados.data.resultados.map((item) => {
        const cliente = [
          cpfMask(item.CPF),
          item.NOME,
          item.LOGRADOURO + ", num° " + item.NUMERO,
          item.CIDADE + "/" + item.SIGLA,
          // prettier-ignore
          formataValorReais(
            parseFloat(item.LIMITE_INICIAL) + parseFloat(item.VAR_LIMITE),
          ),
          <Link
            to={`/admin/clientes/${item.CPF}`}
            key={item.CPF}
            style={{
              cursor: "pointer",
            }}
          >
            <MonetizationOn />
          </Link>,
          <Button
            key={item.CPF}
            color="info"
            onClick={() => {
              let estado = estados.find((it) => {
                return it.CODIGO_IBGE === item.COD_ESTADO;
              });
              let cidade = cidadesCompleto.data[item.COD_ESTADO].find((it) => {
                return it.CODIGO_IBGE === item.COD_CIDADE;
              });
              setEstadoEdicao(estado);
              setCidadeSelecionadaEdicao(cidade);
              setClientEdit(item);
            }}
          >
            Editar
          </Button>,
        ];
        return cliente;
      });
      if (!resultados.data.sucesso) logout();
      setClientes(clis);
    } catch (err) {
      console.log(err.message);
      setClientes([]);
      logout();
    }
  };

  const mudaValor = (e) => {
    let valor = e.target.value;
    valor = valor.replace(/\D/g, "");
    valor = parseFloat(valor) || 0.0;
    valor = valor / 100.0;
    setLimite(valor);
  };

  const mudaCPF = (e) => {
    setCpf(cpfMask(e.target.value));
  };
  const mostraMsg = (mensagem, color) => {
    let cor_msg = color ? color : "danger";
    setCor(cor_msg);
    setMensagemErro(mensagem);
    showNotification();
  };
  const cadastraValores = async () => {
    if (!validaCpf(cpf)) {
      mostraMsg("CPF inválido");
    } else {
      console.log(`${limite}, ${cpf}, ${observacao}`);
      try {
        const resultado = await postAutenticado(API_URL + "preaprovalimite", {
          cpf: cpf,
          vlrLimite: limite,
          observacao: observacao,
        });
        if (!resultado.data.sucesso) {
          mostraMsg(resultado.data.mensagem);
        } else {
          mostraMsg("Limite cadastrado com sucesso!", "success");
        }
      } catch (error) {
        mostraMsg("Erro. Tente mais tarde");
      }
    }
  };

  const confereEdicao = async () => {
    let erro = false;
    setMensagemErro("");
    if (!clienteEdit.NOME || clienteEdit.NOME.length < 3) {
      erro = true;
      setMensagemErro("Confira o Nome!\n");
    } else if (clienteEdit.FONE && clienteEdit.FONE.length < 8) {
      erro = true;
      setMensagemErro("Confira o Telefone!\n");
    } else if (!clienteEdit.LOGRADOURO || clienteEdit.LOGRADOURO.length < 3) {
      erro = true;
      setMensagemErro("Confira o Logradouro!\n");
    } else if (!clienteEdit.NUMERO || clienteEdit.NUMERO.length < 1) {
      erro = true;
      setMensagemErro("Confira o Número!");
    } else if (!clienteEdit.BAIRRO || clienteEdit.BAIRRO.length < 1) {
      erro = true;
      setMensagemErro("Confira o Bairro!\n");
    } else if (!clienteEdit.CEP || clienteEdit.CEP.length < 8) {
      erro = true;
      setMensagemErro("Confira o Cep!");
    } else if (!estadoSelecionadoEdicao) {
      erro = true;
      setMensagemErro("Confira a UF!");
    } else if (!cidadeSelecionadaEdicao) {
      erro = true;
      setMensagemErro("Confira a cidade!");
    }
    if (erro) {
      setCor("danger");
      showNotification();
    } else {
      try {
        await postAutenticado(API_URL + "alteracliente", {
          dados: {
            CPF: clienteEdit.CPF,
            NOME: clienteEdit.NOME,
            LOGRADOURO: clienteEdit.LOGRADOURO,
            BAIRRO: clienteEdit.BAIRRO,
            NUMERO: clienteEdit.NUMERO,
            RG: clienteEdit.RG,
            ESTADO_CIVIL: clienteEdit.ESTADO_CIVIL,
            PROFISSAO: clienteEdit.PROFISSAO,
            TELEFONE: clienteEdit.FONE,
            COD_CIDADE: cidadeSelecionadaEdicao.CODIGO_IBGE,
            COMPLEMENTO: clienteEdit.COMPLEMENTO ? clienteEdit.COMPLEMENTO : "",
            CEP: clienteEdit.CEP,
            LIMITE_INICIAL: clienteEdit.LIMITE_INICIAL,
          },
        });
        carregaClientes();
        setMensagemErro("Cliente alterado com sucesso");
        setCor("success");
        showNotification();
      } catch (err) {
        if (err.response.data.mensagem)
          setMensagemErro(err.response.data.mensagem);
        else setMensagemErro("Erro no processamento. Tente mais tarde");
        setCor("danger");
        showNotification();
      } finally {
        setClientEdit(null);
      }
    }
  };

  const classes = useStyles();

  const mudaValorLim = (valor) => {
    valor = valor.replace(/\D/g, "");
    valor = parseFloat(valor) || 0.0;
    valor = valor / 100.0;
    setClientEdit((prevState) => ({
      ...prevState,
      LIMITE_INICIAL: valor,
    }));
  };

  const clienteEditChange = (e) => {
    let { name, value } = e.target;
    if (name == "LIMITE_INICIAL") {
      mudaValorLim(value);
      return;
    }
    if (name === "FONE") value = mascaraPhone(value);
    else if (name === "CEP") value = mascaraCEP(value);
    setClientEdit((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(carregaClientes, []);
  const editFragment = () => (
    <React.Fragment>
      <GridContainer>
        <Card>
          <CardHeader color="primary">
            {" "}
            <h4 className={classes.cardTitleWhite}>Editar Cliente</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={8}>
                <TextField
                  label="Nome"
                  id="nome-edicao"
                  name="NOME"
                  value={clienteEdit.NOME}
                  onChange={clienteEditChange}
                  fullWidth={true}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <TextField
                  label="RG"
                  id="rg-edicao"
                  name="RG"
                  onChange={clienteEditChange}
                  value={clienteEdit.RG}
                  fullWidth={true}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <TextField
                  label="Telefone"
                  id="fone-edicao"
                  name="FONE"
                  onChange={clienteEditChange}
                  value={mascaraPhone(clienteEdit.FONE)}
                  fullWidth={true}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <TextField
                  label="Profissao"
                  id="profissao-edicao"
                  name="PROFISSAO"
                  onChange={clienteEditChange}
                  value={clienteEdit.PROFISSAO}
                  fullWidth={true}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <TextField
                  label="Estado Civil"
                  id="fone-edicao"
                  name="ESTADO_CIVIL"
                  onChange={clienteEditChange}
                  value={clienteEdit.ESTADO_CIVIL}
                  fullWidth={true}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <h5>Endereço</h5>
                <TextField
                  label="Logradouro"
                  id="logradouro-edicao"
                  name="LOGRADOURO"
                  value={clienteEdit.LOGRADOURO}
                  onChange={clienteEditChange}
                  fullWidth={true}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <TextField
                  label="Número"
                  id="numero-endereco-EDICAO"
                  name="NUMERO"
                  onChange={clienteEditChange}
                  value={clienteEdit.NUMERO}
                  fullWidth={true}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <TextField
                  label="Complemento"
                  id="complemento-edicao"
                  name="COMPLEMENTO"
                  value={clienteEdit.COMPLEMENTO}
                  onChange={clienteEditChange}
                  fullWidth={true}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <TextField
                  label="Bairro"
                  id="bairro-edicao"
                  name="BAIRRO"
                  value={clienteEdit.BAIRRO}
                  onChange={clienteEditChange}
                  fullWidth={true}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <TextField
                  label="CEP"
                  id="cep-edicao"
                  name="CEP"
                  value={mascaraCEP(clienteEdit.CEP)}
                  onChange={clienteEditChange}
                  fullWidth={true}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <Autocomplete
                  style={{ marginTop: 10 }}
                  options={estados}
                  getOptionLabel={(option) => option.NOME}
                  id="uf-edicao"
                  onChange={(event, value) => estadoEdicaoChange(value)}
                  defaultValue={estados[31]}
                  value={estadoSelecionadoEdicao}
                  renderInput={(params) => (
                    <TextField {...params} label="Estado" />
                  )}
                  fullWidth={true}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <Autocomplete
                  style={{ marginTop: 10 }}
                  options={cidadesEstadoEdicao}
                  getOptionLabel={(option) => option.NOME}
                  defaultValue={
                    cidadesCompleto.data[clienteEdit.COD_ESTADO][
                      clienteEdit.COD_CIDADE
                    ]
                  }
                  value={cidadeSelecionadaEdicao}
                  onChange={(event, value) => cidadeChangeEdicao(value)}
                  id="cidade-edicao"
                  renderInput={(params) => (
                    <TextField {...params} label="Cidade" />
                  )}
                  fullWidth={true}
                />
              </GridItem>
            </GridContainer>
            <h5>Limite</h5>
            <GridContainer>
              <GridItem xs={4} sm={4} md={4}>
                <TextField
                  label="Limite Base"
                  id="limite-base-edicao"
                  name="LIMITE_INICIAL"
                  value={formataValorReais(clienteEdit.LIMITE_INICIAL)}
                  onChange={clienteEditChange}
                  fullWidth={true}
                />
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <TextField
                  label="Limite Usado"
                  value={formataValorReais(clienteEdit.VAR_LIMITE)}
                  onChange={() => {}}
                  fullWidth={true}
                />
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <TextField
                  label="Limite Total"
                  value={
                    // prettier-ignore
                    formataValorReais(
                    parseFloat(clienteEdit.LIMITE_INICIAL) +
                      parseFloat(clienteEdit.VAR_LIMITE),
                  )
                  }
                  onChange={() => {}}
                  fullWidth={true}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
          <CardFooter>
            <span>
              <Button color="info" onClick={() => setClientEdit(null)}>
                Voltar
              </Button>
              <Button color="success" onClick={() => confereEdicao()}>
                Salvar
              </Button>
            </span>
          </CardFooter>
        </Card>
      </GridContainer>
      <Snackbar
        place="bc"
        color={cor || "warning"}
        icon={AddAlert}
        message={mensagemErro}
        open={alerta}
        closeNotification={() => setAlerta(false)}
        close
      />
    </React.Fragment>
  );

  const [cpfPesq, setCpfPesq] = useState("");
  const [infosPreCad, setInfoPreCad] = useState([]);
  const [empreLink, setEmpreLink] = useState("");

  const fetchLimitesPreCadastrados = async () => {
    if (!validaCpf(cpfPesq)) {
      mostraMsg("CPF inválido");
      setInfoPreCad([]);
      setEmpreLink("");
    } else {
      try {
        const _infoCpf = await getAutenticado(
          `${API_URL}consultacpf/${cpfPesq}`
        );
        const data_limites = _infoCpf.data.limites;
        const data_emprestimos = _infoCpf.data.emprestimos;
        const hasLimites =
          data_limites && data_limites.length && data_limites.length > 0;
        const hasEmps =
          data_emprestimos &&
          data_emprestimos.length &&
          data_emprestimos.length > 0;
        if (!hasEmps && !hasLimites)
          mostraMsg("Nenhum dado encontrado", "info");
        if (hasEmps) {
          setEmpreLink(cpfPesq);
        } else {
          setEmpreLink("");
        }
        setInfoPreCad(hasLimites ? data_limites : []);
      } catch (error) {
        mostraMsg("Erro ao buscar a ficha do cliente");
        setInfoPreCad([]);
      }
    }
  };
  const cardLimiteCadastrados = (
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>Consultar CPF</h4>
      </CardHeader>
      <CardBody>
        <TextField
          label="CPF"
          id="observacao"
          onChange={(e) => {
            setCpfPesq(cpfMask(e.target.value));
          }}
          value={cpfPesq}
        />
        <Button color="info" onClick={fetchLimitesPreCadastrados}>
          Pesquisar
        </Button>
        <h4>Limites Pré cadastrados</h4>
        <Table
          tableHeaderColor="primary"
          tableHead={["Dt.hora", "Limite", "Obs", "Admin"]}
          tableData={infosPreCad.map((i) => {
            return [
              formataDataHora(new Date(i.DT_HORA_CADASTRO)),
              formataValorReais(i.LIMITE),
              i.OBSERVACAO,
              i.ADMIN,
            ];
          })}
        />
        <h4>
          {empreLink.length > 0 ? (
            <Link to={`/admin/emprestimos?cpf=${empreLink}`} target="#">
              ver empréstimos: cpf {empreLink}
            </Link>
          ) : null}
        </h4>
      </CardBody>
    </Card>
  );

  const telaInicial = (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Cadastrar Limite</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <TextField
                    label="CPF"
                    id="cpf-pesquisa"
                    onChange={mudaCPF}
                    value={cpf}
                  />
                </GridItem>{" "}
                <GridItem xs={12} sm={12} md={3}>
                  <TextField
                    label="Limite (R$)"
                    id="vlr-limite"
                    onChange={(e) => mudaValor(e)}
                    value={formataValorReais(limite)}
                  />
                </GridItem>{" "}
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    multiline={true}
                    label="Observação"
                    id="observacao"
                    value={observacao}
                    onChange={(e) => setObservacao(e.target.value)}
                    fullWidth={true}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button color="primary" onClick={cadastraValores}>
                Cadastrar
              </Button>
            </CardFooter>
          </Card>
          {cardLimiteCadastrados}
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Clientes</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Table
                    tableHeaderColor="primary"
                    tableHead={[
                      "CPF",
                      "Nome",
                      "Endereço",
                      "Cidade/UF",
                      "Limite",
                      "...",
                    ]}
                    tableData={clientes}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <Snackbar
        place="bc"
        color={cor || "warning"}
        icon={AddAlert}
        message={mensagemErro}
        open={alerta}
        closeNotification={() => setAlerta(false)}
        close
      />
    </React.Fragment>
  );
  const chave = path.pathname.replace(/\/admin\/clientes\/?/, "");
  const fetchClientInfo = useCallback(async () => {
    try {
      const response = await getAutenticado(
        `${API_URL}fichafinanceira/${chave}`
      );
      return response.data.data;
    } catch (error) {
      mostraMsg("Erro ao buscar a ficha do cliente");
    }
  }, [chave]);
  if (typeof chave === "string" && chave.length >= 11)
    return <FichaFinanceiraCliente fetchInfo={fetchClientInfo} />;
  return clienteEdit ? editFragment(clienteEdit) : telaInicial;
}
