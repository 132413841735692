import React, { useState } from "react"
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";

const MultiFiltro = (props) => {
    const { itens, setIds, chaveLabel, chaveID, callback } = props
    const [idSelecionado, setIdSelecionado] = useState([])

    return (<>
        <GridContainer>
            {itens.map((it) => <GridItem xs={12} sm={12} md={12} key={it[chaveID]}>
                <div style={{ border: "2px solid black" }}
                    onClick={
                        () => {
                            setIdSelecionado(it[chaveID]);
                            setIds(it[chaveID]);
                            if (callback)
                                callback()
                        }
                    }>
                    {it[chaveID] === idSelecionado ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />} <span>{it[chaveLabel]}</span>
                </div>
            </GridItem>)}
        </GridContainer>
    </>)
}
MultiFiltro.propTypes = {
    itens: PropTypes.array,
    setIds: PropTypes.func,
    callback: PropTypes.func,
    visualisaParcelasEstab: PropTypes.func,
    chaveLabel: PropTypes.string,
    chaveID: PropTypes.string
}

export default MultiFiltro