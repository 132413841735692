import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Logout from "components/Logout";
//import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody";
import logout from "js_utils/logout";
import { MenuItem, Select, TextField } from "@material-ui/core";
import DateAdapter from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import Button from "components/CustomButtons/Button";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.js";

const {
  getAutenticado,
  postAutenticado,
  formataValorReais,
  formataDataHoraSQL,
} = require("../../js_utils/util");

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function Saidas() {
  const { API_URL } = require("../../consts");
  //let [logado, setLogado] = useState(true);
  const logado = true;
  const [vlrSaida, setVlrSaida] = useState(0.0);
  const [contas, setContas] = useState({});
  const [contaSelecionada, setContaSelecionada] = useState("");
  const [tiposLancamento, setTiposLancamento] = useState({});
  const [tipoLancamentoSelecionado, setTipoLancamentoSelecionado] = useState(
    ""
  );
  const [observacao, setObsercavao] = useState("");
  const [data, setData] = useState(new Date());

  //Snackbar
  let [mensagemErro, setMensagemErro] = useState("");
  const [alerta, setAlerta] = useState(false);
  const [cor, setCor] = useState("warning");
  const showNotification = () => {
    if (!alerta) {
      setAlerta(true);
      setTimeout(function () {
        setAlerta(false);
      }, 6000);
    }
  };

  const buscaContas = async () => {
    try {
      const resultados = await getAutenticado(API_URL + "contas");
      setContas(resultados.data.resultados);
      //PERIGOSO SE NÃO HOUVER PELO MENOS UMA CONTA
      setContaSelecionada(resultados.data.resultados[0]);
    } catch (err) {
      console.log(err);
      logout();
    }
  };

  const buscaTiposLancamentos = async () => {
    try {
      const resultados = await getAutenticado(API_URL + "tiposlancamento/D");
      setTiposLancamento(resultados.data.resultados);
      //PERIGOSO SE NÃO HOUVER PELO MENOS UM TIPO DE LANCAMENTO 'D'
      setTipoLancamentoSelecionado(resultados.data.resultados[0]);
    } catch (err) {
      console.log(err);
      logout();
    }
  };

  const mudaValor = (e) => {
    let valor = e.target.value;
    valor = valor.replace(/\D/g, "");
    valor = parseFloat(valor) || 0.0;
    valor = valor / 100.0;
    setVlrSaida(valor);
  };
  const listaContas = () => {
    let items = [];
    for (let i = 0; i < contas.length; i++)
      items.push(
        <MenuItem key={i} value={contas[i]}>
          {contas[i].NUM_CONTA + "-" + contas[i].DV_CONTA}
        </MenuItem>
      );
    return items;
  };

  const listaTiposLancamento = () => {
    let items = [];
    for (let i = 0; i < tiposLancamento.length; i++)
      items.push(
        <MenuItem key={i} value={tiposLancamento[i]}>
          {tiposLancamento[i].CD + "-" + tiposLancamento[i].DESCRICAO}
        </MenuItem>
      );
    return items;
  };

  const confirmaSaida = async () => {
    // console.log(vlrSaida);
    // console.log(tipoLancamentoSelecionado);
    // console.log(formataDataHoraSQL(data));
    // console.log(observacao);
    if (vlrSaida > 0.0 && contaSelecionada && tipoLancamentoSelecionado) {
      const resultado = await postAutenticado(API_URL + "lancamentoconta", {
        contaID: contaSelecionada.ID,
        tipoLancamentoID: tipoLancamentoSelecionado.ID,
        dataLancamento: formataDataHoraSQL(data),
        valor: vlrSaida,
        observacao: observacao,
      });
      setVlrSaida(0.0);
      if (resultado.data.sucesso) {
        setCor("success");
        setMensagemErro("Lancamento realizado com sucesso");
      } else {
        setCor("danger");
        setMensagemErro(
          "Erro ao realizar o lancamento. Confira os dados e tente mais tarde"
        );
      }
      showNotification();
    }
  };

  useEffect(buscaContas, []);
  useEffect(buscaTiposLancamentos, []);
  const classes = useStyles();
  return logado ? (
    <div>
      <Card>
        <CardHeader color="primary">
          <h5 className={classes.cardTitleWhite}>Saida</h5>
        </CardHeader>
        <CardBody>
          <h4>
            Conta:
            <Select
              style={{ marginLeft: 5 }}
              value={contaSelecionada}
              onChange={(e) => setContaSelecionada(e.target.value)}
            >
              {listaContas()}
            </Select>
          </h4>
          <h4>
            Tipo de Lancamento:
            <Select
              style={{ marginLeft: 5 }}
              value={tipoLancamentoSelecionado}
              onChange={(e) => setTipoLancamentoSelecionado(e.target.value)}
            >
              {listaTiposLancamento()}
            </Select>
          </h4>
          <LocalizationProvider dateAdapter={DateAdapter}>
            <DesktopDatePicker
              label="Data da Saida"
              inputFormat="dd/MM/yyyy"
              value={data}
              onChange={(val) => {
                setData(val);
              }}
              disabled
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <br />
          <TextField
            label="Vlr. Saida"
            name="vlrSaida"
            value={formataValorReais(vlrSaida)}
            onChange={(e) => mudaValor(e)}
          />
          <br />
          <TextField
            label="Observação"
            name="obs"
            value={observacao}
            onChange={(e) => setObsercavao(e.target.value)}
            inputProps={{ maxLength: 150 }}
            multiline
          />
          <br />
          <Button
            color="success"
            disabled={vlrSaida <= 0.0}
            onClick={() => {
              confirmaSaida();
            }}
          >
            Confirmar
          </Button>
        </CardBody>
      </Card>
      <Snackbar
        place="bc"
        color={cor || "warning"}
        icon={AddAlert}
        message={mensagemErro}
        open={alerta}
        closeNotification={() => setAlerta(false)}
        close
      />
    </div>
  ) : (
    <Logout></Logout>
  );
}
